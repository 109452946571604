import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Mixin as VueMixinDecorator } from 'vue-mixin-decorator';
import { Message } from '@/shared/models';
import { Operation } from '@/models';
import { SimulationOperation } from '@/components/Wizard/Commun/models';
import { uuidv4 } from '@/shared/helpers';
import { AxiosResponse } from 'axios';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';

/**
 * Mixin regroupant les différentes méthodes de utilisées pour la gestion des étapes du wizard.
 * Pour avoir plus d'infos : https://stackoverflow.com/questions/57152971/how-to-create-and-extend-a-abstract-class-component-in-vuejs.
 * Classe de base contenant les infos de base pour les étapes d'un WorkFlow ou Wizard.
 */
@VueMixinDecorator
export default class WizardEtapeComponentBase extends Vue {
    [x: string]: any;

    public messages: Message[] = [];
    public loadingWizard: boolean = false;

    @Prop({ default: () => -1 }) public readonly tabIndex!: number;
    @Prop({ default: () => ({}) }) public readonly model!: { [x: string]: any };
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    @Prop({ default: null }) public readonly simulationDossierId!: number;
    @Prop() public readonly estDossier: boolean;

    public ['titre'](): string {
        throw new Error('Not Implemented');
    }

    public ['icone'](): string {
        throw new Error('Not Implemented');
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public ['validerForm'](): Promise<ResultatValidationEtape> {
        throw new Error('Not Implemented');
    }

    /**
     * Valide l'étape courant avant un changement d'étape.
     * Cette validation n'est pas nécessaire pour les dossiers.
     */
    public validerEtapeAsync(): Promise<boolean> {
        if (this.estDossier) {
            return Promise.resolve(true);
        }
        return new Promise<boolean>((resolve) => this.validerForm().then((result) => {
            resolve(result.estValide);
        }));
    }

    /**
     * Notifie le loader.
     *
     */
    public setLoadingWizard(value: boolean): void {
        this.loadingWizard = value;
        this.bus.$emit('on-loading', this.loadingWizard);
    }

    /**
     * Met à jour le message d'erreur..
     *
     */
    public setErrorMessage(messages: Message[]): void {
        this.messages = messages;
        this.bus.$emit('on-wizard-error', this.messages);
    }

    /**
     * Duplique une opération d'une simulation ou dossier.
     * @param estSimulation
     * @param simulationId
     * @param operationModel
     */
    public validerDuplicationOperationParcoursUtilisateur(
        parcoursUtilisateurId: number | null,
        operationModel: Operation): Promise<{ isValid: boolean, data: Operation }> {
        return this.ajouterOuDupliquerOperationParcoursUtilisateur(true, parcoursUtilisateurId, operationModel);
    }

    /**
     * Ajoute une opération à une simulation ou dossier si existe.
     * @param estSimulation
     * @param simulationId
     * @param operationModel
     */
    public ajouterOperationParcoursUtilisateur(
        parcoursUtilisateurId: number | null,
        operationModel: Operation): Promise<{ isValid: boolean, data: Operation }> {
        return this.ajouterOuDupliquerOperationParcoursUtilisateur(false, parcoursUtilisateurId, operationModel);
    }

    /**
     * Ajouter ou dupliquer une opération.
     * @param url Url de l'ajout ou de la duplication.
     * @param params Les params de la requête post si il y en a.
     * @param parcoursUtilisateurId L'identifiant de parcours utilisateur.
     * @param operationModel Le modèle de l'opération.
     */
    private ajouterOuDupliquerOperationParcoursUtilisateur(
        estDuplication: boolean,
        parcoursUtilisateurId: number | null,
        operationModel: Operation): Promise<{ isValid: boolean, data: Operation }> {
        return new Promise<{ isValid: boolean, data: Operation }>((resolve) => {
            // On retire le HTML présent dans l'objet POST car le WAF considère ça comme une attaque XSS (On ne peut pas POST du HTML sur la pprod et la prod).
            // Gestion des '<=' et '<' pour la BAT-TH-155
            if (operationModel) {
                const regex = /(?!<=\s+)(?!<\s+)(&nbsp;|<([^>]+)>)/ig;
                let operationModelStringified: string = JSON.stringify(operationModel);
                let operationModelStringifiedWithoutHtml: string = operationModelStringified.replace(regex, '');
                operationModel = JSON.parse(operationModelStringifiedWithoutHtml);
                delete (operationModel as any).listeEnumReferentiel;
                delete (operationModel as any).config;
            }
            // Si la simulation n'est pas définie, on fera juste l'ajout côté client. Mais on fait quand même un appel côté back pour récupérer certaines données.
            if (parcoursUtilisateurId === null || typeof parcoursUtilisateurId === 'undefined' || !parcoursUtilisateurId) {
                resolve({ isValid: true, data: operationModel });
            } else if (parcoursUtilisateurId && operationModel && operationModel.uuid && (!estDuplication || operationModel.id)) {
                // On fait l'ajout côté serveur.

                // Préparation de l'appel post.
                let promiseAxios: Promise<AxiosResponse<any>>;
                if (estDuplication) {
                    const url = `/simulationDossier/dupliquerOperation/${parcoursUtilisateurId}/${operationModel.id}`;
                    promiseAxios = this.$http.post(url);
                } else {
                    const url = `/simulationDossier/ajouterOperation/${parcoursUtilisateurId}`;
                    promiseAxios = this.$http.post(url, { ...operationModel, ...{ simulationDossierId: parcoursUtilisateurId } });
                }

                // Appel back.
                promiseAxios.then((result) => {
                    const data = result.data;
                    const isValid = data && !data.isError;
                    if (isValid) {
                        this.setErrorMessage(null);
                        resolve({ isValid, data: data.data as Operation });
                    } else {
                        resolve({ isValid: false, data: {} as Operation });
                    }
                }).finally(() => {
                    resolve({ isValid: false, data: {} as Operation });
                });
                //
            } else {
                resolve({ isValid: false, data: {} as Operation });
            }
        });
    }

    /**
     * Duplique une opération à partir d'une autre.
     *
     * @private
     * @memberof ResultatSimulation
     */
    public async dupliquerOperationParcoursUtilisateur(operation: Operation) {
        await this.faireUneActionSurOperation(
            operation,
            'Dupliquer une opération',
            `Confirmez-vous la duplication de l\'opération<br/>${operation.code}-${operation.natureDesTravaux} sur ce site ?`,
            `Duplication de l\'opération<br/>${operation.code}-${operation.natureDesTravaux}...`,
            { color: 'gray', width: 500, zIndex: 200 },
            ((resolve: ((value?: void | PromiseLike<void>) => void)) => {
                this.validerDuplicationOperationParcoursUtilisateur(this.model.simulationDossierId, operation)
                    .then((resultat: { isValid: boolean, data: Operation }) => {
                        if (resultat && resultat.isValid && resultat.data) {
                            // Fait l'ajout dans le tableau.
                            this.operations.push(resultat.data);
                            if (this.simulationConvention) {
                                this.simulationConvention.operations.push(resultat.data);
                            }

                            // Met à jour les opérations du parent.
                            this.bus.$emit('on-modification-nombre-operations', this.operations);
                        }
                    }).finally(() => resolve());
            }));
    }

    /**
     * Supprime une opération d'une simulation ou dossier.
     * @param operation Opération à supprimer.
     */
    public async supprimerOperationParcoursUtilisateur(operation: Operation) {
        await this.faireUneActionSurOperation(
            operation,
            'Supprimer une opération ?',
            `Confirmez-vous la suppression de l\'opération<br/><strong>${operation.code}-${operation.natureDesTravaux}</strong> sur ce site ?`,
            `Suppression de l\'opération<br/>${operation.code}-${operation.natureDesTravaux}...`,
            { color: 'red', width: 500, zIndex: 200 },
            ((resolve: ((value?: void | PromiseLike<void>) => void)) => {
                this.validerSuppressionOperationParcoursUtilisateur(this.model.simulationDossierId, operation).then((resultat: any) => {
                    if (resultat) {
                        // Fait la suppression dans le tableau.
                        const index = this.operations.findIndex((item: Operation) => item.uuid === operation.uuid);
                        if (index > -1) {
                            this.operations.splice(index, 1);
                        }

                        // Met à jour les opérations du parent.
                        this.bus.$emit('on-modification-nombre-operations', this.operations);
                        this.bus.$emit('on-suppression-operation', operation, true);
                    }
                }).finally(() => resolve());
            }));
    }

    /**
     * Supprime une opération d'une simulation ou dossier sans la popu de confirmation.
     * @param simulationDossierId Identifiant dossier ou simulation.
     * @param operation Opération à supprimer.
     */
    public supprimerOperationSansConfirmPopup( simulationDossierId: number, operation: Operation): Promise<void> {
        return new Promise<void>((resolve) => {
            this.validerSuppressionOperationParcoursUtilisateur(simulationDossierId, operation).then((resultat: any) => {
                if (resultat) {
                    // Fait la suppression dans le tableau.
                    const index = this.operations.findIndex((item: Operation) => item.uuid === operation.uuid);
                    if (index > -1) {
                        this.operations.splice(index, 1);
                    }

                    // Met à jour les opérations du parent.
                    this.bus.$emit('on-modification-nombre-operations', this.operations);
                    this.bus.$emit('on-suppression-operation', operation);
                    // Fais le resolve
                    resolve();
                }
            });
        });
    }

    /**
     * Retourner template d'opération et ajoute à la simulation ou dossier si besoin.
     * @param estSimulation
     * @param simulationId
     * @param operations
     */
    public async retournerTemplateOperation(
        faireAjout: boolean,
        parcoursUtilisateurId: number | null,
        operations: Operation[]): Promise<Operation[]> {
        //
            let bufferConfig = new Map();
            let bufferOperation: Operation[] = [];

            for (let i = 0; i < operations.length; i++) {
                let operation = operations[i];
                if (bufferConfig.get(operation.templateOperationId)) {
                    bufferOperation.push(this.loadTemplate(operation, bufferConfig.get(operation.templateOperationId)));
                }
                else {
                    const result = await this.$http.get(`/templateOperations/${operation.templateOperationId}/config`);
                    bufferConfig.set(operation.templateOperationId, result);
                    bufferOperation.push(this.loadTemplate(operation, result));              
                }
            }
                   
            const temp = [...bufferOperation];

            // Retourne.
            return new Promise<Operation[]>((resolve) => {
                Promise.all(temp).then((reponse: Operation[]) => {
                    Promise.all([
                        ...reponse.map((item) => {
                            return new Promise<Operation>((resolveInternal) => {
                                if (faireAjout) {
                                    this.ajouterOperationParcoursUtilisateur(parcoursUtilisateurId, item)
                                        .then((result: { isValid: boolean, data: Operation }) => {
                                            const tmpData = result.data;
                                            tmpData.config = item.config;
                                            tmpData.code = item.code;
                                            tmpData.natureDesTravaux = item.natureDesTravaux;
                                            // Si ne contient pas de documents.
                                            if (!(tmpData.typeDocumentTemplateOperations && tmpData.typeDocumentTemplateOperations.length>=1)) {
                                                tmpData.typeDocumentTemplateOperations = item.typeDocumentTemplateOperations || [];
                                            }
                                            resolveInternal(tmpData);
                                        });
                                } else {
                                    resolveInternal(item);
                                }
                            });
                        })
                    ]).then((operationsArray: Operation[]) => resolve(operationsArray));
                });
            });
        //
    }


    private loadTemplate (operation: Operation, template: any): Operation {
        const { data: { data: { formConfig: config } } } = template;
        const { data: { data: { typeDocumentTemplateOperations: typeDocumentTemplateOperations } } } = template;
        // Construit le résultat.
        const composedResult = {
            ...new Operation(),
            ...operation,
            ...{
                // Copie les informations.
                code: config.templateOperationCode,
                natureDesTravaux: config.templateOperationLibelle,
            },
            ...{ uuid: operation.uuid || uuidv4() },
            ...config,
            config,
            typeDocumentTemplateOperations,
        } as Operation;

        return composedResult;
    }

    /**
     * Récupération des simulations à partir de son Id.
     *
     * @private
     * @memberof ResultatSimulation
     */
    protected recupererSimulationDossier(simulationDossierId: number): Promise<SimulationOperation> {
        return new Promise<SimulationOperation>((resolve) => {
            this.$http.get(`/simulationDossier/recupererSimulationDossier/${simulationDossierId}`).then((result: any) => {
                const simulation = result.data.data as SimulationOperation;
                resolve(simulation);
            });
        });
    }

    /**
     * Supprime une opération d'une simulation ou d'un dossier après la confirmation de l'utilisateur.
     * *@param estSimulation Détermine le type de parcours.
     * @param parcoursUtilisateurId Supprime également l'opération côté serveur si la simulation est déjà enregistrée.
     * @param operation Opération à supprimer.
     */
    protected validerSuppressionOperationParcoursUtilisateur(parcoursUtilisateurId: number | null, operation: Operation): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            // Si la simulation n'est pas définie, on fera juste la suppression côté client
            if (parcoursUtilisateurId === null || typeof parcoursUtilisateurId === 'undefined' || !parcoursUtilisateurId) {
                // On doit attendre un minimum, sinon la popup ne catche pas le résultat et l'overlay reste...
                setTimeout(() => resolve(true), 500);
            } else if (parcoursUtilisateurId && operation && operation.uuid && operation.id) {
                // On fait la suppression côté serveur.
                const url = `/simulationDossier/supprimerOperation/${parcoursUtilisateurId}`;
                // Appel AJAX.
                this.$http.delete(`${url}/${operation.id}`).then((result) => {
                    const data = result.data;
                    const isValid = data && !data.isError;
                    if (isValid) {
                        this.setErrorMessage(null);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((error) => {
                    resolve(false);
                }).finally(() => {
                    resolve(false);
                });
                //
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Fait une action spécifique sur l'opération en fonction de la lambda en paramètre.
     *
     * @param operation Opération à affecter.
     * @param title Titre de la popup.
     * @param message Message à afficher pour confirmation.
     * @param messageDialog Message à afficher après confirmation.
     * @param options Options d'affichage.
     * @param callback Action à exécuter par la suite.
     */
    protected async faireUneActionSurOperation(
        operation: Operation,
        title: string,
        message: string,
        messageDialog: string,
        options: { color: string, width: number, zIndex: number },
        callback: ((resolve: ((value?: void | PromiseLike<void>) => void)) => void),
    ) {
        if (operation) {

            // Force le type sans déclarer les $refs pour éviter le conflit avec le CommonMixin.
            const refs = this.$refs as any;
            if (await refs.confirm.open(title, message, options)) {
                refs.dialogLoader.start(messageDialog, null, () => {
                    return new Promise((resolve) => callback(resolve));
                }, { enabled: false });
            }
        }
    }
}
