import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * MIXIN regroupant les différentes méthodes utilisées pour valider le composant Documents.
 */
@Component
export default class DocumentsValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin) {

    // Liste des rules pour les documents à valider.
    public documentsRules: any = [        
        (value: string | any) => {
            return !!value
            || (value && value.size < 10000000)
            || 'Merci de choisir un fichier pdf ou image. La taille du fichier doit être inférieure à 10 Mo.';
        },       
    ];

}