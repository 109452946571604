/**
 * Énumération des étapes de simulation.
 */
export enum EtapeParcoursSimulation {
    ChoixSecteurEtOperation = 1,
    Operation = 2,
    ChoixBeneficiareOuInstallateur = 3,
    SiteDesTravaux = 4,
    DonneesBeneficiaire = 5,
    DonneesInstallateur = 6,
    ResultatSimulation = 7,
    Valorisation = 8

}
