import { Operation } from '@/models';
import { StatutSimulation } from '@/shared/enums';

export class SimulationOperation {

    /**
     * Statut de la simulation.
     */
    public statutSimulationId: StatutSimulation;

    /**
     * La liste des opérations.
     *
     * @type {Array<Operation>[]}
     * @memberof Simulation
     */
    public operations: Operation[];
}
