import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant DonneesBeneficiaire.
 */
@Component
export default class DonneesBeneficiaireValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {

    public typeBeneficiaireRules = [
        (v: number | any) => this.required(v) && v > 0 || 'Le type bénéficiaire est obligatoire',
    ];

    public nbBailleurSociauxRules = [
        (v: number | any) => this.required(v) && v >= 0 || 'Le nombre de bailleurs sociaux est obligatoire',
        (v: number | any) => this.isNumeric(v) || 'Le nombre de bailleurs sociaux est invalide',
    ];
}

