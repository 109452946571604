


































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TypeDeWizardComposant } from '@/components/Wizard/Composants';
import { Profils } from '@/shared/enums';

@Component({
    name: 'RubriqueAide',
})
export default class RubriqueAide extends Vue {
    @Prop()
    public typeOnglet: TypeDeWizardComposant;
    @Prop()
    public profilCurrentUser: string;
    @Prop({ default: false })
    public estSoumisPolitiqueControle: boolean;
    @Prop({ default: false })
    public prixFixeContrat: boolean;
    public TypeDeWizardComposant: any = TypeDeWizardComposant;
    public profile: any = Profils;
}
