



































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';

import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { DataTableHeader } from '@/shared/models';

/**
 * Composant pour afficher les infos historisées d'une opération dans un tableau.
 *
 * */
@Component({
    name: 'GestionOperationHistorique',
    components: {
        CeeDatePicker,
    },
})
export default class GestionOperationHistorique extends Vue {

    // Propriétés de l'opération à afficher.
    @Prop() public readonly formData!: object;

    // Elements de la grille.
    public items = new Array<{ code: string, valeur: string}>();

    // Colonnes du tableau.
    public colonnes: DataTableHeader[] = [
        { text: 'Nom du champ', value: 'code' },
        { text: 'Valeur', value: 'valeur' },
    ];

    // Volume de l'opération. 
    @Prop() public readonly volumeCeeClassique!: number;

    // Date engagement des travaux.
    @Prop() public readonly dateEngagementTravaux: string;

    // Date de fin des travaux.
    @Prop() public readonly dateFinTravaux: string;

    // Charge l'opération historique.
    public mounted() {
        if (this.formData) {
            Object.keys(this.formData).forEach((key) => {
                this.items.push({ code: (<any>this.formData)[key].fieldName, valeur: (<any>this.formData)[key].value })
            });
        }
    }
}
