import Vue from 'vue';
import { Getter, } from 'vuex-class';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import { Profils, StatutSimulation, MessageLevel } from '@/shared/enums';
import { Operation, Societe } from '@/models';

import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';

import template from './ChoixBeneficiareOuInstallateur.Template.vue';
import { ApiService } from '@/services/base/ApiService';
import { BaseCommonApiHelper } from '@/services/BaseCommonApiHelper';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { ClientRepresente } from '@/models/ClientRepresente.model';
import { EtapeParcoursSimulation } from '@/shared/enums/EtapeSimulation';
import ChoixBeneficiareOuInstallateurValidator from './ChoixBeneficiareOuInstallateur.Validator';

import { getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle, Message } from '@/shared/models';
import { ArrayHelper } from '@/shared/helpers/ArrayHelper';
import { UserProfile } from '@/store/modules/auth/types';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';

enum For {
    Installateur,
    Beneficiaire,
}

interface IMixinInterface extends CommonMixin, WizardEtapeComponentBase, Vue, ChoixBeneficiareOuInstallateurValidator { }

@Component({
    ...template,
    ref: 'ChoixBeneficiareOuInstallateur',
    name: 'ChoixBeneficiareOuInstallateur',
    mixins: [template, CommonMixin, WizardEtapeComponentBase],
})
export default class ChoixBeneficiareOuInstallateur extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentBase,
    ChoixBeneficiareOuInstallateurValidator) implements IWizardEtapeComponent {
    [x: string]: any;

    public $refs!: {
        form: HTMLFormElement,
    };
    // Promesse pour récupérer l'utilisateur.
    @Getter(AuthStoreMethods.USER)
    public getUser: Promise<any>;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    // Liste des sociétés de l'utilisateur.
    public societes: Array<Societe & {accordCadreId: number}> = new Array<Societe & {accordCadreId: number}>();

    // Société sélectionnée.
    public selectedSociete: number = null;

    // Information (Raison sociale et SIRET) de la 1ère sociétés (ou la seule) de l'utilisateur.
    public societe: string | null = null;

    // Code du profil de l'utilisateur.
    public profilCode: string = null;

    // Type de client (utile uniquement pour les apporteurs d'affaires).
    public clientRepresenteId: number | null = null;

    public createurOuUserCourant: {profil: Profils, estInterne: boolean, email: string} = {profil: Profils.None, estInterne: false, email: ''};

    /**
     * Permet de choisir un client uniquement dans le cas ou aucun client n'a pas encore été choisi et sauvegardé dans la base.
     * Une fois cette étape sauvegardée, le choix du client doit être en lecture seule.
     * @type {boolean}
     * @memberof ChoixBeneficiareOuInstallateur
     */
    public changementChoixPossible: boolean = true;

    /**
     * Le mapping entre un secteur de la BDD et l'affichage
     *
     * @type { SecteurReferentiel[] }
     * @memberof ChoixSecteurEtOperation
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.ClientRepresente))
    public clientsRepresentes: ClientRepresente[];

    // Liste des icônes par type de client.
    public iconesClient = [{ 'fa fa-user-friends': true }, { 'fa fa-hard-hat': true }];

    // Titre de l'étape qui change selon le profil de l'utilisateur.
    public titreChoixSociete: string;

    public typeClient: Array<{
        for: For,
        value: number,
    }> = new Array<{
        for: For,
        value: number,
    }>();

    public title: string = '';
    public loading: boolean = false;
    public titre(): string { return this.profilCode === Profils.ApporteurAffaires ? 'Choix du client' : 'Choix de la société'; }
    public icone(): string { return 'fas fa-network-wired'; }

    public get urlSauvegarde(): string {
        if (this.simulationDossierId === null || typeof this.simulationDossierId === 'undefined' || !this.simulationDossierId) {
            return 'ajouterEtapeBeneficiareOuInstallateur';
        } else {
            return 'updateEtapeBeneficiareOuInstallateur';
        }
    }

    /**
     * Retourne l'identifiant du template d'opération.
     *
     */
    public get templateOperationId(): number | null {
        return this.model.templateOperationId;
    }

    /**
     * Retourne l'identifiant du secteur.
     *
     */
    public get secteurId(): number | null {
        return this.model.secteurId;
    }

    /**
     * Retourne l'identifiant de l'opération.
     *
     */
    public get operationId(): number | null {
        return this.model.operationId;
    }
    /**
     *
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.setLoadingWizard(true);
    }
    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        let element: HTMLElement = null;
        this.recupererInfosSimulationEtProfil().then(() => {
            if (!this.createurOuUserCourant.estInterne) {
                if (this.createurOuUserCourant.profil === Profils.ApporteurAffaires) {
                    this.getValeursReferentielles(TypeValeurReferentielle.ClientRepresente);
                } else {
                    this.obtenirSocietesUtilisateur().then((items) => {
                            this.societes = items.map((item) => ({
                                accordCadreId: null,
                                ...item
                            }));
                        }).finally(() => {
                            BaseCommonApiHelper.obtenirSocietesAffiliees().then((societesAffiliees) => {
                                const societesAffilieesAvecAcId = societesAffiliees.map((item) => ({
                                    accordCadreId: 0,
                                    ...item
                                }));
                                this.societes.push(...societesAffilieesAvecAcId);
                            }).finally(() => {
                                this.societes = [...ArrayHelper.remove_duplicate<Societe & {accordCadreId: number}>(this.societes)];
                                if (this.societes.length === 1 && this.profilCode !== Profils.ApporteurAffaires) {
                                    this.selectedSociete = this.societes[0].id;
                                }
                                });
                            }).finally(() => {
                                    this.setLoadingWizard(false);
                            });
                }
            }
        })
        .then(() => {
            this.setLoadingWizard(false);
        })
        .finally(() => {
            if (this.createurOuUserCourant.estInterne) {
                element = this.getHtmlButtonElement(this.model.direction);
                element.click();
                return;
            }
        });
    }

    /**
     * Valide l'étape et passe à l'étape suivante.
     *
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        return new Promise<ResultatValidationEtape>((resolve, reject) => {
            if (this.$refs.form.validate()) {
                this.$http.post(`/simulation/${this.urlSauvegarde}`, this.construireModele()).then((result) => {
                    const data = result.data;
                    const isValid = data && !data.isError;
                    if (isValid) {
                        // Transmet la mise à jour de l'identifiant de simulation.
                        this.bus.$emit('on-step-informations-simulation-dossier', { simulationDossierId: data.data.id });
                        // On doit aussi mettre à jour les opérations parce que le post a créé des ids.
                        this.bus.$emit('on-step-informations-operations', data.data.operations);
                        this.setErrorMessage(null);
                        this.changerStatutSimulation(data.data.id, StatutSimulation.EN_SIMULATION, EtapeParcoursSimulation.SiteDesTravaux)
                        .then(() => {
                            resolve(new ResultatValidationEtape(true, true));
                        });
                    } else {
                        resolve(new ResultatValidationEtape(false, false));
                    }
                })
                .catch((error) => {
                    reject(error);
                    resolve(new ResultatValidationEtape(false, false));
                });
            } else {
                resolve(new ResultatValidationEtape(false, false));
            }
        });
    }

    /**
     * Construction du modèle a envoyer lors de la validation.
     *
     * @returns
     * @memberof ChoixBeneficiareOuInstallateur
     */
    public construireModele() {
        const searchSociete = this.societes.find((societe) => societe.id === this.selectedSociete);
        const model = {
                accordCadreId: !!searchSociete ? searchSociete.accordCadreId : null,
                simulationId: this.model.simulationDossierId,
                SimulationDossierId: this.model.simulationDossierId,
                operations: (this.model.operations as Operation[]).map((o) => {
                    o.config = null;
                    o.sections = null;
                    o.nomDepot = null;
                    return o;
                }),
                clientRepresenteId: this.clientRepresenteId,
                typeInstallateurId: this.typeClient.find((tc) => tc.for === For.Installateur).value,
                typeBeneficiaireId: this.typeClient.find((tc) => tc.for === For.Beneficiaire).value,
        };
        if (this.createurOuUserCourant.profil === Profils.Installateur) {
            Object.assign(model, {societeInstallateurId: this.selectedSociete});
        } else if (this.createurOuUserCourant.profil === Profils.Entreprise || this.createurOuUserCourant.profil === Profils.SyndicSDC) {
            Object.assign(model, {societeBeneficiaireId: this.selectedSociete});
        }
        return model;
    }

    /**
     * Récupération des sociétés de l'utilisateur connecté.
     *
     */
    public obtenirSocietesUtilisateur(): Promise<Societe[]> {
        return new Promise<Societe[]>((resolve, reject) => {
            const societeService = new ApiService<Societe>('societe/obtenirSocietesUtilisateur');
            societeService.getWhere(`email=${this.createurOuUserCourant.email}`).then((response) => {
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }


    /**
     * Récupérer les infos du créateur ou profil courant et les infos de la simulation en mode modification.
     *
     * @returns {Promise<void>}
     */
    private recupererInfosSimulationEtProfil(): Promise<void> {
        return new Promise<void>((resolve, reject) =>  {
            // Faire un appel back de récupération si on est en mode modification.
            if (!!this.simulationDossierId) {
                return this.obtenirSimulationById()
                .then(() => resolve())
                .catch((error: string) => {
                    this.setErrorMessage([{text: error, messageLevel: MessageLevel.Error} as Message]);
                });
            // A défaut initialiser des infos a null et l'utilisateur courant.
            } else {
                this.typeClient.push({for: For.Beneficiaire, value: null});
                this.typeClient.push({for: For.Installateur, value: null});
                return this.obtenirProfilUtilisateur()
                .then(() => resolve())
                .catch((error: string) => {
                    this.setErrorMessage([{text: error, messageLevel: MessageLevel.Error} as Message]);
                });
            }
        }).finally(() => {
            if (this.createurOuUserCourant.profil === Profils.ApporteurAffaires) {
                this.titreChoixSociete = 'Installateur des travaux';
            } else {
                this.titreChoixSociete = 'Sélectionnez une société';
            }
        });
    }

    /**
     * Récupérer infos simulation.
     *
     * @returns {Promise<void>}
     */
    public obtenirSimulationById(): Promise<void> {
        return new Promise((resolve, reject) =>  {
            return this.$http.get(`/simulation/recupererSimulationEtape3/${this.simulationDossierId}`).then((result: any) => {
                // Infos simulation.
                const profilCode = result.data.data.utilisateurCreation.profilUtilisateur as Profils;
                this.clientRepresenteId = result.data.data.clientRepresenteId;
                this.typeClient.push({for: For.Beneficiaire, value: result.data.data.typeInstallateurId});
                this.typeClient.push({for: For.Installateur, value: result.data.data.typeBeneficiaireId});
                this.changementChoixPossible = !result.data.data.clientRepresenteId;

                // Infos profil.
                this.createurOuUserCourant = { profil: profilCode, estInterne: this.estInterne(profilCode), email: result.data.data.utilisateurCreation.email};

                // Infos société (utilisateur Externe hors AA).
                if (this.createurOuUserCourant.profil === Profils.Installateur) {
                    this.selectedSociete = result.data.data.societeInstallateurId;
                } else if (this.createurOuUserCourant.profil === Profils.Entreprise || this.profilCode === Profils.SyndicSDC) {
                    this.selectedSociete = result.data.data.societeBeneficiaireId;
                }
                resolve();
            })
            .catch(() => {
                    reject('Une erreur est survenue lors de la récupération des données de la simulation');
            });
        });
    }

    /**
     * Récupération du code de profil de l'utilisateur connecté.
     *
     */
    public obtenirProfilUtilisateur(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
                this.getUserProfile.then((user: UserProfile) => {
                    if (!!user) {
                        this.createurOuUserCourant = {profil: user.profilCode as Profils, estInterne: user.isInterne, email: user.email };
                        resolve();
                    } else {
                        reject('Utilisateur non trouvé.');
                    }
                });
        });
    }

    /**
     * Changement du statut de la simulation.
     *
     * @private
     * @memberof Valorisation
     */
    public changerStatutSimulation(simulationDossierId: number, statut: StatutSimulation, etape: EtapeParcoursSimulation): Promise<void> {
        this.loading = true;
        return new Promise((resolve, reject) =>  {
            return this.$http.post(`/simulation/modifierStatutSimulation`,
                {Id: simulationDossierId, StatutSimulationId: statut, etapeParcoursUtilisateurId: etape, IntituleDossier: ''}
            ).then(() => {this.loading = false;
                          resolve();
            })
            .catch(
                () => {
                    throw new Error('Une erreur est survenue lors de la modification du statut de la simulation');
            });
        });
    }

    /**
     * Est utilisateur résolu interne ?
     *
     * @private
     * @param {Profils} profilCode
     */
    private estInterne(profilCode: Profils): boolean {
        return profilCode  === Profils.AdministrateurCEE || profilCode  === Profils.AdministrateurInformatique
        || profilCode  === Profils.GestionnaireBO;
    }

    /**
     * Récupérer bouton précédent/suivant.
     * @returns {HTMLElement}
     */
    public getHtmlButtonElement(index: number): HTMLElement {
        return  document.getElementsByClassName('wizard-btn')[index] as HTMLElement;
    }
}
