



































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Societe, Civilite } from '@/models';
import { CompteValidationMixin } from '@/shared/mixins';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { ProfilsInternes } from '@/shared/enums';

@Component({
    name: 'SocieteSousTraitanteForm'
})
export default class SocieteSousTraitanteForm extends Mixins(CompteValidationMixin) {
    public profil: string = '';
    public estInterne: boolean = false;
    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;
    @Prop({ default: new Societe() })
    private readonly societe!: Societe;
    @Prop({ default: () => new Array<Civilite>() })
    private civilite: Civilite[];
    @Prop({ default: false })
    private isDisabled: boolean;

    // Pour savoir si on affiche le titre ou pas.
    @Prop({ default: true })
    private showTitle!: boolean;

    // Champ requis.
    @Prop({ default: true })
    private estRequis: boolean;

    constructor() {
        super();
    }
    public created() {

        if(!this.societe.societeSousTraitante)
        {
            // Initialisation de la société sous traitante.
            this.societe.societeSousTraitante = new Societe();
            this.societe.societeSousTraitante.adresse = null;
            // Forme juridique par défaut pour les sous-traitantes
            this.societe.societeSousTraitante.formeJuridiqueId = 13;
        }

        this.getUserProfile.then((userProfile) => {
            this.profil = userProfile.profilCode;
            this.estInterne = Object.keys(ProfilsInternes).some((key: string) => key === this.profil);
        });
    }

    /**
     * Définition de la bonne règle à appliquer sur le champ siret.
     */
    public get rulesSiret() {
        if (this.estInterne) {
            return this.siretRulesInterne;
        }
        return this.siretRules;
    }
}
