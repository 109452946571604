





















































































































import Vue from 'vue';
import { Prop, ProvideReactive } from 'vue-property-decorator';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import FormBuilder from '@/formsGenerator/FormBuilder.vue';
import { TemplateTypeBlocReferentiel, TemplateTypeChampReferentiel, Operation as OperationModel } from '@/models';
import { CommonMixin } from '@/shared/mixins';
import { SurfacesControle } from '@/shared/enums';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import OperationValidator from '@/components/Wizard/Commun/Operation/Operation.Validator';

//  Créer une interface pour fusionner les mix ins.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentBase, Vue { }
@Component({
    name: 'OperationElement',
    components: {
        FormBuilder,
        CeeDatePicker,
    },
    inject: []
})
export default class OperationElement extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentBase, OperationValidator) implements IWizardEtapeComponent {

    public $refs!: Vue['$refs'] & {          
        datePreviFormRef: HTMLFormElement,  
    };

    @Prop({}) public readonly formConfig!: { [key: string]: any };
    @Prop({}) public readonly formData!: object;
    @Prop({ default: false }) public readonly hasManyOperations: boolean;
    @Prop({ default: false }) public readonly estDossier: boolean;
    public loading = false;
    public loadingConfig = false;


    public sections: TemplateTypeBlocReferentiel[] = new Array<TemplateTypeBlocReferentiel>();
    public champs: TemplateTypeChampReferentiel[] = new Array<TemplateTypeChampReferentiel>();
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;

    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];
    /**
     * Affiche un indicateur de chargement si le calcul est en cours.
     */
    public calculEnCours = false;

    /**
     * Opération affichée dans ce composant, nécessaire pour certains éléments du calcul.
     */
    @Prop({ default: () => new OperationModel() }) public operation!: OperationModel;

    /**
     * Calcul opérations affichage ou non des messages d'erreurs.
     */
    @Prop({ default: '' }) public dateRai!: string;

    @ProvideReactive('dateRai')
    public get dateRaiBis() {
        return this.dateRai;
    }

    @ProvideReactive('dateDebutEngagement')
    public get dateDebutEngagement(): string {
        return this.operation ? this.operation.dateDebutTravaux : '';
    }

    @ProvideReactive('idOperation')
    public get idOperation(): number {
        return this.operation.id;
    }

    /**
     * Retour du calcul de l'opération.
     */
    public reponseCalculOperation: { data: OperationModel; isError: boolean; messages: string[] } = {
        data: new OperationModel(),
        isError: false,
        messages: new Array<string>(),
    };
    public formPreviewProps: { [key: string]: any } = {};
    public dialogConditionEligibilite = false;

    /**
     * Constructeur.
     */
    constructor() {
        super();
    }

    /**
     * Met à jour le résultat du calcul.
     * @param reponse
     */
    public metAJourResultatCalcul(data: { data: OperationModel; isError: boolean; messages: string[] }) {
        this.$emit('onCalculTermine', data.data);
        this.reponseCalculOperation = data;
        this.calculEnCours = false;
    }

    /**
     * Récupère le volume de CEE précarité, sans les unités.
     * @returns Volume de CEE précarité, sans les unités.
     */
    public get volumeCeePrecarite(): number {
        return this.reponseCalculOperation.data.volumeCeePrecarite;
    }
    /**
     * Récupère le résultat du volume classique.
     * @returns Volume de CEE classique, avec les unités.
     */
    public get volumeEtUniteCeeClassique(): string {
        return this.reponseCalculOperation.data.volumeEtUniteCeeClassique;
    }
    /**
     * Récupère le résultat du volume précarité.
     * @returns Volume de CEE précarité, avec les unités.
     */
    public get volumeEtUniteCeePrecarite(): string {
        return this.reponseCalculOperation.data.volumeEtUniteCeePrecarite;
    }

    public handleUpdateDonnees(elt: any) {
        if (Object.values(SurfacesControle).includes((elt as any).fieldName)) {                            
            if ((elt.value) !== this.operation.operationValues[elt.dataIndex].value) {
                this.operation.operationValues[elt.dataIndex].value = elt.value;
                this.$emit('updateDonneesSaisies', elt);
            }               
        }
        else {                
            this.$emit('updateDonneesSaisies', elt);
        }            
    }
}

