





















































































































































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { DateHelper } from '@/shared/helpers/DateHelper';
import { Operation as OperationModel } from '@/models';
import { StatutCommercialOperation, EtatTraitementOperation } from '@/shared/enums';
import { Profils } from '@/shared/enums';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { ValeurReferentielle } from '@/shared/models';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { UserProfile } from '@/store/modules/auth/types';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';

/**
 * Composant pour afficher les infos historisées d'une opération dans un tableau.
 *
 * */
@Component({
    name: 'HistoriqueOperation',
    components: {
        CeeDatePicker,
    },
})
export default class HistoriqueOperation extends Vue {

    // Désactivation de tous les champs du tableau si "true".
    @Prop() public readonly disabled: boolean;

    @Prop()
    public referentielsEtapes: DossierReferentiels;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    // Référentiel StatutsCommercial     
    public statutsCommercial: ValeurReferentielle[] = [];
    // Référentiel EtatTraitement     
    public etatsTraitement: ValeurReferentielle[] = [];
    // Référentiel StatutsPNCEE    
    public statutsPncee: ValeurReferentielle[] = [];
    // Référentiel AbandonRefus.
    public listeAbandonRefus: ValeurReferentielle[];
 
    /**
     * L'opération.
     */
    @Prop({ default: new OperationModel() })
    public readonly operation: OperationModel;

    @Prop({ default: (null) })
    public readonly simulationDossierId: number;

    /**
     * Indique si l'utilisateur connecté est un administrateur.
     */
    public estAdministrateur: boolean = false;

    // Permet de faire en sorte que le template ait accès à l'énumération.
    public Profils = Profils;
    // Permet de faire en sorte que le template ait accès à l'énumération.
    public StatutCommercialOperations = StatutCommercialOperation;
    public EtatTraitementOperations = EtatTraitementOperation;
    /**
     * Retourne le libelle du StatutCommercial sélectionné..
     */
    public get libelleStatutCommercial(): string | undefined | null {
        const selectObject = this.statutsCommercial.find((item: { id: number }) => {
            return item.id === this.operation.statutCommercialId;
        }) as { libelle: string };
        if (selectObject) {
            return selectObject.libelle;
        }
        return null;
    }
    /**
     * Retourne le libelle du EtatTraitement sélectionné..
     */
    public get libelleEtatTraitement(): string | undefined | null {
        const selectObject = this.etatsTraitement.find((item: { id: number }) => {
            return item.id === this.operation.etatTraitementId;
        }) as { libelle: string };
        if (selectObject) {
            return selectObject.libelle;
        }
        return null;
    }
    /**
     * Retourne le libelle du StatutPncee sélectionné..
     */
    public get libelleStatutPncee(): string | undefined | null {
        const selectObject = this.statutsPncee.find((item: { id: number }) => {
            return item.id === this.operation.statutPnceeId;
        }) as { libelle: string };
        if (selectObject) {
            return selectObject.libelle;
        }
        return null;
    }
    /**
     * Date de finalisation de l'opération par ENGIE.
     *
     * @type {string}
     * @memberof Operation
     */
    public get dateFinalisationBO(): string | null {
        // Gestion Date Finalisation.
        this.operation.dateFinalisationBO = this.operation.dateFinalisationBO || '';
        //
        if (!(this.operation.statutCommercialId === StatutCommercialOperation.ABANDONNE_REFUSE_ENGIE)) {
            this.operation.actionAPayer = false;
            this.operation.abandonRefusId = null;
        }
        // Retourne le résultat.
        return this.operation.dateFinalisationBO;
    }
    /**
     * Date d’aujourd’hui au format ISO.
     */
    public get aujourdhui(): string {
        return DateHelper.todayIso();
    }
    /**
     * Récupère la date au format FR.
     * @param date La date.
     */
    public formatDate(date: string): string {
        return DateHelper.format(date);
    }
    /**
     * Récupère la date au format FR avec l'heure.
     * @param date La date avec l'heure.
     */
    public formatDateParam(date: string, format: string): string {
        return DateHelper.formatParam(date, format);
    }

    public created(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdministrateur = profile.isAdmin;
            }
        });
        this.statutsPncee = this.referentielsEtapes.statutPncee;
        this.listeAbandonRefus = this.referentielsEtapes.abandonRefus;
        this.statutsCommercial = this.referentielsEtapes.statutCommercialOperation;
        this.etatsTraitement = this.referentielsEtapes.etatTraitementOperation;
    }
}
