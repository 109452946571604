import Vue from 'vue';
import { Mixin as VueMixinDecorator, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { Dossier, Paiement, SimulationDossier } from '@/models';
import { TypeDeWizardComposant } from '@/components/Wizard/Composants';
import WizardEtapeComponentBase from './WizardEtapeComponentBase';
import { ApiService } from '@/services/base/ApiService';
import { Message } from '@/shared/models';
import { cloneDeep, isEqual, omit } from 'lodash-es';
import { Watch, Prop } from 'vue-property-decorator';
import { Notifications } from '@/shared/Notifications';
import { AxiosResponse } from 'axios';
import { Getter, Action, Mutation } from 'vuex-class';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import { TableauDeBordStoreMethods } from '../../../store/modules/TableauDeBord/TableauDeBordStore';

class SingletonClassDossier {
    private static _instance: SingletonClassDossier = new SingletonClassDossier();

    private _dossier: Dossier = new Dossier();

    constructor() {
        if (SingletonClassDossier._instance) {
            throw new Error('Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.');
        }
        SingletonClassDossier._instance = this;
    }

    public static getInstance(): SingletonClassDossier {
        return SingletonClassDossier._instance;
    }

    public getDossier(): Dossier {
        return this._dossier;
    }

    public setDossier(dossier: Dossier) {
        this._dossier = Object.assign(this._dossier, dossier);
    }
}
const dossierManager = SingletonClassDossier.getInstance();

//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends WizardEtapeComponentBase, Vue { }

// tslint:disable-next-line:max-classes-per-file
@VueMixinDecorator
export default class WizardEtapeComponentsDossier extends VueMixinsDecorator<IMixinInterface>(WizardEtapeComponentBase) {

    // Récupère la liste des étapes déjà complétées.
    @Prop({ default: null })
    public etapesCompletes: WizardEtapeComponentsDossier[];

    @Getter(InformationsSimulationDossierStoreMethods.NOTES)
    public notes: any[];

    @Getter(InformationsSimulationDossierStoreMethods.REFERENCE)
    public intituleDossier: string;

    // Récupération du statut du dossier.
    @Action(InformationsSimulationDossierStoreMethods.RECUPERER_INFORMATIONS)
    public recupererStatutDossier: (simulationDossierId: number) => Promise<{ id: number, intituleDossier: string }>;

    // Set loading étape.
    @Mutation(InformationsSimulationDossierStoreMethods.SET_LOADING_ETAPE)
    public setLoadingEtape: (loadingEtape: string) => void;

    // Unset loading étape.
    @Mutation(InformationsSimulationDossierStoreMethods.UNSET_LOADING_ETAPE)
    public unsetLoadingEtape: (loadingEtape: String) => void;

    // Permet se récupérer le profil de l'utilisateur connecté.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;


    @Mutation(TableauDeBordStoreMethods.UPDATE_CONSULTED_DOSSIERS)
    public updateConsultedDossier: (simulationDossier: SimulationDossier) => void;

    // L'utilisateur est-il interne ou externe.    
    public isInterne: boolean = false;

    // Constructeur des parents.
    constructor() {
        super();
    }

    // Définit les observeurs si on doit observer un objet complexe.
    public created() {
        const donnees = this.getModeleDonnees();
        if (donnees) {
            Object.keys(donnees).forEach((propriete) => {
                this.$watch(propriete, this.majModeleDonnees);
            });
        }

        this.getUserProfile.then((profil) => {
            this.isInterne = profil && profil.isInterne;
        });
    }

    /**
     * Exporter le modèle des étapes à redéfinir sur les composants du dossier.
     *
     * @returns {{model: any, meta: any }}
     * @memberof WizardEtapeComponentsDossier
     */
    public ['exporterEtapeModel'](): { model: any, meta: any } {
        throw new Error('Not Implemented');
    }

    /**
     * Définit le modèle de données sur lequel observer les changements.
     * Remarque : fonction à surcharger dans les cas simples (un seul objet à observer).
     */
    public get modeleDonnees(): any {
        if (this.donnees) {
            return this.donnees;
        }
        return null;
    }
    protected modeleDonneesInitial: any = null;

    /**
     * Dans les cas plus complexes, définit
     * Remarque : fonction à surcharger dans les cas complexes (dictionnaire d'objets à observer).
     */
    public getModeleDonnees(): { [propriete: string]: any } { return {} }
    protected donnees: any = null;

    // Mise à jour de toutes les données d'export quand on modifie l'une d'entre elles.
    protected majModeleDonnees(): void {

        // Affecte l'objet la première fois.
        const donnees = this.getModeleDonnees();
        if (this.donnees === null) {
            this.donnees = donnees;
        }

        // Sinon, copie les propriétés de l'objet au lieu de le réaffecter pour éviter de réinitialiser la validation.
        else {
            Object.assign(this.donnees, donnees);
        }
    }

    /**
     * Détermine si l'étape a été modifiée depuis le dernier enregistrement.
     */
    public get estModifiee(): boolean {
        return !isEqual(this.modeleDonnees, this.modeleDonneesInitial);
    }

    /**
     * Repasse l'étape à l'état initial après enregistrement.
     * @param forcerRefresh Force le modèle à se rafraîchir même s'il n'était pas nul.
     */
    @Watch('modeleDonnees', { immediate: true })
    public rafraichirEtape(forcerRefresh: true): void {

        if (!this.modeleDonneesInitial || forcerRefresh) {

            // Copie la valeur originale du modèle.
            this.modeleDonneesInitial = cloneDeep(this.modeleDonnees);

            // Effectue une validation lors du changement de modèle.
            if (this.estDossier && this.$refs.form) {
                this.$nextTick().then(() => {
                    this.validerEtape(true);
                });
            }
        }
    }

    /**
     * Valide l'étape et enregistre le résultat dans des variables synchrones.
     * @param resetValidation Si vrai, réinitialise la validation pour éviter que l'utilisateur arrive sur une page pleine d'erreurs s'il ne l'a pas encore ouverte.
     */
    public estValide = false;
    public estEnErreur = false;
    public erreursSpecifiques: string[] = [];
    public validerEtape(resetValidation = false): Promise<ResultatValidationEtape> {
        this.setLoadingEtape(this.$options.name);
        return this.validerForm().then((resultatValidation) => {
            // Réinitialise la validation pour éviter que l'utilisateur arrive sur une page pleine d'erreurs s'il ne l'a pas encore ouverte
            if (resetValidation) {
                const form = this.$refs.form as HTMLFormElement;
                if (form !== undefined) {
                    form.resetValidation();
                }
            }

            // Sauvegarde le résultat dans des variables synchrones pour affichage.
            this.estValide = resultatValidation.estValide;
            this.estEnErreur = !resultatValidation.estValide && !resetValidation;

            // Vide les erreurs spécifiques si l'étape est validée.
            if (!this.estEnErreur) {
                // Clear les messages d'erreur.
                this.erreursSpecifiques = [];
            }
            this.unsetLoadingEtape(this.$options.name);

            return resultatValidation;
        });
    }

    /**
     * Détermine si l'étape est complète ou non.
     */
    public get estComplete(): boolean {
        return this.estValide;
    }

    /**
     * Dossier.
     * @type {Dossier}
     */
    public dossier: Dossier = dossierManager.getDossier();
    public setDossier(dossier: Dossier) {
        dossierManager.setDossier(dossier);
        this.dossier = dossierManager.getDossier();
    }

    /**
     * Vérifie si le dossier est valide et l'enregistre le cas échéant.
     *
     * @param etapes Étapes du dossier à enregistrer.
     * @memberof WizardEtapeComponentsDossier
     */
    public validerEtEnregistrerDossier(etapes: WizardEtapeComponentsDossier[], busWizard: Vue): Promise<Dossier> {
        return new Promise<Dossier>((resolve, reject) => {
            // Vérifie chaque étape.
            this.$nextTick(() => {
                Promise.all(etapes.map((etape) => etape.validerEtape())).then((result) => {
                    // On check que les étapes soit valide ainsi que la coherence entre les paiements et les valorisations.
                    let sauvegardePossible = result.every((r) => r.sauvegardePossible);
                    // Si toutes les étapes modifiées sont valides, ou qu'on est sur un profil interne.
                    if (sauvegardePossible) {
                        // Récupère les données du dossier.
                        this.dossier = dossierManager.getDossier();
                        this.construireModeleDossier(etapes);
                        // Enregistre le dossier.
                        if (!!this.dossier.id) {
                            this.modifierDossier(this.dossier).then((dossier) => {
                                // Actualisation dossier.
                                this.setDossier(dossier);
                                // Actualisation du statut du dossier et des opérations.
                                this.recupererStatutDossier(dossier.id).then(() => {
                                    busWizard.$emit('on-update-dossier');
                                });
                                busWizard.$emit('on-step-informations-simulation-dossier', { simulationDossierId: dossier.id });
                                this.updateConsultedDossier(dossier);
                                resolve(dossier);
                            }).catch((errorResponse: AxiosResponse<any>) => {
                                Notifications.handledNotifications(errorResponse);
                                reject(null);
                            });
                        } else {
                            this.enregistrerDossier(this.dossier).then((dossier) => {
                                this.dossier.id = dossier.id;
                                this.dossier.prescripteurInterneId = dossier.prescripteurInterneId;
                                this.dossier.typeCeeId = dossier.typeCeeId;
                                this.dossier.dateRoleActifIncitatif = dossier.dateRoleActifIncitatif;
                                busWizard.$emit('on-step-informations-simulation-dossier', {
                                    // Infos à mettre à jour.
                                    simulationDossierId: dossier.id,
                                    id: dossier.id,
                                    statutDossierId: dossier.statutDossierId,
                                    utilisateurCreationId: dossier.utilisateurCreationId,
                                });
                                busWizard.$emit('on-creation-dossier', dossier.id);
                                resolve(dossier);
                            })
                                .catch((errorResponse: AxiosResponse<any>) => {
                                    Notifications.handledNotifications(errorResponse);
                                    reject(null);
                                });
                        }
                        // Clear les messages d'erreur.
                        busWizard.$emit('on-wizard-error', []);
                    } else {
                        if (!this.wizardMessages || this.wizardMessages.length === 0) {
                            let etapesEnErreur: string = "";
                            etapes.filter(x => x.estValide === false).forEach(x => etapesEnErreur += ' [' + x.title + ']');
                            busWizard.$emit('on-wizard-error', [{ text: 'Erreur lors de la validation du dossier, veuillez corriger les onglets en erreur et réessayer.' + etapesEnErreur }] as Message[]);
                        }
                        reject(null);
                    }
                });
            });
        });
    }

    /**
     * Construire le modèle du dossier à partir de chacune des étapes.
     * @param etapes Étapes du dossier.
     * @memberof WizardEtapeComponentsDossier
     */
    protected construireModeleDossier(etapes: WizardEtapeComponentsDossier[]): void {
        // Récupère les étapes auxquelles il a accès.
        etapes.forEach((etape) => {
            const getter = etape.exporterEtapeModel();
            if (!!getter.meta && !!getter.meta.param) {
                // On fait ceci car les données ENGIE, c'est déjà les infos de Dossier.
                if (etape.$options.name === TypeDeWizardComposant.DonneesEngie) {
                    this.dossier = Object.assign({}, this.dossier, getter.model);
                }
                // Récupère les données de l'étape.
                else {
                    Vue.set(this.dossier, getter.meta.param, getter.model);

                    // On met à jour le dossier avec les informations supplémentaires de méta, sauf PARAM car déjà traité avant.
                    const otherMetaParam = omit(getter.meta, ['param']);
                    if (Object.keys(otherMetaParam).length >= 1) {
                        Object.entries(otherMetaParam).forEach(([clef, valeur]) => Vue.set(this.dossier, clef, valeur));
                    }
                    // Ici gestion de 'estModifiee'.
                    const param = (<any>this.dossier)[getter.meta.param];

                    if (typeof (param) !== 'number') {
                        Vue.set(param, 'estModifiee', etape.estModifiee);
                    }
                }
            }
        });
        this.ajouterInfosSupplementaires();
    }

    /**
     * Appel à l'API pour passer le dossier courant à l'état "Envoyé à engie".
     *
     * @returns {Promise<Dossier>} Retour de l'appel.
     * @memberof WizardEtapeComponentsDossier
     */
    public envoyerDossier(): Promise<Dossier> {
        return this.appelerApiDossier(this.dossier, 'envoyerDossier');
    }

    /**
     * API enregistrer un dossier.
     *
     * @param {Dossier} dossier
     * @returns {Promise<Dossier>}
     * @memberof WizardEtapeComponentsDossier
     */
    protected enregistrerDossier(dossier: Dossier): Promise<Dossier> {
        if (dossier) {
            const regex = /(&nbsp;|<([^>]+)>)/ig;
            let dossierStringified: string = JSON.stringify(dossier);
            let dossierStringifiedWithoutHtml: string = dossierStringified.replace(regex, '');
            dossier = JSON.parse(dossierStringifiedWithoutHtml);
        }
        const dossierService = new ApiService<Dossier>(`dossier/enregistrerDossier`);
        return new Promise<Dossier>((resolve, reject) => {
            return dossierService.post(dossier)
                .then((response) => resolve(response.data.data))
                .catch((error: { response: Error; }) => reject(error.response));
        });
    }

    /**
     * API enregistrer un dossier.
     *
     * @param {Dossier} dossier
     * @returns {Promise<Dossier>}
     * @memberof WizardEtapeComponentsDossier
     */
    protected modifierDossier(dossier: Dossier): Promise<Dossier> {
        return this.appelerApiDossier(dossier, 'modifierDossier');
    }

    /**
     * Effectue un appel à l'API dossier.
     * @param dossier Dossier à envoyer.
     * @param action Action à appeler.
     */
    protected appelerApiDossier(dossier: Dossier, action: string): Promise<Dossier> {
        const dossierService = new ApiService<Dossier>(`dossier/${action}`);
        return new Promise<Dossier>((resolve, reject) => {
            return dossierService.put(dossier)
                .then((response) => resolve(response.data.data))
                .catch((error: { response: Error; }) => reject(error.response));
        });
    }

    /**
     * API enregistrer les paiements.
     *
     * @memberof WizardEtapeComponentsDossier
     */
    protected enregistrerPaiements(): void {
        const paiementService = new ApiService<Paiement[]>(`paiement/enregistrerLesPaiements`);
        paiementService.post(this.dossier.paiements)
            .catch(() => {
                throw new Error('Une erreur est survenu lors de l\'enregistrent des paiements');
            });
    }

    protected confirmerEnvoieAH(): Promise<void> {
        const dossierService = new ApiService<Dossier>(`dossier/confirmerEnvoiAH/${this.simulationDossierId}`);
        return new Promise<void>((resolve, reject) => {
            return dossierService.put(null)
                .then((response) => resolve())
                .catch((error: { response: Error; }) => reject(error.response));
        });
    }

    private ajouterInfosSupplementaires(): void {
        this.dossier.notes = JSON.stringify(this.notes);
        this.dossier.intituleDossier = this.intituleDossier;
    }
}
