import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant opération.
 */
@Component
export default class SiteDesTravauxValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {
    public libelleSiteTravauxRules = [
        (v: string | any) => this.required(v) || 'Le libelle du site de travaux est obligatoire',
    ];
}
