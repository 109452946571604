














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { UserProfile } from '@/store/modules/auth/types';
import { DateHelper } from '@/shared/helpers';

@Component({
    name: 'Notes',
})
export default class Notes extends Vue {

    public menu: boolean = false;

    @Prop({ default: null })
    public dossierId: number;

    /*
     * Méthodes du store.
     */
    @Action(InformationsSimulationDossierStoreMethods.MODIFIER_REFERENCE)
    public preModifierNotes: (simulationDossierId: number) => Promise<{ id: number, intituleDossier: string }>;

    @Mutation(InformationsSimulationDossierStoreMethods.ADD_NOTE)
    public addNote: (note: {
        idCreateur: number,
        createur: string,
        date: string
        message: string,
        vue: boolean,
    }) => void;

    @Getter(InformationsSimulationDossierStoreMethods.NOTES)
    public notes: Array<{
        idCreateur: number,
        createur: string,
        date: string
        message: string,
        vue: boolean,
    }>;
    @Prop({ default: null })
    public profile: UserProfile;

    public loading: boolean = false;

    public note: string = '';

    @Watch('notes')
    public onNotesLoaded(value: Array<{
        idCreateur: number,
        createur: string,
        date: string
        message: string,
        vue: boolean,
    }>) {
        this.note = (this.notes.find(n => n.idCreateur === parseInt(this.profile.sub, 10)) || { message: '' }).message;
    }

    public modifierNotes() {
        this.loading = true;
        this.addNote({
            idCreateur: parseInt(this.profile.sub, 10),
            createur: `${this.profile.given_name} ${this.profile.family_name}`,
            date: DateHelper.format(new Date().toString()),
            message: this.note,
            vue: false,
        });
        this.notes.filter(note => note.idCreateur !== parseInt(this.profile.sub, 10)).forEach(note => note.vue = true);
        this.preModifierNotes(this.dossierId).finally(() => {
            this.loading = false;
            this.menu = false;
        });
    }

    public get currentOwnNote(): string {
        const currentNote = this.notes.find(note => note.idCreateur === parseInt(this.profile.sub, 10));
        return !!currentNote ? currentNote.message : '';
    }

    public get notification(): boolean {
        const notesNonVue = this.notes.filter(note => note.vue === false && note.idCreateur !== parseInt(this.profile.sub, 10));
        return notesNonVue.length > 0;
    }
}
