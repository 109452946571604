





































































































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { CommonMixin } from '@/shared/mixins';
import { TemplateOperation, SecteurReferentiel } from '@/models';
import ChoixSecteurEtOperationValidator from './ChoixSecteurEtOperationValidator';
import SelectableSecteurList from '@/components/SelectableSecteurList.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { DateHelper } from '@/shared/helpers/DateHelper';
import { cloneDeep } from 'lodash-es';
import { UserProfile } from '@/store/modules/auth/types';
import { Profils } from '@/shared/enums';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Utilisateur } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { ChoixSecteurEtOperationResult } from '../models/ChoixSecteurEtOperationResult.model';

//  Créer une interface pour fusionner les mixins.
interface IMixinInterface extends CommonMixin, ChoixSecteurEtOperationValidator { }

@Component({
    components: {
        'cee-secteurs': SelectableSecteurList,
        ValidationProvider,
        CeeDatePicker,
    },
    mixins: [CommonMixin],
})
export default class ChoixSecteurEtOperationElement extends Mixins<IMixinInterface>(CommonMixin, ChoixSecteurEtOperationValidator) {
    [x: string]: any;
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement;
    };
    public valid = false;
    public loadingTemplateOperation = false;       

    @Prop()
    public secteurs: SecteurReferentiel[];

    /**
     * Le profil utilisateur.
     */
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    /**
     * Indique si il faut saisir la date d'engagement des travaux.
     */
    @Prop({ default: false })
    public saisieDateEngagementTravaux: boolean;

    /**
     * Identifiant du dossier si jamais on est dans le contexte d'un dossier.
     */
    @Prop()
    public dossierId: undefined | number;

    /**
     * Date du rôle actif et incitatif.
     */
    @Prop({default: undefined})
    public dateRai: string;

    /**
     * Date de début des travaux saisie.
     */
    public dateEngagementTravaux: string = '';

    /**
     * Permet de ne mettre à jour la liste des secteurs que si le retour correspond au dernier appel en cours.
     */
    public appelEnCours: any;

    /**
     * Date d'ajourd'hui au format ISO.
     */
    public aujourdhui: string = DateHelper.todayIso();

    /**
     * Liste template opération filtré par secteur.
     *
     * @type { TemplateOperation[] }
     * @memberof ChoixSecteurEtOperation
     */
    public templateOperations: TemplateOperation[] = new Array<TemplateOperation>();

    /**
     * Le template opération sélectionné.
     *
     * @type { TemplateOperation }
     * @memberof ChoixSecteurEtOperation
     */
    public selectedTemplateOperation: TemplateOperation | null = null;

    /**
     * Le template opération sélectionné.
     *
     * @type { TemplateOperation }
     * @memberof ChoixSecteurEtOperation
     */
    public templateOperationId: number | null = null;

    /**
     * Le secteur sélectionné.
     *
     * @type { SecteurReferentiel }
     * @memberof ChoixSecteurEtOperation
     */
    public selectedSecteur: SecteurReferentiel | null = null;

    /**
     * Le secteur sélectionné.
     *
     * @type { SecteurReferentiel }
     * @memberof ChoixSecteurEtOperation
     */
    public secteurId: number | null = null;

    public currentUser: Utilisateur = null;
    
    /**
     * Indique si l'utilisateur connecté est un compte entreprise ou installateur.
     */
    public estEntrepriseOuInstallateur: boolean = false;

    /**
     * Indique si l'utilisateur connecté est un compte entreprise ou installateur.
     */
    public estSyndicSDC: boolean = false;
    
    /**
     *
     */
    constructor() {
        super();
    }

    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.secteurId = null;
        this.templateOperationId = null;
    }

    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     */
    public mounted(): void {
        // Données du formulaire.
        if (this.model) {
            this.secteurId = this.model.secteurId;
            this.templateOperationId = this.model.templateOperationId;
        }
        //
        if (this.secteurId && this.templateOperationId) {
            this.selectedSecteur = this.secteurs.find((item: SecteurReferentiel) => item.id === this.secteurId) || null;
            this.selectedTemplateOperation = { id: this.templateOperationId } as TemplateOperation;
            if (this.selectedSecteur && this.selectedTemplateOperation) {
                this.recupererTemplateOperations(this.selectedTemplateOperation);
            }
        }              

        // Les secteurs affichées doivent être les secteurs standard pour les profils externes. Pour les internes on affiche tout.
        this.getUserProfile.then((profile: UserProfile) => {                
            if (!!profile) {
                this.estEntrepriseOuInstallateur = (profile.profilCode === Profils.Entreprise || profile.profilCode === Profils.Installateur);
                this.estSyndicSDC = profile.profilCode === Profils.SyndicSDC;

                if (parseInt(profile.sub as string, 10) > 0) {

                    const userId = parseInt(profile.sub as string, 10);
                    if (userId > 0) {

                        const utilisateurService = new ApiService<Utilisateur>('utilisateur/obtenir');
                        utilisateurService.get(userId).then(userResponse => {
                            this.currentUser = userResponse.data.data;
                        });
                    }
                }
            }
        });
    }

    /**
     * Événement qui se déclenche lorsque le secteur est sélectionné.
     *
     * @param {SecteurReferentiel} secteur
     * @memberof ChoixSecteurEtOperation
     */
    public onSelectedSecteur(secteur: SecteurReferentiel) {
        this.secteurId = secteur.id;
        this.templateOperationId = null;
        this.chargerDataTemplateOperations();
        this.$emit('onSecteurSelected', secteur.id);
    }

    /**
     * Événement qui se déclenche lorsque la date d'engagement change.
     *
     * @param {string} dateSaisie La date d'engagement saisie.
     * @memberof ChoixSecteurEtOperation
     */
    public onDateEngagementUpdated(dateSaisie: string) {
        this.dateEngagementTravaux = dateSaisie;
        this.templateOperationId = null;
        this.chargerDataTemplateOperations();
        this.$emit('onDateEngagementUpdated', dateSaisie);
    }

    /**
     * Détermination du libellé de la combo-box de choix de l'opération.
     */
    public get labelChoixOperation(): string {

        if (this.saisieDateEngagementTravaux) {
            if (this.selectedSecteur === null && !this.dateEngagementTravaux) {
                return 'Sélectionnez une date d\'engagement des travaux et un secteur';
            } else if (!this.dateEngagementTravaux) {
                return 'Sélectionnez une date d\'engagement des travaux';
            }
        }

        if (this.selectedSecteur === null) {
            return 'Sélectionnez un secteur';
        }

        return 'Choisir le type de travaux envisagés';
    }

    /**
     * Récupérer les données en fonction du secteur sélectionné.
     *
     * @param {string} secteur
     * @memberof ChoixSecteurEtOperation
     */
    public chargerDataTemplateOperations() {
        this.recupererTemplateOperations(null);
    }

    /**
     * Récupérer les template operation par secteur.
     *
     * @param {string} secteur
     * @memberof ChoixSecteurEtOperation
     */
    public recupererTemplateOperations(selectedTplOperation: TemplateOperation | null) {
        this.selectedTemplateOperation = selectedTplOperation;
        if (this.selectedSecteur === null || typeof this.selectedSecteur === 'undefined' || (this.saisieDateEngagementTravaux && !this.dateEngagementTravaux)) {
            this.templateOperations = new Array<TemplateOperation>();
        } else {
            this.loadingTemplateOperation = true;
            this.templateOperations = new Array<TemplateOperation>();

            // Stocke la récupération des opérations pour vérifier qu'on n'affiche que le dernier appel.
            const appel = this.recupererTemplateOperationsParSecteur(this.selectedSecteur.code, this.dateEngagementTravaux);
            this.appelEnCours = appel;
            appel.then((result: TemplateOperation[]) => {

                // Ne met à jour la liste que s'il s'agit bien du dernier appel.
                if (appel === this.appelEnCours) {
                    if (!!result && result.length > 0) {
                        this.templateOperations = result;
                    }
                    this.loadingTemplateOperation = false;
                }
            }).catch(() => this.loadingTemplateOperation = false);
        }
    }

    /**
     * Valide uniquement le formulaire.
     */
    public validerFormulaire(): Promise<ChoixSecteurEtOperationResult> {
        return new Promise<ChoixSecteurEtOperationResult>(resolve => {
            
            let estSoumisAControle: boolean = false;
            let textePolitiqueControle: string = null;

            // Opération soumise à politique de contrôle.
            const templateOperation = this.templateOperations.filter(t => t.id === this.templateOperationId);
            if (templateOperation.length > 0) {

                // Soumis à controle => date engagement supérieure à date d'entrée en controle et si inférieure à date fin de contrôle.
                if(templateOperation[0].estSoumisAControle 
                    && !!templateOperation[0].dateEntreeApplicationControle
                    && this.dateEngagementTravaux
                    && DateHelper.premiereSuperieurASecondeWithoutTime(
                        this.dateEngagementTravaux,
                        DateHelper.frOrBasetoIsoString(templateOperation[0].dateEntreeApplicationControle),
                        true
                    )
                    && !!templateOperation[0].dateEntreeApplicationControleHorsSurface
                    && DateHelper.premiereSuperieurASecondeWithoutTime(
                        this.dateEngagementTravaux,
                        DateHelper.frOrBasetoIsoString(templateOperation[0].dateEntreeApplicationControleHorsSurface),
                        true
                    )
                ) {
                    estSoumisAControle = true;
                }

                textePolitiqueControle = templateOperation[0].textePolitiqueControle;
            }

            const result = new ChoixSecteurEtOperationResult(
                this.$refs.form.validate(),
                this.secteurId,
                this.templateOperationId,
                this.dateEngagementTravaux,
                estSoumisAControle,
                textePolitiqueControle
            );

            setTimeout(() => resolve(cloneDeep(result)), 0);
        });
    }

    /**
     * Valide uniquement le formulaire.
     */
    public resetFormulaire(): void {
        this.$emit('reset');
        this.$refs.form.reset();
        this.selectedTemplateOperation = null;
        this.selectedSecteur = null;
        this.dateEngagementTravaux = '';
    }

    /**
     * Retire le numéro d'arrêté du code de l'opération afin de simplifier l'affichage dans la liste.
     * @param code Code complet de l'opération (type "AGRI-TH-102 - Arrêté 14").
     * @returns Code simplifié (type "AGRI-TH-102").
     */
    public getCodeSimplifie(code: string): string {
        return code ? code.split(' - ')[0] : '';
    }

    /**
     * Récupérer une date Rai différente (dateActivationCompte) des règles établient dans le cas d'un secteur tertiaire.
     */
    public getMinDateEngagementComputed() {
        return [this.dateRai, this.aujourdhui].reduce((a, b) =>  a < b ? a : b);
    }
}

