import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from '@/shared/mixins/ValidationMixin';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant opération.
 */
@Component
export default class OperationValidator extends Mixins(ValidationMixin) {

    /**
     * Vérifie qu'une date de fin de travaux prévisionnelle a été définie.
     */
    public dateFinTravauxPrevisionnelleRules = [
        (v: string | any) => this.required(v) || 'La date de fin de travaux prévisionnelle est obligatoire'
    ];

    // Date engagement obligatoire si Entreprise / Installateur / SDC.
    public dateEngagementRules = [
        (v: string | any) => this.required(v) || 'La date d\'engagement est obligatoire'
    ];
}
