import { Vue, Prop } from 'vue-property-decorator';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import template from './Facturation.Template.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { TypeCee, Paiement } from '@/models';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import { Getter } from 'vuex-class';
import { TypeContribution } from '@/models/TypeContribution.model';
import { isEqual } from 'lodash-es';
import FacturationValidator from './Facturation.Validator';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import FactureComponent from './Facture.vue';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { DossierReferentiels } from '@/models/DossierReferentiels.model';

//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, FacturationValidator, Vue { }
@Component({
    ...template,
    name: 'Facturation',
    ref: 'Facturation',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
    components: {
        CeeDatePicker, CeeAutocomplete, FactureComponent
    }
})
export default class Facturation extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, FacturationValidator)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    /**
     * constructor.
     */
    constructor() { super(); }
    // Titre.
    public titre(): string { return 'Facturation'; }
    public icone(): string { return 'fas fa-euro'; }
    public $refs!: { form: HTMLFormElement };

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    @Prop()
    public referentielsEtape: DossierReferentiels;

    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public get modeleDonnees(): Paiement[] {
        return this.dossier.paiements;
    }

    /**
     * Gestion initialisation du canal collecte.
     */
    private initCanalCollecte: boolean = true;

    /**
     * Détermine si l'étape a été modifiée depuis le dernier enregistrement.
     * Marque l'onglet comme modifié lors de la création car il contient des données essentielles à l'enregistrement.
     */
    public get estModifiee(): boolean {
        return !this.simulationDossierId || !isEqual(this.modeleDonnees, this.modeleDonneesInitial);
    }

    /**
     * Profil admin.
     */
    public estAdministrateur: boolean = false;
    
    public typeCee: TypeCee[];
    public typeContribution: TypeContribution[];
    public canauxCollectes: string[] = [];

    /**
     * Exécuté lors de la création du composant.
     *
     * @memberof DonneesEngie
     */
    public created() {
        this.typeCee = this.referentielsEtape.typeCee;
        this.typeContribution = this.referentielsEtape.typeContribution;
        this.canauxCollectes = this.referentielsEtape.canalCollecte;
        this.getUserProfile.then((donneesProfil: any) => {
            if (!!donneesProfil && parseInt(donneesProfil.sub as string, 10) > 0) {                
                this.estAdministrateur = donneesProfil.isAdmin;
            }
        });        
    }

    /**
     * Exporter le modèle de cette étape.
     *
     * @returns {{ model: any; meta: any; }}
     * @memberof DonneesEngie
     */
    public exporterEtapeModel(): { model: any; meta: any; } {
        return {
            model: null,
            meta: {
                param: null
            }
        };
    }
 
    /**
     * Supprimer le message d'erreur si le numéro de dossier est défini.
     *
     * @param {string} numDossier
     * @memberof DonneesEngie
     */
    public supprimerErreurSiNumDossierValide() {
        if (this.dossier.numeroDossier) {
            this.setErrorMessage([]);
        }
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        // Avant de relancer la validation, on clear les messages d'erreurs.
        this.setErrorMessage(null);
        let estValide = this.$refs.form.validate();
        return Promise.resolve(new ResultatValidationEtape(estValide, estValide));
    }

    public updateMontant(index: number, param: string, value: number) {
        this.$set(this.dossier.paiements[index], param, value);
        this.dossier.paiements = [...this.dossier.paiements];
    }
}
