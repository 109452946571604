














import { Component, Vue, Prop } from 'vue-property-decorator';
import { SecteurReferentiel } from '@/models';

@Component({
    name: 'SelectableSecteur',
})
export default class SelectableSecteur extends Vue {

    /**
     * Dictionnaire des icônes par secteur.
     */
    private readonly secteurs: { [code: string]: string } = {
        'transport': 'bus',
        'reseaux': 'project-diagram',
        'residentiel': 'building',
        'industrie': 'industry',
        'agriculture': 'tractor',
        'tertiaire': 'school'
    };

    /**
     * Le mapping entre un secteur de la BDD et l'affichage
     *
     * @type { SecteurReferentiel }
     * @memberof SelectableSecteur
     */
    @Prop({ default: () => null as unknown as SecteurReferentiel })
    public readonly secteur!: SecteurReferentiel;
    /**
     * Le secteur sélectionné.
     *
     * @type { SecteurReferentiel }
     * @memberof SelectableSecteurList
     */
    @Prop({ default: () => null as unknown as SecteurReferentiel })
    public selectedSecteur: SecteurReferentiel | null = null;
    /**
     * Constructeur
     */
    constructor() {
        super();
    }
    /**
     * Met à jour le secteur sélectionné.
     */
    public updateSelectedSecteurId(): void {
        this.$parent.$emit('update:selectedSecteur', { ...this.secteur });
        this.$parent.$emit('onSelectedSecteur', { ...this.secteur });
    }
    /**
     * Retourne la classe CSS du secteur.
     * :class="[selectedSecteur && secteur !== selectedSecteur?  'billet-unselected': 'billet']"
     */
    public get getClassSecteur(): { [key: string]: any } {
        const isSelected = (this.selectedSecteur && this.secteur && this.secteur.id === this.selectedSecteur.id);
        return {
            'billet': isSelected,
            'billet billet-unselected': !isSelected,
        };
    }

    /**
     * Récupère l'icône en fonction du secteur affiché.
     * @param secteur
     */
    public getIconeSecteur(secteur: string): string {
        return 'fa fa-' + this.secteurs[secteur.toLowerCase()];
    }
}
