import { CriteriaBase } from '@/shared/models';
import { OperationDateTravaux } from './OperationDateTravaux.model';

/**
 * Modèle pour les vérifications de dates des travaux sur les opérations.
 *
 */
export class DateTravauxCriteria extends CriteriaBase {

    /**
     * La nouvelle date de début des travaux.
     */
    public nouvelleDateDebutTravaux: string;

    /**
     * Opérations à vérifier.
     */
    public operations: OperationDateTravaux[];

    /**
     * Surchage querystring.
     */
    public get queryString() {
        return this.encodeNested(this);
    }

    /**
     * Serialisation.
     * @param objet
     * @param nesting
     */
    public encodeNested(objet: object, nesting: string = ''): any {
        const pairs: any[] = Object.entries(objet).map(([key, val]) => {
            if (Array.isArray(val)) {
                return val.map((subVal, subKey) => {
                    if (typeof subVal === 'object') {
                        return this.encodeNested(subVal, key + `[${subKey}].`);
                    } else {
                        return [nesting + key, subVal].map(escape).join('=');
                    }
                }).join('&');
            } else if (val === undefined || val === null) {
                return '';
            } else if (typeof val === 'object') {
                return this.encodeNested(val, nesting + `${key}.`);
            } else {
                return [nesting + key, val].map(escape).join('=');
            }
        });
        return pairs.join('&');
    }

    /**
     * Constructeur.
     * @param nouvelleDateDebutTravaux Nouvelle date de début des travaux.
     * @param operations Opérations à vérifier.
     */
    constructor(nouvelleDateDebutTravaux: string, operations: OperationDateTravaux[]) {
        super();

        this.nouvelleDateDebutTravaux = nouvelleDateDebutTravaux;
        this.operations = operations;
    }

}