import { Vue, Watch, Prop } from 'vue-property-decorator';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import template from './DonneesApporteurAffaires.Template.vue';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import { FormeJuridique, Civilite, Utilisateur } from '@/models';
import { Getter } from 'vuex-class';
import SocieteFormSimulation from '@/components/Wizard/Commun/SocieteFormSimulation.vue';
import ContactForm from '@/components/Wizard/Commun/ContactForm.vue';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';
import { EnumProfilsAsNumber } from '@/shared/enums';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { ApiService } from '@/services/base/ApiService';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Contact } from '../../../../models/Contact.model';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';

interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, Vue { }

@Component({
    ...template,
    name: 'DonneesApporteurAffaires',
    ref: 'DonneesApporteurAffaires',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
    components: {
        SocieteFormSimulation,
        ContactForm,
        CeeAutocomplete,
    },
})
export default class DonneesApporteurAffaires extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {

    public $refs!: {
        form: HTMLFormElement,
    };
    public titre(): string { return 'Données apporteur d\'affaires'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-handshake'; }

    // Données AA.
    public apporteurAffaires: Utilisateur = new Utilisateur();

    // Informations contact AA.
    public contactApporteurAffaires: Contact = null;

    @Prop()
    public referentielsEtape: DossierReferentiels;
 
    public formeJuridique: FormeJuridique[];   
    public civilite: Civilite[];

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    public created() {
        this.formeJuridique = this.referentielsEtape.formeJuridique;
        this.civilite = this.referentielsEtape.civilite;
    }

    public mounted() {
        if (!this.modeleDonneesInitial) {
            this.majModeleDonnees();
        }

        this.getApporteurAffairesByIdAutoCompletePromise().then((aa: Utilisateur) => {
            if (aa) {
                this.apporteurAffaires = aa;
            }
        });
    }

    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public getModeleDonnees(): { [propriete: string]: any } {
        return {
            apporteurAffaires: this.apporteurAffaires.id || null
        }
    }

    /**
     * Récupération des apporteurs d'affaires.
     *
     * @param {string} recherche
     * @returns {Promise<Utilisateur[]>}
     * @memberof DonneesEngie
     */
    public getApporteurAffairesAutoCompletePromise(recherche: string): Promise<Utilisateur[]> {
        return ApiAutocompleteHelper.getAutocompleteByUtilisateurCriteriaPromise<Utilisateur>(recherche, [EnumProfilsAsNumber.ApporteurAffaires], 'utilisateur/obtenirTousUtilisateursParType');
    }

    /**
     * Récupération d'un apporteur d'affaires par .
     *
     * @param {string} recherche
     * @returns {Promise<Utilisateur[]>}
     * @memberof DonneesEngie
     */
    public getApporteurAffairesByIdAutoCompletePromise(): Promise<Utilisateur> {
        if (!!this.model.simulationDossierId) {
            return ApiAutocompleteHelper.getAutocompletebyIdPromise<Utilisateur>(this.model.simulationDossierId, 'dossier/recupererApporteurAffairesParDossier');
        } else {
            return Promise.resolve(new Utilisateur());
        }
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        const estValide = this.apporteurAffaires.id !== undefined && this.apporteurAffaires.id !== 0;
        return Promise.resolve(new ResultatValidationEtape(estValide, estValide));
    }

    public exporterEtapeModel(): { model: any; meta: any; } {
        return {
            model: this.apporteurAffaires ? this.apporteurAffaires.id : null,
            meta: {
                param: 'apporteurAffairesId'
            }
        };
    }

    /**
     * Réinitialiser l'autocomplete.
     *
     * @memberof DonneesApporteurAffaires
     */
    public reset(): void {
        this.apporteurAffaires = new Utilisateur();
        this.contactApporteurAffaires = null;
        this.$refs.form.reset();
    }

    /**
     * Récupérer les informations d'historique du dossier.
     *
     * @returns {Promise<HistoriqueInfosDossier>}
     * @memberof HistoriqueDossier
     */
    public recupererInfosHistoriqueDossier(): Promise<Utilisateur> {
        return new Promise<Utilisateur>((resolve, reject) => {
            const simulationService = new ApiService<Utilisateur>('dossier/recupererApporteurAffairesParDossier');
            simulationService.get(this.model.simulationDossierId)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error: { response: Error; }) => {
                reject(error.response);
            });
        });
    }

    /**
    * Watcher pour faire en sorte que les opération du dossiers changes.
    */
    @Watch('dossier.apporteurAffairesId', { immediate: true })
    public onApporteurAffairesIdChanged(newItem: number) {

        if ((typeof this.apporteurAffaires.id === null
            || typeof this.apporteurAffaires.id === 'undefined' || this.apporteurAffaires.id < 0)
            && newItem > 0) {
            this.apporteurAffaires.id = newItem;
            this.$forceUpdate();
        }

        this.donnees = this.getModeleDonnees();
    }

    /**
     * Création du contact en fonction de l'AA saisi.
     */
    @Watch('apporteurAffaires')
    public onApporteurAffairesChanged() {
        if (this.apporteurAffaires != null) {
            this.contactApporteurAffaires = new Contact(TypeContact.Interlocuteur, this.apporteurAffaires);
        }
        else {
            this.contactApporteurAffaires = null;
        }
    }

}

