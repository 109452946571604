













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'CeeCurrencyTextField',
    inheritAttrs: false,
})
export default class CeeCurrencyTextField extends Vue {

    @Prop({default: null})
    public origin: any;

    @Prop({ required: false, default: false })
    public autoriseMontantsNegatifs: boolean;

    public isInputActive: boolean = false;

    public trigger() {
        this.isInputActive = !this.isInputActive 
    }

        // Les attributs du composant.
    public get attrs(): Record<string, any> {
            const { ...attrs } = this.$attrs;
            const finalAttrs = {
                ...attrs as unknown as  Record<any, any>,
                ...{
                    required: true,
                    // appendIcon:'fas fa-euro-sign'
                } as Record<any, any>,
            } as Record<any, any>;

            return finalAttrs;
    }
        // Les événements du composant.
    public get listeners(): Record<string, any> {
            const { input, ...listeners  } = this.$listeners;
            return listeners as unknown as Record<string, any>;
    }

    // Computed de la data affiché.
    public get displayValue() {
        if(!this.origin && this.origin !== 0) {
            return null
        } else {
            if(this.isInputActive || this.origin === 0) {
                return this.origin
            } else {
                return parseFloat(this.origin).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?:\,\d+)?$)/g, "$1 ");
            }
        }
    }
    public set displayValue(value: string) {
        let regexDisplay = this.autoriseMontantsNegatifs ? /[^\d\.\-]/g : /[^\d\.]/g;

        let newValue = parseFloat(value.replace(',', '.').replace(regexDisplay, ""));
        if (isNaN(newValue)) {
            newValue = null;
        }
        this.$emit('input', newValue);
    }
}
