





import {Vue, Component, Prop} from 'vue-property-decorator';
@Component({
    name: 'CeeBouttonImport',
})
export default class CeeBouttonImport extends Vue {
    @Prop({default:''})
    private label: string;

    public emitEventOnClick(): void {
      this.$emit('buttonClicked');
    }
}
