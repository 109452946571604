import { Societe, SiteTravaux, Operation, Utilisateur } from '@/models';
import MontantRetroceder from './MontantRetroceder';

/**
 * Classe SimulationSociete.
 *
 * @export
 * @class SimulationSociete
 */
export class SimulationConvention {
    /**
     * Identifiant de la simulation.
     *
     * @type {number}
     * @memberof SimulationConvention
     */
    public id: number;

    /**
     * Objet société bénéficiaire attaché à la simulation.
     *
     * @type {Societe}
     * @memberof SimulationConvention
     */
    public societeBeneficiaire: Societe;

    /**
     * Objet société bénéficiaire attaché à la simulation.
     *
     * @type {Societe}
     * @memberof SimulationConvention
     */
    public societeInstallateur: Societe;

    /**
     * Le client est-il Syndic/SDC.
     *
     * @type {boolean}
     * @memberof SimulationConvention
     */
    public estSdc: boolean;

    /**
     * Numéro dossier.
     *
     * @type {string}
     * @memberof SimulationConvention
     */
    public numeroDossier: string;

    /**
     * Le client est-il Syndic/SDC.
     *
     * @type {boolean}
     * @memberof SimulationConvention
     */
    public siteTravaux: SiteTravaux;

    /**
     * La liste des opérations.
     *
     * @type {Array<Operation>[]}
     * @memberof SimulationConvention
     */
    public operations: Operation[];

    /**
     * Identifiant Client représenté.
     *
     * @type {number}
     * @memberof SimulationConvention
     */
    public clientRepresenteId: number;

    /**
     * Date de la première génération de la convention.
     *
     * @type {number}
     * @memberof SimulationConvention
     */
    public dateGenerationConvention: string;

    /**
     * Obtient ou définit si l'opération est de type secteur résidentiel.
     *
     * @type {boolean}
     * @memberof SimulationConvention
     */
    public estOperationSecteurResidentielExiste: boolean;

    /**
     * Montant à rétrocéder.
     *
     * @type {MontantRetroceder}
     * @memberof SimulationConvention
     */
    public montantRetroceder: MontantRetroceder;

    /**
     * Créateur de la simulation.
     *
     * @type {Utilisateur}
     * @memberof SimulationConvention
     */
    public utilisateurCreation: Utilisateur = null;


    /**
     * Création d'une instance de SimulationSociete.
     * @memberof SimulationConvention
     */
    constructor();
    constructor(id: number, societeBeneficiaire: Societe, numeroDossier: string, estSdc: boolean, operations: Operation[], siteTravaux: SiteTravaux, clientRepresenteId: number);
    constructor(id?: number, societeBeneficiaire?: Societe, numeroDossier?: string, estSdc?: boolean,
        operations?: Operation[], siteTravaux?: SiteTravaux, clientRepresenteId?: number) {
        this.id = id || 0;
        this.societeBeneficiaire = societeBeneficiaire || null;
        this.estSdc = estSdc || null;
        this.numeroDossier = numeroDossier || '';
        this.estSdc = estSdc || null;
        this.operations = operations || null;
        this.siteTravaux = siteTravaux || null;
        this.clientRepresenteId = clientRepresenteId || 0;
    }
}
