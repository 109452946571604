import Vue from 'vue';
import { Watch, Prop } from 'vue-property-decorator';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import template from './DonneesInstallateur.Template.vue';
import SocieteFormSimulation from '@/components/Wizard/Commun/SocieteFormSimulation.vue';
import ContactForm from '@/components/Wizard/Commun/ContactForm.vue';
import SocieteSousTraitanteForm from '@/components/Wizard/Commun/SocieteSousTraitanteForm.vue';
import DonneesSociete from '@/components/Wizard/Commun/DonneesSociete.vue';
import { Societe, FormeJuridique, Civilite, TypeInstallateur, Operation } from '@/models';
import { Contact } from '@/models';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { Mutation } from 'vuex-class';
import DonneesInstallateurValidator from './DonneesInstallateur.Validator';
import { Message } from '@/shared/models';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import { Profils } from '@/shared/enums';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import BaseCommonApiHelper from '@/services/BaseCommonApiHelper';
import { ArrayHelper } from '@/shared/helpers/ArrayHelper';
import { ClientRepresente } from '@/shared/enums/ClientRepresente.enum';
import SocieteSimulationDossierBase from '../SocieteSimulationDossierBase';
import { SimulationDossierSocieteInstallateur, SimulationDossierSocietePilotage } from '../models';
import { TypeDeWizardComposant } from '../../Composants';
import { InformationsSimulationDossierStoreMethods } from '../../../../store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';

//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends CommonMixin, SocieteSimulationDossierBase<SimulationDossierSocieteInstallateur>,
    WizardEtapeComponentsDossier, DonneesInstallateurValidator, Vue { }

@Component({
    ...template,
    name: 'DonneesInstallateur',
    ref: 'DonneesInstallateur',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
    components: {
        SocieteFormSimulation,
        ContactForm,
        SocieteSousTraitanteForm,
        DonneesSociete,
        CeeAutocomplete,
    },
})
export default class DonneesInstallateur
    extends VueMixinsDecorator<IMixinInterface>(CommonMixin, SocieteSimulationDossierBase,
        WizardEtapeComponentsDossier, DonneesInstallateurValidator, Vue)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    [x: string]: any;


    public titre(): string { return 'Données installateur'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-hard-hat'; }
    public $refs!: {
        form: HTMLFormElement,
    };
    /**
     * Information définissant si l'une des opérations est éligible pour le pilotage.
     */
    public estPilotage: boolean = false;

    /**
     * Profil administrateur.
     */
    public estAdministrateur: boolean = false;

    @Prop()
    public referentielsEtape: DossierReferentiels;

    // Valeurs référentielles.

    public formeJuridique: FormeJuridique[];
    public civilite: Civilite[];
    public typeInstallateur: TypeInstallateur[];

    // Enum ClientRepresente.
    public ClientRepresente = ClientRepresente;

    // Ajout des champs non renseignés de l'étape si erreur dans la validation.
    @Mutation(InformationsSimulationDossierStoreMethods.ADD_CHAMPS_NON_RENSEIGNES)
    public addChampsNonRenseignes: (champsNonRenseignes: any) => void;

    // Suppression des champs non renseignes de l'étape si ok après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.REMOVE_CHAMPS_NON_RENSEIGNES)
    public removeChampsNonRenseignes: (etape: TypeDeWizardComposant) => void;

    public newSociete = new Societe();
    public newDonneesPilotage = new SimulationDossierSocietePilotage();

    /**
     * Définit liste Installateurs existants pour le pilotage.
     */
    public get installateursExistantsPilotage(): Societe[] {
        return [
            ...this.donnesOngletSocieteAuChoix.map(item => item.societe),
            ...this.societeOfCurrentUserAndSocieteAC
        ];
    }

    /**
     * Données lié aux onglets Société de pilotage.
     *
     * @private
     * @type {Societe}
     * @memberof SocieteSimulationDossierBase
     */
    public donneesOngletSocietePilotage: SimulationDossierSocietePilotage = null;

    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public getModeleDonnees(): { [propriete: string]: any } {
        return {
            donneesOngletSociete: this.donneesOngletSociete,
            donneesOngletSocietePilotage: this.donneesOngletSocietePilotage
        };
    }

    /**
     * Création d'une instance de l'objet DonneesInstallateur.
     * @memberof DonneesInstallateur
     */
    constructor() {
        super();
    }

    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     * @memberof DonneesInstallateur
     */
    public created(): void {
        this.setLoadingWizard(true);
        this.donneesOngletSociete = new SimulationDossierSocieteInstallateur();
        this.donneesOngletSocietePilotage = new SimulationDossierSocietePilotage();
        this.donnesOngletSocieteAuChoix = new Array<SimulationDossierSocieteInstallateur>();

        Promise.all([
            this.formeJuridique = this.referentielsEtape.formeJuridique,
            this.civilite = this.referentielsEtape.civilite,
            this.typeInstallateur = this.referentielsEtape.typeInstallateur,
        ]).then(() => {
            // Pour les propositions "Choisir une société existante".
            this.getUserProfile.then((userProfil: any) => {
                if (!!userProfil && parseInt(userProfil.sub as string, 10) > 0) {
                    this.estAdministrateur = userProfil.isAdmin;
                    this.currentUser = {
                        estInterne: userProfil.isInterne as boolean,
                        profil: userProfil.profilCode as Profils,
                        email: userProfil.email as string,
                        username: userProfil.preferred_username as string,
                        civiliteId: userProfil.civiliteId,
                        lastName: userProfil.family_name as string,
                        firstName: userProfil.given_name as string,
                        fonction: userProfil.fonction || ' ' as string,
                        telephone: (userProfil.phoneNumber !== null) ? userProfil.phoneNumber : ''
                    };
                    if (!this.isInstallateur) {
                        this.recupererDonneesSocieteAChoisir(this.model.simulationDossierId);
                    }
                } else {
                    throw new Error('Une erreur est survenue lors de la récupération du profil de l\'utilisateur courant');
                }
            }).finally(() => {
                // S'il s'agit d'un dossier et que l'utilisateur est un installateur.
                if (this.isInstallateur) {
                    Promise.all([this.recupererSocietesUtilisateur(), BaseCommonApiHelper.obtenirSocietesAffiliees()])
                        .then(([societes, societesAffiliees]) => {
                            this.completerContactSociete(societes, true);
                            this.societeOfCurrentUserAndSocieteAC = societes;
                            this.societeOfCurrentUserAndSocieteAC.push(...societesAffiliees);

                        }).finally(() => {
                            this.societeOfCurrentUserAndSocieteAC = [...ArrayHelper.remove_duplicate<Societe>(this.societeOfCurrentUserAndSocieteAC)];                            
                            //On set la société par défaut.
                            if (this.societeOfCurrentUserAndSocieteAC.length === 1) {
                                this.donneesOngletSociete.societeId = this.societeOfCurrentUserAndSocieteAC[0].id;
                                this.donneesOngletSociete.societe = this.societeOfCurrentUserAndSocieteAC[0];                       
                            }
                            // Si dossier déjà créé on override la société set par defaut.
                            if (!!this.model.simulationDossierId) {
                                this.handleRecuperationSocieteInstallateurDossier();
                            }
                            this.finaliserChargement();
                        });
                }
                if (!!this.model.simulationDossierId && !this.isInstallateur) {
                    this.handleRecuperationSocieteInstallateurDossier();
                }
                this.finaliserChargement();
            });
        });
    }


    private handleRecuperationSocieteInstallateurDossier(): void {
        this.donneesOngletSociete.id = this.model.simulationDossierId;
        Promise.all([
            this.recupererSocieteBySimulationDossier(this.model.simulationDossierId),
            this.recupererSocietePilotageBySimulationDossier(this.model.simulationDossierId)
        ]).then(([simulationDossierInstallateur, simulationDossierPilotage]) => {
            // Si l'utilisateur courant est un installateur, la priorité des données de contacts est sur celles de la société installateur du dossier et pas celles présentent dans son profil.
            this.affecterSociete(
                simulationDossierInstallateur,
                simulationDossierPilotage as unknown as SimulationDossierSocietePilotage)
            this.finaliserChargement();
        });
    }

    /**
     * Hook qui se déclenche lorsque le dom est monté.
     *
     * @memberof DonneesInstallateur
     */
    public mounted(): void {     
        this.setLoadingWizard(false);
    }

    // Utilisateur courant est un compte installateur.
    public get isInstallateur(): boolean {
        return super.isConnectedUserInstallateur;
    }

    // Utilisateur courant est un compte AA avec client Installateur.
    public get isApporteurAffairesClientInstallateur(): boolean {
        return ((this.dossier && this.dossier.isDossierApporteurAffaires) || super.isConnectedUserApporteurAffaires)
            && this.dossier.clientRepresenteId === ClientRepresente.ClientInstallateur;
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        // Avant de relancer la validation, on clear les messages d'erreurs.
        this.setErrorMessage(null);
        const valid = this.$refs.form.validate();
        if ((valid) && !this.estDossier) {
            this.removeChampsNonRenseignes(TypeDeWizardComposant.DonneesInstallateur);

            return new Promise<ResultatValidationEtape>((resolve) => {
                // Préparation du contact avant soumission au back.
                this.completerAvantEnvoi();
                // Lie la société.
                this.creerOuLierSocieteSimulationDossier(this.model.simulationDossierId)
                    .then(() => {
                        this.setErrorMessage(null);
                        resolve(new ResultatValidationEtape(true, true));
                    })
                    .catch((error) => {
                        this.setErrorMessage([{ text: error }] as Message[]);
                        resolve(new ResultatValidationEtape(false, this.currentUser.estInterne));
                    });
            });
        } else {
            if (!valid) {

                const errors = this.$refs.form.inputs.filter(
                    (input: any) => input.errorBucket.length).map((error: any) => error.errorBucket);
                this.addChampsNonRenseignes({ etape: TypeDeWizardComposant.DonneesInstallateur, champs: errors });
            }

            // Si simulation et client bénéficiaire => on skip la validation.
            if (!this.model.isDossier && !this.estModifiee) {
                const estValide = valid || this.donneesOngletSociete.clientRepresenteId === ClientRepresente.ClientBeneficiaire;
                return Promise.resolve(new ResultatValidationEtape(estValide, (estValide || this.currentUser.estInterne)));
            }

            return Promise.resolve(new ResultatValidationEtape(valid, (valid || this.estAdministrateur)));
        }
    }

    /**
     * Réinitialisation du formulaire.
     *
     * @memberof SiteDesTravaux
     */
    public reset(): void {
        this.resetDonnees(SimulationDossierSocieteInstallateur);
        this.$refs.form.reset();
    }

    /**
     * Récupérer le bouton HTML pour accéder au suivant.
     *
     * @param {number} index
     * @returns {HTMLElement}
     * @memberof DonneesInstallateur
     */
    public getHtmlButtonElement(index: number): HTMLElement {
        return document.getElementsByClassName('wizard-btn')[index] as HTMLElement;
    }

    /**
     * Exporter le modèle de l'onglet courant pour enregistrement du dossier.
     *
     * @returns {{model: any, meta: any}}
     * @memberof DonneesBeneficiaire
     */
    public exporterEtapeModel(): { model: any, meta: any } {
        // Préparation du contact avant soumission au back.
        this.completerAvantEnvoi();
        (this.donneesOngletSocietePilotage.societe as any).estModifiee = true;
        // Retourne la DATA.
        return {
            model: this.donneesOngletSociete.societe,
            meta: {
                typeInstallateurId: this.donneesOngletSociete.typeClientId || null,
                societeInstallateurId: this.donneesOngletSociete.societeId || null,
                societePilotageId: this.estPilotage ? this.donneesOngletSocietePilotage.societeId || null : null,
                societePilotage: this.estPilotage ? this.donneesOngletSocietePilotage.societe : null,
                param: 'societeInstallateur',
            }
        };
    }

    /**
     * Vérifier si l'une des opérations enregistré à un cas de précarité défini sur le cas 2.
     *
     * @readonly
     * @type {boolean}
     * @memberof DonneesInstallateur
     */
    @Watch('dossier.operations', { immediate: true })
    public onOperationsForDossierChanged(newItems: Operation[]) {

        if (this.estDossier) {
            const operations = ((newItems || []) as Operation[]);
            if (newItems && newItems.length >= 1) {
                this.estPilotage = operations.some(itemOperation => {
                    return itemOperation && this.listeOperationsPiloatge.includes(itemOperation.code)
                });
            } else {
                this.estPilotage = false;
            }
        } else {
            this.estPilotage = false;
        }

    }
    private listeOperationsPiloatge: Array<string> = ['BAR-TH-107-SE'];
    /**
     * Méthode à appeler en fin de chargement du composant pour le marquer comme initialisé.
     */
    private finaliserChargement(): void {

        // Marque l'étape comme initialisée, on peut commencer à traquer les changements.
        this.majModeleDonnees();
        this.rafraichirEtape(true);
        this.setLoadingWizard(false);
    }
    /**
     * Complète certaines propriétés avant de l'envoyer au Back.
     */
    private completerAvantEnvoi(): void {
        // Préparation du contact avant soumission au back.
        // Si c'est une simulation, on fait des check en plus.
        // Sur l'adresse Id.
        if (!this.estDossier && this.donneesOngletSociete.societe) {
            // Vérification AdresseId
            if (!this.donneesOngletSociete.societe.adresseId) {
                this.donneesOngletSociete.societe.adresseId = 0;
            }
            if (!this.donneesOngletSocietePilotage.societe.adresseId) {
                this.donneesOngletSocietePilotage.societe.adresseId = 0;
            }
        }
        this.donneesOngletSociete.typeClientId = this.donneesOngletSociete.typeClientId || null;
        this.donneesOngletSocietePilotage.typeClientId = this.donneesOngletSocietePilotage.typeClientId || null;
        // On ajoute les 2 contact possibles pour les bénéficiaires.
        // Gestion installateur.
        this.donneesOngletSociete.societe.contacts = [];
        this.donneesOngletSociete.societe.contacts.push(...[
            this.donneesOngletSociete.societe.interlocuteur,
            this.donneesOngletSociete.societe.signataire
        ]);
        //
        if (this.donneesOngletSociete.typeClientId === 2) {
            this.donneesOngletSociete.societe.societeSousTraitante.contacts = [];
            this.donneesOngletSociete.societe.societeSousTraitante.contacts.push(this.donneesOngletSociete.societe.sousTraitant);
        } else {
            this.donneesOngletSociete.societe.societeSousTraitante = null;
        }

        // Gestion pilotage.
        this.donneesOngletSocietePilotage.societe.contacts = [];
        this.donneesOngletSocietePilotage.societe.contacts.push(...[
            this.donneesOngletSocietePilotage.societe.signataire
        ]);
    }

    /**
     * Complète certaines propriétés avant de l'envoyer au Back.
     */
    private affecterSociete(       
        simulationDossierInstallateur: SimulationDossierSocieteInstallateur,
        simulationDossierPilotage: SimulationDossierSocietePilotage): void {
        
        // Gestion Société Installatrice.
        if (simulationDossierInstallateur.societe) {
            this.donneesOngletSociete = simulationDossierInstallateur;
            this.flags.societeAlreadyExist = true;
        }
        else {
            this.donneesOngletSociete.clientRepresenteId = simulationDossierInstallateur.clientRepresenteId;           
        }

        // Gestion Société Pilotage.
        if (simulationDossierPilotage) {
            this.donneesOngletSocietePilotage = simulationDossierPilotage;
            this.donneesOngletSocietePilotage.societeId = simulationDossierPilotage.societeId;
            if (!simulationDossierPilotage.societe) {
                this.donneesOngletSocietePilotage.societe = new Societe();
            }
        }
    }

    // Crée un Signataire vide pour société de pilotage.
    public creerDefaultSignatairePilotage(): Contact {
        const signataire = new Contact(TypeContact.Signataire);
        if (this.donneesOngletSocietePilotage.societe
            && this.donneesOngletSocietePilotage.societe.contacts) {
            const contacts = this.donneesOngletSocietePilotage.societe.contacts;
            const hasSignataire = contacts.some((c) => c.typeContactId === TypeContact.Signataire);
            if (!hasSignataire) {
                this.donneesOngletSocietePilotage.societe.contacts.push(signataire);
            }
        }
        return signataire;
    }

    // Réinitialisation des données du formulaire de sous-traitant.
    public gestionSousTraitant(): void {

        if (this.donneesOngletSociete.typeClientId === 2
            && (this.donneesOngletSociete.societe.societeSousTraitante === null
                || this.donneesOngletSociete.societe.sousTraitant === null)) {
            this.donneesOngletSociete.societe.sousTraitant = new Contact(TypeContact.SousTraitant);
            // Initialisation de la société sous traitante.
            this.donneesOngletSociete.societe.societeSousTraitante = new Societe();
            this.donneesOngletSociete.societe.societeSousTraitante.adresse = null;
            // Forme juridique par défaut pour les sous-traitantes
            this.donneesOngletSociete.societe.societeSousTraitante.formeJuridiqueId = 13;
        }
        else if (this.donneesOngletSociete.typeClientId !== 2) {
            this.donneesOngletSociete.societe.sousTraitant = null;
            this.donneesOngletSociete.societe.societeSousTraitante = null;
        }
    }
}
