






































import { Component, Prop, Mixins } from 'vue-property-decorator';
import ChoixSdcForm from './ChoixSdcForm.vue';
import { Getter, Mutation } from 'vuex-class';
import { ResultatSimulationStoreMethods } from '@/store/modules/simulation/ResultatSimulationStore';
import MontantRetroceder from '@/components/Wizard/Commun/models/MontantRetroceder';
import ResultatSimulationValidator from '@/components/Wizard/Commun/ResultatSimulation/ResultatSimulation.Validator';
import { round } from 'lodash';

@Component({
    name: 'MontantRetrocederForm',
    components: { ChoixSdcForm }
})
export default class MontantRetrocederForm extends Mixins(ResultatSimulationValidator) {
    @Prop({ default: true }) private estBlocVisible!: boolean;

    @Prop({ default: 0 }) private prixInstallateur!: number;

    @Prop({ default: "" }) private messageCoupDePouce!: string;

    @Prop({ default: 0 }) private montantCoupDePouce!: number;

    @Prop({ default: false }) private isDisabled!: boolean;       

    @Getter(ResultatSimulationStoreMethods.MONTANT_A_RETROCEDER)
    private montantRetroceder: MontantRetroceder;

    @Mutation(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER_BY_PRIORITIES)
    private setParamMontantRetroceder: (property: Object) => void;

    public montantARetrocederRuleCustom(): string | boolean {
        if (!this.montantRetroceder.montant) {
            return 'Le montant à rétroceder est obligatoire';
        }
        else if (isNaN(this.montantRetroceder.montant)) {
            return 'Le montant de la remise n\'est pas au bon format';
        }
        else if (this.montantCoupDePouce && this.montantRetroceder.montant < this.montantCoupDePouce) {
            return 'La remise CEE effectuée pour les offres Coup de Pouce doit être supérieure ou égale à ' + this.messageCoupDePouce;
        }
        else if (this.montantRetroceder.montant > this.prixInstallateur) {
            return 'Le montant à rétrocéder au client ne peut excéder celui de la prime CEE versée';
        }
        else if (this.montantRetroceder.montant < this.montantARetrocederMinimumReel) {
            return 'Le montant à rétrocéder au client doit représenter au minimum 25% de la prime CEE versée';
        }
        return true;
    }

    /**
     * Affiche un message d'information non-bloquant à l'utilisateur si le montant est compris entre 25% et 65% de la prime CEE versée.
     */
    public get montantARetrocederInformation(): string {
        if (this.montantRetroceder.montant
            && this.montantRetroceder.montant >= this.montantARetrocederMinimumReel
            && this.montantRetroceder.montant < this.montantARetrocederMinimumTheorique) {
            return 'Rappel : Le montant à rétrocéder au client devra représenter au minimum 65% de la prime CEE versée'
        }
        return '';
    }

    /**
     * Propriété calculée pour la valeur minimale réelle du montant à rétrocéder (on bloque l'utilisateur à moins de 25% de la prime CEE versée).
     */
    public get montantARetrocederMinimumReel(): number {
        return round((this.prixInstallateur * 0.25), 2);
    }

    /**
     * Propriété calculée pour la valeur minimale théorique du montant à rétrocéder (on affiche un message d'information à moins de 25% de la prime CEE versée).
     */
    public get montantARetrocederMinimumTheorique(): number {
        return round((this.prixInstallateur * 0.65), 2);
    }
}
