































import { Component, Vue } from 'vue-property-decorator';

interface SnackbarType {
    enabled: boolean;
    color: string;
    mode: string;
    timeout: number;
    message: string;
    callback: (() => Promise<void>);
    location: string;
    close: {
        text: string;
        color: string;
    };
}

@Component({
    name: 'DialogLoader',
})
export default class DialogLoader extends Vue {
    public dialog = false;
    public resolve: ((value: boolean | PromiseLike<boolean>) => void) = (() => {/* Laissez vide express.*/ });
    public reject: ((reason?: string) => void) = (() => {/* Laissez vide express.*/ });
    public message: string | null = null;
    public title: string | null = null;
    public options: { color: string; width: number; zIndex: number } = {
        color: 'primary',
        width: 500,
        zIndex: 200,
    };
    public snackbarVisible = false;
    public snackbar: SnackbarType = {
        enabled: false,
        color: 'success',
        mode: 'multi-line',
        timeout: 4000,
        message: 'Success!',
        callback: (() => Promise.resolve(undefined)),
        location: 'bottom',
        close: {
            text: 'Close',
            color: ''
        }
    };
    /**
     * Show loader without any callbacks or timeout (must be manually hidden)
     * @param {string} message
     * @param {object} options
     */
    public show(message: string | null, options: { color: string; width: number; zIndex: number }): void {
        this.dialog = true;
        this.message = message;
        this.options = Object.assign(this.options, options);
    }
    /**
     * Hide loader (and show SNACKBAR if enabled)
     */
    public hide(): void {
        this.dialog = false;
        if (this.snackbar.enabled) {
            this.showSnackbar(null, null);
        }
    }
    /**
     * Start/Show Loader (and maybe SNACKBAR)
     *
     * @param {string} message
     * @param {object} options
     * @param {Promise.<function>} [callback]
     * @param {boolean|object} [snackbar]
     */
    public start(message: string | null,
        options: { color: string; width: number; zIndex: number },
        callback: (() => Promise<void>),
        snackbar: boolean | { enabled: boolean }): void {
        this.show(message, options);

        if (typeof snackbar === 'object') {
            this.snackbar.enabled = true;
            this.snackbar = Object.assign(this.snackbar, snackbar);
        } else if (typeof snackbar !== 'undefined') {
            this.snackbar.enabled = true;
        }

        if (typeof callback === 'function') {
            callback().then(this.hide).catch(error => {
                this.snackbar.color = 'error';
                this.snackbar.message = error;
                this.hide();
            });
        }
    }
    /**
     * Stop/Hide loader and show SNACKBAR with optional callback
     *
     * @param {string} message
     * @param {object} snackbarOptions
     * @param {function} callback
     */
    public stop(
        message: string | null,
        snackbarOptions: SnackbarType | null,
        callback: (() => Promise<void>)
    ): void {
        this.hide();

        this.snackbar.enabled = true;
        this.snackbar = Object.assign(this.snackbar, snackbarOptions);

        if (typeof callback === 'function') {
            this.snackbar.callback = callback;
            // Use our own timeout to call callback
            setTimeout(this.hideSnackbar, this.snackbar.timeout);
            // Set to zero to allow our timeout above to handle hiding
            this.snackbar.timeout = 0;
        }
    }
    /**
     * Hide SNACKBAR (and call callback if previously set)
     */
    public hideSnackbar(): void {
        this.snackbarVisible = false;
        if (this.snackbar.callback) {
            this.snackbar.callback();
        }
    }
    /**
     * Show SNACKBAR
     * @param {string} message
     * @param {object} snackbarOptions
     */
    public showSnackbar(message: string | null, snackbarOptions: SnackbarType | null): void {
        if (message) {
            this.snackbar.message = message;
        }
        this.snackbar.enabled = true;

        if (typeof snackbarOptions === 'object') {
            this.snackbar = Object.assign(this.snackbar, snackbarOptions);
        }

        this.snackbarVisible = true;
    }
}
