import { Component, Mixins } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';
import { isValidIBAN, isValidBIC} from 'ibantools';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant DonneesBeneficiaire.
 */
@Component
export default class FacturationValidator extends Mixins(ValidationMixin) {
    public ibanRules: any = [
        (value: string | any) => {
            return !value
                || isValidIBAN(value.toUpperCase())
                || 'L\'iban n\'est pas valide.';
        },
    ];

    public bicRules: any = [
        (value: string | any) => {
            return !value
                || isValidBIC(value)
                || 'Le BIC n\'est pas valide.';
        },
    ];

    public numeroFactureRules: any = [
        (value: string | any) => {
            return !value
                || value.length < 21
                || 'Le numéro de facture ne peut pas excéder plus de 20 caractères.';
        },
    ];

    // Vérifie si le champ SIRET est valide
    public siretRules = [
        (v: string | any) => this.verifySiret(v) || 'Le siret n\'est pas valide',
    ];

    public commentaireRules: any = [];
}
