import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';

import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';

import template from './Valorisation.Template.vue';
import { SimulationOperation } from '../models';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { ContratApporteursAffaires,  Profil } from '@/models';
import { UserProfile } from '@/store/modules/auth/types';
import { DataTableHeader, Message } from '@/shared/models';
import DataTableValorisationOperation from './DataTableValorisationOperation.vue';
import '@/shared/extension/ArrayExtensions';
import { ApiService } from '@/services/base/ApiService';
import ValorisationValidator from './Valorisation.Validator';
import { StatutSimulation } from '@/shared/enums';
import { EtapeParcoursSimulation } from '@/shared/enums/EtapeSimulation';
import { getPrecisionFromNumber } from '@/shared/helpers/Utility';
import { ConvertHelper } from '@/shared/helpers';
import CeeCurrencyTextField from '@/components/CeeCurrencyTextField.vue';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';

/**
 * Énumération Type du résultat Classique Ou  résultat Précarité.
 *
 * @enum {number}
 */
enum TypeResultat {
    CEEClassique = 'CEE Classique',
    CEEPrecarite = 'CEE Précarite'
}
//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentBase, ValorisationValidator, Vue { }

@Component({
    ...template,
    ref: 'Valorisation',
    name: 'Valorisation',
    mixins: [template, CommonMixin, WizardEtapeComponentBase],
    components: { DataTableValorisationOperation, CeeCurrencyTextField }
})
export default class Valorisation extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentBase, ValorisationValidator) implements IWizardEtapeComponent {
    [x: string]: any;

    public titre(): string { return 'Valorisation'; }
    public icone(): string { return 'fas fa-euro-sign'; }

    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement,
    };

    /**
     * Permet d'exposer les enum au sein du template.
     *
     */
    private StatutSimulation: typeof StatutSimulation = StatutSimulation;
    private EtapeParcoursSimulation: typeof EtapeParcoursSimulation = EtapeParcoursSimulation;

    /**
     * Affichage de la boite de dialogue après une demande de valorisation.
     *
     */
    private informationDialog: boolean = false;

    /**
     * Simulation Opérations.
     *
     * @private
     * @type {SimulationOperation}
     * @memberof Valorisation
     */
    private simulation: SimulationOperation = new SimulationOperation();

    /**
     * Contrat Apporteur d'affaires.
     *
     * @private
     * @type {ContratApporteursAffaires}
     * @memberof Valorisation
     */
    private contratApporteurAffaire: ContratApporteursAffaires = new ContratApporteursAffaires();

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    /**
     * Identifiant profil.
     *
     * @type {number}
     * @memberof Valorisation
     */
    public profilId: number = 0;

    /**
     * Profils.
     *
     * @private
     * @type {Profil[]}
     * @memberof ResultatSimulation
     */
    private profils: Profil[] = null;

    /**
     * Regroupement des parts initiales.
     *
     * @private
     * @type {{
     *         partBeneficiaireClassique: number,
     *         partBeneficiairePrecarite: number,
     *         partApporteurAffaires: number
     *     }}
     * @memberof Valorisation
     */
    private partsInitial: {
        partBeneficiaireClassique: number,
        partBeneficiairePrecarite: number,
        partApporteurAffaires: number
    } = { partBeneficiaireClassique: 0.0, partBeneficiairePrecarite: 0.0, partApporteurAffaires: 0.0 };

    /**
     * Regroupement des parts pour un administrateur.
     *
     * @private
     * @type {{
     *         partBeneficiaireClassique: number,
     *         partBeneficiairePrecarite: number,
     *         partApporteurAffaires: number
     *     }}
     * @memberof Valorisation
     */
    private partsPourAdmin: {
        partBeneficiaireClassique: number,
        partBeneficiairePrecarite: number,
        partApporteurAffaires: number
    } = { partBeneficiaireClassique: 0.0, partBeneficiairePrecarite: 0.0, partApporteurAffaires: 0.0 };

    /**
     * Regroupement des parts.
     *
     * @private
     * @type {{
     *         partBeneficiaireClassique: number,
     *         partBeneficiairePrecarite: number,
     *         partApporteurAffaires: number
     *     }}
     * @memberof Valorisation
     */
    private partsPourAdminKwh: {
        partBeneficiaireClassique: number,
        partBeneficiairePrecarite: number,
        partApporteurAffaires: number
    } = { partBeneficiaireClassique: 0.0, partBeneficiairePrecarite: 0.0, partApporteurAffaires: 0.0 };

    /**
     * Part AA destiné au slider en MWh.
     *
     * @private
     * @type {number}
     * @memberof Valorisation
     */
    private partApporteurAffaireSliderMWh: number = 0;


    /**
     * Part AA destiné au slider en MWh.
     *
     * @private
     * @type {number}
     * @memberof Valorisation
     */
    private nbDecimal: number = 0.0;

    private stepSlider: number = 0.0;

    /**
     * Colonnes du tableau.
     *
     * @private
     * @type {DataTableHeader[]}
     * @memberof Valorisation
     */
    private columns: DataTableHeader[] = [
        { text: '', align: 'left' },
        { text: 'Prime INSTALLATEUR (€)', align: 'left' },
        { text: 'Votre rémunération (€)', align: 'left' },
    ];
    /**
     * Données du tableau.
     *
     * @private
     * @type {Array<{
     *         classiqueOuPrecarite: string,
     *         primeInstallateur: number,
     *         primeAA: number,
     *     }>}
     * @memberof Valorisation
     */
    private itemsGlobal: Array<{
        classiqueOuPrecarite: string,
        primeInstallateur: number,
        primeAA: number,
    }> = [];

    /**
     * Données du tableau pour un administrateur.
     *
     * @private
     * @type {Array<{
     *         classiqueOuPrecarite: string,
     *         primeInstallateur: number,
     *         primeAA: number,
     *     }>}
     * @memberof Valorisation
     */
    private itemsAdmin: Array<{
        classiqueOuPrecarite: string,
        primeInstallateur: number,
        primeAA: number,
    }> = [];
    /**
     * Sommes des volumes classique.+
     * @private
     * @type {number}
     * @memberof Valorisation
     */
    private sommeDesVolumesClassiqueOperations: number = 0.0;
    /**
     * Sommes des volumes précarité.
     *
     * @private
     * @type {number}
     * @memberof Valorisation
     */
    private sommeDesVolumesPrecariteOperations: number = 0.0;

    private loading: boolean = false;

    /**
     * Type de client Installateur/Bénéficiaire.
     *
     * @type {number}
     * @memberof Valorisation
     */
    public typeClientId: number;

    /**
     * Intitulé du dossier.
     *
     * @private
     * @type {string}
     * @memberof Valorisation
     */
    private intituleDossier: string = '';

    /**
     * Chargement lors du render du composant en fonction du profil.
     *
     * @private
     * @type {boolean}
     * @memberof Valorisation
     */
    private isLoading: boolean = true;

    private partsDisplayed: {
        partBeneficiaireClassiqueData: number,
        partBeneficiairePrecariteData: number,
        partAAData: number,
    } = {
        partBeneficiaireClassiqueData: 0,
        partBeneficiairePrecariteData: 0,
        partAAData: 0,
    }
    /**
     * Constructeur.
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.setLoadingWizard(true);
        this.isLoading = true;
        this.getProfils().then(() => {
            this.fetchSimulation().then((idCreateur) => {
                this.fetchContratApporteurAffaires(idCreateur).then(() => {
                    this.isLoading = false;
                    this.setLoadingWizard(false);
                });
            });
        });
        this.getUserProfile.then((response: UserProfile) => this.profilId = response.profilId);
    }
    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        this.itemsGlobal.push(
            {
                classiqueOuPrecarite: TypeResultat.CEEClassique,
                primeInstallateur: this.normaliserPrixOuVolumes(this.contratApporteurAffaire.partBeneficiaireClassique * this.sommeDesVolumesClassiqueOperations),
                primeAA: this.normaliserPrixOuVolumes(this.contratApporteurAffaire.partApporteurAffaires * this.sommeDesVolumesClassiqueOperations),
            },
            {
                classiqueOuPrecarite: TypeResultat.CEEPrecarite,
                primeInstallateur: this.normaliserPrixOuVolumes(this.contratApporteurAffaire.partBeneficiairePrecarite * this.sommeDesVolumesPrecariteOperations),
                primeAA: this.normaliserPrixOuVolumes(this.contratApporteurAffaire.partApporteurAffaires * this.sommeDesVolumesPrecariteOperations),
            },
        );
        this.itemsAdmin.push(
            {
                classiqueOuPrecarite: TypeResultat.CEEClassique,
                primeInstallateur: this.normaliserPrixOuVolumes(this.partsPourAdmin.partBeneficiaireClassique * this.sommeDesVolumesClassiqueOperations),
                primeAA: this.normaliserPrixOuVolumes(this.partsPourAdmin.partApporteurAffaires * this.sommeDesVolumesClassiqueOperations),
            },
            {
                classiqueOuPrecarite: TypeResultat.CEEPrecarite,
                primeInstallateur: this.partsPourAdmin.partBeneficiairePrecarite * this.sommeDesVolumesPrecariteOperations,
                primeAA: this.partsPourAdmin.partApporteurAffaires * this.sommeDesVolumesPrecariteOperations,
            },
        );
    }
    /**
     * Propriété computed get: part bénéficiaire classique.
     *
     * @readonly
     * @memberof Valorisation
     */
    public get partBeneficiaireClassique() {
        this.contratApporteurAffaire.partBeneficiaireClassique = Math.max(this.partsInitial.partBeneficiaireClassique + this.partAccordeeBeneficiaire, 0);
        this.partsDisplayed.partBeneficiaireClassiqueData = this.contratApporteurAffaire.partBeneficiaireClassique * 1000;
        return this.contratApporteurAffaire.partBeneficiaireClassique;
    }

    /**
     * Propriété computed get : part bénéficiaire précarité.
     *
     * @readonly
     * @memberof Valorisation
     */
    public get partBeneficiairePrecarite() {
        this.contratApporteurAffaire.partBeneficiairePrecarite = Math.max(this.partsInitial.partBeneficiairePrecarite + this.partAccordeeBeneficiaire, 0);
        this.partsDisplayed.partBeneficiairePrecariteData = this.contratApporteurAffaire.partBeneficiairePrecarite * 1000;
        return this.contratApporteurAffaire.partBeneficiairePrecarite;
    }

    /**
     * Propriété computed set: part bénéficiaire classique administrateur.
     *
     * @memberof Valorisation
     */
    public setPartBeneficiaireClassiqueAdmin(partBeneficiareClassiqueValue: string) {
        this.partsDisplayed.partBeneficiaireClassiqueData = parseFloat(partBeneficiareClassiqueValue);
        this.partsPourAdmin.partBeneficiaireClassique = parseFloat(partBeneficiareClassiqueValue) - this.partAccordeeBeneficiaire * 1000;
    }

    /**
     * Propriété computed set: part bénéficiaire précarité administrateur.
     *
     * @memberof Valorisation
     */
    public setPartBeneficiairePrecariteAdmin(partBeneficiarePrecariteValue: string) {
        this.partsDisplayed.partBeneficiairePrecariteData = parseFloat(partBeneficiarePrecariteValue);
        this.partsPourAdmin.partBeneficiairePrecarite = ConvertHelper.parseFloat(partBeneficiarePrecariteValue) - this.partAccordeeBeneficiaire * 1000;
    }

    /**
     * Met à jour les parts bénéficiaire pour que la somme reste la même.
     */
    public setPartApporteurAffairesAdmin(partApporteurAffaires: string): void {
        this.partsPourAdmin.partApporteurAffaires = parseFloat(partApporteurAffaires) || 0;
        this.partsDisplayed.partAAData = parseFloat(partApporteurAffaires) || 0;
        this.partApporteurAffaireSliderMWh = parseFloat(partApporteurAffaires) || 0;

        // Met à jour les parts bénéficiaires uniquement si on avait des données de contrat.
        if (this.contratApporteurAffaire.prixFixeAuContrat) {
            this.setPartBeneficiaireClassiqueAdmin('' + this.partBeneficiaireClassique * 1000);
            this.setPartBeneficiairePrecariteAdmin('' + this.partBeneficiairePrecarite * 1000);
        }
    }

    /**
     * Part supplémentaire accordée par l'apporteur d'affaire au bénéficiaire.
     * S'il s'agit d'un prix fixé au dossier, l'apporteur d'affaire ne peut pas fixer de prix.
     */
    public get partAccordeeBeneficiaire(): number {
        return this.contratApporteurAffaire.prixFixeAuContrat
            ? this.partsInitial.partApporteurAffaires - this.toEurosKWhc(this.partApporteurAffaireSliderMWh)
            : 0;
    }

    /**
     * Fonction computed des items du tableau.
     *
     * @readonly
     * @memberof Valorisation
     */
    public get itemsGlobalList() {
        this.itemsGlobal.forEach((item) => {
            if (this.simulation.operations && this.simulation.operations.length > 0) {
                this.majItemsList(item, {
                    partBeneficiaireClassique: this.partBeneficiaireClassique,
                    partBeneficiairePrecarite: this.partBeneficiairePrecarite,
                    partApporteurAffaires: this.toEurosKWhc(this.partApporteurAffaireSliderMWh)
                });
            }
        });
        return this.itemsGlobal;
    }
    /**
     * Fonction computed des items du tableau.
     *
     * @readonly
     * @memberof Valorisation
     */
    public get itemsAdminList() {
        this.itemsAdmin.forEach((item) => {
            Object.assign(this.partsPourAdminKwh, {
                partBeneficiaireClassique: this.toEurosKWhc(this.partsPourAdmin.partBeneficiaireClassique + this.partAccordeeBeneficiaire * 1000),
                partBeneficiairePrecarite: this.toEurosKWhc(this.partsPourAdmin.partBeneficiairePrecarite + this.partAccordeeBeneficiaire * 1000),
                partApporteurAffaires: this.toEurosKWhc(this.partsPourAdmin.partApporteurAffaires),
            });
            if (this.simulation.operations && this.simulation.operations.length) {
                this.majItemsList(item, this.partsPourAdminKwh);
            }
        });
        return this.itemsAdmin;
    }

    /**
     * Mise à jour des items du tableau récapitulatif applicable sur les getters.
     *
     * @param {{
     *         classiqueOuPrecarite: string,
     *         primeInstallateur: number,
     *         primeAA: number,
     *     }} item
     * @param {{
     *         partBeneficiaireClassique: number,
     *         partBeneficiairePrecarite: number,
     *         partApporteurAffaires: number
     *     }} parts
     * @memberof Valorisation
     */
    public majItemsList(item: {
        classiqueOuPrecarite: string,
        primeInstallateur: number,
        primeAA: number,
    },                  parts: {
        partBeneficiaireClassique: number,
        partBeneficiairePrecarite: number,
        partApporteurAffaires: number
    }) {
        if (item.classiqueOuPrecarite === TypeResultat.CEEClassique) {
            item.primeInstallateur = this.normaliserPrixOuVolumes(parts.partBeneficiaireClassique * this.sommeDesVolumesClassiqueOperations);
            item.primeAA = this.normaliserPrixOuVolumes(parts.partApporteurAffaires * this.sommeDesVolumesClassiqueOperations);
        } else if (item.classiqueOuPrecarite === TypeResultat.CEEPrecarite) {
            item.primeInstallateur = this.normaliserPrixOuVolumes(parts.partBeneficiairePrecarite * this.sommeDesVolumesPrecariteOperations);
            item.primeAA = this.normaliserPrixOuVolumes(parts.partApporteurAffaires * this.sommeDesVolumesPrecariteOperations);
        }
    }
    /**
     * Vérifier si l'utilisateur courant est un administrateur.
     *
     * @returns {boolean}
     * @memberof Valorisation
     */
    public get estAdministrateur(): boolean {
        if (this.profils) {
            return this.profils.find((profil) => profil.id === this.profilId).isAdministrateur;
        }
        return false;
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        if (!this.contratApporteurAffaire.prixFixeAuContrat && !this.estAdministrateur) {
            return Promise.resolve(new ResultatValidationEtape(this.estPrixFixeAdministrateur, this.estPrixFixeAdministrateur));
        } else {
            if (this.$refs.form.validate()) {
                return new Promise<ResultatValidationEtape>((resolve) => {
                    this.updatePrixOperations().then((succeeded) => {
                        if (succeeded) {
                            this.majPaiements().then(() => {
                                this.changerStatutSimulation(StatutSimulation.EN_SIMULATION, EtapeParcoursSimulation.ResultatSimulation)
                                    .then(() => {
                                        resolve(new ResultatValidationEtape(true, true));
                                    });
                            });
                        }
                    });
                })
                .catch(() => {
                    this.setErrorMessage([{ text: 'Une erreur est survenue lors de la valorisation' }] as Message[]);
                    return Promise.resolve(new ResultatValidationEtape(false, false));
                });
            } else {
                return Promise.resolve(new ResultatValidationEtape(false, false));
            }
        }
    }

    /**
     * Récupération des données.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private fetchSimulation(): Promise<number> {
        return new Promise<number>((resolve) => {
            return this.$http.get(`/simulation/recupererSimulation/${this.model.simulationDossierId}`).then((result: any) => {
                this.simulation.statutSimulationId = result.data.data.statutSimulationId;
                Vue.set(this.simulation, 'operations', result.data.data.operations);

                this.typeClientId = result.data.data.clientRepresenteId;
                this.sommeDesVolumesClassiqueOperations = this.simulation.operations.SumBy((o) => o.volumeCeeClassique);
                this.sommeDesVolumesPrecariteOperations = this.simulation.operations.SumBy((o) => o.volumeCeePrecarite);
                resolve(this.estAdministrateur ? result.data.data.utilisateurCreationId : 0);
            })
                .catch((e: ExceptionInformation) => {
                throw new Error('Une erreur est survenue lors de la récupération des données de simulations');
            });

        });
    }

    /**
     * Récupération des données Contrat apporteur affaire.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private fetchContratApporteurAffaires(idCreateur: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            return this.$http.get(`/contratApporteursAffaires/obtenirParUtilisateur/${idCreateur}`).then((result: any) => {
                this.contratApporteurAffaire = result.data.data as ContratApporteursAffaires;
                this.partsInitial.partApporteurAffaires = this.contratApporteurAffaire.partApporteurAffaires;
                this.partApporteurAffaireSliderMWh = this.remunerationApporteurAffaires;
                this.partsInitial.partBeneficiaireClassique = this.partInstallateurClassique;
                this.partsInitial.partBeneficiairePrecarite = this.partInstallateurPrecarite;
                this.nbDecimal = getPrecisionFromNumber(this.contratApporteurAffaire.partApporteurAffaires * 1000) > 2 ? 3 : 2;
                this.stepSlider = this.nbDecimal === 3 ? 0.001 : 0.01;

                // Copie des données pour l'administrateur en convertissant l'unité.
                this.partsPourAdmin.partApporteurAffaires = this.remunerationApporteurAffaires;
                this.partsDisplayed.partAAData = this.partsPourAdmin.partApporteurAffaires;
                this.partsPourAdmin.partBeneficiaireClassique = this.partInstallateurClassique * 1000 ;
                this.partsPourAdmin.partBeneficiairePrecarite = this.partInstallateurPrecarite * 1000 ;
                this.partsDisplayed.partBeneficiaireClassiqueData = this.partInstallateurClassique * 1000 ;
                this.partsDisplayed.partBeneficiairePrecariteData = this.partInstallateurPrecarite * 1000 ;
                resolve();
            })
            .catch(() => {
                throw new Error('Une erreur est survenue lors de la récupération des données de contrat apporteur d\'affaires');
            });
        });
    }

    /**
     * Récupérer tous les profils.
     *
     * @returns {Promise<Profil[]>}
     * @memberof Valorisation
     */
    public getProfils(): Promise<void> {
        const profilService = new ApiService<Profil>('profil/obtenirTous');
        return new Promise<void>((resolve, reject) => {
            return profilService.getAll()
                .then((response) => {
                    this.profils = response.data.data;
                    resolve();
                })
                .catch((error: { response: Error; }) => {
                    throw new Error(error.response.message);
                });
        });
    }
    /**
     * Changement du statut de la simulation.
     *
     * @private
     * @memberof Valorisation
     */
    public changerStatutSimulation(statut: StatutSimulation, etape: EtapeParcoursSimulation): Promise<void> {
        this.loading = true;
        return new Promise((resolve, reject) => {
            return this.$http.post(`/simulation/modifierStatutSimulation`,
                { Id: this.model.simulationDossierId, StatutSimulationId: statut, etapeParcoursUtilisateurId: etape }
            ).then(() => {
                this.loading = false;
                resolve();
            })
                .catch(
                    () => {
                        throw new Error('Une erreur est survenue lors de la modification du statut de la simulation');
                    });
        });
    }

    public majPaiements() {
        return new Promise((resolve, reject) => {
            return this.$http.post(`/simulation/miseAJourPaiementsApresValorisation/${this.model.simulationDossierId}`,
            ).then(() => {
                resolve();
            })
                .catch(
                    () => {
                        throw new Error('Une erreur est survenue lors de la mise à jour des paiements');
                    });
        });
    }
    public validerEtChangerStatutSimulation(statut: StatutSimulation, etape: EtapeParcoursSimulation) {
        if (this.$refs.form.validate()) {
            this.changerStatutSimulation(statut, etape);
        }
    }
    /**
     * Mise à jour des prix et valorisation des opération dans le cas ou il y'a eu un changement.
     *
     * @private
     * @memberof Valorisation
     */
    public updatePrixOperations(): Promise<boolean> {
        if (this.partsInitial.partApporteurAffaires !== this.toEurosKWhc(this.partApporteurAffaireSliderMWh) || this.estAdministrateur) {
            const partBeneficiaireClassique = this.estAdministrateur ? this.partsPourAdminKwh.partBeneficiaireClassique : this.partBeneficiaireClassique;
            const partBeneficiairePrecarite = this.estAdministrateur ? this.partsPourAdminKwh.partBeneficiairePrecarite : this.partBeneficiairePrecarite;
            const partApporteurAffaires = this.estAdministrateur ? this.partsPourAdminKwh.partApporteurAffaires
                : this.toEurosKWhc(this.partApporteurAffaireSliderMWh);

            this.simulation.operations.forEach((operation) => {
                operation.remunerationApporteurAffaires = partApporteurAffaires;
                operation.valorisationCeeClassique = partBeneficiaireClassique;
                operation.valorisationCeePrecarite = partBeneficiairePrecarite;
            });
            return new Promise<boolean>((resolve, reject) => {
                return this.$http.put(`/operation/modifierCalculerOperations`, this.simulation.operations)
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch((error: { response: Error; }) => {
                        reject(error.response);
                    });
            });
        }
        return Promise.resolve(true);
    }
    /**
     * Normaliser les prix et les volumes avec une précision de 2 nombres après la virgule.
     *
     * @param {number} prixOuVolume
     * @returns
     * @memberof Valorisation
     */
    public normaliserPrixOuVolumes(prixOuVolume: number) {
        return ConvertHelper.parseFloat(prixOuVolume.toFixed(3));
    }
    /**
     * Conversion euro/MWhc => euro/KWhc. Force la limite de décimales à 6 car JS n'est pas capable de gérer une précision exacte
     * (voir https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript).
     *
     * @param {number} part
     * @returns
     * @memberof Valorisation
     */
    public toEurosKWhc(part: number): number {
        return (part / 1000).toFixed(6) as unknown as number;
    }

    public redirect(route: string) {

        this.$router.push({name: route});
    }

    /**
     * Détermine s'il faut afficher les champs réservés aux simulations AA avec prix fixé au contrat.
     */
    public get prixFixeAuContrat(): boolean {
        return this.contratApporteurAffaire.prixFixeAuContrat || this.estAdministrateur || this.estPrixFixeAdministrateur;
    }

    /**
     * Détermine si le prix de la simulation a été fixé par un administrateur.
     */
    public get estPrixFixeAdministrateur(): boolean {
        return this.simulation.operations
            && this.simulation.operations.length
            && this.simulation.operations[0].estPrixFixeAdministrateur;
    }

    /**
     * Rémunération AA fixée sur la simulation, ou par défaut la rémunération par défaut de l'apporteur affaires.
     */
    private get remunerationApporteurAffaires(): number {
        if (!this.estPremiereValorisation) {
            return this.simulation.operations[0].remunerationApporteurAffaires * 1000;
        }
        return this.contratApporteurAffaire.partApporteurAffaires * 1000;
    }

    /**
     * Part installateur fixée sur la simulation pour les CEE classique, ou par défaut la part bénéficiaire par défaut de l'apporteur affaires.
     */
    private get partInstallateurClassique(): number {
        if (!this.estPremiereValorisation) {
            return this.simulation.operations[0].valorisationCeeClassique - this.partAccordeeBeneficiaire;
        }
        return this.contratApporteurAffaire.partBeneficiaireClassique;
    }

    /**
     * Part installateur fixée sur la simulation pour les CEE précarité, ou par défaut la part bénéficiaire par défaut de l'apporteur affaires.
     */
    private get partInstallateurPrecarite(): number {
        if (!this.estPremiereValorisation) {
            return this.simulation.operations[0].valorisationCeePrecarite - this.partAccordeeBeneficiaire;
        }
        return this.contratApporteurAffaire.partBeneficiairePrecarite;
    }

    /**
     * Détermine si l'on a déjà eu une valorisation d'effectuée sur cette simulation.
     */
    private get estPremiereValorisation(): boolean {
        return !this.simulation.operations
            || !this.simulation.operations.length
            || this.simulation.statutSimulationId === StatutSimulation.EN_SIMULATION;
    }

    /**
     * Crée une nouvelle simulation.
     * @memberof ResultatSimulation
     */
    public nouvelleSimulation() {
        if (this.$route.name !== 'editer-simulation') {
            this.$router.push('/');
            this.$router.replace('/simulateur');
        } else {
            this.$router.push('/simulateur');
        }
    }
}
