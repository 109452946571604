import { SimulationDossierSocieteBase } from './SimulationDossierSocieteBase.model';

/**
 * Classe SimulationDossierSociete Bénéficiaire.
 *
 * @export
 * @class SimulationSociete
 */
export class SimulationDossierSocieteBeneficiaire extends SimulationDossierSocieteBase {
    /**
     * Le client est-il Syndic/SDC.
     *
     * @type {boolean}
     * @memberof SimulationSociete
     */
    public estSdc: boolean;

    /**
     * Nombre de bailleurs sociaux.
     *
     * @type {number}
     * @memberof SimulationDossierSocieteBeneficiaire
     */
    public nbBailleursSociaux: number = 1;

    constructor() {
        super();
        this.estSdc = false;
        this.nbBailleursSociaux = 1;
    }
}
