import Vue from 'vue';
import { Mixins, Prop } from 'vue-property-decorator';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { DateHelper } from '@/shared/helpers/DateHelper';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';
import Confirm from '@/components/Confirm.vue';
import DialogLoader from '@/components/DialogLoader.vue';
import template from './ResultatSimulation.Template.vue';
import { Profil, Operation, SiteTravaux, Societe } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { FileContentResult, Message } from '@/shared/models';
import ResultatSimulationTab from '@/components/Wizard/Commun/ResultatSimulation/ResultatSimulationTab.vue';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { OperationsStoreMethods } from '@/store/modules/operations/OperationsStore';
import { Getter, Mutation, Action } from 'vuex-class';
import { SimulationConvention } from '../models/SimulationConvention';
import { ClientRepresente } from '@/models/ClientRepresente.model';
import ChoixSecteurEtOperationElementDialog from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperationElementDialog.vue';
import { ResultatSimulationStoreMethods } from '@/store/modules/simulation/ResultatSimulationStore';
import MontantRetroceder from '../models/MontantRetroceder';
import MontantRetrocederForm from '@/components/Wizard/Commun/MontantRetrocederForm.vue';
import { cloneDeep } from 'lodash-es';
import ApiHelper from '@/services/ApiHelper';
import { EnumProfilsAsNumber, Profils } from '@/shared/enums';
import { getPrecisionFromNumber } from '@/shared/helpers/Utility';
import { ClientRepresente as EnumClientRepresente } from '@/shared/enums/ClientRepresente.enum';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { ChoixSecteurEtOperationResult } from '../models/ChoixSecteurEtOperationResult.model';
import { SujetPolitiqueControle } from '../models/SujetPolitiqueControle.model';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';


//  Créer une interface pour fusionner les mixins.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentBase, Vue { }

@Component({
    ...template,
    ref: 'ResultatSimulation',
    name: 'ResultatSimulation',
    mixins: [template, CommonMixin, WizardEtapeComponentBase],
    components: {
        ResultatSimulationTab,
        Confirm,
        DialogLoader,
        ChoixSecteurEtOperationElementDialog,
        MontantRetrocederForm,
    },
    filters: {
        formatDate(value: string): string {
            return DateHelper.format(value);
        },
        add2Months(value: string): string {
            return DateHelper.add(value, 2, 'M');
        }
    },
})
export default class ResultatSimulation
    extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentBase, Mixins(GrilleMixin)) implements IWizardEtapeComponent {
    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement,
        refFormChoixOperation: {
            validerFormulaire: (() => Promise<ChoixSecteurEtOperationResult>),
            resetFormulaire: (() => void),
        },
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        refChoixOperation: {
            open: ((title: string | null, message: string | null, options?: { color?: string, width?: number, zIndex?: number }) => Promise<any>),
        },
        dialogLoader: {
            start: ((message: string | null,
                options: { color: string, width: number, zIndex: number },
                callback: (() => Promise<void>),
                snackbar: boolean | { enabled: boolean },
            ) => Promise<boolean>),
        },
    };

    /**
     * Énumération de profil.
     *
     * @returns {Profils}
     * @memberof ResultatSimulation
     */
    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    @Mutation(OperationsStoreMethods.SET_OPERATION_EN_COURS)
    public setOperationEnCours: (operation: Operation) => void;

    @Prop()
    public referentielsEtape: DossierReferentiels;

    /**
     * Le titre du composant.
     *
     * @returns {string}
     * @memberof ResultatSimulation
     */
    public titre(): string { return 'Résultat de la simulation'; }
    /**
     * L'icône du composant.
     *
     * @returns {string}
     * @memberof ResultatSimulation
     */
    public icone(): string { return 'fas fa-hand-holding-usd'; }

    private siteTravaux: SiteTravaux = new SiteTravaux();
    /**
     * La list des opération liées a la simulation.
     *
     * @private
     * @type {Operation[]}
     * @memberof ResultatSimulation
     */
    public operations: Operation[] = new Array<Operation>();

    /**
     * Les types de clients représentés.
     *
     * @private
     * @type {Array<ClientRepresente>}
     * @memberof ResultatSimulation
     */
    private clientRepresente: ClientRepresente[] = new Array<ClientRepresente>();

    /**
     * Simulation Convention.
     *
     * @private
     * @type {SimulationConvention}
     * @memberof ResultatSimulation
     */
    private simulationConvention: SimulationConvention = new SimulationConvention();


    /**
     * Marque la fin de la génération de la convention
     *
     * @private
     * @type {boolean}
     * @memberof ResultatSimulation
     */
    private estConventionGenere: boolean = false;

    /**
     * Loading flag.
     *
     * @private
     * @type {boolean}
     * @memberof ResultatSimulation
     */
    private loading: boolean = false;

    /**
     * Récupère la liste des opération liées a la simulation.
     *
     * @private
     * @type {Operation[]}
     * @memberof ResultatSimulation
     */
    public get operationsList(): Operation[] {
        return this.operations;
    }

    /**
     * Définie la liste des opération liées a la simulation.
     *
     * @memberof ResultatSimulation
     */
    public set operationsList(value: Operation[]) {
        value.forEach((o: Operation) => this.operations.push(o));
    }

    /**
     * Profils.
     *
     * @private
     * @type {Profil[]}
     * @memberof ResultatSimulation
     */
    private profils: Profil[] = new Array<Profil>();

    /**
     * Profil.
     *
     * @private
     * @type {string}
     * @memberof ResultatSimulation
     */
    private profil: string = '';

    /**
     * Montant
     *
     * @private
     * @type {MontantRetroceder}
     * @memberof ResultatSimulation
     */
    @Getter(ResultatSimulationStoreMethods.MONTANT_A_RETROCEDER)
    private montantRetroceder: MontantRetroceder;

    @Mutation(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER)
    private setMontantRetroceder: (montantRetroceder: MontantRetroceder) => void;

    @Action(ResultatSimulationStoreMethods.MODIFIER_DONNEES_MONTANTRETROCEDER)
    private modifierInfosMontantRetroceder: () => Promise<MontantRetroceder>;

    @Mutation(InformationsSimulationDossierStoreMethods.SET_DISCRIMINATOR)
    private setDiscriminator: (value: string) => void;

    // Selon les droits de l'utilisateur : Permet de savoir si celui-ci peut accepter une simulation et la transformer en dossier.
    private hasAccesCreationDossier: boolean = true;

    /**
     *
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.setLoadingWizard(true);
        this.getProfils().then((data) => {
            this.profils = data;
        }).then(() => {
            this.getUserProfile.then((profile: any) => {
                if (!!profile) {
                    this.profil = profile.profilCode;
                    this.hasAccesCreationDossier = profile.hasAccesCreationSimulationDossier;
                } else {
                    throw new Error('Une erreur est survenue lors de la récupération des données');
                }
            });
        })
            .then(() => {
                this.majTarifs().then(() => {
                    this.fetchSimulation();
                });
            })
            .finally(() => {
                this.setLoadingWizard(false);
            });
    }

    /**
     * Permet de savoir si l'utilisateur est un interne ou pas.
     *
     */
    public estInterne(): boolean {
        const currentProfil: Profil = this.profils && this.profils.length ? this.profils.find((x) => x.name === this.profil) : null;
        return currentProfil ? currentProfil.isIntern : false;
    }

    /**
     * Permet de savoir si l'utilisateur est un apporteur d'affaires ou pas.
     *
     */
    public estApporteurAffaires(): boolean {
        return this.currentProfil ? this.currentProfil.id === EnumProfilsAsNumber.ApporteurAffaires : false;
    }

    /**
     * Permet de savoir si l'utilisateur est un administrateur ou pas.
     *
     */
    public estAdministrateur(): boolean {
        return this.currentProfil ? this.currentProfil.isAdministrateur : false;
    }

    /**
     * Indique si la simulation d'Apporteurs Affaires
     */
    public estSimulationApporteurAffaires: boolean = false;

    /**
     * Récupère le profil current de l'utilisateur connecté.
     *
     */
    public get currentProfil(): Profil {
        return this.profils && this.profils.length ? this.profils.find((x) => x.name === this.profil) : null;
    }

    public get prixTotalInstallateur() {
        return this.operations.SumBy((o) => (o.prixCeeClassique + o.prixCeePrecarite));
    }

    public get valorisationTotaleApporteurAffaires() {
        return this.operations.SumBy((o) => (o.valorisationApporteurAffaires));
    }

    public messageCoupDePouce: string = '';

    public montantCoupDePouce: number = 0;

    /**
     * Le nombre de decimal à afficher sur les prix pour un apporteur d'affaire.
     *
     * @private
     * @memberof ResultatSimulation
     */
    public nbDecimal: number = 2;

    /**
     * Information coup de pouce à afficher.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private informationCoupDePouce() {
        const operationCoupDePouce = this.operations.find((o: Operation) => (o.arreteLibelle.includes('Coup de pouce')));
        if (operationCoupDePouce) {
            let values = operationCoupDePouce.operationValues;
            values = Object.keys(values).map((key) => {
                return [key, values[key]];
            });
            let precarite = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'PRECARITE_CAS'));
            if (precarite) {
                precarite = precarite[1].value;
            }
            let surface = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'SURFACE_ISOLANT_OUVERTURE'));
            if (surface) {
                surface = surface[1].value;
            }
            let nbLogement = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_LOGEMENT_CP'));
            if (nbLogement) {
                nbLogement = nbLogement[1].value;
            }
            let nbEmetteur = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_EMETTEURS_ELECTRIQUES'));
            if (nbEmetteur) {
                nbEmetteur = nbEmetteur[1].value;
            }
            let nbChaudiere = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_CHAUDIERE'));
            if (nbChaudiere) {
                nbChaudiere = nbChaudiere[1].value;
            }
            const funcCondition = (value: string) => parseInt(value, 10) > 1;
            switch (operationCoupDePouce.code) {
                case 'BAR-EN-101':
                    this.montantCoupDePouce = funcCondition(precarite) ? 20 * surface : 10 * surface;
                    this.messageCoupDePouce = funcCondition(precarite) ? '20 €/m² d\'isolant posé' : '10 €/m² d\'isolant posé';
                    break;
                case 'BAR-EN-103':
                    if (operationCoupDePouce.arreteLibelle === 'Coup de pouce V3') {
                        this.montantCoupDePouce = funcCondition(precarite) ? 30 * surface : 20 * surface;
                        this.messageCoupDePouce = funcCondition(precarite) ? '30 €/m² d\'isolant posé' : '20 €/m² d\'isolant posé';
                    } else if (operationCoupDePouce.arreteLibelle === 'Coup de pouce V4') {
                        this.montantCoupDePouce = funcCondition(precarite) ? 20 * surface : 10 * surface;
                        this.messageCoupDePouce = funcCondition(precarite) ? '20 €/m² d\'isolant posé' : '10 €/m² d\'isolant posé';
                    }
                    break;
                case 'BAR-TH-104':
                    this.montantCoupDePouce = funcCondition(precarite) ? 4000 * nbLogement : 2500 * nbLogement;
                    this.messageCoupDePouce = funcCondition(precarite) ? '4000 € par pompe à chaleur posée' : '2500 € par pompe à chaleur posée';
                    break;
                case 'BAR-TH-106':
                    this.montantCoupDePouce = funcCondition(precarite) ? 1200 * nbLogement : 600 * nbLogement;
                    this.messageCoupDePouce = funcCondition(precarite) ? '1200 € par chaudière posée' : '600 € par chaudière posée';
                    break;
                case 'BAR-TH-113':
                    this.montantCoupDePouce = funcCondition(precarite) ? 4000 * nbLogement : 2500 * nbLogement;
                    this.messageCoupDePouce = funcCondition(precarite) ? '4000 € par chaudière posée' : '2500 € par chaudière posée';
                    break;
                case 'BAR-TH-158':
                    this.montantCoupDePouce = funcCondition(precarite) ? 100 * nbEmetteur : 50 * nbEmetteur;
                    this.messageCoupDePouce = funcCondition(precarite) ? '100 € par émetteur électrique installé' : '50 € par émetteur électrique installé';
                    break;
                case 'BAR-TH-163':
                    this.montantCoupDePouce = funcCondition(precarite) ? 700 * nbChaudiere : 450 * nbChaudiere;
                    this.messageCoupDePouce = funcCondition(precarite) ? '700 € par chaudière raccordée au conduit' : '450 € par chaudière raccordée au conduits';
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Récupération des données.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private fetchSimulation() {
        this.loading = true;
        this.$http.get(`/simulation/recupererSimulationConvention/${this.model.simulationDossierId}`).then((result: any) => {
            this.simulationConvention = result.data.data as SimulationConvention;
            this.simulationConvention.societeInstallateur = this.simulationConvention.societeInstallateur ? this.simulationConvention.societeInstallateur : new Societe();
            this.simulationConvention.siteTravaux = result.data.data.siteTravaux;
            this.operations = [];
            this.operationsList = result.data.data.operations as Operation[];
            if (!!this.simulationConvention.montantRetroceder) {
                this.setMontantRetroceder(new MontantRetroceder(this.simulationConvention.id, this.simulationConvention.montantRetroceder.montant,
                    this.simulationConvention.montantRetroceder.nomSyndic, this.simulationConvention.estSdc));
            } else {
                this.setMontantRetroceder(new MontantRetroceder(this.simulationConvention.id, 0, '', this.simulationConvention.estSdc));
            }
            this.informationCoupDePouce();
            this.nbDecimal = getPrecisionFromNumber(this.operationsList[0].remunerationApporteurAffaires * 1000) < 3 ? 2 : 3;
            this.estSimulationApporteurAffaires = result.data.data.utilisateurCreation.profilUtilisateur === Profils.ApporteurAffaires;

        }).catch(() => {
            throw new Error('Une erreur est survenue lors de la récupération des données');
        }).finally(() => this.loading = false);
    }

    public getProfils(): Promise<Profil[]> {
        const profilService = new ApiService<Profil>('profil/obtenirTous');
        return new Promise<Profil[]>((resolve, reject) => {
            return profilService.getAll().then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    public majTarifs(): Promise<void> {
        const simulationService = new ApiService<void>(`simulation/miseAjourTarifs/${this.model.simulationDossierId}`);
        return new Promise<void>((resolve, reject) => {
            return simulationService.post(null)
                .then(() => resolve())
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    public async genererConventionApporteurAffaires(endpoint: string): Promise<void> {
        this.setErrorMessage(null);
        let continueWithValoIsZero = true;
        if (this.simulationConvention.utilisateurCreation.profilUtilisateur === Profils.ApporteurAffaires) {
            await this.verifierValorisation().then((result) => continueWithValoIsZero = result);
        }
        if (this.$refs.form.validate() && continueWithValoIsZero) {
            this.loading = true;
            this.simulationConvention.montantRetroceder = cloneDeep(this.montantRetroceder);
            this.simulationConvention.estSdc = this.montantRetroceder.estSdc;
            this.$http.post(`simulation/${endpoint}`, this.simulationConvention)
                .then((response) => {
                    this.fetchSimulation();
                    this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.data.convention), response.data.data.nomFichier);
                    this.estConventionGenere = true;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.setErrorMessage([{ text: 'Une erreur est survenue lors de la génération de la convention.' }] as Message[]);
                });
        }
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        this.setErrorMessage(null);
        return Promise.resolve(new ResultatValidationEtape(true, true));
    }

    public createAndDownloadBlobFile(body: any, filename: string, extension = 'pdf') {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    public base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }
    /**
     * Ajouter une nouvelle opération.
     *
     * @private
     * @memberof ResultatSimulation
     */
    public ajoutOperation() {
        this.$refs.refChoixOperation.open(null, null, null).then((resultatChoixOperation) => {
            // Ajout de l'opération si le choix est non null (le choix est null si on a annulé le choix de l'opération).
            if (resultatChoixOperation) {
                this.$refs.dialogLoader.start('Ajout de l\'opération en cours...', null, () => {
                    return new Promise((resolve) => {
                        this.retournerTemplateOperation(true, this.model.simulationDossierId, [resultatChoixOperation]).then((operations: Operation[]) => {
                            setTimeout(() => {
                                try {
                                    // Résolve.
                                    resolve();
                                }
                                finally {
                                    // Ouvre la simulation.
                                    this.ouvrirOperation(operations[operations.length - 1]);
                                }
                            }, 0);
                        });
                    });
                }, { enabled: false });
            }
        });
    }

    /**
     * Retourne à l'étape des opérations en affichant l'opération en paramètre par défaut.
     * @param operation Opération à afficher par défaut.
     */
    public ouvrirOperation(operation: Operation) {
        this.bus.$emit('on-ouverture-operation', operation);
        this.setOperationEnCours(operation);
    }


    /**
     * Crée une nouvelle simulation.
     * @memberof ResultatSimulation
     */
    public nouvelleSimulation() {

        // Hack : si on était sur la création d'une simulation, la redirection ne fonctionnera pas.
        // Pour contourner le problème, on passe par une page intermédiaire.
        if (this.$route.name !== 'editer-simulation') {
            this.$router.push('/');
            this.$router.replace('/simulateur');
        } else {
            this.$router.push('/simulateur');
        }
    }

    /**
     * Accepter la simulation et créer le dossier.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private async accepterSimulation() {
        try {
            this.loading = true;

            // Récupération du profil créateur de la simulation.
            const none = { profilUtilisateur: Profils.None };
            const profil = (this.simulationConvention.utilisateurCreation || none).profilUtilisateur;

            // Récupération des infos pour choisir de générer ou pas le cadre de contribution.
            const estSdc = this.montantRetroceder.estSdc || profil === Profils.SyndicSDC;
            const estInstallateur = profil === Profils.Installateur;
            const estOperationResidentiel = this.simulationConvention.estOperationSecteurResidentielExiste;

            // Valider le formulaire pour un installateur avec un bénéficiaire sdc a défaut définir form comme valide.
            const valid = estInstallateur && estSdc ? this.$refs.form.validate() : true;

            if (valid) {
                if (estInstallateur && estSdc && estOperationResidentiel) {
                    // Modification du montant à rétrocéder.
                    await this.modifierInfosMontantRetroceder();
                }
                // Afficher une pop-up au cas ou une des opération est soumis a une politique de contrôle avant de transformer la simulation en dossier.
                this.confirmationInfoPolitiqueControle().then((confirmation: boolean) => {
                    if (confirmation) {
                        this.$refs.dialogLoader.start('Transformation de la simulation en dossier en cours...', null, () => {
                            return new Promise((resolve) => {
                                this.$http.post(`/simulation/transformerSimulationEnDossier/${this.model.simulationDossierId}`).then((response) => {
                                    this.setDiscriminator(null);
                                    this.$router.push({ name: 'editer-dossier', params: { id: response.data.data.id } }).catch(() => { /* Laissé vide exprès. */ });
                                }).finally(() => resolve());
                            });
                        }, { enabled: false });
                    }
                });
            }
        } catch (error) {
            throw new Error(error);
        } finally {
            this.loading = false;
        }
    }

    /**
    * Permet de verfifier que la valorisation saisit ne soit pas 0 avant de générer la convention
    *
    * @private
    * @memberof ResultatSimulation
    */
    public verifierValorisation(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (this.simulationConvention.operations[0].valorisationApporteurAffaires === 0 && this.simulationConvention) {
                this.$refs.confirm.open('Génération Convention',
                    "La part apporteur d'affaires est à 0, êtes vous sûr de vouloir quand même génerer la convention ?",
                    { color: 'blue', width: 700, zIndex: 200 }).then((responseConfirmation) => {
                        resolve(responseConfirmation)
                    });
            }
            else {
                resolve(true);
            }               
        });
    }

    /**
     * Affichage d'une confirmation d'information sur la politique de contrôle.
     */
    public confirmationInfoPolitiqueControle(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.$http.post(`/simulation/sujetPolitiqueControle/${this.model.simulationDossierId}`).then((response) => {
                const sujet: SujetPolitiqueControle = response.data.data;
                if (sujet && sujet.estSoumisAControle && sujet.textePolitiqueControle) {
                    this.$refs.confirm.open('Politique de contrôle',
                        sujet.textePolitiqueControle, 
                        { color: 'blue', width: 1000, zIndex: 200 }).then((responseConfirmation) => resolve(responseConfirmation));
                } else {
                    resolve(true);
                }
            }).catch(() => resolve(true));
        });
    }

    /**
     * Impression du récapitulatif de la simulation.
     */
    public imprimerRecapitulatifSimulation() {
        this.$refs.dialogLoader.start('Génération du récapitulatif de la simulation en cours...', null, () => {
            return new Promise((resolve) => {
                const api = new ApiService<FileContentResult>(`simulation/recupererRecapitulatifSimulation/${this.model.simulationDossierId}`);
                api.getWhereSingle('').then((result) => ApiHelper.createAndDownloadBlobFile(result.data)).finally(() => resolve());
            });
        }, { enabled: false });
    }

    /**
     * Duplique une opération à partir d'une autre.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private async dupliquerOperation(operation: Operation) {
        this.dupliquerOperationParcoursUtilisateur(operation);
    }

    /**
     * Supprime une opération après confirmation de l'utilisateur.
     */
    public async supprimerOperation(operation: Operation) {
        this.supprimerOperationParcoursUtilisateur(operation);
    }

    /**
     * Bloc montant rétroceder est il visible (Maj 150420: Ajouter la clause ou l'utilisateur courant est un installateur)
     *
     * @readonly
     * @memberof ResultatSimulation
     */
    public get estBlocMontantAretrocederVisible() {
        const none = { profilUtilisateur: Profils.None };
        return (this.simulationConvention.clientRepresenteId === EnumClientRepresente.ClientInstallateur
            || (this.simulationConvention.utilisateurCreation || none).profilUtilisateur === Profils.Installateur)
            && this.simulationConvention.estOperationSecteurResidentielExiste;
    }
}
