




























































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';
    import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
    import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';

@Component({
    name: 'InformationsSimulationDossier',
})
export default class InformationsSimulationDossier extends Vue {

    /** Chargement lors de la modification de la référence. */
    private loading: boolean = false;

    /** Mode édition ? */
    @Getter(InformationsSimulationDossierStoreMethods.EDITING_MODE)
    private isEditingMode: boolean;

    @Mutation(InformationsSimulationDossierStoreMethods.TOGGLE_EDITING_MODE)
    private toggleEditingMode: () => void;

    /** Identifiant de la simulation ou du dossier. */
    @Prop({default: 0})
    private simulationDossierId: number;

    /** Type d'entité. */
    @Prop()
    private typeSimulationDossier: SimulationOuDossier;

    /**Référence de la simulation (DB : intitulé dossier). */
    @Getter(InformationsSimulationDossierStoreMethods.NUMERO)
    public numero: string;

    /**Référence de la simulation (DB : intitulé dossier). */
    @Getter(InformationsSimulationDossierStoreMethods.REFERENCE)
    private reference: string;

    /** Statut de la simulation/du dossier (différent en fonction du profil de l'utilisateur). */
    @Getter(InformationsSimulationDossierStoreMethods.STATUT)
    public statut: string;

    @Mutation(InformationsSimulationDossierStoreMethods.SET_REFERENCE)
    public setReference: (input: string) => void;

    /*
     * Méthodes du store.
     */
    @Action(InformationsSimulationDossierStoreMethods.MODIFIER_REFERENCE)
    public preModifierReference: (simulationDossierId: number) => Promise<{id: number, intituleDossier: string}>;

    @Action(InformationsSimulationDossierStoreMethods.RECUPERER_INFORMATIONS)
    public recupererIntituleDossier: (simulationDossierId: number) => Promise<{id: number, intituleDossier: string}>;

    /** Récupération des informations de la simulation dès qu'on a l'id. */
    @Watch('simulationDossierId', { immediate: true })
    public onSimulationDossierIdChanged(): void {
        if(this.simulationDossierId) {
            this.recupererIntituleDossier(this.simulationDossierId).catch(() => {
                        throw new Error('Une erreur est survenue lors de la modification de la référence simulation');
            });
        }
    }

    /** Récupération du libellé du statut en fonction du type d'entité. */
    public get libelleStatut(): string {
        return this.typeSimulationDossier === SimulationOuDossier.Simulation ? 'Statut de la simulation' : 'Statut du dossier';
    }

    /** Récupération du libellé du numéro de dossier en fonction du type d'entité. */
    public get libelleNumero(): string {
        return this.typeSimulationDossier === SimulationOuDossier.Simulation ? 'Numéro de simulation' : 'Numéro de dossier';
    }

    /** Modification de la référence. */
    public modifierReference(): void {
        if(!this.isEmptyOrWhiteSpaces(this.reference)) {
            this.loading = true;
            this.preModifierReference(this.simulationDossierId).then(() => {
                this.loading = false;
                if (this.isEditingMode) {
                    this.toggleEditingMode();
                }
            })
            .catch(() => {
                throw new Error('une erreur est survenue lors de la modification de la référence.');
            })
        }
        else if (this.isEditingMode) {
            this.toggleEditingMode();
        }
    }
    /**
      * Test la nullité.
      * @param input
      */
    public isEmptyOrWhiteSpaces(input: string | string | number) {
        return input === null || typeof input === 'undefined' || !input || String(input).match(/^ *$/) !== null;
    }
}
