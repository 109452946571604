import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { Message } from '@/shared/models';
import { TemplateTypeBlocReferentiel, TemplateTypeChampReferentiel, Operation as OperationModel, Paiement } from '@/models';
import { SimulationOperation, StatutOperation, OperationDateTravaux, DateTravauxCriteria } from '@/components/Wizard/Commun/models';
import { CommonMixin } from '@/shared/mixins';
import { SecteurAsNumber, Arretes, EnumProfilsAsNumber, StatutDossier, StatutCommercialOperation, MessageLevel, SurfacesControle } from '@/shared/enums/index';
import { DateHelper } from '@/shared/helpers/DateHelper';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';
import OperationElement from '@/components/Wizard/Commun/OperationElement.vue';
import Confirm from '@/components/Confirm.vue';
import DialogLoader from '@/components/DialogLoader.vue';
import ChoixSecteurEtOperation from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperation';
import ChoixSecteurEtOperationElementDialog from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperationElementDialog.vue';
import HistoriqueOperation from './HistoriqueOperation.vue';
import MontantRetrocederForm from '@/components/Wizard/Commun/MontantRetrocederForm.vue';
import GestionOperationHistorique from './GestionOperationHistorique.vue';
import ResultatSimulationTab from '@/components/Wizard/Commun/ResultatSimulation/ResultatSimulationTab.vue';
import { Profils } from '@/shared/enums';
import template from './Operation.Template.vue';
import { OperationResult } from '../models/OperationResult';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { Getter, Mutation, Action } from 'vuex-class';
import { OperationsStoreMethods } from '@/store/modules/operations/OperationsStore';
import { UserProfile } from '@/store/modules/auth/types';
import { cloneDeep, omit } from 'lodash-es';
import { uuidv4, getPrecisionFromNumber } from '@/shared/helpers';
import { Watch, Prop } from 'vue-property-decorator';
import { ResultatSimulationStoreMethods } from '@/store/modules/simulation/ResultatSimulationStore';
import MontantRetroceder from '../models/MontantRetroceder';
import { ClientRepresente } from '@/shared/enums/ClientRepresente.enum';
import { TypeDeWizardComposant } from '../../Composants';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { isNullOrEmpty, isNullOrUndefined } from '@/shared/helpers/Utility';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import OperationValidator from './Operation.Validator';
import { ApiService } from '@/services/base/ApiService';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';
import { getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import moment from 'moment';

//  Créer une interface pour fusionner les mix ins.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentBase, WizardEtapeComponentsDossier, Vue { }

@Component({
    ...template,
    name: 'Operation',
    ref: 'Operation',
    components: {
        OperationElement,
        Confirm,
        DialogLoader,
        ChoixSecteurEtOperation,
        ChoixSecteurEtOperationElementDialog,
        CeeDatePicker,
        HistoriqueOperation,
        GestionOperationHistorique,
        ResultatSimulationTab,
        MontantRetrocederForm,
    },
    mixins: [CommonMixin],
})
export default class Operation extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentBase, WizardEtapeComponentsDossier, OperationValidator)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    [x: string]: any;
    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        montantRetrocederFormRef: HTMLFormElement,
        datePreviFormRef: Array<HTMLFormElement>,
        dateDebutTravaux: Array<HTMLFormElement>,
        refChoixOperation: {
            open: ((title: string | null, message: string | null, options?: { color?: string, width?: number, zIndex?: number }) => Promise<any>),
            agree: () => void
        },
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        confirm2: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        dialogLoader: {
            start: ((
                message: string | null,
                options: { color: string, width: number, zIndex: number },
                callback: (() => Promise<void>),
                snackbar: boolean | { enabled: boolean },
            ) => Promise<boolean>),
        },
    };
    public titre(): string { return 'Opérations'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-calculator'; }

    public readonly numDepartementPropertyName: string = 'NUM_DEPARTEMENT';

    public EnumProfilsAsNumber = EnumProfilsAsNumber;

    public codeOperationNonStandard: string = 'NON-STANDARD';
    /**
     * Bus pour partager les données entre opérations.
     */
    public busSharedData: Vue = new Vue();
    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    public readonly fieldsNameSharedData: string[] = [
        this.numDepartementPropertyName,
        'PRECARITE_CAS',
        'PRECARITE_MENAGE_GDE_PRECARITE',
        'PRECARITE_NB_MENAGE_TOTAL',
        'PRECARITE_NB_MENAGE_PRECARITE',
        'PRECARITE_NB_MENAGE_GRANDE_PRECARITE',
        'PRECARITE_NB_MENAGE_OCCUP_LOGEMENT_SOCIAUX',
        'PRECARITE_NB_MENAGE_PRECARITE_JUSTIF_REVENUS',
        'PRECARITE_NB_MENAGE_GRANDE_PRECARITE_JUSTIF_REVENUS',
        'PRECARITE_POURCENTAGE_MENAGE_PRECARITE_COPRO',
        'PRECARITE_POURCENTAGE_MENAGE_GRANDE_PRECARITE_COPRO',
        'PRECARITE_NOM_QUARTIER_PRIORITAIRE',
        'PRECARITE_CODE_QUARTIER_PRIORITAIRE'
    ];

    /**
     * Champ valorisation classique.
     */
    public readonly valorisationClassiqueField = 'VALORISATION_CLASSIQUE';
    /**
     * Champ valorisation précarité.
     */
    public readonly valorisationPrecariteField = 'VALORISATION_PRECARITE';

    /**
     * Dictionnaire des icônes par secteur, à factoriser.
     */
    public readonly secteurs: string[] = [
        'bus',
        'project-diagram',
        'building',
        'industry',
        'tractor',
        'school'
    ];

    @Getter(OperationsStoreMethods.OPERATION_EN_COURS)
    public operationAAfficher: OperationModel;

    // Référentiel StatutsCommercial
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.StatutCommercial))
    public statutsCommercial: ValeurReferentielle[];

    @Mutation(OperationsStoreMethods.SET_OPERATION_EN_COURS)
    public setOperationAAfficher: (operation: OperationModel) => void;

    // Ajout des champs non renseignés de l'étape après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.ADD_CHAMPS_NON_RENSEIGNES)
    public addChampsNonRenseignes: (champsNonRenseignes: any) => void;

    // Suppression des champs non renseignes de l'étape si ok après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.REMOVE_CHAMPS_NON_RENSEIGNES)
    public removeChampsNonRenseignes: (etape: TypeDeWizardComposant) => void;

    @Action(OperationsStoreMethods.GET_VALIDATION_SAISIES)
    public getValidationSaisies: (formDataOperations: any[]) => Promise<string[]>;

    // Liste des opérations.
    public operations: OperationModel[] = new Array<OperationModel>();

    // Opération en cours.
    public operationEnCours: OperationModel = null;

    // Retourne la visibilité des éléments du panneau.
    public panneauSections: number = null;
    // Correspondances index panneaux <=> opérations.
    public indexPanneauxParOperation: string[] = [];

    public formConfig: object = {};
    public loading: boolean = false;
    public loadingConfig: boolean = false;
    public loadingEtape: boolean = true;
    public sections: TemplateTypeBlocReferentiel[] = new Array<TemplateTypeBlocReferentiel>();
    public champs: TemplateTypeChampReferentiel[] = new Array<TemplateTypeChampReferentiel>();
    public reponseCalculOperation: { [key: string]: any } = {};
    public formPreviewProps: { [key: string]: any } = {};
    public sectionComponentRowItemProps: { [key: string]: any } = {};

    // Boîte de dialogue pour afficher les conditions d'éligibilité.
    public dialogConditionEligibilite: boolean = false;

    // Boîte de dialogue pour afficher les choix de l'opération.
    public dialogOperation: boolean = false;

    // Boîte de dialogue pour afficher modification du montant à rétrocéder.
    public dialogMontantRetroceder: boolean = false;

    public dialogModalitesCalcul = false;

    public dateEngagementTravauxUpdated = false;

    public estCalculValide: boolean = false;

    public volumeTotal: { classique: number, precarite: number } = { classique: 0, precarite: 0 };
    public prixDetail: { classique: number, precarite: number } = { classique: 0, precarite: 0 };

    public calculLoading: boolean = false;

    public resultatsCalculOperationsEnMasse: OperationResult[] = new Array<OperationResult>();

    public statutCommercialIdGlobal: number = StatutDossier.EN_CREATION;

    /**
     * Date d’aujourd’hui au format ISO.
     */
    public aujourdhui: string = DateHelper.todayIso();

    /**
     * Date limite de fixation du prix.
     */
    public dateLimiteFixationPrix: string = '';

    /**
     * Indique si l'utilisateur connecté est interne.
     */
    public estInterne: boolean = false;

    /**
     * Indique si l'utilisateur connecté est un apporteur d'affaires.
     */
    public estApporteurAffaires: boolean = false;

    /**
     * Indique si l'utilisateur connecté est un compte entreprise ou installateur.
     */
    public estEntrepriseOuInstallateur: boolean = false;

    /**
     * Indique si l'utilisateur connecté est un syndic.
     */
    public estSyndicSDC: boolean = false;

    public dateRaiComputed: string = '';

    /**
     * Indique si l'utilisateur connecté est un administrateur.
     */
    public estAdministrateur: boolean = false;


    public currentProfil: EnumProfilsAsNumber = EnumProfilsAsNumber.None;

    public infosCoupDePouce: { montant: number, message: string } = { montant: null, message: null };

    public loadingMontantRetroceder: boolean = false;

    public listeOperationsReference: OperationModel[] = [];

    @Prop()
    public referentielsEtape: DossierReferentiels;

    @Getter(ResultatSimulationStoreMethods.MONTANT_A_RETROCEDER)
    private montantRetroceder: MontantRetroceder;

    @Mutation(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER)
    private setMontantRetroceder: (montantRetroceder: MontantRetroceder) => void;

    @Action(ResultatSimulationStoreMethods.MODIFIER_DONNEES_MONTANTRETROCEDER)
    private modifierInfosMontantRetroceder: () => Promise<MontantRetroceder>;

    public lambdaFilterResidentielle(operation: OperationModel): boolean {
        const idResidentiel: number = SecteurAsNumber.Residentiel;
        return operation.secteurId === idResidentiel;
    }

    // Vérifie si l'utilisateur peut ajouter une opération à ce dossier.
    public get canAddOperation(): boolean {
        return this.dossier && this.dossier.canAddOperation;
    }

    public get isCalculProcessedAfterChange() {
        return this.operations.length === this.listeOperationsReference.length
            && this.operations.every((l) => this.listeOperationsReference.some((o) => o.id === l.id))
            && this.listeOperationsReference.every((operation) => operation.volumeCeeClassique > 0 && operation.valorisationCeeClassique > 0);
    }
    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    @Watch('operations', { deep: true })
    public initialiserOperations(newValue: OperationModel[]): void {

        // Ouvre la dernière opération si le nombre d'opérations a changé.
        if (this.valeurInitiale && newValue && this.valeurInitiale.length !== newValue.length) {
            this.calculerPanneauSections();
        }

        this.infosCoupDePouce = this.getInfosCoupDePouce();
        // Mise à jour du model commun des composants du dossier.
        this.bus.$emit('on-step-informations-operations', this.operations);
    }

    private valeurInitiale: OperationModel[];
    private valeurModifiee = false;

    /**
     * Détermine si l'étape a été modifiée depuis le dernier enregistrement.
     */
    public get estModifiee(): boolean {
        return this.valeurModifiee;
    }

    /**
     * Détermine si l'étape est complète ou non.
     */
    public get estComplete(): boolean {
        return this.estValide
            && this.operations.length
            && this.operations.every((o) => o.statutOperationId === StatutOperation.Complete);
    }

    public prixTotal: number = 0;

    public get estMontantRetrocederVisible() {
        const profil = (this.dossier.utilisateurCreation || { profilUtilisateur: Profils.None }).profilUtilisateur;
        return ((profil === Profils.ApporteurAffaires && this.dossier.clientRepresenteId === ClientRepresente.ClientInstallateur)
            || profil === Profils.Installateur) && this.dossier.estOperationSecteurResidentielExiste && this.estDossier;
    }

    @Watch('dossier.montantRetroceder')
    public onMontantRetrocederChange(newValue: number, oldValue: number) {
        if (newValue !== oldValue) {
            this.setMontantRetroceder(new MontantRetroceder(this.dossier.id, this.dossier.montantRetroceder, this.dossier.nomSyndic, this.dossier.estSdc));
        }
    }

    public cancelMontantRetroceder() {
        this.montantRetroceder.montant = this.dossier.montantRetroceder;
        this.montantRetroceder.nomSyndic = this.dossier.nomSyndic;
        this.dialogMontantRetroceder = false;
    }

    /**
     * Surcharge la fonction de rafraîchissement du modèle car on passe par du custom pour ce composant.
     * @param forcerRefresh
     */
    public rafraichirEtape(forcerRefresh: true): void {
        if (forcerRefresh) {
            this.valeurInitiale = this.deepCopyOperations(this.operations);
            this.valeurModifiee = false;
        }
    }


    /**
     * Récupère l'icône en fonction du secteur affiché.
     * @param secteur
     */
    public getIconeSecteur(secteur: number): string {
        return 'fa fa-' + (secteur ? this.secteurs[secteur - 1] : 'calculator');
    }
    /**
     * Retourne l'identifiant de la simulation.
     *
     */
    public get simulationId(): number | null {
        return this.model.simulationDossierId;
    }
    /**
     * Permet de savoir s'il contient plusieurs opérations.
     *
     */
    public get aPlusieursOperation(): boolean {
        return this.operations.length > 1;
    }
    /**
     * Retourne un booléen pour savoir si c'est une modification ou pas.
     *
     */
    public get estModification(): boolean {
        const simulationDossierId = this.model.simulationDossierId;
        return (simulationDossierId !== null && typeof simulationDossierId !== 'undefined' && simulationDossierId);
    }
    /**
     * Vérifie si au moins un template correspond à une opération historique (périodes P1/P2) en se basant sur la version de son arrêté.
     */
    public get estDossierHistorique(): boolean {
        return this.operations.filter((o) => o.arreteId && o.arreteId < Arretes.ARR_14).length > 0;
    }

    /**
     * Retourne un booléen pour savoir si son model d'étape est défini.
     *
     */
    public get estModelEtapeDefini(): boolean {
        return (this.model.modelEtape && Object.keys(this.model.modelEtape).length !== 0);
    }

    /**
     * Affichage le montant total des CEE du dossier.
     */
    public get montantCeeTotal(): number {
        return this.operations.SumBy(o => o.volumeCeeClassique * o.valorisationCeeClassique);
    }

    /**
     * constructor.
     */
    constructor() {
        super();
    }
    /**
     * Récupérer les template d'opération.
     *
     */
    public recupererOperations(): Promise<OperationModel[]> {
        return new Promise<OperationModel[]>((resolve) => {
            if (this.estModification) {
                if (this.estDossier) {
                    resolve(this.dossier.operations);
                }
                else {
                    // Récupère les opérations depuis la simulation.
                    this.recupererSimulationDossier(this.model.simulationDossierId).then((result: SimulationOperation) => {
                        resolve(result.operations);
                    });
                }
            } else {
                // Récupère les opérations en mémoire.
                resolve(this.estModelEtapeDefini ? [...this.model.operations] : [...this.model.secteurEtOperations]);
            }
        });
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     */
    public created(): void {
        this.setLoadingWizard(true);

        this.bus.$on('on-suppression-operation', (operation: OperationModel, reload: boolean = false) => {
            const index = this.indexPanneauxParOperation.indexOf(operation.uuid);
            if (index > -1) {
                this.indexPanneauxParOperation.splice(index, 1);
            }

            this.indexPanneauxParOperation = this.operations.map((item) => item.uuid);
            if (this.estDossier && this.dossier.id > 0 && reload && this.indexPanneauxParOperation.length > 0) {
                this.chargementListeOperations();
            }
        });

        // Actualisation des opérations quand le dossier a été modifié.
        this.bus.$on('on-update-dossier', () => {
            this.chargementListeOperations();
        });

        this.bus.$on('on-code-postal-change', (codePostal: string) => {
            this.updateCodePostalOperations(codePostal);
        });
    }

    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     */
    public mounted(): void {
        // Les secteurs affichées doivent être les secteurs standard pour les profils externes. Pour les internes on affiche tout.
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estInterne = profile.isInterne;
                this.estApporteurAffaires = (profile.profilCode === Profils.ApporteurAffaires);
                this.estEntrepriseOuInstallateur = (profile.profilCode === Profils.Entreprise || profile.profilCode === Profils.Installateur);
                this.estSyndicSDC = profile.profilCode === Profils.SyndicSDC;
                this.estAdministrateur = profile.isAdmin;
                this.currentProfil = profile.profilId as EnumProfilsAsNumber;
            }
        });

        this.busSharedData.$on('updateDateDebutTravauxSite', (idOperation: number, newDateDebutTravauxSite: string) => {
            let operation = this.operations.find(x => x.id === idOperation);
            if (moment(newDateDebutTravauxSite) > moment(operation.dateFinTravaux)) {
                operation.dateFinTravaux = '';
            } 
        });

        // Charge les opérations.
        this.chargementListeOperations();

        if (this.model.isDossier) {
            this.recupererDateLimiteFixationPrix(this.model.simulationDossierId).then((result: string) => this.dateLimiteFixationPrix = DateHelper.format(result));
        }
    }

    @Watch('dossier.dateRoleActifIncitatif')
    public onDateRaiChanged(newValue: string, oldValue: string): void {
        const dateActivationCompte: string = this.estAdministrateur ? '01-01-2015' : (this.dossier.utilisateurCreation || {} as any).dateActivationCompte;
        if (oldValue !== newValue) {
            if (!this.dossier.dateRoleActifIncitatif) {
                this.recupererDateRai(this.model.simulationDossierId).then((dateRai) => {
                    this.dateRaiComputed = dateActivationCompte || dateRai;
                });
            } else {
                this.dateRaiComputed = this.dossier.dateRoleActifIncitatif;
            }

        }
        else {
            this.dateRaiComputed = dateActivationCompte || '01-01-2015';
        }
    }

    /**
     * Récupérer une date Rai différente (dateActivationCompte) des règles établient dans le cas d'un secteur tertiaire.
     *
     * @param {number} secteurId
     */
    public getMinDateEngagementComputed(secteurId: number) {
        const dateActivationCompte: string = (this.dossier.utilisateurCreation || {} as any).dateActivationCompte;
        if (this.estEntrepriseOuInstallateur) {
            return this.dossier.dateRoleActifIncitatif;
        } else if ((secteurId === SecteurAsNumber.Tertiaire && this.estSyndicSDC) || this.estAdministrateur) {
            return this.estAdministrateur ? '01-01-2015' : dateActivationCompte || '01-01-2015';
        } else {
            return [this.dateRaiComputed, dateActivationCompte].reduce((a, b) => a < b ? a : b);
        }
    }

    // Données nécessaires à l'affichage du récapitulatif pour un dossier.

    /** Détermine s'il s'agit d'un dossier apporteur d'affaires. */
    public get estDossierApporteurAffaires(): boolean {
        return this.dossier.utilisateurCreation && this.dossier.utilisateurCreation.profilUtilisateur === Profils.ApporteurAffaires;
    }

    /**
     * Nombre de décimales à afficher sur le récapitulatif du dossier.
     */
    public get nombreDecimales(): number {

        // Ce nombre peut varier uniquement pour un dossier AA.
        if (this.estDossierApporteurAffaires && this.operations.length) {

            // Récupère la plus grande précision trouvée sur une opération, en limitant à 3.
            const precision = Math.max.apply(Math, this.operations.map(o => getPrecisionFromNumber(o.remunerationApporteurAffaires * 1000)));
            return precision >= 3 ? 3 : 2;
        }

        // Dans tous les autres cas, on affiche le nombre par défaut.
        return 2;
    }

    /**
     * Évènement qui se déclenche lorsqu'on veut ajouter une nouvelle opération au dossier.
     * @param nvelleDateDebutTravauxSaisie
     */
    private onCheckDateDebut(nvelleDateDebutTravauxSaisie: string, secteurId: number): void {
        const ancienneDateDebutTravauxSaisie = this.operations[0].dateDebutTravaux;
        if (!DateHelper.sontEgales(ancienneDateDebutTravauxSaisie, nvelleDateDebutTravauxSaisie) && secteurId !== SecteurAsNumber.NonStandard) {
            // On créé une operation model temporaire pour la nouvelle opération qu'on est en train de saisir, pour permettre une bonne synchronisation des dates.
            const newOperation = new OperationModel();
            newOperation.dateDebutTravaux = nvelleDateDebutTravauxSaisie;
            newOperation.secteurId = secteurId;
            this.validationDateTravauxPromise(ancienneDateDebutTravauxSaisie, nvelleDateDebutTravauxSaisie, newOperation)
                .then((result: boolean) => {
                    if (result) {
                        this.$refs.refChoixOperation.agree();
                    }
                });
        } else {
            this.$refs.refChoixOperation.agree();
        }
    }

    /**
     * Validation de la nouvelle date saisie.
     * @param ancienneDateDebutTravauxSaisie
     * @param nvelleDateDebutTravauxSaisie
     * @param operationEnCours
     */
    private validationDateTravauxPromise(ancienneDateDebutTravauxSaisie: string, nvelleDateDebutTravauxSaisie: string, operationEnCours: OperationModel): Promise<boolean> {
        const dateDebutPropertyName = 'dateDebutTravaux';
        return new Promise((resolve) => {
            this.ValidateDateTravaux('verificationDateDebutTravaux', this.operations, nvelleDateDebutTravauxSaisie)
                .then((response: any) => {
                    const result = response as ({ errorMessage: string, operations: OperationDateTravaux[] });
                    if (result && result.errorMessage !== "" && result.operations.length > 0) {
                        this.$refs.confirm.open(null, result.errorMessage, {}).then((resultConfirm) => {
                            if (resultConfirm) {

                                result.operations.forEach((operation: OperationDateTravaux, index: number) => {

                                    if (!operation.estValide) {
                                        /*
                                        *   Supprime l'opération courante et ajoute la nouvelle opération
                                        *   qui correspond à la date de début des travaux.
                                        */
                                        this.supprimerOperationSansConfirmPopup(
                                            this.model.simulationDossierId,
                                            this.operations[index]).then(() => {
                                                const nouvelItemOperation: OperationModel = {
                                                    uuid: uuidv4(),
                                                    secteurId: operation.secteurId,
                                                    templateOperationId: operation.templateOperationId,
                                                    dateEngagementTravaux: nvelleDateDebutTravauxSaisie,
                                                    dateDebutTravaux: nvelleDateDebutTravauxSaisie
                                                } as unknown as OperationModel;

                                                this.validerAjoutOperation(nouvelItemOperation);
                                            });
                                    }
                                });
                                this.synchroDateOperations(nvelleDateDebutTravauxSaisie, dateDebutPropertyName, operationEnCours);
                                this.busSharedData.$emit('on-date-debut-travaux-updated');
                                resolve(true);
                            } else {
                                this.synchroDateOperations(ancienneDateDebutTravauxSaisie, dateDebutPropertyName, operationEnCours);
                                resolve(false);
                            }
                        });
                    }
                    else if (result && result.errorMessage !== "" && result.operations.length === 0) {
                        this.$refs.confirm2.open(null, result.errorMessage, {})
                        this.synchroDateOperations(ancienneDateDebutTravauxSaisie, dateDebutPropertyName, operationEnCours);
                        resolve(false);
                    }
                    else {
                        this.synchroDateOperations(nvelleDateDebutTravauxSaisie, dateDebutPropertyName, operationEnCours);
                        this.busSharedData.$emit('on-date-debut-travaux-updated');
                        resolve(true);
                    }
                });
        });
    }

    /**
     * Événement qui se déclenche lorsque la date d'engagement change.
     * @param {string} ancienneDateDebutTravauxSaisie Ancienne date d'engagement saisie.
     * @param {string} nvelleDateDebutTravauxSaisie Nouvelle date d'engagement saisie.
     * @param {string} operationEnCours Opération en cours.
     * @memberof Operation
     */
    public onDateDebutTravauxUpdated(ancienneDateDebutTravauxSaisie: string, nvelleDateDebutTravauxSaisie: string, operationEnCours: OperationModel) {
        this.validationDateTravauxPromise(ancienneDateDebutTravauxSaisie, nvelleDateDebutTravauxSaisie, operationEnCours).then((result) => {
            if (result) {
                const oldDate = DateHelper.frToIso(DateHelper.format(ancienneDateDebutTravauxSaisie));
                const newDate = DateHelper.frToIso(DateHelper.format(nvelleDateDebutTravauxSaisie));
                const unAvril2021 = DateHelper.frToIso('01/04/2021')
                const unMai2021 = DateHelper.frToIso('01/05/2021');
                const unJuillet2021 = DateHelper.frToIso('01/07/2021');
                const operationBarEn101ou103 = operationEnCours.code === 'BAR-EN-101' || operationEnCours.code === 'BAR-EN-103';
                if ((this.isPrecarite() && (this.checkDepassementSeuilDate(newDate, oldDate, unAvril2021)) || (operationBarEn101ou103 && (this.checkDepassementSeuilDate(newDate, oldDate, unMai2021) || this.checkDepassementSeuilDate(newDate, oldDate, unJuillet2021))))) {
                    // SHOW POP-UP + RECALCUL
                    this.dateEngagementTravauxUpdated = true;
                    this.dialogModalitesCalcul = true;
                    this.operations.forEach(o => o.dateDebutTravaux = nvelleDateDebutTravauxSaisie);
                    this.calculOperationsEnMasse();
                }
            }
            // On recheck la politique de contrôle.
            this.checkSoumisAControle();
        });
        this.valeurModifiee = true;
        this.bonificationCPEEnabler(operationEnCours.secteurId, nvelleDateDebutTravauxSaisie);
    }

    /**
     * Événement qui se déclenche lorsque la date de fin previsionnelle des travaux change.
     * @param {string} ancienneDateFinTravauxPreviSaisie Ancienne date de fin previsionnelle saisie.
     * @param {string} nvelleDateFinTravauxPreviSaisie Nouvelle date de fin previsionnelle saisie.
     * @param {string} operationEnCours Opération en cours.
     * @memberof Operation
     */
    public onDateFinTravauxPreviUpdated(ancienneDateFinTravauxPreviSaisie: string, nvelleDateFinTravauxPreviSaisie: string, operationEnCours: OperationModel) {
        const oldDate = ancienneDateFinTravauxPreviSaisie === "" ? DateHelper.frToIso(DateHelper.format(operationEnCours.dateDebutTravaux)) : DateHelper.frToIso(DateHelper.format(ancienneDateFinTravauxPreviSaisie));
        const newDate = DateHelper.frToIso(DateHelper.format(nvelleDateFinTravauxPreviSaisie));
        const seuilPrecarite = DateHelper.frToIso('01/10/2021');
        const trenteSeptembre2021 = DateHelper.frToIso('30/09/2021');
        const unMai2022 = DateHelper.frToIso('01/05/2022');
        const operationBarEn101ou103 = operationEnCours.code === 'BAR-EN-101' || operationEnCours.code === 'BAR-EN-103';
        const seuilBonification = operationBarEn101ou103 ? trenteSeptembre2021 : unMai2022;
        if (this.isPrecarite() && (this.checkDepassementSeuilDate(newDate, oldDate, seuilPrecarite) || this.checkDepassementSeuilDate(newDate, oldDate, seuilBonification))) {
            // SHOW POP-UP + RECALCUL
            this.dateEngagementTravauxUpdated = false;
            this.dialogModalitesCalcul = true;
            this.calculOperationsEnMasse();
        }
        this.synchroDateOperations(nvelleDateFinTravauxPreviSaisie, 'dateFinTravauxPrevisionnelle', operationEnCours);
        this.valeurModifiee = true;
    }

    /**
     * Événement qui se déclenche lorsque la date de fin des travaux change.
     * @param {string} ancienneDateFinTravauxSaisie Ancienne date de fin saisie.
     * @param {string} nvelleDateFinTravauxSaisie Nouvelle date de fin saisie.
     * @param {string} operationEnCours Opération en cours.
     * @memberof Operation
     */
    public onDateFinTravauxUpdated(ancienneDateFinTravauxSaisie: string, nvelleDateFinTravauxSaisie: string, operationEnCours: OperationModel) {
        const oldDate = ancienneDateFinTravauxSaisie === "" ? DateHelper.toIsoString(operationEnCours.dateFinTravauxPrevisionnelle) : DateHelper.frToIso(DateHelper.format(ancienneDateFinTravauxSaisie));
        const newDate = nvelleDateFinTravauxSaisie === "" ? DateHelper.toIsoString(operationEnCours.dateFinTravauxPrevisionnelle) : DateHelper.frToIso(DateHelper.format(nvelleDateFinTravauxSaisie));
        const seuilPrecarite = DateHelper.frToIso('01/10/2021');
        const trenteSeptembre2021 = DateHelper.frToIso('30/09/2021');
        const unMai2022 = DateHelper.frToIso('01/05/2022');
        const operationBarEn101ou103 = operationEnCours.code === 'BAR-EN-101' || operationEnCours.code === 'BAR-EN-103';
        const seuilBonification = operationBarEn101ou103 ? trenteSeptembre2021 : unMai2022;
        if (this.isPrecarite() && (this.checkDepassementSeuilDate(newDate, oldDate, seuilPrecarite) || this.checkDepassementSeuilDate(newDate, oldDate, seuilBonification))) {
            // SHOW POP-UP + RECALCUL
            this.dateEngagementTravauxUpdated = false;
            this.dialogModalitesCalcul = true;
            this.calculOperationsEnMasse();
        }
        this.synchroDateOperations(nvelleDateFinTravauxSaisie, 'dateFinTravaux', operationEnCours);
        this.valeurModifiee = true;
    }

    // Verifie si de la precarité a été saisie sur au moins une opertation.
    private isPrecarite(): boolean {
        this.recupererDonneesSaisiesOperations();
        let isPrecarite = false;
        this.operations.forEach((item: any) => {
            if (item.operationValues) {
                for (let key in item.operationValues) {
                    if (item.operationValues.hasOwnProperty(key) && item.operationValues[key].fieldName === "PRECARITE_CAS" && Number(item.operationValues[key].value) !== 1) {
                        isPrecarite = true;
                    }
                }
            }
        });
        return isPrecarite;
    }

    // Permet de determiner si une date dépasse un certain seuil par rapport à son ancienne valeur.
    private checkDepassementSeuilDate(newDate: string, oldDate: string, seuilDate: string): boolean {
        let seuilDepasse = false;
        if ((DateHelper.premiereSuperieurASeconde(oldDate, seuilDate, true) &&
            DateHelper.premiereSuperieurASeconde(seuilDate, newDate)) ||
            (DateHelper.premiereSuperieurASeconde(seuilDate, oldDate) &&
                DateHelper.premiereSuperieurASeconde(newDate, seuilDate, true))) {
            seuilDepasse = true;
        }
        return seuilDepasse;
    }

    /**
     * Set la date de début sur toutes les opérations.
     * @param dateDebutTravaux
     */
    private synchroDateOperations(date: string, dateProperty: string, operationEnCours: OperationModel) {
        this.operations.forEach((operation: OperationModel) => {
            if (operationEnCours && operationEnCours.secteurId !== SecteurAsNumber.NonStandard && operation.secteurId !== SecteurAsNumber.NonStandard) {
                (operation as any)[dateProperty] = date;
            } else {
                if (operationEnCours) {
                    (operationEnCours as any)[dateProperty] = date;
                }
            }
        });
    }

    /**
     * Récupère une date liée au dossier.
     * @param endpoint Le endpoint de la date sur le controller Dossier.
     * @param {string} operationsDateTravaux opérations à vérifier.
     * @param {string} dateDebutTravaux date Travaux.
     */
    private ValidateDateTravaux(endpoint: string, operations: OperationModel[], nouvelleDateDebutTravaux: string): Promise<any> {

        const criteria: DateTravauxCriteria = new DateTravauxCriteria(
            nouvelleDateDebutTravaux,
            operations.map((x: OperationModel) => {
                return new OperationDateTravaux(
                    x.code,
                    x.arreteId,
                    null,
                    null
                );
            })
        );

        return new Promise<string>((resolve, reject) => {
            this.$http.post(`/dossier/${endpoint}`, criteria).then((result) => {
                const {
                    data: {
                        data: responseData
                    }
                } = result as ({ data: { data: any, isError: boolean, messages: any[] } });
                resolve(responseData);
            }).catch((error: { response: string }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Calcul et arrondi à l'entier le plus proche du volume classique.
     * @param operation
     */
    public getVolumeClassiquePrevisionnel(operation: OperationModel): number {
        // Astuce pour afficher
        return this.formatVolume(operation.volumeCeeClassiquePrevisionnel);
    }

    /**
     * Calcul et arrondi à l'entier le plus proche du volume précarité.
     * @param operation
     */
    public getVolumePrecaritePrevisionnel(operation: OperationModel): number {
        return this.formatVolume(operation.volumeCeePrecaritePrevisionnel);
    }

    /**
     * Mise à jour volume classique.
     * @param operation Opération.
     * @param event Input.
     */
    public updateOperationVolumeCeeClassiquePrevisionnel(operation: OperationModel, event: any) {
        if (!isNullOrUndefined(event) && event !== '') {
            operation.volumeCeeClassiquePrevisionnel = event * 1000;
        }
    }

    /**
     * Mise à jour volume précarité.
     * @param operation Opération.
     * @param event Input.
     */
    public updateOperationVolumeCeePrecaritePrevisionnel(operation: OperationModel, event: any) {
        if (!isNullOrUndefined(event) && event !== '') {
            operation.volumeCeePrecaritePrevisionnel = event * 1000;
        }
    }

    /**
     * Astuce pour afficher correctement la valeur avec 3 décimales.
     * https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
     * @param valeur
     */
    private formatVolume(valeur: number): number {
        return Math.round((valeur / 1000 + Number.EPSILON) * 1000) / 1000;
    }

    /**
     * Recopie les volumes calculés dans les volumes prévisionnels.
     * @param operation L'opération.
     */
    public copierVolumesCalculesDansVolumesPrevisionnels(operation: OperationModel) {
        operation.volumeCeePrecaritePrevisionnel = this.getCeePrecarite(operation);
        operation.volumeCeeClassiquePrevisionnel = this.getCeeClassique(operation);
    }

    /**
     * Récupère le montant des CEE classiques pour cette opération.
     * @param operation Opération à parcourir.
     */
    public getCeeClassique(operation: OperationModel) {
        // Récupère le volume enregistré s'il existe.
        if (operation.volumeCeeClassique) {
            return operation.volumeCeeClassique;
        } else {
            // Sinon, on se base sur le calcul global.
            const resultats = this.resultatsCalculOperationsEnMasse.filter((r) => r.data && r.data.uuid === operation.uuid);
            if (resultats.length) {
                return resultats[0].data.volumeCeeClassique;
            }
        }

        return 0;
    }

    /**
     * Récupère le montant des CEE précarité pour cette opération.
     * @param operation Opération à parcourir.
     */
    public getCeePrecarite(operation: OperationModel) {
        // Récupère le volume enregistré s'il existe.
        if (operation.volumeCeePrecarite) {
            return operation.volumeCeePrecarite;
        } else {
            // Sinon, on se base sur le calcul global.
            const resultats = this.resultatsCalculOperationsEnMasse.filter((r) => r.data && r.data.uuid === operation.uuid);
            if (resultats.length) {
                return resultats[0].data.volumeCeePrecarite;
            }
        }

        return 0;
    }
    /**
     * Permet de savoir quel panneau sera déplié par défaut.
     * Dans le cas d'un dossier, on ne déplie pas d'opération par défaut : l'utilisateur doit d'abord demander à voir le détail.
     */
    public calculerPanneauSections(operation: OperationModel = null): void {
        // N'ouvre une opération par défaut que pour une simulation. Dans le cas d'un dossier, on affichera plutôt le récapitulatif.
        if (this.estDossier && !operation) {
            return;
        }
        const operationAffichee = operation ? operation : this.operationEnCours;
        // Détermine quelle opération ouvrir en fonction des paramètres.
        if (this.operations.length > 1) {
            if (operationAffichee && operationAffichee.id) {
                // Affiche l'opération demandée s'il y en a une.
                this.panneauSections = this.indexPanneauxParOperation.findIndex((uuid) => uuid === operationAffichee.uuid);
            } else {
                // Sinon on déplie le dernier panneau par défaut.
                this.panneauSections = this.indexPanneauxParOperation.length - 1;
            }
        } else {
            // Pas besoin de se poser la question s'il n'y a pas plusieurs panneaux.
            this.panneauSections = 0;
        }

        // Fait défiler l'écran pour afficher le panneau lors de l'ouverture de l'opération, excepté pour le premier chargement.
        if (this.operationEnCours || (operation && this.operations.length > 1)) {
            const panneaux = document.getElementsByClassName('anchor-operation');
            const indexAnchor = this.operations.findIndex((op: OperationModel) => op.uuid === operationAffichee.uuid);
            // Le timeout doit être décalé pour attendre la fin de l'animation
            // (le plus simple serait de supprimer cette animation mais je n'ai pas trouvé comment dans la doc).
            const timeout = operation ? 1000 : 100;
            if (panneaux[indexAnchor]) {
                setTimeout(() => panneaux[indexAnchor].scrollIntoView({ behavior: 'smooth', block: 'start' }), timeout);
            }
        }
        // On reset la valeur du store.
        if (this.operationAAffichee != null) {
            this.setOperationAAfficher(null);
        }
    }

    /**
     * Permet de savoir le panneau qui sera déplié par défaut.
     */
    public affecterOperations(itemOperations: OperationModel[], reponse: OperationModel[]): Promise<void> {
        return new Promise<void>(() => {
            // Fusion des valeurs.
            this.operations = itemOperations.map((item) => {
                const item2 = reponse.find((i2) => i2.uuid === item.uuid);
                return item2 ? { ...item, ...item2 } : item;
            });

            if (!this.estDossier) {
                this.onSortOperation('dateCreation', false);
            }

            this.indexPanneauxParOperation = this.operations.map((item) => item.uuid);

            let operationAAffichee: OperationModel = null;
            if (this.operationAAfficher != null) {
                operationAAffichee = this.operations.find((op) => op.id === this.operationAAfficher.id);
            }
            // Affiche une opération par défaut.
            this.calculerPanneauSections(operationAAffichee);

            // Stocker les opérations avant interaction avec l'utilisateur.
            this.listeOperationsReference = cloneDeep(this.operations);
        });
    }

    /**
     * Trie les opérations dans le même ordre que le tableau récapitulatif.
     * @param property
     * @param desc
     */
    public onSortOperation(property: string, desc: boolean) {
        let orderByDesc = desc;
        if (orderByDesc === undefined) {
            orderByDesc = false;
        }

        this.operations = this.operations.sort((op1: any, op2: any) => {

            if (orderByDesc && op1[property] < op2[property] || !orderByDesc && op1[property] > op2[property]) {
                return 1;
            }
            if (orderByDesc && op1[property] > op2[property] || !orderByDesc && op1[property] < op2[property]) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Ouvre la popup de choix d'une opération.
     */
    public ajouterOperation(): void {
        this.$refs.refChoixOperation.open(null, null, null).then((resultatChoixOperation: OperationModel) => {
            if (resultatChoixOperation) {
                this.validerAjoutOperation(cloneDeep(resultatChoixOperation));
                this.valeurModifiee = true;
            }
        });
    }

    /**
     * Duplique une opération et l'ajoute au dossier.
     */
    private async dupliquerOperation(operation: OperationModel) {
        this.dupliquerOperationParcoursUtilisateur(operation);
    }

    /**
     * Supprime une opération après confirmation de l'utilisateur.
     */
    public async supprimerOperation(operation: any) {

        // Rend le calcul obsolète (normalement à faire uniquement si l'utilisateur confirme, mais plus difficile à faire proprement...)
        this.estCalculValide = false;

        // Demande la confirmation de l'utilisateur.
        this.supprimerOperationParcoursUtilisateur(operation);
    }

    /**
     * Permet d'ajouter la nouvelle opération choisie.
     * @param resultatChoixOperation Operation à ajouter à la liste.
     */
    public validerAjoutOperation(resultatChoixOperation: OperationModel): void {
        if (this.estDossier && !!this.dossier) {
            resultatChoixOperation.statutCommercialId = this.dossier.statutDossierId === StatutDossier.EN_CREATION ?
                StatutCommercialOperation.EN_CREATION : this.dossier.statutDossierId === StatutDossier.ENVOYE_ENGIE ?
                    StatutCommercialOperation.ENVOYE_ENGIE : this.dossier.statutDossierId === StatutDossier.EN_TRAITEMENT_ENGIE ?
                        StatutCommercialOperation.EN_TRAITEMENT_ENGIE : null;
        }

        // Si on a déjà des opérations dans le dossier.
        if (resultatChoixOperation.secteurId !== SecteurAsNumber.NonStandard) {
            if (this.operations && this.operations.length > 0) {
                const dateDebut = DateHelper.stringToDate(resultatChoixOperation.dateDebutTravaux);
                const dateFinTravauxPrevisionnelle = DateHelper.stringToDate(this.operations[0].dateFinTravauxPrevisionnelle);
                const dateFinTravaux = DateHelper.stringToDate(this.operations[0].dateFinTravaux);

                if (dateDebut > dateFinTravauxPrevisionnelle) {
                    // Si la nouvelle date de début est supérieur à la date de fin prévisionnelle on la vide.
                    this.synchroDateOperations(null, 'dateFinTravauxPrevisionnelle', resultatChoixOperation);
                } else {
                    // Sinon on applique par défaut la même date à la nouvelle opération.
                    resultatChoixOperation.dateFinTravauxPrevisionnelle = this.operations[0].dateFinTravauxPrevisionnelle;
                }

                if (dateDebut > dateFinTravaux) {
                    // Si la nouvelle date de début est supérieur à la date de fin on la vide.
                    this.synchroDateOperations(null, 'dateFinTravaux', this.resultatChoixOperation);
                } else {
                    // Sinon on applique par défaut la même date à la nouvelle opération.
                    resultatChoixOperation.dateFinTravaux = this.operations[0].dateFinTravaux;
                }
            }
        }

        this.$refs.dialogLoader.start('Ajout de l\'opération en cours...', null, () => {
            return new Promise((resolve) => {
                this.retournerTemplateOperation(true, this.model.simulationDossierId, [resultatChoixOperation]).then((operations: OperationModel[]) => {
                    // Ajoute l'opération à la liste.
                    this.chargerOperations(operations);
                    // On fait le resolve.
                    resolve();
                });
            });
        }, { enabled: false });
    }

    /**
     * Chargement des opérations.
     * @param operations
     */
    public chargerOperations(operations: OperationModel[]) {
        if (operations && operations.length >= 1) {
            this.operations.push(...operations);
            this.estCalculValide = false;

            // Ouvre cette opération par défaut.
            const operation = this.operations[this.operations.length - 1];
            // On stock l'ordre de l'opération dans le panneau.
            this.indexPanneauxParOperation.push(operation.uuid);

            this.calculerPanneauSections(operation);

            setTimeout(() => {
                let departementTravauxValue = this.getPropertyValueOfFirstOperation(this.numDepartementPropertyName) as string | number | null;
                if (this.operations.length === 1 && this.dossier.siteTravaux && this.dossier.siteTravaux.adresse) {
                    departementTravauxValue = this.dossier.siteTravaux.adresse.ville.codePostal.slice(0, 2) || null;
                }
                this.setDepartementTravaux(departementTravauxValue);
                // Gestion spécifique des opérations résidentielles.
                const operationsResidentielles = this.getOperationsResidentielles();
                if (operationsResidentielles && operationsResidentielles.length >= 1) {
                    this.setPrecariteOperationsResidentielles();
                    this.setPrecariteReadOnly(operationsResidentielles, true);
                }

                this.operationEnCours = null;
                // Envoie d’événement.
                this.bus.$emit('on-ouverture-operation');
                // Met à jour les opérations du parent.
                this.bus.$emit('on-modification-nombre-operations', this.operations);
            }, 0);
        }
    }
    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        return new Promise<ResultatValidationEtape>((resolve) => {
            // Vérifie qu'au moins une opération a été ajoutée.
            if (!this.operations || !this.operations.length) {
                resolve(new ResultatValidationEtape(false, false));
            }
            // Validité opérations.
            else {
                let erreurDatesOperations = false;
                if (this.estDossier) {

                    const dateEngagementTravaux = DateHelper.frOrBasetoIsoString(this.operations[0].dateDebutTravaux);
                    const dateFinTravauxPrevisionnelle = DateHelper.frOrBasetoIsoString(this.operations[0].dateFinTravauxPrevisionnelle);
                    const dateFinTravaux = DateHelper.frOrBasetoIsoString(this.operations[0].dateFinTravaux);

                    // Vérification des dates de début et de fin de travaux.
                    this.operations.forEach(o => {
                        let minDateDebut = this.estAdministrateur ? '01-01-2015' : DateHelper.frOrBasetoIsoString(this.getMinDateEngagementComputed(o.secteurId));
                        let maxDateDebut = DateHelper.frOrBasetoIsoString(o.dateFinTravaux);
                        let minDateFin = DateHelper.frOrBasetoIsoString(o.dateDebutTravaux || this.dateRaiComputed);
                        let maxDateFin = DateHelper.frOrBasetoIsoString(this.getMaxDate(o, false));
                        let oDateDebutTravaux = DateHelper.frOrBasetoIsoString(o.dateDebutTravaux);
                        let oDateFinTravaux = DateHelper.frOrBasetoIsoString(o.dateFinTravaux);
                        let oDateFinTravauxPrevisionnelle = DateHelper.frOrBasetoIsoString(o.dateFinTravauxPrevisionnelle);
                        if (!oDateFinTravauxPrevisionnelle) {
                            erreurDatesOperations = true;
                            this.$refs.datePreviFormRef.forEach((form) => form.validate());
                        }

                        // Date de début obligatoire pour profils Entreprise / Installateur / SDC
                        if ((this.estEntrepriseOuInstallateur || this.estSyndicSDC) && !o.dateDebutTravaux) {
                            erreurDatesOperations = true;
                            this.$refs.dateDebutTravaux.forEach((form) => form.validate());
                        }

                        // On ignore les verifications de cohérence de date pour les operations non standard.
                        if (o.secteurId !== SecteurAsNumber.NonStandard) {
                            // Date de début incohérente.
                            if (
                                oDateDebutTravaux &&
                                (minDateDebut && DateHelper.premiereSuperieurASecondeWithoutTime(minDateDebut, oDateDebutTravaux)
                                    || maxDateDebut && DateHelper.premiereSuperieurASecondeWithoutTime(oDateDebutTravaux, maxDateDebut))) {
                                erreurDatesOperations = true;
                            }

                            if (
                                oDateFinTravaux &&
                                (minDateFin && DateHelper.premiereSuperieurASecondeWithoutTime(minDateFin, oDateFinTravaux)
                                    || maxDateFin && DateHelper.premiereSuperieurASecondeWithoutTime(oDateFinTravaux, maxDateFin))) {
                                erreurDatesOperations = true;
                            }
                        }
                    });
                }

                if (erreurDatesOperations) {
                    resolve(new ResultatValidationEtape(false, false));
                }
                else {
                    // Dans le cadre d'une simulation, la date de fin de travaux prévisionnelle est obligatoire.
                    const dateFinTravauxPrevisionnelleManquante = this.operations.some((o: OperationModel) => { return !o.dateFinTravauxPrevisionnelle; });
                    if (dateFinTravauxPrevisionnelleManquante) {
                        // On va activer la validation du champs date previ sur tous les composants enfants OperationElement.
                        this.operations
                            .map((operation: OperationModel, index: number) => {
                                return { operation, clef: 'operation-' + index, index };
                            })
                            .forEach((item: any) => {
                                const refs = (this.$refs as any);
                                const { operation, clef } = item;
                                if (refs[clef] && refs[clef].length > 0) {
                                    refs[clef][0].$refs.datePreviFormRef.validate();
                                }
                            });
                        resolve(new ResultatValidationEtape(false, false));
                        return;
                    }
                }

                // Pré-calcule chaque opération.
                const tableau: Array<Promise<OperationResult>> = this.preCalculOperationsEnMasse();
                let estValide: boolean = false;
                let estValideConventionAH = false;

                Promise.all(tableau).then((reponse: OperationResult[]) => {
                    estValide = reponse.every((elem) => (elem && !elem.isError));
                    estValideConventionAH = this.checkConventionEtAhRecue();
                    if (estValide && estValideConventionAH) {
                        this.operations = this.operations.map((operation) => {
                            const operation2 = reponse.find((i2) => i2.uuid === operation.uuid).data;
                            return operation2 ? { ...operation, ...operation2 } : operation;
                        });
                        if (this.estModifiee && !this.estDossier && this.simulationId) {
                            // Si on est en simulation est que les opérations ont été modifiées on met à jour les opérations côté serveur.                      
                            this.$http.put(`/operation/modifierCalculerOperations`, this.operations).then((result) => {
                                const data = result.data;
                                const isValid = data && !data.isError;
                                if (isValid) {
                                    this.bus.$emit('on-step-validate', this.tabIndex, isValid, this.operations);
                                }
                                resolve(new ResultatValidationEtape(isValid, isValid));
                            }).catch(() => {
                                this.setErrorMessage([{ text: 'Une erreur est survenue lors de la mise à jour des opérations.' }] as Message[]);
                                resolve(new ResultatValidationEtape(false, false));
                            });
                        }
                        else {
                            this.bus.$emit('on-step-validate', this.tabIndex, estValide, this.operations);
                            resolve(new ResultatValidationEtape(estValide, estValide));
                        }
                    }
                    else {
                        this.bus.$emit('on-step-validate', this.tabIndex, false, this.operations);
                        resolve(new ResultatValidationEtape(false, false));
                    }
                });
            }
        });
    }

    public get prixTotalMontantRetroceder() {
        return this.operations.SumBy((o) => (o.prixCeeClassique + o.prixCeePrecarite));
    }

    /**
     * Information coup de pouce à afficher.
     *
     * @private
     * @memberof ResultatSimulation
     */
    private getInfosCoupDePouce(): {
        montant: number,
        message: string
    } {
        const operationCoupDePouce = this.operations.find((o) => (o.arreteLibelle === 'Coup de pouce'));
        if (operationCoupDePouce) {
            let values = operationCoupDePouce.operationValues;
            values = Object.keys(values).map((key) => {
                return [key, values[key]];
            });
            let precarite = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'PRECARITE_CAS'));
            if (precarite) {
                precarite = precarite[1].value;
            }
            let surface = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'SURFACE_ISOLANT_OUVERTURE'));
            if (surface) {
                surface = surface[1].value;
            }
            let nbLogement = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_LOGEMENT_CP'));
            if (nbLogement) {
                nbLogement = nbLogement[1].value;
            }
            let nbEmetteur = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_EMETTEURS_ELECTRIQUES'));
            if (nbEmetteur) {
                nbEmetteur = nbEmetteur[1].value;
            }
            let nbChaudiere = values.find((o: { [key: string]: any }) => (o[1].fieldName === 'NB_CHAUDIERE'));
            if (nbChaudiere) {
                nbChaudiere = nbChaudiere[1].value;
            }
            switch (operationCoupDePouce.code) {
                case 'BAR-EN-101':
                    return {
                        montant: precarite ? 20 * surface : 10 * surface,
                        message: precarite ? '20 euros par m² d\'isolant posé' : '10 euros par m² d\'isolant posé'
                    };
                case 'BAR-EN-103':
                    if (operationCoupDePouce.arreteLibelle === 'Coup de pouce V3') {
                        return {
                            montant: precarite ? 30 * surface : 20 * surface,
                            message: precarite ? '30 euros par m² d\'isolant posé' : '20 euros par m² d\'isolant posé'
                        };
                    } else if (operationCoupDePouce.arreteLibelle === 'Coup de pouce V4') {
                        return {
                            montant: precarite ? 20 * surface : 10 * surface,
                            message: precarite ? '20 euros par m² d\'isolant posé' : '10 euros par m² d\'isolant posé'
                        };
                    }
                    break;
                case 'BAR-TH-104':
                    return {
                        montant: precarite ? 4000 * nbLogement : 2500 * nbLogement,
                        message: precarite ? '4000 € par pompe à chaleur posée' : '2500 € par pompe à chaleur posée'
                    };
                case 'BAR-TH-106':
                    return {
                        montant: precarite ? 1200 * nbLogement : 600 * nbLogement,
                        message: precarite ? '1200 € par chaudière posée' : '600 € par chaudière posée'
                    };
                case 'BAR-TH-113':
                    return {
                        montant: precarite ? 4000 * nbLogement : 2500 * nbLogement,
                        message: precarite ? '4000 € par chaudière posée' : '2500 € par chaudière posée'
                    };
                case 'BAR-TH-158':
                    return {
                        montant: precarite ? 100 * nbEmetteur : 50 * nbEmetteur,
                        message: precarite ? '100 € par émetteur électrique installé' : '50 € par émetteur électrique installé'
                    };
                case 'BAR-TH-163':
                    return {
                        montant: precarite ? 700 * nbChaudiere : 450 * nbChaudiere,
                        message: precarite ? '700 € par chaudière raccordée au conduit' : '450 € par chaudière raccordée au conduits'
                    };
            }
        }
        return { montant: null, message: null };
    }

    /**
     * Permet de calculer les volumes en masse.
     */
    public calculOperationsEnMasse() {
        this.volumeTotal = { classique: 0, precarite: 0 };
        this.prixTotal = 0;
        this.calculLoading = true;
        const tableau: Array<Promise<OperationResult>> = this.preCalculOperationsEnMasse();
        Promise.all(tableau).then((reponse: OperationResult[]) => {
            this.resultatsCalculOperationsEnMasse = reponse;
            this.estCalculValide = reponse.every((elem) => (elem && !elem.isError));
            if (this.estCalculValide) {
                // Initialisation des montants à 0.
                this.prixDetail.classique = 0.0;
                this.prixDetail.precarite = 0.0;
                reponse.forEach((elem) => {
                    let valorisationCeeClassique = elem.data.valorisationCeeClassique;
                    let valorisationCeePrecarite = elem.data.valorisationCeePrecarite;

                    this.volumeTotal.classique += elem.data.volumeCeeClassique;
                    this.volumeTotal.precarite += elem.data.volumeCeePrecarite;
                    this.prixDetail.classique += (valorisationCeeClassique * elem.data.volumeCeeClassique);
                    this.prixDetail.precarite += (valorisationCeePrecarite * elem.data.volumeCeePrecarite);
                    this.prixTotal += (valorisationCeeClassique * elem.data.volumeCeeClassique)
                        + (valorisationCeePrecarite * elem.data.volumeCeePrecarite)
                        + (elem.data.remunerationApporteurAffaires * (elem.data.volumeCeeClassique + elem.data.volumeCeePrecarite));
                });
            }
        }).catch(() => {
            this.calculLoading = false;
            throw new Error('Une erreur est survenue lors du calcul.');
        }).finally(() => {
            this.calculLoading = false;
        });
    }
    /**
     * Permet de pré calculer les volumes en masse.
     */
    public preCalculOperationsEnMasse(): Array<Promise<OperationResult>> {
        this.getChampsNonRenseignes();
        return [
            ...this.operations.map((operation: { [key: string]: any }, index: number) => {
                return { operation, clef: 'operation-' + index };
            }).map((item) => {
                const refs = (this.$refs as any);
                const { operation, clef } = item;

                const uuid = operation.uuid;
                const id = operation.id;

                if (refs[clef] && refs[clef].length > 0) {
                    const operationElement = refs[clef][0];
                    const operationProps = operationElement.formPreviewProps;
                    // Récupération des champs non renseignés essentiels.
                    return new Promise<OperationResult>(async (resolveTableau) => {
                        try {
                            const isValid = await operationProps.validate();
                            if (isValid) {
                                operationProps.validate().then(() => {
                                    operationProps.calculer().then((reponse: { data: { data: OperationModel, messages: Message[], handleError: boolean } }) => {

                                        const data = reponse.data;

                                        const isError = reponse.data.messages ? reponse.data.messages.some((message: Message) => message.messageLevel.toString() === 'Error') : false;
                                        // Mise à jour du résultat du calcul de l'opération.
                                        operationElement.metAJourResultatCalcul(data);
                                        // Met à jour les valeurs calculées de l'opération.
                                        this.updateOperationApresCalcul(data.data, uuid);

                                        const resultatTmp: any = {
                                            ...operation,
                                            ...{
                                                code: data.data.code,
                                                operationValues: data.data.operationValues,
                                                statutOperationId: data.data.statutOperationId,
                                                uniteVolumeCeeClassique: data.data.uniteVolumeCeeClassique,
                                                uniteVolumeCeePrecarite: data.data.uniteVolumeCeePrecarite,
                                                valorisationCeeClassique: data.data.valorisationCeeClassique,
                                                valorisationCeePrecarite: data.data.valorisationCeePrecarite,
                                                volumeCeeClassique: data.data.volumeCeeClassique,
                                                volumeCeePrecarite: data.data.volumeCeePrecarite,
                                                volumeEtUniteCeeClassique: data.data.volumeEtUniteCeeClassique,
                                                volumeEtUniteCeePrecarite: data.data.volumeEtUniteCeePrecarite
                                            },
                                            ...{ id },
                                        };
                                        resolveTableau({ uuid, isError, messages: data.messages, data: resultatTmp as OperationModel });
                                    });
                                });
                            } else {
                                this.updateOperationApresCalcul(new OperationModel(), uuid);
                                resolveTableau({ uuid, isError: true, messages: new Array<Message>(), data: new OperationModel() });
                            }
                        } catch (error) {
                            this.updateOperationApresCalcul(new OperationModel(), uuid);
                            resolveTableau({ uuid, isError: true, messages: new Array<Message>(), data: new OperationModel() });
                        }
                    });
                } else {
                    return new Promise<OperationResult>(async (resolveTableau) => {
                        resolveTableau({ uuid, isError: true, messages: new Array<Message>(), data: new OperationModel() });
                    });
                }
            }),
        ];
    }

    /**
     * Modification des valorisations par un admin.
     * @param operation
     */
    public onModificationAdmin(operation: OperationModel) {
        if (this.estAdministrateur) {
            if (operation.secteurId === SecteurAsNumber.NonStandard) {
                const valorisationClassique = Object.values<any>(operation.operationValues).find((x) => x.fieldName === this.valorisationClassiqueField);
                valorisationClassique.field = valorisationClassique;
                const valorisationPrecarite = Object.values<any>(operation.operationValues).find((x) => x.fieldName === this.valorisationPrecariteField);
                valorisationPrecarite.field = valorisationPrecarite;
                this.busSharedData.$emit('updateValorisationNonStandard', valorisationClassique);
                this.busSharedData.$emit('updateValorisationNonStandard', valorisationPrecarite);
            }
            // Mise à jour des paiements suite à la modification de l'opération d'un dossier en création par un admin.
            if (this.dossier.id && this.dossier.statutDossierId === StatutDossier.EN_CREATION) {
                const paiementService = new ApiService<Paiement[]>('paiement/recupererLesPaiementsParDossier');
                new Promise<Paiement[]>((resolve, reject) => {
                    return paiementService
                        .get(this.dossier.id)
                        .then((response) => resolve(response.data.data))
                        .catch((error: { response: Error; }) => reject(error.response));
                })
                    .then((paiements) => {
                        this.$set(this.dossier, 'paiements', paiements.sort((p1, p2) => p2.typeContributionId - p1.typeContributionId));
                    });
            }
            this.updateOperationApresCalcul(null, '', true);
        }
    }

    /**
     * Met à jour les valeurs calculées de l'opération et annule le précédent calcul.
     * @param resultat Résultat du calcul de l'opération à mettre à jour.
     * @param uuid Identifiant unique de l'opération.
     * @param supprimerResultatPrecedent Supprime le calcul de masse précédent, à activer quand on a seulement recalculé une opération.
     */
    public updateOperationApresCalcul(resultat: OperationModel, uuid: string, supprimerResultatPrecedent = false) {

        // Annule le calcul en masse précédent.
        if (supprimerResultatPrecedent) {
            this.resultatsCalculOperationsEnMasse = [];
        }

        // Récupère l'opération correspondant au résultat.
        const operations = this.operations.filter(op => op.uuid === uuid);

        if (operations.length !== 1) {
            return;
        }
        const operation = operations[0];

        if (operation.remunerationApporteurAffaires === undefined) {
            operation.remunerationApporteurAffaires = resultat.remunerationApporteurAffaires;
        }

        // Met à jour les valeurs.
        operation.statutOperationId = resultat.statutOperationId;
        operation.volumeCeeClassique = resultat.volumeCeeClassique;
        operation.volumeCeePrecarite = resultat.volumeCeePrecarite;
        operation.volumeCeeClassiqueEnMWhCumac = resultat.volumeCeeClassiqueEnMWhCumac;
        operation.volumeCeePrecariteEnMWhCumac = resultat.volumeCeePrecariteEnMWhCumac;
        operation.valorisationCeeClassique = resultat.valorisationCeeClassique;
        operation.valorisationCeePrecarite = resultat.valorisationCeePrecarite;
        operation.prixCeeClassique = operation.volumeCeeClassique * operation.valorisationCeeClassique;
        operation.prixCeePrecarite = operation.volumeCeePrecarite * operation.valorisationCeePrecarite;
        operation.valorisationApporteurAffaires = (operation.volumeCeeClassique + operation.volumeCeePrecarite) * operation.remunerationApporteurAffaires;

        // Mise à jour des opérations dans le singleton dossier.      
        this.dossier.operations = this.dossier.operations.map(x => (x.id === operation.id) ? operation : x);
    }

    /**
     * Validation et enregistrement des infos du montant à rétrocéder.
     *
     */
    public enregistrerMontantRetroceder(): void {
        this.loadingMontantRetroceder = true;
        if (this.$refs.montantRetrocederFormRef.validate()) {
            this.modifierInfosMontantRetroceder().then((result) => {
                this.loadingMontantRetroceder = false;
                // Mise à jour des infos sur le dossier, ppour éviter de perdre ces infos au moment de l'enregistrement du dossier.
                this.dossier.montantRetroceder = result.montant;
                this.dossier.nomSyndic = result.nomSyndic;
                this.dossier.estSdc = result.estSdc;
            }).finally(() => {
                this.dialogMontantRetroceder = false;
            });
        }
    }

    /**
     * Met à jour les valeurs saisies des opérations
     */
    public recupererDonneesSaisiesOperations(): OperationModel[] {
        return this.operations
            .map((operation: { [key: string]: any }, index: number) => {
                return { operation, clef: 'operation-' + index };
            })
            .map((item) => {
                const refs = (this.$refs as any);
                const { operation, clef } = item;
                if (refs[clef] && refs[clef][0]) {
                    const operationProps = refs[clef][0].formPreviewProps;
                    const operationValues = operationProps.getValeursSaisies() as { [key: string]: any };
                    // Set OperationCode + Operation Values.
                    operation.operationValues = operationValues;
                    //
                    delete operation.listeEnumReferentiel;
                }

                return operation as OperationModel;
            });
    }

    /**
     * Exporter le model.
     *
     * @returns
     * @memberof Operation
     */
    public exporterEtapeModel() {
        this.listeOperationsReference = cloneDeep(this.operations);
        const operationsFinal = cloneDeep(this.recupererDonneesSaisiesOperations()).map((o) => {
            o.config = {};
            return o;
        });
        return {
            model: operationsFinal,
            meta: {
                volumeTotal: this.volumeTotal,
                param: 'operations',
            }
        };
    }

    /**
     * Méthode de récupération des opérations résidentielles de la simulation ou du dossier.
     *
     * @returns
     * @memberof Operation
     */
    public getOperationsResidentielles(): OperationModel[] {
        const operationsExistantes = this.recupererDonneesSaisiesOperations();
        if (operationsExistantes.length >= 1) {
            return (operationsExistantes || []).filter(this.lambdaFilterResidentielle);
        }
        return new Array<OperationModel>();
    }

    /**
     * Les opérations résidentielles (hors 1ère) ont leur précarité en READONLY.
     *
     * @returns
     * @memberof Operation
     */
    public setPrecariteReadOnly(operationsResidentielles: OperationModel[], active: boolean) {
        if (operationsResidentielles && operationsResidentielles.length >= 1) {
            operationsResidentielles
                .filter((o: OperationModel) => (operationsResidentielles.indexOf(o) !== 0))
                .forEach((o: OperationModel) => {
                    if (o.sections !== undefined) {
                        o.sections[3].schemas.forEach((s: any) => {
                            s.readonly = active;
                        });
                    }
                });
        }
    }

    /**
     * Méthode de récupération des valeurs de la précarité de la 1ère opération résidentielle de la simulation ou du dossier.
     *
     * @returns
     * @memberof Operation
     */
    public getPrecariteFirstOperationResidentielle(): { [key: string]: string | number | boolean | null } {
        const precariteValues: { [key: string]: string | number | boolean | null } = {};
        const operationsResidentielles = this.getOperationsResidentielles();
        if (operationsResidentielles && operationsResidentielles.length >= 1) {
            const premiereOperationResidentielle = operationsResidentielles[0];
            if (premiereOperationResidentielle && premiereOperationResidentielle.operationValues) {
                const values: { [key: string]: any } = premiereOperationResidentielle.operationValues;
                if (values && Object.keys(values).length >= 1) {
                    Object.keys(values).forEach((k: string) => {
                        if (k.startsWith("3_")) {
                            precariteValues[k] = values[k].value;
                        }
                    });
                }
            }
        }
        return precariteValues;
    }

    /**
     * Méthode de récupération de la valeur d'un champ de la 1ère opération.
     * @param propertyName Nom du champ.
     */
    public getPropertyValueOfFirstOperation(propertyName: string): string | number | null {
        const operationsExistantes = this.recupererDonneesSaisiesOperations();
        if (operationsExistantes) {
            const firstOperation = operationsExistantes[0];
            if (firstOperation && firstOperation.operationValues && Object.keys(firstOperation.operationValues).length >= 1) {
                const propertyObject = Object.values<{ fieldName: string, indexCalculeChamp: string }>(firstOperation.operationValues).find(x => x.fieldName === propertyName);
                if (propertyObject && propertyObject.indexCalculeChamp) {
                    return firstOperation.operationValues[propertyObject.indexCalculeChamp].value;
                }
            }
        }
        return null;
    }

    /**
     * Définit la précarité des autres opérations résidentielles.
     */
    public setPrecariteOperationsResidentielles() {
        const precariteValues = this.getPrecariteFirstOperationResidentielle() as {
            [key: string]: string | number | boolean | null
        };
        this.operations
            .map((operation: OperationModel, index: number) => {
                return { operation, clef: 'operation-' + index, index };
            })
            .filter((item) => this.lambdaFilterResidentielle(item.operation))
            .filter((item) => item.index !== 0)
            .forEach((item: any) => {
                const refs = (this.$refs as any);
                const { operation, clef } = item;
                if (refs[clef] && refs[clef].length > 0) {
                    const operationProps = refs[clef][0].formPreviewProps;
                    operationProps.updatePrecariteValues(precariteValues);
                }
            });
    }

    /**
     * Définit le numéro de département des autres opérations.
     */
    public setDepartementTravaux(departementTravauxValue: string | number | null) {
        this.operations
            .map((operation: OperationModel, index: number) => {
                return { operation, clef: 'operation-' + index, index };
            })
            .forEach((item: any) => {
                const refs = (this.$refs as any);
                const { operation, clef } = item;
                if (refs[clef] && refs[clef].length > 0) {
                    const operationProps = refs[clef][0].formPreviewProps;
                    operationProps.updateProperty(this.numDepartementPropertyName, departementTravauxValue);
                }
            });
    }

    /**
    * Définit la check box soumis à contrôle.
    */
    public setSoumisAControle(value: string | number | null, numeroOperation: string) {
        const soumisPolitiqueControleField = 'SOUMISE_POLITIQUE_CONTROLE';
        this.operations
            .map((operation: OperationModel, index: number) => {
                return { operation, clef: 'operation-' + index, index };
            })
            .filter(x => x.operation.numero === numeroOperation)
            .forEach((item: any) => {
                const refs = (this.$refs as any);
                const { operation, clef } = item;
                if (refs[clef] && refs[clef].length > 0) {
                    const operationProps = refs[clef][0].formPreviewProps;
                    operationProps.updateProperty(soumisPolitiqueControleField, value);
                }
            });
    }

    /**
     * Initialisation des opérations
     */
    private chargementListeOperations(): void {
        this.loadingEtape = true;
        this.loadingConfig = true;
        this.recupererOperations().then((itemOperations: OperationModel[]) => {
            // Si c'est en modification on affecte les opérations directement, car on déjà fait un fetch.
            this.retournerTemplateOperation(false, this.model.simulationDossierId, itemOperations)
                .then((reponse: OperationModel[]) => {
                    this.affecterOperations(itemOperations, reponse);
                    this.loadingConfig = false;
                })
                .then(() => {
                    this.checkSoumisAControle();
                })
                .finally(() => {
                    this.loadingConfig = false;

                    // Ajoute un délai à la fin du chargement car il y a pas mal d’initialisations réalisées dans les sous-composants qu'on ne peut pas forcément traquer.
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.validerEtape(true).then(() => this.loadingEtape = false);
                        }, 50);
                    });
                });

        }).catch((error: string) => {
            this.setErrorMessage([{ text: error || 'Une erreur est survenue lors du chargement des opérations.' }] as Message[]);
        }).finally(() => {
            this.setLoadingWizard(false);
        });
    }

    /**
     * Définie les libellés des champs non renseignés.
     * @param operationValues
     */
    private getChampsNonRenseignes() {

        const champsNonRenseignes: string[] = [];
        const prefixMessage = 'Veuillez renseigner le champ : ';

        this.removeChampsNonRenseignes(TypeDeWizardComposant.Operation);

        const operationsExistantes = this.recupererDonneesSaisiesOperations();

        operationsExistantes.forEach((operation: OperationModel) => {
            if (!operation.dateDebutTravaux) {
                champsNonRenseignes.push(prefixMessage + 'Date de début des travaux');
            }
            if (!operation.dateFinTravaux) {
                champsNonRenseignes.push(prefixMessage + 'Date de fin des travaux');
            }
            const operationValues = operation.operationValues;

            /**
             * Parcours des opérations et récupération des libellés
             * des champs non renseignée et obligatoire.
             */
            if (operationValues && typeof operationValues !== 'undefined') {
                Object.keys(operationValues).forEach((key: string) => {
                    const operationValue = operationValues[key];
                    if (operationValue.estVisible
                        && isNullOrEmpty(operationValue.value)) {
                        this.operations.forEach((op: any) => {
                            op.config.sections[operationValue.sectionIndex].schemas.forEach((schema: any) => {
                                if (schema.templateChamp &&
                                    !schema.templateChamp.estIgnoreValidation &&
                                    schema.name === operationValue.fieldName &&
                                    !champsNonRenseignes.includes(schema.label)) {
                                    champsNonRenseignes.push(prefixMessage + schema.label);
                                }
                            });
                        });
                    }
                });
            }
            this.addChampsNonRenseignes({ etape: TypeDeWizardComposant.Operation, champs: champsNonRenseignes });
        });
    }

    /**
     * Détermine la date max pour les travaux de l'opération.
     * @param operation
     */
    public getMaxDate(operation: OperationModel, estPrevisionnelle: boolean): string {
        const dateAchevementFin = DateHelper.toIsoString((operation.config as any).templateOperationDateAchevementFin);
        if (estPrevisionnelle) {
            return dateAchevementFin;
        }
        else {
            return operation.secteurId === SecteurAsNumber.NonStandard && this.estInterne ? null : DateHelper.getClosest(dateAchevementFin, this.aujourdhui);
        }
    }

    /**
     * Détermine la date min pour la date de fin des travaux de l'opération.
     * @param operation
     */
    public getMinDateFinTravaux(operation: OperationModel): string {
        let dateRai = this.dateRaiComputed;
        let dateDebutEngagement = operation.dateDebutTravaux;
        let dateDebutSurSite = '';

        if (operation.operationValues) {
            let operationValues = Object.keys(operation.operationValues).map((key) => {
                return [key, operation.operationValues[key]];
            });

            let dateDebutSurSiteField = operationValues.find((x: any) => x[1].fieldName === "DATE_DEBUT_TRAVAUX_SITE");
            if (dateDebutSurSiteField) {
                dateDebutSurSite = DateHelper.frToIso(dateDebutSurSiteField[1].value);
            }
        }

        let moments = [dateRai, dateDebutEngagement, dateDebutSurSite].map(date => moment(date)).filter(date => date.isValid());
        let minDate = moment.max(moments);

        return minDate.format();
    }

    /**
     * Possibilité de supprimer une opération.
     */
    public get peutSupprimerOperation(): boolean {

        if (this.currentProfil === EnumProfilsAsNumber.AdministrateurInformatique ||
            this.currentProfil === EnumProfilsAsNumber.AdministrateurCEE ||
            this.currentProfil === EnumProfilsAsNumber.Finance) {
            // On peut supprimer si on est administrateur, quelque soit le statut du dossier.
            return true;
        } else if (this.currentProfil === EnumProfilsAsNumber.GestionnaireBO) {
            // Les gestionnaires BO ne peuvent pas supprimer.
            return false;
        }

        const statutsBloquesGestionnaireBo: StatutDossier[] = [
            StatutDossier.ENVOYE_ENGIE,
            StatutDossier.A_RECEVOIR,
            StatutDossier.EN_TRAITEMENT_ENGIE,
            StatutDossier.FINALISE_ENGIE,
            StatutDossier.NON_DEPOSE_PNCEE,
            StatutDossier.DEPOSE_PNCEE,
            StatutDossier.TRAITE_PNCEE
        ];

        // Impossible de supprimer une opération avec ces statuts de dossier.
        if (this.dossier && statutsBloquesGestionnaireBo.indexOf(this.dossier.statutDossierId) > -1) {
            return false;
        }

        return !this.estDossierHistorique;
    }

    /**
     * Dossier éditable.
     */
    public get canEditDossier(): boolean {
        if (!this.dossier) {
            return false;
        }

        // Intern peut modifier quelque soit le statut.
        if (this.estInterne) {
            return true;
        }

        // Pour les externes, on vérifie qu'il n'a pas déjà été envoyé.
        return this.dossier.statutDossierId === StatutDossier.EN_CREATION;
    }

    // Modification de statut commercial des opérations selectionnées.
    public confirmUpdateStatut(): void {
        const operationsSelected = this.operations.filter(o => o.selected);
        operationsSelected.forEach(o => o.statutCommercialId = this.statutCommercialIdGlobal);
        if (operationsSelected.length > 0) {
            const operationService = new ApiService<OperationModel[]>('operation/modifierStatutCommercialEnMasse');
            operationService.put(operationsSelected).then((result) => {
                this.dossier.operations = result.data.data;
            });
        }
    }

    /**
     * Créer une copie des opérations
     * en exluant les informations ajoutées par la modification
     * d'un champ.
     * @param operations
     * @returns OperationModel[]
     */
    private deepCopyOperations(operations: OperationModel[]) {
        const cloneOperations = cloneDeep(operations);
        cloneOperations.forEach((operation: any) => {
            operation = omit(operation, ['uuid']);
            if (operation.operationValues) {
                Object.keys(operation.operationValues).forEach((index) => {
                    operation.operationValues[index] = omit(
                        operation.operationValues[index],
                        ['id', 'operationId', 'positionInArray', 'positionIndexArray']);
                });
            }
        });
        return cloneOperations;
    }

    /** Il n'est pas possible de passer une opération en valider par engie si l'original de l'AH et/ou la convention n'a pas été reçue. */
    private checkConventionEtAhRecue(): boolean {
        let estValide = true;
        let dossierAAConvention = this.dossier.apporteurAffairesId != null || this.estDossierPourApporteurAffaires;
        if (this.operations.some(o => o.statutCommercialId === StatutCommercialOperation.VALIDE_ENGIE) && !this.estAdministrateur) {
            if (this.dossier.originaleAHRecue !== 1 && dossierAAConvention && this.dossier.originaleConventionRecue !== 1) {
                this.setErrorMessage([{ text: "Sauvegarde du dossier impossible : L'original de l'AH et de la convention doivent être reçus", messageLevel: MessageLevel.Error }] as Message[]);
                estValide = false;
            }
            else if (this.dossier.originaleAHRecue !== 1) {
                this.setErrorMessage([{ text: "Sauvegarde du dossier impossible : L'original de l'AH doit être reçue.", messageLevel: MessageLevel.Error }] as Message[]);
                estValide = false;
            }
            else if (this.dossier.originaleConventionRecue !== 1 && dossierAAConvention) {
                this.setErrorMessage([{ text: "Sauvegarde du dossier impossible : L'original de la convention doit être reçue.", messageLevel: MessageLevel.Error }] as Message[]);
                estValide = false;
            }
        }

        return estValide;
    }

    /**
     * Sync des code postaux
     * @param codePostal
     */
    private updateCodePostalOperations(codePostal: string): void {
        if (codePostal.length === 5) {
            let departement = codePostal.slice(0, 2);
            let departementReferentiel = this.referentielsEtape.departement.find(x => x.code === departement);

            if (departementReferentiel) {
                this.setDepartementTravaux(departement);
            }
        }
    }

    /**
     * Met à jour les opérationsValues de la liste des opérations.
     * @param operation
     */
    private majOperationValues(operationValue: any, operationId: number): void {
        let operationToUpdateIndex = this.operations.findIndex(op => op.id === operationId);
        if (operationToUpdateIndex > -1) {
            let operationValueIndex = operationValue.dataIndex;
            this.operations[operationToUpdateIndex].operationValues[operationValueIndex].value = operationValue.value;
            this.valeurModifiee = true;
            if ((Object.values(SurfacesControle).includes((operationValue.fieldName)))) {
                this.checkSoumisAControle();
            }
        }
    }

    /**
     * Check si les operations doivent être soumises à contrôle.
     */
    private checkSoumisAControle(): Promise<void> {
        return new Promise<void>(() => {
            this.$nextTick(() => {
                this.operations
                    .forEach((operation: OperationModel) => {
                        const champSurface = Object.values<any>(operation.operationValues).find((x) => Object.values(SurfacesControle).includes(x.fieldName));
                        let surface!: number;
                        if (champSurface) {
                            surface = champSurface.value as number;
                        }

                        if (!operation.dateDebutTravaux) {
                            operation.dateDebutTravaux = this.aujourdhui;
                        }

                        if (operation.dateDebutTravaux >= (operation as any).dateEntreeApplicationControleHorsSurface) {
                            this.setSoumisAControle("true", operation.numero);
                        }
                        else if (surface && surface >= (operation as any).surfaceIsolantApplicationControle && operation.dateDebutTravaux >= (operation as any).dateEntreeApplicationControle) {
                            this.setSoumisAControle("true", operation.numero);
                        }
                        else {
                            this.setSoumisAControle("false", operation.numero);
                        }
                    });
            });
        });
    }

    private bonificationCPEEnabler(secteurId: number, dateDebutTravaux: string) {
        const secteursSansCPE2022 = [SecteurAsNumber.Agriculture, SecteurAsNumber.Industrie, SecteurAsNumber.Reseaux, SecteurAsNumber.Transport];
        this.busSharedData.$emit('toggle-CPE-disable', secteursSansCPE2022.includes(secteurId) && moment(dateDebutTravaux) >= moment('2022-01-01'));
    }
}
