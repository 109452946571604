


























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { ResultatSimulationStoreMethods } from '@/store/modules/simulation/ResultatSimulationStore';
import MontantRetroceder from './models/MontantRetroceder';
@Component({
    name: 'ChoixSdcForm',
})
export default class ChoixSdcForm extends Vue {
    @Prop({default : true}) private estChoixSdcVisible!: boolean;

    @Prop({default : true}) private estSdc!: boolean;

    @Getter(ResultatSimulationStoreMethods.MONTANT_A_RETROCEDER)
    private montantRetroceder: MontantRetroceder;

    @Mutation(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER_BY_PRIORITIES)
    public setPropertyMontantRetroceder: (ac: object) => void;

    @Prop({ default: false }) private isDisabled!: boolean;
}
