import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import SiteDesTravauxValidator from './SiteDesTravaux.Validator';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';

import template from './SiteDesTravaux.Template.vue';
import { SiteTravaux, Adresse} from '@/models';
import SiteTravauxDataPicker from './SiteTravauxDataPicker.vue';
import { ApiService } from '@/services/base/ApiService';
import { cloneDeep, isEqual } from 'lodash-es';
import { SimulationSiteTravaux } from '../models/SimulationSiteTravaux.model';
import { AuthStoreMethods } from '../../../../store/modules/auth/AuthStore';
import { Getter, Mutation } from 'vuex-class';
import CeeAdresse from '@/components/CeeAdresse.vue';
import { EtapeParcoursSimulation } from '@/shared/enums/EtapeSimulation';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { EnumProfilsAsNumber } from '@/shared/enums';
import { UserProfile } from '@/store/modules/auth/types';
import { TypeDeWizardComposant } from '../../Composants';
import { InformationsSimulationDossierStoreMethods } from '../../../../store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { Prop } from 'vue-property-decorator';
import { DossierReferentiels } from '@/models/DossierReferentiels.model';

//  Créer une interface pour fusionner les mixins.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, Vue   { }

@Component({
    ...template,
    name: 'SiteDesTravaux',
    ref: 'SiteDesTravaux',
    mixins: [template, CommonMixin, WizardEtapeComponentBase],
    components: {
        SiteTravauxDataPicker,
        CeeAdresse,
        CeeDatePicker,
    },
})
export default class SiteDesTravaux extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, SiteDesTravauxValidator)
implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    [x: string]: any;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    // Ajout des champs non renseignés de l'étape après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.ADD_CHAMPS_NON_RENSEIGNES)
    public addChampsNonRenseignes: (champsNonRenseignes: any) => void;

    // Suppression des champs non renseignes de l'étape si ok après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.REMOVE_CHAMPS_NON_RENSEIGNES)
    public removeChampsNonRenseignes: (etape: TypeDeWizardComposant) => void;

    @Prop()
    public referentielsEtape: DossierReferentiels;

    /**
     * Vrai si le profil utilisateur c'est un administrateur.
     *
     * @private
     * @type {boolean}
     * @memberof SiteDesTravaux
     */
    public isUserAdmin: boolean = false;

    public titre(): string { return 'Site des travaux'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-map-marker-alt'; }

    public siteTravaux: SiteTravaux = new SiteTravaux();
    public sitesTravaux: SiteTravaux[] = new Array<SiteTravaux>();

    /**
     * Le site de travaux tel qu'il a été sauvegardé dans la base de données.
     *
     * @private
     * @type {SiteTravaux}
     * @memberof SiteDesTravaux
     */
    private originalSiteTravaux: SiteTravaux = new SiteTravaux();

    /**
     * Vrai si le site actuel est importé et sauvegardé en base, faux sinon.
     */
    public isSiteTravauxImported: boolean = false;
    public simulationSiteTravaux: SimulationSiteTravaux = new SimulationSiteTravaux(0, 0, null, EtapeParcoursSimulation.SiteDesTravaux);
    /**
     * Vrai si un site a été sélectionné, faux sinon.
     */
    public isSiteTravauxPicked: boolean = false;
    public etapeSuivante: EtapeParcoursSimulation = 0;
    public isUserInterne: boolean = false;
    public currentUser: EnumProfilsAsNumber = EnumProfilsAsNumber.None;
    public $refs!: {
        form: HTMLFormElement,
    };

    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public getModeleDonnees(): { [propriete: string]: any } {
        return {
            siteTravaux: this.siteTravaux
        };
    }

    /**
     * Constructeur
     */
    constructor() {
        super();
    }

    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.getSitesTravauxBySimulationDossier().then((siteTravaux) => {
            this.siteTravaux = siteTravaux;
            Object.assign(this.originalSiteTravaux, this.siteTravaux);
        }).finally(() => {
            this.getUserProfile.then((profile: UserProfile) => {
                if (!!profile) {
                    this.currentUser = profile.profilId as EnumProfilsAsNumber;
                    this.isUserAdmin = profile.isAdmin;
                    this.etapeSuivante = this.currentUser === EnumProfilsAsNumber.ApporteurAffaires ?
                                        EtapeParcoursSimulation.DonneesBeneficiaire : EtapeParcoursSimulation.ResultatSimulation;
                    this.isUserInterne = profile.isInterne;
                    if (this.isUserAdmin || (!this.estDossier && !this.isSiteTravauxImported) ||
                        (this.estDossier && ((!!this.siteTravaux && !this.siteTravaux.id) || !this.siteTravaux))) {
                        this.getSitesTravauxByCreateur(profile.preferred_username)
                        .then((sites) => {
                            this.sitesTravaux = sites;
                        });
                    }
                } else {
                    throw new Error('Une erreur est survenue lors de la récupération');
                }
            });
            this.setLoadingWizard(false);
        });
    }
    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        this.setLoadingWizard(false);
    }


    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        // Avant de relancer la validation, on clear les messages d'erreurs.
        this.setErrorMessage(null);
        this.removeChampsNonRenseignes(TypeDeWizardComposant.SiteDesTravaux);
        let valid = this.$refs.form.validate();
        // S'il s'agit d'une simulation, on la lie au site si possible.
        if (!this.estDossier) {
            if (valid) {
                this.lierSiteTravauxToSimulation().then((response) => {
                    this.toNextStepEventBus(valid, response.id);
                });
            } else {
                if (this.isSiteTravauxImported) {
                    this.toNextStepEventBus(valid, this.siteTravaux.id);
                } else if (this.isUserInterne || this.isUserAdmin) {
                    valid = true;
                    this.toNextStepEventBus(valid, 0);
                }
            }
        } else {
            const errors = this.$refs.form.inputs.filter(
                (input: any) => input.errorBucket.length).map((error: any) => error.errorBucket);
            this.addChampsNonRenseignes({ etape:  TypeDeWizardComposant.SiteDesTravaux, champs: errors});
        }

        // Retourne la réponse (est-ce que le setTimeout est nécessaire ?)
        return new Promise<ResultatValidationEtape>((resolve) => setTimeout(() => resolve(new ResultatValidationEtape(valid, valid)), 0));
    }

    /**
     * Récupération des données émis par le composant enfant.
     *
     * @param {SiteTravaux} imported
     * @memberof SiteDesTravaux
     */
    public importSiteTravaux(imported: SiteTravaux) {      
        this.siteTravaux = cloneDeep(imported);
        this.dossier.siteTravaux = this.siteTravaux;
        this.isSiteTravauxPicked = true;
    }

    /**
     * Désélectionner le site suite à un clic dans le composant enfant.
     */
    public deselectionnerSiteTravaux() {
        this.isSiteTravauxPicked = false;
        this.reset();
    }

    /**
     * Récupérer le site de travaux par créateur.
     *
     * @param {string} createur
     * @returns {Promise<SiteTravaux[]>}
     * @memberof SiteDesTravaux
     */
    public getSitesTravauxByCreateur(createur: string): Promise<SiteTravaux[]> {
        const siteTravauxService = new ApiService<SiteTravaux>('siteTravaux/obtenirParCreateur');
        return new Promise<SiteTravaux[]>((resolve, reject) => {
            return siteTravauxService.getWhere(`createur=${createur}`).then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    /**
     * Récupérer un site de travaux qui correspondent à un identifiant.
     *
     * @returns {Promise<SiteTravaux>}
     * @memberof SiteDesTravaux
     */
    public getSitesTravauxById(): Promise<SiteTravaux> {
        const siteTravauxService = new ApiService<SiteTravaux>('siteTravaux/obtenir');
        return new Promise<SiteTravaux>((resolve, reject) => {
            return siteTravauxService.get(this.model.siteTravauxId).then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    /**
     * Récupérer un site de travaux qui correspondent à un identifiant.
     *
     * @returns {Promise<SiteTravaux>}
     * @memberof SiteDesTravaux
     */
    public getSitesTravauxBySimulationDossier(): Promise<SiteTravaux> {
        const siteTravauxService = new ApiService<SiteTravaux>('simulationDossier/recupererSiteTravauxParSimulationDossier');
        return new Promise<SiteTravaux>((resolve, reject) => {
            if (!!this.model.simulationDossierId) {
                siteTravauxService.get(this.model.simulationDossierId).then((response) => {
                    if (!!response && !!response.data.data) {
                        resolve(response.data.data);
                        this.isSiteTravauxImported = true;
                        this.isSiteTravauxPicked = false;
                    } else {
                        resolve(new SiteTravaux());
                    }
                }).catch((error: { response: Error; }) => {
                    reject(error.response);
                });
            } else {
                resolve(new SiteTravaux());
            }
        });
    }
    /**
     * Enregistrement d'un site de travaux
     *
     * @returns
     * @memberof SiteDesTravaux
     */
    public saveSiteTravaux() {
        this.siteTravaux.adresse.ville = null;
        const siteTravauxService = new ApiService<SiteTravaux>('siteTravaux/creer');
        return new Promise<SiteTravaux>((resolve, reject) => {
            return siteTravauxService.post(this.siteTravaux).then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }
    /**
     * Enregistrement d'un site de travaux
     *
     * @returns
     * @memberof SiteDesTravaux
     */
    public lierSiteTravauxToSimulation(): Promise<SimulationSiteTravaux> {
        this.simulationSiteTravaux = this.consoliderValideSimulationSiteTravaux();
        const siteTravauxService = new ApiService<SimulationSiteTravaux>('simulation/lierSiteTravauxASimulation');
        return new Promise<SimulationSiteTravaux>((resolve, reject) => {
            return siteTravauxService.post(this.simulationSiteTravaux).then((response) => {
                resolve(response.data.data);
            })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
            });
        });
    }

    /**
     * Envoi d'un événement avec des données de l'étape courante à l'étape qui suit.
     *
     * @param {boolean} valid
     * @param {number} id
     * @memberof SiteDesTravaux
     */
    public toNextStepEventBus(valid: boolean, id: number) {
        this.bus.$emit('on-step-validate', this.tabIndex, valid, {
            siteTravauxId: id,
        });
    }

    /**
     * Réinitialisation du formulaire.
     *
     * @memberof SiteDesTravaux
     */
    public reset(): void {
        this.siteTravaux.id = 0;
        this.siteTravaux.adresseId = 0;
        this.siteTravaux.adresse.id = 0;
        this.isSiteTravauxPicked = false;
        this.siteTravaux.adresse = new Adresse();
        this.$refs.form.reset();
    }

    public consoliderValideSimulationSiteTravaux(): SimulationSiteTravaux {
        // Si le site a été choisi parmi les existents et aucune modification.
        if ((this.isSiteTravauxPicked || this.isSiteTravauxImported) && !this.estSiteTravauxModifie()) {
            return new SimulationSiteTravaux(this.model.simulationDossierId, this.siteTravaux.id, null, this.etapeSuivante);
        }
        // Si l'utilisateur a inséré le site a la main ou a modifié le site choisi alors créer un nouveau site a partir des données de l'UI.
        const adresse: Adresse = cloneDeep(this.siteTravaux.adresse);
        adresse.id = 0;
        adresse.ville = null;
        return new SimulationSiteTravaux(this.model.simulationDossierId, null,
            new SiteTravaux(0, this.siteTravaux.libelle, 0, adresse), this.etapeSuivante);

    }

    public exporterEtapeModel(): {model: any, meta: any} {
        return {
            model: this.siteTravaux,
            meta: {
                isSiteTravauxChoisi: this.isSiteTravauxPicked,
                param: 'siteTravaux'
            }
        };
    }

    /**
     * Vérification du changement des données sur le site de travaux sauvegardé en base.
     */
    private estSiteTravauxModifie(): boolean {
        return !isEqual(this.siteTravaux, this.originalSiteTravaux);
    }

    private changeCodePostal(codePostal: string): void {
        if (!this.dossier.siteTravaux) {
            this.dossier.siteTravaux = this.siteTravaux
        }

        this.dossier.siteTravaux.adresse.ville.codePostal = codePostal;
        this.bus.$emit('on-code-postal-change', codePostal);
    }
}
