import ChoixSecteurEtOperation from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperation';
import Operation from '@/components/Wizard/Commun/Operation/Operation';
import ChoixBeneficiareOuInstallateur from '@/components/Wizard/Commun/ChoixBeneficiareOuInstallateur/ChoixBeneficiareOuInstallateur';
import SiteDesTravaux from '@/components/Wizard/Commun/SiteDesTravaux/SiteDesTravaux';
import DonneesBeneficiaire from '@/components/Wizard/Commun/DonneesBeneficiaire/DonneesBeneficiaire';
import DonneesInstallateur from '@/components/Wizard/Commun/DonneesInstallateur/DonneesInstallateur';
import DonneesEngie from '@/components/Wizard/Commun/DonneesEngie/DonneesEngie';
import DonneesApporteurAffaires from '@/components/Wizard/Commun/DonneesApporteurAffaires/DonneesApporteurAffaires';
import Documents from '@/components/Wizard/Commun/Documents/Documents';
import HistoriqueDossier from '@/components/Wizard/Commun/HistoriqueDossier/HistoriqueDossier';
import ResultatSimulation from '@/components/Wizard/Commun/ResultatSimulation/ResultatSimulation';
import Valorisation from '@/components/Wizard/Commun/Valorisation/Valorisation';
import Facturation from '@/components/Wizard/Commun/Facturation/Facturation';


export enum TypeDeWizardComposant {
    ChoixSecteurEtOperation = 'ChoixSecteurEtOperation',
    Operation = 'Operation',
    ChoixBeneficiareOuInstallateur = 'ChoixBeneficiareOuInstallateur',
    SiteDesTravaux = 'SiteDesTravaux',
    DonneesBeneficiaire = 'DonneesBeneficiaire',
    DonneesInstallateur = 'DonneesInstallateur',
    ResultatSimulation = 'ResultatSimulation',
    Valorisation = 'Valorisation',
    HistoriqueDossier = 'HistoriqueDossier',
    DonneesApporteurAffaires = 'DonneesApporteurAffaires',
    DonneesEngie = 'DonneesEngie',
    Documents = 'Documents',
    Facturation = 'Facturation',
}

export const composantsSimulationDossier = [
    {
        nom: TypeDeWizardComposant.DonneesEngie,
        libelle: 'DonneesEngie',
        composant: DonneesEngie,
        estSimulation: false,
        estDossier: true,
        afficheAide: false,
    },
    {
        nom: TypeDeWizardComposant.ChoixSecteurEtOperation,
        libelle: 'Choix du secteur et de l\'opération',
        composant: ChoixSecteurEtOperation,
        estSimulation: true,
        estDossier: false,
        afficheAide: false,
    },
    {
        nom: TypeDeWizardComposant.Operation,
        libelle: 'Opérations',
        composant: Operation,
        estSimulation: true,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.ChoixBeneficiareOuInstallateur,
        libelle: 'Choix du client',
        composant: ChoixBeneficiareOuInstallateur,
        estSimulation: true,
        estDossier: false,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.SiteDesTravaux,
        libelle: 'Site des travaux',
        composant: SiteDesTravaux,
        estSimulation: true,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.DonneesBeneficiaire,
        libelle: 'Données bénéficiaire',
        composant: DonneesBeneficiaire,
        estSimulation: true,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.DonneesInstallateur,
        libelle: 'Données installateur',
        composant: DonneesInstallateur,
        estSimulation: true,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.ResultatSimulation,
        libelle: 'Résultat de la simulation',
        composant: ResultatSimulation,
        estSimulation: true,
        estDossier: false,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.Valorisation,
        libelle: 'Valorisation',
        composant: Valorisation,
        estSimulation: true,
        estDossier: false,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.DonneesApporteurAffaires,
        libelle: 'Données apporteur d\'affaires',
        composant: DonneesApporteurAffaires,
        estSimulation: false,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.Documents,
        libelle: 'Documents',
        composant: Documents,
        estSimulation: false,
        estDossier: true,
        afficheAide: true,
    },
    {
        nom: TypeDeWizardComposant.HistoriqueDossier,
        libelle: 'Historique du dossier',
        composant: HistoriqueDossier,
        estSimulation: false,
        estDossier: true,
        afficheAide: false,
    },
    {
        nom: TypeDeWizardComposant.Facturation,
        libelle: 'Facturation',
        composant: Facturation,
        estSimulation: false,
        estDossier: true,
        afficheAide: true,
    },
];

export const composants = {
    ChoixSecteurEtOperation,
    Operation,
    ChoixBeneficiareOuInstallateur,
    SiteDesTravaux,
    DonneesBeneficiaire,
    DonneesInstallateur,
    ResultatSimulation,
    Valorisation,
    DonneesApporteurAffaires,
    DonneesEngie,
    Documents,
    HistoriqueDossier,
    Facturation,
};

export const composantsParType = [
    ...composantsSimulationDossier,
];
