import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant ResultatSimulation.
 */
@Component
export default class ResultatSimulationValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {

    public montantARetrocederRule = [
        (v: string | any) => this.required(v) && this.isNumeric(v) || 'Le montant à rétroceder est obligatoire',
    ];

    public nomSyndicRule = [
        (v: string | any) => this.required(v) || 'Le nom du syndic est obligatoire',
    ];
}
