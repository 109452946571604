import { Vue } from 'vue-property-decorator';
import { Mixin as VueMixinDecorator, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';


interface IValidationMixinInterface extends ValidationMixin, Vue { }
/**
 * Mixin regroupant les différentes méthodes utilisées pour les validations de Simulation.
 */
@VueMixinDecorator
export default class ChoixSecteurEtOperationValidator extends VueMixinsDecorator<IValidationMixinInterface>(ValidationMixin, Vue) {
    [x: string]: any;

    private secteursRules = [
        (v: string | any) => (this.required(v)) || 'Le secteur est obligatoire',
    ];

    private templateOperationsRules = [
        (v: string | any) => (this.required(v)) || 'L\'opération est obligatoire',
    ];

    private dateEngagementRules = [
        (v: string | any) => (this.required(v)) || 'La date d\'engagement est obligatoire',
    ]
}
