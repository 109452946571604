import Vue from 'vue';
import { Component, Mixin as VueMixinDecorator, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import template from './DonneesBeneficiaire.Template.vue';
import SocieteFormSimulation from '@/components/Wizard/Commun/SocieteFormSimulation.vue';
import ContactForm from '@/components/Wizard/Commun/ContactForm.vue';
import DonneesSociete from '@/components/Wizard/Commun/DonneesSociete.vue';
import { Societe, FormeJuridique, Civilite, TypeBeneficiaire, Operation } from '@/models';
import { BaseCommonApiHelper } from '@/services/BaseCommonApiHelper';
import { Mutation } from 'vuex-class';
import DonneesBeneficiaireValidator from './DonneesBeneficiaire.Validator';
import { Message } from '@/shared/models';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import { Profils, SecteurAsNumber } from '@/shared/enums';
import { ArrayHelper } from '@/shared/helpers/ArrayHelper';
import SocieteSimulationDossierBase from '../SocieteSimulationDossierBase';
import { SimulationDossierSocieteBeneficiaire } from '../models';
import { Watch, Prop } from 'vue-property-decorator';
import { InformationsSimulationDossierStoreMethods } from '../../../../store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { TypeDeWizardComposant } from '../../Composants';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';

@VueMixinDecorator
class BeneficiaireMixin extends SocieteSimulationDossierBase<SimulationDossierSocieteBeneficiaire> { }
//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier,
    SocieteSimulationDossierBase<SimulationDossierSocieteBeneficiaire>, DonneesBeneficiaireValidator, Vue { }

// tslint:disable-next-line:max-classes-per-file
@Component({
    ...template,
    name: 'DonneesBeneficiaire',
    ref: 'DonneesBeneficiaire',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier, BeneficiaireMixin],
    components: {
        SocieteFormSimulation,
        ContactForm,
        DonneesSociete,
    },
})
export default class DonneesBeneficiaire
    extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, BeneficiaireMixin, DonneesBeneficiaireValidator)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    [x: string]: any;

    public titre(): string { return 'Données bénéficiaire'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-user-friends'; }
    public $refs!: {
        form: HTMLFormElement,
    };

    @Prop()
    public referentielsEtape: DossierReferentiels;

    public formeJuridique: FormeJuridique[];
    public civilite: Civilite[];
    public typeBeneficiaire: TypeBeneficiaire[];

    public Profils = Profils;

    // Ajout des champs non renseignés de l'étape après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.ADD_CHAMPS_NON_RENSEIGNES)
    public addChampsNonRenseignes: (champsNonRenseignes: any) => void;

    // Suppression des champs non renseignes de l'étape si ok après validation.
    @Mutation(InformationsSimulationDossierStoreMethods.REMOVE_CHAMPS_NON_RENSEIGNES)
    public removeChampsNonRenseignes: (etape: TypeDeWizardComposant) => void;

    /**
     * Information définissant si l'une des opérations saisies a un cas de précarité défini sur le Cas2.
     */
    public estCas2Precarite: boolean = false;
    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public getModeleDonnees(): { [propriete: string]: any } {
        return {
            donneesOngletSociete: this.donneesOngletSociete || new SimulationDossierSocieteBeneficiaire(),
        };
    }
    /**
     * Profil admin.
     */
    public estAdministrateur: boolean = false;

    // Getters Bailleurs sociaux.
    get nbBailleursSociaux(): number {
        return this.donneesOngletSociete.nbBailleursSociaux = this.donneesOngletSociete.nbBailleursSociaux || 1;
    }
    // Setters Bailleurs sociaux.
    set nbBailleursSociaux(value: number) {
        if (!this.donneesOngletSociete) {
            this.donneesOngletSociete = new SimulationDossierSocieteBeneficiaire();
        }
        this.donneesOngletSociete.nbBailleursSociaux = value || 1;
    }
    /**
     *
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.donneesOngletSociete = new SimulationDossierSocieteBeneficiaire();
        this.donnesOngletSocieteAuChoix = new Array<SimulationDossierSocieteBeneficiaire>();

        this.setLoadingWizard(true);
        this.formeJuridique = this.referentielsEtape.formeJuridique;
        this.civilite = this.referentielsEtape.civilite;
        this.typeBeneficiaire = this.referentielsEtape.typeBeneficiaire;

        this.getUserProfile.then((donneesProfil: any) => {
            if (!!donneesProfil && parseInt(donneesProfil.sub as string, 10) > 0) {
                this.estAdministrateur = donneesProfil.isAdmin;
                this.currentUser = {
                    estInterne: donneesProfil.isInterne as boolean,
                    profil: donneesProfil.profilCode as Profils,
                    email: donneesProfil.email as string,
                    username: donneesProfil.preferred_username as string,
                    civiliteId: 1,
                    lastName: donneesProfil.family_name as string,
                    firstName: donneesProfil.given_name as string,
                    fonction: donneesProfil.fonction || ' ' as string,
                    telephone: donneesProfil.phoneNumber ? donneesProfil.phoneNumber : ''
                };
                const userId = parseInt(donneesProfil.sub as string, 10);
                if (userId > 0) {
                    this.recupererDonneesSocieteAChoisir(this.model.simulationDossierId);
                }
            } else {
                throw new Error('Une erreur est survenue lors de la récupération du profil de l\'utilisateur courant');
            }
        }).finally(() => {
            if (this.model.isDossier && this.isBeneficiaire) {
                Promise.all([this.recupererSocietesUtilisateur(),
                BaseCommonApiHelper.obtenirSocietesAffiliees()])
                    .then(([societes, societesAffiliees]) => {
                        // Post traitement sociétés.
                        this.completerContactSociete(societes, false);
                        this.societeOfCurrentUserAndSocieteAC = societes;
                        this.societeOfCurrentUserAndSocieteAC.push(...societesAffiliees);
                    }).finally(() => {
                        this.societeOfCurrentUserAndSocieteAC = [...ArrayHelper.remove_duplicate<Societe>(this.societeOfCurrentUserAndSocieteAC)];                       
                        //On set la société par défaut.
                        if (this.societeOfCurrentUserAndSocieteAC.length === 1) {
                            this.donneesOngletSociete.societe = this.societeOfCurrentUserAndSocieteAC[0];
                            this.donneesOngletSociete.societeId = this.societeOfCurrentUserAndSocieteAC[0].id;
                        }
                        // Si dossier déjà créé on override la société set par defaut.
                        if (!!this.model.simulationDossierId) {
                            this.handleRecuperationSocieteBeneficiaireDossier();
                        }
                        this.finaliserChargement();
                    });
            }
            this.donneesOngletSociete.nbBailleursSociaux = this.dossier.nbBailleursSociaux || 1;
            // Sinon on est sur un dossier déjà existant
            if (!!this.model.simulationDossierId && !this.isBeneficiaire) {
                this.handleRecuperationSocieteBeneficiaireDossier()            
            }
            this.finaliserChargement();            
        });
    }

    /**
     * Complète les données manquantes après un changement de la société sélectionnée.
     */
    public finaliserChoixSociete() {

        // On force un siret à 14 zéros pour les syndics sans SIRET.
        if (this.donneesOngletSociete.societe.estSansSiret && !this.donneesOngletSociete.societe.siret) {
            this.donneesOngletSociete.societe.siret = '00000000000000';
        }
    }

    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        this.setLoadingWizard(false);
    }
    // Utilisateur courant est un compte Bénéficiaire [Entreprise ou SDC].
    public get isBeneficiaire(): boolean {
        return (this.dossier && this.dossier.isDossierBeneficiaire) || super.isConnectedUserBeneficiaire;
    }
    // Utilisateur courant est un compte Entreprise.
    public get isEntreprise(): boolean {
        return (this.dossier && this.dossier.isDossierEntreprise) || super.isConnectedUserEntreprise;
    }
    // Utilisateur courant est un compte SDC.
    public get isSdc(): boolean {
        return (this.dossier && this.dossier.isDossierSyndicSDC) || super.isConnectedUserSdc;
    }
    // Utilisateur courant est un compte installateur.
    public get isInstallateur(): boolean {
        return (this.dossier && this.dossier.isDossierInstallateur) || super.isConnectedUserInstallateur;
    }
    // Utilisateur courant est un compte aa.
    public get isApporteurAffaires(): boolean {
        return (this.dossier && this.dossier.isDossierApporteurAffaires) || super.isConnectedUserApporteurAffaires;
    }

    // Doit-on afficher le choix SDC.
    public get afficherChoixSdc(): boolean {

        return this.currentUser.estInterne ||
            (this.estOperationSecteurResidentielExiste
                && (this.isSdc || this.isInstallateur || this.donneesOngletSociete.clientRepresenteId > 0)
                && (!super.isConnectedUserEntreprise && !super.isConnectedUserSdc)); // Bugfix #88820
    }
    /**
     * Vérifier si le NbBailleursSociaux a changé pour le dossier.utile juste pour l'initialisation
     * @type {number}
     * @memberof DonneesBeneficiaire
     */
    @Watch('dossier.nbBailleursSociaux', { immediate: true })
    public onnbBailleursSociauxChanged(newItem: number) {
        this.nbBailleursSociaux = newItem;
    }
    // Nom du champ, du chois de précarité.
    private precariteCasFieldName = 'PRECARITE_CAS';
    /**
     * Vérifier si l'une des opérations enregistré à un cas de précarité défini sur le cas 2.
     *
     * @readonly
     * @type {boolean}
     * @memberof DonneesBeneficiaire
     */
    @Watch('dossier.operations', { immediate: true })
    public onOperationsForDossierChanged(newItems: Operation[]) {

        const operations = ((newItems || []) as Operation[]);
        const operationsValues = operations.map((operation) => operation.operationValues);
        if (newItems && newItems.length >= 1) {

            // Re calcul de la notion de Précarité si nécessaire.
            if (!this.estOperationSecteurResidentielExiste) {
                this.estOperationSecteurResidentielExiste = operations.some((operation) => {
                    return operation.secteurId === SecteurAsNumber.Residentiel;
                });
            }

            // Calcul du cas de précarité.
            if (this.estOperationSecteurResidentielExiste) {
                this.estCas2Precarite = operationsValues.some((values) => {
                    if (values && Object.keys(values).length >= 1) {
                        return Object.keys(values)
                            .map((valueIndex: string) => values[valueIndex])
                            .some((value: { fieldName: string, value: string | number | boolean | null }) =>
                                value.fieldName === this.precariteCasFieldName && parseInt(value.value as string, 10) === 3
                            );
                    } else {
                        return false;
                    }
                });
            } else {
                this.estCas2Precarite = false;
            }
        }
    }

    /**
     * On réinitisalise la validation lorsqu'on change cette input
     * car les règles sur le siret peuvent changer.
     */
    @Watch('donneesOngletSociete.estSdc', { immediate: false })
    private resetValidation() {
        const form = this.$refs.form as HTMLFormElement;
        if (form !== undefined) {
            form.resetValidation();
        }
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        // Avant de relancer la validation, on clear les messages d'erreurs.
        this.setErrorMessage(null);
        const valid = this.$refs.form.validate();

        if (valid && !this.estDossier) {
            this.removeChampsNonRenseignes(TypeDeWizardComposant.DonneesBeneficiaire);
            return new Promise<ResultatValidationEtape>((resolve) => {
                // Préparation du contact avant soumission au back.
                this.completerAvantEnvoi();
                // Lie la société.
                this.creerOuLierSocieteSimulationDossier(this.model.simulationDossierId)
                    .then(() => {
                        this.setErrorMessage(null);
                        resolve(new ResultatValidationEtape(true, true));
                    })
                    .catch((error) => {
                        this.setErrorMessage([{ text: error }] as Message[]);
                        resolve(new ResultatValidationEtape(false, this.currentUser.estInterne));
                    });
            });
        } else {
            if (!valid) {
                const errors = this.$refs.form.inputs.filter(
                    (input: any) => input.errorBucket.length).map((error: any) => error.errorBucket);
                this.addChampsNonRenseignes({ etape: TypeDeWizardComposant.DonneesBeneficiaire, champs: errors });
            }
            return Promise.resolve(new ResultatValidationEtape(valid, (valid || this.estAdministrateur)));
        }
    }

    /**
     * Réinitialisation du formulaire.
     *
     * @memberof DonneesBeneficiaire
     */
    public reset(): void {
        this.resetDonnees(SimulationDossierSocieteBeneficiaire);
        this.$refs.form.reset();
    }

    /**
     * Exporter le modèle de l'onglet courant pour enregistrement du dossier.
     *
     * @returns {{model: any, meta: any}}
     * @memberof DonneesBeneficiaire
     */
    public exporterEtapeModel(): { model: any, meta: any } {
        // Préparation du contact avant soumission au back.
        this.completerAvantEnvoi();
        // Retourne le modèle pour enregistrement et plus...
        return {
            model: this.donneesOngletSociete.societe,
            meta: {
                typeBeneficiaireId: this.donneesOngletSociete.typeClientId || null,
                estSdc: this.donneesOngletSociete.estSdc || false,
                nbBailleursSociaux: this.donneesOngletSociete.nbBailleursSociaux || 1,
                societeBeneficiaireId: this.donneesOngletSociete.societeId || null,
                param: 'societeBeneficiaire',
            }
        };
    }


    /**
     * Méthode à appeler en fin de chargement du composant pour le marquer comme initialisé.
     */
    private finaliserChargement(): void {

        // Marque l'étape comme initialisée, on peut commencer à traquer les changements.
        this.majModeleDonnees();
        this.rafraichirEtape(true);
        this.setLoadingWizard(false);
    }
    /**
     * Complète certaines propriétés avant de l'envoyer au Back.
     */
    private completerAvantEnvoi(): void {
        // Préparation du contact avant soumission au back.

        // Si c'est une simulation, on fait des check en plus.
        // Sur l'adresse Id.
        if (!this.estDossier && this.donneesOngletSociete.societe) {
            // Vérification AdresseId
            if (!this.donneesOngletSociete.societe.adresseId) {
                this.donneesOngletSociete.societe.adresseId = 0;
            }
        }
        this.donneesOngletSociete.typeClientId = this.donneesOngletSociete.typeClientId || null;
        // On ajoute les 2 contact possibles pour les bénéficiaires.
        this.donneesOngletSociete.societe.contacts = [];
        this.donneesOngletSociete.societe.contacts.push(...[
            this.donneesOngletSociete.societe.interlocuteur,
            this.donneesOngletSociete.societe.signataire
        ]);
    }

    /**
     *  Gère la récuperation de la société affilié à un dossier existant
     */
    private handleRecuperationSocieteBeneficiaireDossier(): void {
        this.donneesOngletSociete.id = this.model.simulationDossierId;
        this.recupererSocieteBySimulationDossier(this.model.simulationDossierId)
            .then((simulationDossierBeneficiaire: any) => {
                if (simulationDossierBeneficiaire.societe) {
                    this.donneesOngletSociete = simulationDossierBeneficiaire;
                    this.flags.societeAlreadyExist = true;
                }
                else {
                    this.donneesOngletSociete.clientRepresenteId = simulationDossierBeneficiaire.clientRepresenteId;
                }

                this.finaliserChargement();
            });
    }

}
