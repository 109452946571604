import { SiteTravaux } from '@/models';

export class SimulationSiteTravaux {
    /**
     * Identifiant de la simulation.
     *
     * @type {number}
     * @memberof SimulationSiteTravaux
     */
    public id: number = 0;
    /**
     * Identifiant du site travaux attaché à la simulation.
     *
     * @type {number}
     * @memberof SimulationSiteTravaux
     */
    public siteTravauxId: number = 0;
    /**
     * Objet site travaux attaché à la simulation.
     *
     * @type {SiteTravaux}
     * @memberof SimulationSiteTravaux
     */
    public siteTravaux: SiteTravaux = new SiteTravaux();

    /**
     * identifiant étape utilisateur.
     *
     * @type {number}
     * @memberof SimulationSiteTravaux
     */
    public etapeParcoursUtilisateurId: number = 0;
    /**
     * Créer une instance de SimulationSiteTravaux.
     * @param {number} id
     * @param {number} siteTravauxId
     * @param {SiteTravaux} siteTravaux
     * @memberof SimulationSiteTravaux
     */
    constructor(id: number, siteTravauxId: number, siteTravaux: SiteTravaux, etapeParcoursUtilisateurId: number) {
        this.id = id;
        this.siteTravauxId = siteTravauxId;
        this.siteTravaux = siteTravaux;
        this.etapeParcoursUtilisateurId = etapeParcoursUtilisateurId;
    }
}
