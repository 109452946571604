declare global {
    interface Array<T> {
        SumBy(this: T[], selector: (x: T) => number): number;
        Remove(this: T[], o: T): T[];
    }
  }
function sumBy<T>(this: T[], selector: (x: T) => number) {
    return this.reduce((pre, cur) => pre + selector(cur), 0);
}
function remove<T>(this: T[], elem: T): T[] {
    return this.filter((e) => e !== elem);
}
Array.prototype.SumBy = sumBy;
Array.prototype.Remove = remove;

export {};
