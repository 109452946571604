import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import { uuidv4 } from '@/shared/helpers';
import Confirm from '@/components/Confirm.vue';
import { Operation as OperationModel, SecteurReferentiel } from '@/models';
import template from './ChoixSecteurEtOperation.Template.vue';
import ChoixSecteurEtOperationElement from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperationElement.vue';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import { Profils } from '@/shared/enums';
import { DateHelper } from '@/shared/helpers';
import { ChoixSecteurEtOperationResult } from '../models/ChoixSecteurEtOperationResult.model';

@Component({
    ...template,
    ref: 'ChoixSecteurEtOperation',
    name:'ChoixSecteurEtOperation',
    components: {
        ChoixSecteurEtOperationElement,
        ValidationProvider,
        Confirm,
    },
})
export default class ChoixSecteurEtOperation extends Vue {

    /**
     * Date du rôle actif et incitatif.
     */
    @Prop({default: undefined})
    public dateRai: string;

    /**
     * Indique si on est dans le cadre d'un dossier.
     */
    @Prop({ default: false })
    public estDossier: boolean;

    /**
     * Identifiant du dossier si jamais on est dans le contexte d'un dossier.
     */
    @Prop()
    public dossierId: undefined | number;

    @Prop()
    public secteurReferentiels: SecteurReferentiel[];

    /**
     * Le profil utilisateur.
     */
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    /**
     * Indique si il faut saisir la date d'engagement des travaux.
     */
    public get saisieDateEngagementTravaux(): boolean {
        return this.estDossier;
    }

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement,
        refFormChoixOperation: {
            validerFormulaire: (() => Promise<ChoixSecteurEtOperationResult>),
            resetFormulaire: (() => void),
        },
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };
    public secteurId: number | null = null;
    public templateOperationId: number | null = null;

    public operation: OperationModel = null;
    public dateAcceptationPolitiqueControle: string = null;

    /**
     * Validation et enregistrement du secteur et de l'opération choisies.
     */
    public validerAjoutOperation(): Promise<boolean> {
        this.operation = null;
        this.dateAcceptationPolitiqueControle = null;

        return new Promise<boolean>((resolve) => setTimeout(() => {
            const formulaire = this.$refs.refFormChoixOperation;

            // Récupère le formulaire.
            if (formulaire) {
                formulaire.validerFormulaire().then(result => {
                    const valid = result && result.valid;

                    if (valid && result.secteurId && result.templateOperationId) {
                        this.confirmationInfoPolitiqueControle(result).then((confirm) => {
                            if (confirm) {
                                // Récupère le résultat.
                                this.operation = {
                                    uuid: uuidv4(),
                                    secteurId: result.secteurId,
                                    templateOperationId: result.templateOperationId,
                                    dateEngagementTravaux: result.dateEngagementTravaux,
                                    dateDebutTravaux: result.dateEngagementTravaux,
                                    dateAcceptationPolitiqueControle: this.dateAcceptationPolitiqueControle,
                                } as unknown as OperationModel;

                                // Envoie l’événement.
                                this.$emit('onAjoutOperation', this.operation);
                                resolve(valid);
                            }
                            else {
                                resolve(false);
                            }
                        });
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        }, 0));
    }

    /**
     * Affichage d'une confirmation d'information sur la politique de contrôle.
     */
    private confirmationInfoPolitiqueControle(result: ChoixSecteurEtOperationResult): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            
            if (this.estDossier && result.estSoumiseAControle && result.textePolitiqueControle) {
                
                // Récupération du profil.
                this.getUserProfile.then((profile: UserProfile) => {

                    // Affichage de la popup uniquement pour les profils Installateur & Entreprise.
                    if (!!profile && (profile.profilCode === Profils.Entreprise || profile.profilCode === Profils.Installateur)) {
                        this.$refs.confirm.open('Politique de contrôle',
                            result.textePolitiqueControle, 
                            { color: 'blue', width: 1000, zIndex: 200 }).then((responseConfirmation) => {
                                // Mise à jour date acceptation.
                                this.dateAcceptationPolitiqueControle = DateHelper.todayIso();

                                resolve(responseConfirmation);
                            });
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }
}
