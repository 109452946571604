








































import { Component, Prop } from 'vue-property-decorator';
import ChoixSecteurEtOperationElement from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperationElement.vue';
import { Mixins } from 'vue-mixin-decorator';
import ChoixSecteurEtOperation from './ChoixSecteurEtOperation';
import Confirm from '@/components/Confirm.vue';
import { Operation as OperationModel, SecteurReferentiel } from '@/models';

@Component({
    name: 'ChoixSecteurEtOperationElementDialog',
    components: {
        ChoixSecteurEtOperationElement,
        Confirm,
    },
})
export default class ChoixSecteurEtOperationElementDialog extends Mixins(ChoixSecteurEtOperation) {

    @Prop()
    public secteurReferentiels: SecteurReferentiel[];
    public dialog = false;
    public key: number = 0;
    public resolve: ((value: OperationModel | PromiseLike<OperationModel>) => void) = (() => {/* Laissez vide express.*/ });
    public reject: ((reason?: string) => void) = (() => {/* Laissez vide express.*/ });
    public message: string | null = null;
    public title: string | null = null;
    public options: { color: string; width: number; zIndex: number } = {
        color: 'rgb(1, 118, 188)',
        width: 1096,
        zIndex: 250,
    };

    public dateEngagement: string = null;

    /**
     *
     */
    constructor() {
        super();
    }
    /**
     * Ouverture du composant en mode popup.
     *
     */
    public open(
        title: string | null,
        message: string | null,
        options: { color: string; width: number; zIndex: number }) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = { ...this.options, ...options };
        return new Promise<OperationModel>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
    /**
     * Confirmation de la boîte de dialogue.
     *
     */
    public agree() {
        this.$emit('onCheckDateDebut', this.dateEngagement, this.secteurId);
        this.validerAjoutOperation().then((valid) => {
            if (valid) {
                this.close();
            }
        });
    }
    /**
     * Annulation de la boîte de dialogue.
     *
     */
    public cancel() {
        this.operation = null;
        this.close();
    }

    /**
     * Ferme la modale et renvoie la valeur sélectionnée.
     */
    public close() {
        this.resolve(this.operation);
        this.dialog = false;
        this.$refs.refFormChoixOperation.resetFormulaire();
    }
}
