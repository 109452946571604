/**
 * Modèle pour les dates de travaux sur les opérations.
 *
 */
export class OperationDateTravaux {

    /**
     * Code de l'opération.
     */
    public code: string;

    /**
     * Numéro d'arrêté.
     */
    public arreteId: number;

    /**
     * Secteur.
     */
    public secteurId: number;

    /**
     * Template opération.
     */
    public templateOperationId: number;

    /**
     * Valide pour changement de date.
     */
    public estValide: boolean = true;

    /**
     * Constructeur.
     * @param code code.
     * @param arreteId arrêté id.
     * @param secteurId secteur id.
     * @param templateOperationId template opération id.
     */
    constructor(code: string, arreteId: number, secteurId: number, templateOperationId: number) {
        this.code = code;
        this.arreteId = arreteId;
        this.secteurId = secteurId;
        this.templateOperationId = templateOperationId;
    }
}