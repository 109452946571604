







































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Contact, Civilite } from '@/models';
import { CompteValidationMixin } from '@/shared/mixins';
import { TypeContact } from '@/shared/enums/TypeContact.enum';

@Component({
    name: 'ContactForm'
})
export default class ContactForm extends Mixins(CompteValidationMixin) {
    @Prop({ default: new Contact() })
    private contact!: Contact;
    @Prop({ default: () => new Array<Civilite>() })
    private civilite: Civilite[];
    @Prop({ default: '' })
    private titreFormulaire!: string;
    @Prop({ default: false })
    private isDisabled: boolean;

    // Pour savoir si c'est un dossier ou pas.
    @Prop({ default: false })
    private estDossier: boolean;

    // Requis ou pas.
    @Prop({ default: true })
    private estRequis: boolean;

    // Utilisateur connecté interne.
    @Prop({ default: false })
    private estInterne: boolean;

    /** Donne accès à l'ENUM */
    public TypeContact = TypeContact;

    constructor() {
        super();
    }

    @Watch('contact')
    public onContactChanged() {
        this.$emit('onContactChanged', this.contact);
    }

    public contactIntitule(): string {
        switch (this.contact.typeContactId) {
            case TypeContact.Signataire:
                return 'signataire';
            case TypeContact.Interlocuteur:
                return 'interlocuteur';
            case TypeContact.SousTraitant:
                return 'sous-traitant';
        }
        throw new Error('Erreur');
    }


}
