import { Societe } from '@/models';

/**
 * Classe SimulationSociete.
 *
 * @export
 * @class SimulationSociete
 */
export class SimulationDossierSocieteBase {
    /**
     * Identifiant de la simulation.
     *
     * @type {number}
     * @memberof SimulationSociete
     */
    public id: number;
    /**
     * Identifiant de la société attaché à la simulation.
     *
     * @type {number}
     * @memberof SimulationSociete
     */
    public societeId: number;
    /**
     * Objet société attaché à la simulation.
     *
     * @type {Societe}
     * @memberof SimulationSociete
     */
    public societe: Societe;

    /**
     * Type de client Installateur/Bénéficiaire.
     *
     * @type {number}
     * @memberof SimulationSociete
     */
    public typeClientId: number;

    /**
     * identifiant étape utilisateur.
     *
     * @type {number}
     * @memberof SimulationSiteTravaux
     */
    public etapeParcoursUtilisateurId: number;

    /**
     * Client représenté.
     *
     * @type {number}
     * @memberof SimulationSociete
     */
    public clientRepresenteId: number;

    /**
     * Création d'une instance de SimulationSociete.
     * @memberof SimulationSociete
     */
    constructor();
    constructor(id: number, societeId: number, societe: Societe, typeCliendId: number, etapeParcoursUtilisateurId: number, clientRepresenteId: number);
    constructor(id?: number, societeId?: number, societe?: Societe, typeCliendId?: number, etapeParcoursUtilisateurId?: number, clientRepresenteId?: number) {
        this.id = id || 0;
        this.societeId = societeId || 0;
        this.societe = societe || new Societe();
        this.typeClientId = typeCliendId || 0;
        this.etapeParcoursUtilisateurId = etapeParcoursUtilisateurId || 0;
        this.clientRepresenteId = clientRepresenteId || 0;
    }
}
