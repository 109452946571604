import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { Mutation } from 'vuex-class';
import { CommonMixin } from '@/shared/mixins';
import { Message } from '@/shared/models';
import { Dossier, Operation as OperationModel, Paiement, ContratApporteursAffaires } from '@/models';
// Local registration vue-form-wizard.
import { FormWizard, TabContent, WizardStep } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import template from './FormWizardSimulationDossier.Template.vue';
import ChoixSecteurEtOperation from '@/components/Wizard/Commun/ChoixSecteurEtOperation/ChoixSecteurEtOperation';
import { composants, TypeDeWizardComposant, composantsSimulationDossier } from '@/components/Wizard/Composants';
import { Direction } from '@/shared/enums/Direction.enum';
import InformationsSimulationDossier from '@/components/Wizard/Commun/InformationsSimulationDossier.vue';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import WizardEtapeComponentsDossier from '../@Abstracts/WizardEtapeComponentsDossier';
import { UserProfile } from '@/store/modules/auth/types';
import RubriqueAide from '@/components/Wizard/Commun/RubriqueAide.vue';
import Notes from '@/components/Wizard/Commun/Notes.vue';
import { isEmpty } from 'lodash-es';
import { ApiService } from '@/services/base/ApiService';
import { StatutDossierAsString, StatutDossier } from '@/shared/enums/StatutDossier.enum';
import VueRouter from 'vue-router';
import MontantRetroceder from '../Commun/models/MontantRetroceder';
import { ResultatSimulationStoreMethods } from '@/store/modules/simulation/ResultatSimulationStore';
import { SecteurAsNumber, Profils } from '@/shared/enums';
import moment from 'moment';
import Confirm from '@/components/Confirm.vue';
import { DateHelper } from '@/shared/helpers';
import { DossierReferentiels } from '@/models/DossierReferentiels.model';

//  Crée une interface pour fusionner les mixins.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, Vue { }
@Component({
    name: 'FormWizardSimulationDossier',
    ...template,
    components: {
        FormWizard,
        TabContent,
        WizardStep,
        ...composants,
        InformationsSimulationDossier,
        ChoixSecteurEtOperation,
        RubriqueAide,
        Notes,
        Confirm
    },
    mixins: [template, CommonMixin],
})
export default class FormWizardSimulationDossier
    extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, Vue) {

    @Prop({ default: () => new Array() }) public readonly etapes: Array<{ hasError: boolean, message: string | string[] }>;

    @Prop({ default: null }) public simulationDossierId!: number | null;
    @Prop({ default: null }) public readonly simulationOuDossier: SimulationOuDossier;

    public $refs!: Vue['$refs'] & { form: HTMLFormElement } & {
        wizard: FormWizard & { activateAll: () => void }
        & { changeTab: (previousIndex: number, newIndex: number) => void }
        & { tabs: any[] } & { activeTabIndex: number };
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };    

    @Mutation(InformationsSimulationDossierStoreMethods.SET_STATUT)
    public setStatutDossier: (input: string) => void;

    @Mutation(InformationsSimulationDossierStoreMethods.SET_DISCRIMINATOR)
    public setDiscriminator: (discriminator: string) => void;
    /**
     * Forme des tabs en fonction du type.
     */
    public get shape(): string {
        return this.simulationOuDossier === SimulationOuDossier.Simulation ? 'circle' : 'tab';
    }

    /**
     *  Permet de savoir si les étapes sont bien définies.
     */
    public get areEtapesDefined(): boolean {
        return (this.etapes || new Array<{ hasError: boolean, message: string | string[] }>()).every((item) => {
            return !isEmpty(item);
        });
    }

    // Documents en attente de liaison  avec le dossier qui sera potentiellement créé.
    public pendingDocuments: number[] = [];

    /** Permet de gérer l'affichage de la modale d'aide. */
    public etapeAide: string = null;

    public confirmation: boolean = false;

    public estDossierApporteurAffaires: boolean = false;
        
    // Model final.
    public finalModel: {
        simulationDossierId: number | null;
        secteurEtOperations: Array<{ uuid: string, templateOperationId: number | null; secteurId: number | null; dateEngagementTravaux: string | null; }>;
        operations: OperationModel[]
        etapesModel: Map<number, { [x: string]: any }>
        direction: Direction,
        isDossier: boolean
    } = {
            simulationDossierId: this.simulationDossierId,
            secteurEtOperations: new Array<{ uuid: string, templateOperationId: number | null, secteurId: number | null; dateEngagementTravaux: string | null; }>(),
            operations: new Array<OperationModel>(),
            etapesModel: new Map<number, { [x: string]: any }>(),
            direction: Direction.Suivant,
            isDossier: this.simulationOuDossier === SimulationOuDossier.Dossier       
        };

    /** Index actif, par défaut celui passé en paramètre. */
    @Prop({ default: 0 }) public stepIndex!: number;
    public activeTabIndex: number = this.stepIndex;

    public loadingWizard: boolean = false;
    public wizardMessages: Message[] = [];
    public busWizard: Vue = new Vue();
    @Prop({ default: null })
    public profile: UserProfile;

    public referentielsEtape: DossierReferentiels = null;

    @Mutation(InformationsSimulationDossierStoreMethods.RESET)
    public reset: () => void;

    @Mutation(InformationsSimulationDossierStoreMethods.RESET_CHAMPS_NON_RENSEIGNES)
    public resetChampsNonRenseignes: () => void;

    @Mutation(ResultatSimulationStoreMethods.SET_MONTANT_A_RETROCEDER)
    private setMontantRetroceder: (montantRetroceder: MontantRetroceder) => void;

    /**
     * Retourne vrai si tous les onglets ont été complétés.
     */
    public get estDossierComplet(): boolean {
        return this.etapesDossier.length > 0 && this.etapesDossier.every((etape) => etape.estComplete);
    }

    /**
     * Retourne vrai si au moins un onglet a été modifié depuis le dernier chargement.
     */
    public get estDossierModifie(): boolean {
        return this.etapesDossier.filter((etape) => etape.estModifiee).length > 0;
    }

    /**
     * Retourne le titre de l'étape.
     * @param etape
     */
    public getTitreEtape(etape: any): string {
        return etape.options.name === TypeDeWizardComposant.ChoixBeneficiareOuInstallateur
            && this.profile.profilCode === Profils.ApporteurAffaires ? 'Choix du client' : etape.options.methods.titre();
    }

    /**
     * Détermine si on a au moins une opération de sélectionnée.
     */
    public aOperations = false;

    public estSoumisPolitiqueControle: boolean = false;

    public prixFixeContrat: boolean = false;

    /**
     * Hook qui se déclenche lorsque le composant est créé.
     */
    public created(): void {
        this.reset();

        this.resetChampsNonRenseignes();

        this.busWizard.$on('on-loading', (value: boolean) => this.setLoading(value));
        this.busWizard.$on('on-wizard-error', (messages: Message[]) => this.handleErrorMessage(messages));
        this.busWizard.$on('on-creation-dossier', (id: number) => this.recupererDossier(id).then(() => {
            this.busWizard.$emit('on-update-dossier');   
            // Appel Back.
            if (this.pendingDocuments.length > 0) {
                this.$http.put(`dossier/linkPendingDocuments`, {
                    dossierId: id,
                    ids: this.pendingDocuments
                });
            }
        }));
        this.busWizard.$on('on-step-informations-operations', (operations: OperationModel[]) => {
            if (operations) {
                this.finalModel.operations = operations;
            }
        });

        // Copie des propriétés récupérées lors de l'enregistrement d'une simulation/d'un dossier sur les différentes étapes du wizard.
        this.busWizard.$on('on-step-informations-simulation-dossier', (data: { simulationDossierId: number | null } & { [key: string]: any }) => {
            if (data && data.simulationDossierId) {
                this.finalModel.simulationDossierId = data.simulationDossierId;

                // Copie les propriétés du dossier passées en paramètre sur chacune des étapes.
                this.etapesDossier.forEach((etape) => {
                    for (const [key, value] of Object.entries(data)) {
                        (etape.dossier as any)[key] = value;
                    }
                });
            }
        });

        this.busWizard.$on('on-step-validate', (tabIndex: number, validated: boolean, model: { [x: string]: any }) => {
            this.onStepValidate(tabIndex, validated, model);
        });

        // Redirige l'utilisateur sur l'étape de choix des opérations, en affichant l'opération éventuellement passée en paramètre.
        this.busWizard.$on('on-ouverture-operation', () => {
            // Ouvre l'étape Opérations.
            this.changeTab('Opérations');
        });

        // Détermine si on a au moins une opération de sélectionnée quand on met à jour la liste.
        this.busWizard.$on('on-modification-nombre-operations', (operations: OperationModel[]) => {
            this.aOperations = operations.length > 0;
            // Est ce qu'elle contient une opération ?.
            if (!!this.aOperations) {
                // Notifie les autres onglets autre que l'onglet opération pour mettre à jour les opérations.
                const etapesPourOperationsAMettreAJour = this.etapesDossier
                    .filter((etape) => etape.$options.name !== TypeDeWizardComposant.Operation);

                if (etapesPourOperationsAMettreAJour && etapesPourOperationsAMettreAJour.length >= 1) {
                    etapesPourOperationsAMettreAJour.forEach((etape) => etape.dossier.operations = operations);
                }
            }
            // On met à jour met à jours la propriété qui indique si le dossier possède une opération résidentielle.
            this.dossier.estOperationSecteurResidentielExiste = operations.some((operation) => {
                return operation.secteurId === SecteurAsNumber.Residentiel;
            });
            // Mise à jour des paiements : Actualisation des paiements à la suppression d'une opération d'un dossier en création, par un compte interne.
            if (this.profile.isInterne && this.dossier.id && this.dossier.statutDossierId === StatutDossier.EN_CREATION) {
                this.recupererPaiements(this.dossier.id).then((paiements) => {
                    this.$set(this.dossier, 'paiements', paiements.sort((p1, p2) => p2.typeContributionId - p1.typeContributionId));
                });
            }          
        });

        this.busWizard.$on('disableOrEnableTabApporteurAffaire', (mustBeDisabled: boolean) => {
            this.disableOrEnableTabApporteurAffaire(mustBeDisabled);
        });

        this.busWizard.$on('on-trigger-pending-documents', (p: number[]) => {
            this.pendingDocuments = [...p];
        });       

        this.getDossierReferentiels();    

        // Si c'est un dossier, on va récupérer les données côté serveur.
        if (this.isDossier) {              
            this.recupererDossier(this.simulationDossierId).finally(() => {
                if (this.simulationDossierId) {
                    this.confirmationInfoPolitiqueControle();
                }
            });
            // Récupère les étapes du dossier.
            this.getEtapesDossier();
        } else {
            this.recupererTypeContratAA();
        }
    }

    /**
     * À l'initialisation, active toutes les étapes sur lesquelles l'utilisateur est déjà passé/peut retourner.
     */
    public mounted() {
        this.activeTabIndex = this.stepIndex;
        const wizard = this.$refs.wizard;

        // Si c'est un dossier, on va récupérer les données côté serveur.
        if (this.isDossier) {
            wizard.activateAll();
        } else {
            const tabs = wizard.tabs;
            wizard.tabs = tabs.slice(0, this.stepIndex);
            wizard.activateAll();
            wizard.tabs = tabs;
        }
    }

    /**
     * Vérifie si le dossier est valide et l'enregistre le cas échéant.
     *
     * @param {*} refs
     * @memberof WizardEtapeComponentsDossier
     */
    public construireValiderEtEnregistrerDossier(): Promise<Dossier> {
        this.setLoading(true);
        // On retire l'étape apporteur d'affaire pour la validation car la validation de celle-ci s'effectue même quand l'étape est cachée...     
        let etapes = this.etapesDossier.filter(etape => etape.titre() !== "Données apporteur d'affaires");
        etapes = this.etapesDossier.filter((etape => etape.estModifiee));
        return this.validerEtEnregistrerDossier(etapes, this.busWizard)
            .then((dossier) => {
                // Met à jour les étapes enregistrées.
                this.etapesDossier.forEach((etape) => etape.rafraichirEtape(true));
                return dossier;
            })
            .finally(() => this.setLoading(false));
    }

    /**
     * Marque le dossier comme envoyé à Engie
     */
    public envoyerDossierComplet(): void {
        if (this.estDossierComplet && !this.estDateDelaiDepasse()) {
            this.setLoading(true);
            this.envoyerDossier().then((dossier) => {
                this.setStatutDossier(StatutDossierAsString.ENVOYE_ENGIE);
            }).finally(() => {
                this.setLoading(false);
                this.confirmation = true;
            });
        }
        else {
            const errorMessage = "Comme indiqué dans les Conditions Générales d'Utilisation, vous disposiez de 3 mois pour valider et transmettre votre dossier. Le délai n'étant pas respecté, il est impossible d'envoyer votre dossier.";
            this.$refs.confirm.open("Délai d'envoi dépassé", errorMessage, {});
        }
    }

    /**
     * Check que la date de fin des travaux ne soit pas anterieur à 4 mois par rapport à la date d'aujourd'hui.
     */
    public estDateDelaiDepasse(): boolean {
        let estDateDelaiDepasse = false;
        if (!this.isInterne) {    
            const todaySub4Month = DateHelper.toIsoString(moment().subtract(4, 'months').startOf('day').toDate());           
            estDateDelaiDepasse = this.dossier.operations.some(x => DateHelper.premiereSuperieurASeconde(todaySub4Month, x.dateFinTravaux, true));
        }
        return estDateDelaiDepasse;
    }

    /**
     * À l'obtention de l'identifiant de dossier/simulation, met à jour l'url.
     */
    @Watch('finalModel.simulationDossierId')
    public onSimulationDossierIdChanged(nouveau: number, precedent: number) {

        // Change l'URL affichée pour pouvoir rafraîchir la page ultérieurement.
        const origin = this.finalModel.isDossier ? '/dossier/' : '/simulateur/';
        if (nouveau !== precedent) {
            history.replaceState({}, null, `${origin}${nouveau}`);
        }
    }

    /**
     * Renvoie la liste des étapes ayant été complétées.
     */
    public get etapesDossierCompletes(): WizardEtapeComponentsDossier[] {
        return this.etapesDossier.filter(etape => etape.estComplete);
    }

    /**
     * Étapes visibles du dossier.
     * @returns Références des étapes du dossier visibles par l'utilisateur.
     */
    public etapesDossier: WizardEtapeComponentsDossier[] = [];
    public getEtapesDossier(): void {

        // Récupère toutes les étapes disponibles pour un dossier.
        const refs: any = this.$refs;
        const etapes: TypeDeWizardComposant[] = composantsSimulationDossier
            .filter((element: any) => element.estDossier)
            .map((element: any) => element.nom);

        // Les étapes du dossier sont déjà filtrées par le back.
        this.etapesDossier = etapes
            .filter((etape) => refs[etape] !== undefined)
            .map((etape) => refs[etape][0] as WizardEtapeComponentsDossier);
    }

    /**
     * Est un dossier ?
     *
     * @readonly
     * @type {boolean}
     * @memberof FormWizardSimulationDossier
     */
    public get isDossier(): boolean {
        return this.simulationOuDossier === SimulationOuDossier.Dossier;
    }

    public get estDossierDejaEnvoye(): boolean {
        return this.dossier.statutDossierId !== StatutDossier.EN_CREATION;
    }

    /**
     * Cache le bouton d'étape suivante  sur la première étape tant qu'on n'a pas sélectionné une opération pour démarrer une simulation.
     */
    public get nextButtonHidden(): boolean {
        return !this.isDossier && !this.aOperations && !this.finalModel.simulationDossierId;
    }

    /**
     * Set le loader du wizard.
     * @param value
     */
    public setLoading(value: boolean): void {
        this.loadingWizard = value;
    }

    /**
     * Capture la validation.
     * @param isValid
     * @param tabIndex
     */
    public handleValidation(isValid: boolean, tabIndex: number): void {

        // Handle validation sur simulation.
        if (!this.isDossier && isValid) {
            const libelleResultat = 'Résultat de la simulation';
            const indexResultat = this.$refs.wizard.tabs.findIndex((etape: any) => etape.title === libelleResultat);

            let indexNextStep = tabIndex + 1;
            let allNextStepValide = true;

            // Vérifications si toutes les étapes après sont complètes, pour passer directement au récap.
            while (indexNextStep <= indexResultat && allNextStepValide) {
                let nextStep = this.$refs.wizard.tabs[indexNextStep];

                if (!nextStep.checked) {
                    allNextStepValide = false;
                }

                indexNextStep++;
            }

            // Passage au récap si on y a déjà au moins terminé une fois le workflow classique.
            if (allNextStepValide && !this.estModifiee) {
                this.changeTab(libelleResultat);
            }
        }
        
        // Laissez vide express.
    }

    /**
     * Capture le message d'erreur.
     * @param errorMsg
     */
    public handleErrorMessage(messages: Message[]): void {
        this.wizardMessages = messages;
        this.handleFocus(false);
    }
    /**
     * Capture le message d'erreur.
     * @param errorMsg
     */
    public handleDirection(prevIndex: number, nextIndex: number): void {
        this.finalModel.direction = prevIndex > nextIndex ? Direction.Precedent : Direction.Suivant;

        // Valide l'étape précédente, en n'affichant les erreurs que si elle a été modifiée.
        if (this.isDossier && this.etapesDossier.length) {
            const etape = this.etapesDossier.filter(e => e.$options.name === (<any>this.etapes[prevIndex]).options.name)[0];
            etape.validerEtape(!etape.estModifiee);
        }

        this.handleFocus(true);
    }

    /**
     * Validation de l'étape avant de passer à la suivante.
     * @param etape
     * @param ref
     */
    public validerEtapeCouranteAsync(ref: string): Promise<boolean> {        
        return ((this.$refs as any)[ref][0]).validerEtapeAsync();
    }

    /**
     * Affichage des erreurs de l'étage.
     * @param etape
     * @param ref
     */
    public getErreursSpecifiques(ref: string): string[] {
        if (this.isDossier && (this.$refs as any)[ref]) {
            return ((this.$refs as any)[ref][0]).erreursSpecifiques;
        }
        return [];
    }

    /**
     * Événement lorsque l'étape est validée ou pas.
     * @param validated
     * @param model
     */
    public onStepValidate(tabIndex: number, validated: boolean, model: { [x: string]: any }) {
        if (tabIndex >= 0 && validated) {
            this.finalModel.etapesModel.set(tabIndex, model);
        }       
    }

    /**
     * Au changement d'étape, remonte en haut de l'écran pour afficher les informations importantes en premier.
     */
    public retournerEnHaut(): void {

        const header = document.getElementsByClassName('anchor');
        if (header && header.length) {
            setTimeout(() => header[0].scrollIntoView({ behavior: 'smooth', block: 'end' }), 300);
        }
    }

    /**
     *  Focus le premier champs en erreur, sinon retourne en haut de la page.
     */
    public handleFocus(backToTopIfNoError : boolean): void {
        this.$nextTick(() => {
            //Si pas de timeout, une erreur est detéctée au chargement du dossier dans onglet données Engie ce qui enclenche le scroll automatique.
            setTimeout(() => {
                const errorFields = [...document.getElementsByClassName("error--text")];
                const firstVisibleErrorField = errorFields.filter(x => x.parentElement.offsetLeft > 0)[0];

                if (firstVisibleErrorField) {
                    firstVisibleErrorField.scrollIntoView({ behavior: 'smooth', block: "center" });
                }
                else if (backToTopIfNoError) {
                    this.retournerEnHaut();
                }
            }, 500);
        });
    }

    /**
     * Événement lorsque l'étape est validée ou pas.
     * @param validated
     * @param model
     */
    public envoyerModelEtape(tabIndex: number): { [x: string]: any } {
        return {
            simulationDossierId: this.finalModel.simulationDossierId,
            secteurEtOperations: this.finalModel.secteurEtOperations,
            operations: this.finalModel.operations,
            modelEtape: { ...this.finalModel.etapesModel.get(tabIndex) },
            direction: this.finalModel.direction,
            isDossier: this.finalModel.isDossier          
        };
    }

    /**
     * Permet de savoir si on peut afficher l'aide ou non pour un type d'onglet donné .
     * @param typeComposant
     */
    public afficherBoutonAide(typeComposant: string): boolean {
        return composantsSimulationDossier.find((x) => x.nom === typeComposant).afficheAide;
    }

    /**
     * Virer/Remettre l'onglet Données apporteurs affaires en fonction de la valeur du switch.
     *
     * @param {boolean} value
     * @memberof FormWizardSimulationDossier
     */
    public disableOrEnableTabApporteurAffaire(value: boolean) {    
        const name = 'DonneesApporteurAffaires';
        this.estDossierApporteurAffaires = value;
        const tab = document.querySelectorAll('.wizard-nav li');
        const condition = (etape: any) => etape.options.name === name;
        const apporteurAffaireTab = tab.item(this.etapes.findIndex(condition));
        apporteurAffaireTab.setAttribute('style', `display:${value ? 'block' : 'none'}`);
    }

    /**
     * Navigue jusqu'à l'onglet passé en paramètre si celui-ci est actif.
     * @param titre Titre de l'onglet à atteindre.
     */
    public changeTab(titre: string): void {

        // Charge l'onglet.
        const wizard = this.$refs.wizard;
        const newIndex = wizard.tabs.findIndex((etape: any) => etape.title === titre);
        wizard.changeTab(wizard.activeTabIndex, newIndex);
    }

    /**
     * Récupère une étape à partir de son titre.
     * @param titre Titre de l'étape recherchée.
     */
    public getEtape(titre: string): WizardEtapeComponentsDossier {
        // Récupération de l'étape à partir de son titre.
        const etapes = this.etapesDossier.filter((etape) => etape.titre() === titre);

        // Détermine si l'étape a été modifiée.
        if (etapes.length) {
            return etapes[0] as WizardEtapeComponentsDossier;
        }
        return null;
    }

    /**
     * Récupère le dossier en cours à partir de son identifiant et le propage à toutes les étapes.
     *
     * @memberof FormWizardSimulationDossier
     */
    protected recupererDossier(id: number): Promise<Dossier> {
        const url = (id && id > 0) ?
            `/dossier/recupererDossier/${id}` :
            `/dossier/createEmptyShell`;
        // Construction du dossier.
        this.setLoading(true);
        return new Promise<Dossier>((resolve) => {
            this.$http.get(url)
                .then((result: any) => {
                    return result.data.data;
                })
                .then((dossier: Dossier) => {
                    this.setDiscriminator('Dossier');
                    // Montant rétrocéder.
                    if (!!dossier.montantRetroceder) {
                        this.setMontantRetroceder(new MontantRetroceder(dossier.id, dossier.montantRetroceder,
                            dossier.nomSyndic, dossier.estSdc));
                    } else {
                        this.setMontantRetroceder(new MontantRetroceder(dossier.id, 0, '', dossier.estSdc));
                    }

                    // Récupère les étapes du dossier.
                    this.getEtapesDossier();
                    this.setDossier(dossier);

                    // Permet d'initialiser tous les onglets avec les données du dossier.
                    this.etapesDossier.forEach((etape) => { if (etape) { etape.setDossier(dossier) } });

                    // Affiche le statut des onglets à l'initialisation.
                    Promise.all(this.etapesDossier.map((etape) => {
                        if (etape) {
                            etape.validerEtape(true);
                        }                       
                    }));

                    this.busWizard.$emit('on-update-dossier');
                    // Applique le Resolve.
                    resolve(dossier);
                })
                .finally(() => {
                    this.setLoading(false);
                    this.confirmation = this.calculerConfirmationDossier();
                });
        });
    }
    /**
     * calculer ConfirmationDossier.
     * @param dossier Dossier.
     */
    protected calculerConfirmationDossier(): boolean {
        return this.dossier
            && this.dossier.statutDossierId === StatutDossier.ENVOYE_ENGIE
            && !this.dossier.estEnvoiAHConfirme
            && !this.profile.isInterne;
    }
    /**
     * Récupère les paiements du dossier.
     * @param idDossier Identifiant du dossier.
     */
    public recupererPaiements(idDossier: number): Promise<Paiement[]> {
        const paiementService = new ApiService<Paiement[]>('paiement/recupererLesPaiementsParDossier');
        return new Promise<Paiement[]>((resolve, reject) => {
            return paiementService
                .get(idDossier)
                .then((response) => resolve(response.data.data))
                .catch((error: { response: Error; }) => reject(error.response));
        });
    }

    /**
     * Si le profil connecté un est apporteur d'affaires.
     * Permet de savoir si le contrat actif est de type prix fixé au contrat ou non.
     */
    protected recupererTypeContratAA(): Promise<boolean> {
        const paiementService = new ApiService<ContratApporteursAffaires>('contratApporteursAffaires/obtenirParUtilisateur');
        return new Promise<boolean>((resolve) => {
            return paiementService
                .get(parseInt(this.profile.sub, 10))
                .then((result: any) => {
                    return result.data.data;
                })
                .then((contrat: ContratApporteursAffaires) => {
                    if (contrat) {
                        this.prixFixeContrat = contrat.prixFixeAuContrat;
                    }
                });
        });
    }

    /**
     * Affichage d'une confirmation d'information sur la politique de contrôle.
     */
    public confirmationInfoPolitiqueControle(): void {
        this.$http.post(`/simulation/sujetPolitiqueControle/${this.simulationDossierId}`).then((response) => {
                this.estSoumisPolitiqueControle = response.data.data && response.data.data.estSoumisAControle;
            }).catch(() => {
                throw new Error('erreur de la vérification de la soumission du dossier a une politique de contrôle.');
            });
    }

    public confirmLater() {
        this.confirmation = false;
        this.setDiscriminator(null);
        (this.$router as VueRouter).push({ name: 'mes-dossiers', params: { discardConfirmationPopup: 'OUI' } });
    }

    public confirmNow() {
        this.confirmerEnvoieAH().finally(() => {
            this.confirmation = false;
            this.setDiscriminator(null);
            (this.$router as VueRouter).push({ name: 'mes-dossiers', params: { discardConfirmationPopup: 'OUI' } });
        });
    }
    /**
     * Réinitialisation du dossier après la destruction du composant.
     *
     * @memberof FormWizardSimulationDossier
     */
    public destroyed() {
        this.setDossier(new Dossier());
    }

    /**
    * Dossier de bord referentiel.    
    */
    public getDossierReferentiels(): Promise<DossierReferentiels> {
        const tdbService = new ApiService<DossierReferentiels>(`/dossier/recupererReferentielsDossier`);
        return new Promise<DossierReferentiels>((resolve, reject) => {
            tdbService.getWhereSingle("").then((reponse) => {
                this.referentielsEtape = reponse.data;
            }).catch((error: any) => reject(error));
        });
    }

    /**
    * On force le saut d'onglet si on est pas en dossier apporteur affaire.
    */
    public goNext() {
        const libelleOperation = 'Opérations';
        const indexOperation = this.$refs.wizard.tabs.findIndex((etape: any) => etape.title === libelleOperation);
        const wizard = this.$refs.wizard as any;

        if (this.$refs.wizard.activeTabIndex === indexOperation && !this.estDossierApporteurAffaires) {
            wizard.nextTab();
        }
    }

    /**
    * On force le saut d'onglet si on est pas en dossier apporteur affaire.
    */
    public goPrev() {
        const libelleDonneesBeneficiaire = 'Données bénéficiaire';
        const indexDonneesBeneficiaire = this.$refs.wizard.tabs.findIndex((etape: any) => etape.title === libelleDonneesBeneficiaire);
        const wizard = this.$refs.wizard as any;

        if (this.$refs.wizard.activeTabIndex === indexDonneesBeneficiaire && !this.estDossierApporteurAffaires) {
            wizard.prevTab();
        }
    }
}

