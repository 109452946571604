import Vue from 'vue';
import { Mixin as VueMixinDecorator } from 'vue-mixin-decorator';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { SimulationDossierSocieteBeneficiaire, SimulationDossierSocieteInstallateur } from './models';
import { Profils } from '@/shared/enums';
import { Societe, Contact, Utilisateur } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { EtapeParcoursSimulation } from '@/shared/enums/EtapeSimulation';
import { ClientRepresente } from '@/shared/enums/ClientRepresente.enum';
import { UserProfile } from '@/store/modules/auth/types';


@VueMixinDecorator
export default class SocieteSimulationDossierBase<T extends SimulationDossierSocieteBeneficiaire | SimulationDossierSocieteInstallateur> extends Vue {

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    /**
     * Données lié aux onglets Société Bénéficiaire/Installateur.
     *
     * @private
     * @type {Societe}
     * @memberof SocieteSimulationDossierBase
     */
    public donneesOngletSociete: T = null;

    /**
     * Liste des données lié aux onglet Société Bénéficiaire/Installateur à choisir par l'utilisateur.
     *
     * @protected
     * @type {T[]}
     * @memberof SocieteSimulationDossierBase
     */
    public donnesOngletSocieteAuChoix: T[] = null;

    /**
     * Est ce qu'une operation est de type résidentiel.
     *
     * @type {boolean}
     * @memberof SimulationSociete
     */
    public estOperationSecteurResidentielExiste: boolean = false;

    /**
     * Indicateurs.
     *
     * @protected
     * @type {{
     *         fetchFromDb: boolean,
     *         pickedFromPrevious: boolean,
     *     }}
     * @memberof SocieteSimulationDossierBase
     */
    protected flags: {
        pickedFromPrevious: boolean,
        societeAlreadyExist: boolean,
    } = { pickedFromPrevious: false, societeAlreadyExist: false };

    /**
     * Informations sur l'utilisateur courant.
     *
     * @protected
     * @type {{
     *         estInterne: boolean,
     *         profil: Profils,
     *     }}
     * @memberof SocieteSimulationDossierBase
     */
    protected currentUser: {
        estInterne: boolean,
        profil: Profils,
        email: string,
        username: string,
        civiliteId: number,
        lastName: string,
        firstName: string,
        fonction: string,
        telephone?: string,
    } = {
            estInterne: false,
            profil: Profils.None,
            email: null,
            username: null,
            civiliteId: 1,
            lastName: null,
            firstName: null,
            fonction: ' '
        };

    /**
     *  Sociétés de l'utilisateur courant et les sociétés membres accords cadres sur les quels il a le droit.
     *  Pour un installateur sur l'onglet installateur ou Entreprise/SdcSyndic sur l'onglet Bénéficiaire.
     *
     * @protected
     * @type {Societe[]}
     * @memberof SocieteSimulationDossierBase
     */
    protected societeOfCurrentUserAndSocieteAC: Societe[] = new Array<Societe>();

    // *** METHODES *** //
    /**
     * Copier les données interlocuteur vers signataire.
     *
     * @memberof SocieteSimulationDossierBase
     */
    protected copierInterlocuteurToSignataire(): void {
        this.donneesOngletSociete.societe.signataire.civiliteId = this.donneesOngletSociete.societe.interlocuteur.civiliteId;
        this.donneesOngletSociete.societe.signataire.nom = this.donneesOngletSociete.societe.interlocuteur.nom;
        this.donneesOngletSociete.societe.signataire.prenom = this.donneesOngletSociete.societe.interlocuteur.prenom;
        this.donneesOngletSociete.societe.signataire.fonction = this.donneesOngletSociete.societe.interlocuteur.fonction;
        this.donneesOngletSociete.societe.signataire.telephone = this.donneesOngletSociete.societe.interlocuteur.telephone;
        this.donneesOngletSociete.societe.signataire.mail = this.donneesOngletSociete.societe.interlocuteur.mail;
    }

    /**
     * Importer les données de la société depuis les choix proposés.
     *
     * @protected
     * @param {T} value
     * @memberof SocieteSimulationDossierBase
     */
    protected importerDonneesOngletSociete(value: T) {
        this.donneesOngletSociete.societe = value.societe;
        this.donneesOngletSociete.societeId = value.societeId;
        if (!value.societe.interlocuteur) {
            this.assignDefaultContact(TypeContact.Interlocuteur);
        }
        if (!value.societe.signataire) {
            this.assignDefaultContact(TypeContact.Signataire);
        }
        if (!this.isSimulationDossierSocieteBeneficiaire(this.donneesOngletSociete)) {
            this.donneesOngletSociete.societe.societeSousTraitante = this.donneesOngletSociete.societe.societeSousTraitante || new Societe();
            this.donneesOngletSociete.societe.societeSousTraitante.adresse = null;
            if (!value.societe.sousTraitant) {
                this.assignDefaultContact(TypeContact.SousTraitant);
            }
        }
        this.flags.pickedFromPrevious = true;
    }

    /**
     * reset des données de la société.
     *
     * @protected
     * @param {new() => T} instance
     * @memberof SocieteSimulationDossierBase
     */
    protected resetDonnees(instance: new () => T) {
        this.flags.societeAlreadyExist = false;
        this.flags.pickedFromPrevious = false;
        this.donneesOngletSociete = new instance();
    }

    /**
     * Disable les champs de saisie.
     *
     * @readonly
     * @type {boolean}
     * @memberof SocieteSimulationDossierBase
     */
    protected get isDisabled(): boolean {
        return this.isSimulationDossierSocieteBeneficiaire(this.donneesOngletSociete) ?
            this.currentUser.profil === Profils.Entreprise
                || this.currentUser.profil === Profils.SyndicSDC
                || this.currentUser.profil === Profils.ApporteurAffaires :
            this.currentUser.profil === Profils.Installateur ||
            (this.currentUser.profil === Profils.ApporteurAffaires
                && this.donneesOngletSociete.clientRepresenteId === ClientRepresente.ClientInstallateur);
    }

    /**
     * Obtient utilisateur par son identifiant.
     *
     */
    public getUserById(id: number): Promise<Utilisateur> {
        const utilisateurAdminFormEdit = new ApiService<Utilisateur>('utilisateur/obtenirPublic');
        return new Promise<Utilisateur>((resolve, reject) => {
            return utilisateurAdminFormEdit.get(id).then((response) => {
                resolve(response.data.data);
            }).catch((error: { response: any }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Utilisateur courant est un compte Bénéficiaire [Entreprise ou SDC].
     *
     * @readonly
     * @type {boolean}
     * @memberof SocieteSimulationDossierBase
     */
    public get isConnectedUserBeneficiaire(): boolean {
        return this.currentUser.profil === Profils.Entreprise || this.currentUser.profil === Profils.SyndicSDC;
    }
    /**
     * Utilisateur courant est un compte Entreprise.
     *
     * @readonly
     * @type {boolean}
     * @memberof SocieteSimulationDossierBase
     */
    public get isConnectedUserEntreprise(): boolean {
        return this.currentUser.profil === Profils.Entreprise;
    }
    /**
     * Utilisateur courant est un compte SDC.
     *
     * @readonly
     * @type {boolean}
     * @memberof SocieteSimulationDossierBase
     */
    public get isConnectedUserSdc(): boolean {
        return this.currentUser.profil === Profils.SyndicSDC;
    }
    /**
     * Utilisateur courant est un compte installateur.
     *
     * @readonly
     * @type {boolean}
     * @memberof SocieteSimulationDossierBase
     */
    public get isConnectedUserInstallateur(): boolean {
        return this.currentUser.profil === Profils.Installateur;
    }

    public get isConnectedUserApporteurAffaires(): boolean {
        return this.currentUser.profil === Profils.ApporteurAffaires;
    }
    /**
     * Initialiser les contacts dans le cas ou la société ne possède pas de contacts et en effet en créer.
     *
     * @private
     * @param {TypeContact} typeContact
     * @memberof SocieteSimulationDossierBase
     */
    private assignDefaultContact(typeContact: TypeContact) {
        const property = typeContact === TypeContact.Interlocuteur ?
            'interlocuteur' : typeContact === TypeContact.Signataire ? 'signataire' :
                typeContact === TypeContact.SousTraitant ? 'sousTraitant' : null;
        this.$set(this.donneesOngletSociete.societe, property, new Contact(typeContact));
        // Réinitialiser la société pour en créer un autre.
        this.donneesOngletSociete.societeId = 0;
        this.donneesOngletSociete.societe.id = 0;
    }

    /**
     * Définir l'étape suivante.
     *
     * @protected
     * @memberof SocieteSimulationDossierBase
     */
    protected definirEtapeSuivante() {
        this.isSimulationDossierSocieteBeneficiaire(this.donneesOngletSociete) && this.donneesOngletSociete.clientRepresenteId === ClientRepresente.ClientInstallateur ?
            this.donneesOngletSociete.etapeParcoursUtilisateurId = EtapeParcoursSimulation.DonneesInstallateur :
            this.donneesOngletSociete.etapeParcoursUtilisateurId = EtapeParcoursSimulation.Valorisation;
    }

    // *** HELPERS TYPES *** //

    /**
     * Récupérer l'ENDPOINT en fonction de si l'onglet courant est une simulation ou un dossier.
     *
     * @private
     * @param {string} endpointBeneficiaire
     * @param {string} endpointInstallateur
     * @returns
     * @memberof SocieteSimulationDossierBase
     */
    private getEndPoint(endpointBeneficiaire: string, endpointInstallateur: string): string {
        return this.isSimulationDossierSocieteBeneficiaire(this.donneesOngletSociete) ? endpointBeneficiaire : endpointInstallateur;
    }
    /**
     * Vérifier le type générique.
     *
     * @param {(SimulationDossierSocieteBeneficiaire | SimulationDossierSocieteInstallateur)} value
     * @returns {value is SimulationDossierSocieteBeneficiaire}
     * @memberof SocieteSimulationDossierBase
     */
    private isSimulationDossierSocieteBeneficiaire(value: SimulationDossierSocieteBeneficiaire | SimulationDossierSocieteInstallateur)
        : value is SimulationDossierSocieteBeneficiaire {
        return (value as SimulationDossierSocieteBeneficiaire).estSdc !== undefined;
    }

    // *** ENDPOINTS *** //
    /**
     * Récupération des données de sociétés bénéficiaire/installateur créé par l'utilisateur courant et déjà lié à une simulation/dossier.
     *
     * @param {string} createur
     * @memberof SocieteSimulationDossierBase
     */
    protected recupererDonneesSocieteAChoisir(simulationDossierId: number = null) {
        const endpoint = (simulationDossierId && simulationDossierId > 0) ?
            this.getEndPoint(`societesBeneficiaireParCreateurSimulationDossier/${simulationDossierId}`,
                `societesInstallateurParCreateurSimulationDossier/${simulationDossierId}`)
            : this.getEndPoint(`societesBeneficiaire/${this.currentUser.username}`,
                `societesInstallateur/${this.currentUser.username}`);
        this.$http.get(`simulationDossier/${endpoint}`).then((response) => {
            this.donnesOngletSocieteAuChoix = response.data.data;
        }).catch((error: { response: Error; }) => {
            throw error.response;
        });
    }
    /**
     * Récupération des sociétés de l'utilisateur connecté.
     *
     */
    protected recupererSocietesUtilisateur(): Promise<Societe[]> {
        return new Promise<Societe[]>((resolve, reject) => {
            const societeService = new ApiService<Societe>('societe/obtenirSocietesUtilisateur');
            societeService.getAll().then((response) => {
                resolve(response.data.data || []);
            }).catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Récupérer les données de la société installateur/bénéficiaire.
     *
     * @param {number} id
     * @returns {Promise<T>}
     * @memberof SocieteSimulationDossierBase
     */
    protected recupererSocieteBySimulationDossier(id: number): Promise<T> {
        const endpoint = this.getEndPoint('recupererSocieteBeneficiaireParSimulationDossier', 'recupererSocieteInstallateurParSimulationDossier');
        const simulationDossierService = new ApiService<T>(`simulationDossier/${endpoint}`);
        return new Promise<T>((resolve, reject) => {
            return simulationDossierService.get(id)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }
    /**
     * Récupérer les données de la société de pilotage.
     *
     * @param {number} id
     * @returns {Promise<T>}
     * @memberof SocieteSimulationDossierBase
     */
    protected recupererSocietePilotageBySimulationDossier(id: number): Promise<T> {
        const endpoint = 'recupererSocietePilotageParSimulationDossier';
        const simulationDossierService = new ApiService<T>(`simulationDossier/${endpoint}`);
        return new Promise<T>((resolve, reject) => {
            return simulationDossierService.get(id)
                .then((response) => resolve(response.data.data))
                .catch((error: { response: Error; }) => reject(error.response));
        });
    }
    /**
     * Lier + Créer (si pas importé) un Bénéficiaire à la simulation courante.
     *
     * @memberof DonneesBeneficiaire
     */
    protected creerOuLierSocieteSimulationDossier(id: number): Promise<T> {
        this.definirEtapeSuivante();
        const endpoint = this.getEndPoint('lierSocieteBeneficiaireAsimulationDossier', 'lierSocieteInstallateurAsimulationDossier');
        const simulationService = new ApiService<T>(`simulationDossier/${endpoint}`);

        return new Promise<T>((resolve, reject) => {
            simulationService.post(this.donneesOngletSociete).then((response) => {
                resolve(response.data.data);
            }).catch(() => {
                reject('Une erreur est survenu lors de l\'enregistrement de la société.');
            });
        });
    }

    /**
     * Complète les contacts d'une société si incohérents.
     *
     * @memberof Base
     */
    protected completerContactSociete(societes: Societe[], estOngletInstallateur: boolean): void {
        if (societes && societes.length >= 1) {
            societes.forEach((itemSociete) => {

                const estInterlocuteur = itemSociete.contacts.some((c) => c.typeContactId === TypeContact.Interlocuteur);
                const estSignataire = itemSociete.contacts.some((c) => c.typeContactId === TypeContact.Signataire);
                itemSociete.estSousTraitant = itemSociete.contacts.some((c) => c.typeContactId === TypeContact.SousTraitant);

                if (!estInterlocuteur) {
                    const interlocuteur = new Contact(TypeContact.Interlocuteur);
                    interlocuteur.civiliteId = this.currentUser.civiliteId;
                    interlocuteur.nom = this.currentUser.lastName;
                    interlocuteur.prenom = this.currentUser.firstName;
                    interlocuteur.fonction = this.currentUser.fonction;
                    interlocuteur.mail = this.currentUser.email;
                    interlocuteur.telephone = this.currentUser.telephone;

                    itemSociete.contacts.push(interlocuteur);
                    itemSociete.interlocuteur = interlocuteur;
                }
                if (!estSignataire) {
                    const signataire = new Contact(TypeContact.Signataire);
                    signataire.civiliteId = this.currentUser.civiliteId;
                    signataire.nom = this.currentUser.lastName;
                    signataire.prenom = this.currentUser.firstName;
                    signataire.fonction = this.currentUser.fonction;
                    signataire.mail = this.currentUser.email;
                    signataire.telephone = this.currentUser.telephone;

                    itemSociete.contacts.push(signataire);
                    itemSociete.signataire = signataire;
                }
                if (estOngletInstallateur && !itemSociete.estSousTraitant) {
                    const sousTraitant = new Contact(TypeContact.SousTraitant);
                    sousTraitant.civiliteId = this.currentUser.civiliteId;
                    sousTraitant.nom = this.currentUser.lastName;
                    sousTraitant.prenom = this.currentUser.firstName;
                    sousTraitant.fonction = this.currentUser.fonction;
                    sousTraitant.mail = this.currentUser.email;
                    sousTraitant.telephone = this.currentUser.telephone;

                    itemSociete.contacts.push(sousTraitant);
                    itemSociete.sousTraitant = sousTraitant;
                }
            });
        }
    }

    // Crée un Interlocuteur vide.
    public creerDefaultInterlocuteur(): Contact {
        const interlocuteur = new Contact(TypeContact.Interlocuteur);
        if (this.donneesOngletSociete.societe
            && this.donneesOngletSociete.societe.contacts) {
            const contacts = this.donneesOngletSociete.societe.contacts;
            const hasInterlocuteur = contacts.some((c) => c.typeContactId === TypeContact.Interlocuteur);
            if (!hasInterlocuteur) {
                this.donneesOngletSociete.societe.contacts.push(interlocuteur);
            }
        }
        return interlocuteur;
    }
    // Crée un Signataire vide.
    public creerDefaultSignataire(): Contact {
        const signataire = new Contact(TypeContact.Signataire);
        if (this.donneesOngletSociete.societe
            && this.donneesOngletSociete.societe.contacts) {
            const contacts = this.donneesOngletSociete.societe.contacts;
            const hasSignataire = contacts.some((c) => c.typeContactId === TypeContact.Signataire);
            if (!hasSignataire) {
                this.donneesOngletSociete.societe.contacts.push(signataire);
            }
        }
        return signataire;
    }
}
