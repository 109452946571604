import { render, staticRenderFns } from "./Documents.Template.vue?vue&type=template&id=a2d36df4&scoped=true&"
var script = {}
import style0 from "./Documents.scss?vue&type=style&index=0&id=a2d36df4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2d36df4",
  null
  
)

export default component.exports