import { Societe } from '@/models';
import { ApiService } from './base/ApiService';

// tslint:disable-next-line:no-namespace
export namespace BaseCommonApiHelper {
    /**
     * Récupération des sociétés de l'utilisateur connecté.
     *
     */
    export function obtenirSocietesAffiliees(): Promise<Societe[]> {
        return new Promise<Societe[]>((resolve, reject) => {
            const accordCadreService = new ApiService<Societe>('accordCadre/recupererSocietesAffiliees');
            accordCadreService.getAll().then((response) => {
                resolve(response.data.data);
            })
            .catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }
}

export default BaseCommonApiHelper;
