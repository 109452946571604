import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant Valorisation.
 */
@Component
export default class ValorisationValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {
    public partBeneficiaireClassiqueRule = [
        (v: string | any) => this.required(v) || 'La part bénéficiaire classique est obligatoire',
    ];

    public partBeneficiairePrecariteRule = [
        (v: string | any) => this.required(v) || 'La part bénéficiaire précarité est obligatoire',
    ];

    public partAARule = [
        (v: string | any) => this.required(v) || 'La part Apporteur d\'affaires est obligatoire',
    ];

    public intituleDossierRule = [
       (v: string | any) => this.required(v) || 'L\'intitule du dossier est obligatoire',
    ];
}
