import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant DonneesBeneficiaire.
 */
@Component
export default class DonneesInstallateurValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {

        public typeInstallateurRules = [
            (v: number | any) => this.required(v) && v > 0 || 'Le type d\'installateur est obligatoire',
        ];
}
