







































    import { Vue, Component, Prop } from 'vue-property-decorator';
    import CeeBouttonImport from '@/components/CeeBouttonImport.vue';
    import { SimulationDossierSocieteBase } from './models/SimulationDossierSocieteBase.model';
import { SimulationDossierSocieteInstallateur, SimulationDossierSocieteBeneficiaire } from './models';

    @Component({
        name: 'DonneesSociete',
        components: { CeeBouttonImport },
    })
    export default class DonneesSociete extends Vue {
        @Prop({ default: () => new Array<SimulationDossierSocieteInstallateur | SimulationDossierSocieteBeneficiaire>() })
        private data!: SimulationDossierSocieteInstallateur[] | SimulationDossierSocieteBeneficiaire[];
        @Prop({ default: () => '' })
        private type!: string;
        private dialog = false;
        private simulationSociete: SimulationDossierSocieteBase = new SimulationDossierSocieteBase();

        public importSociete() {
            this.$emit('importSociete', this.simulationSociete);
            this.dialog = false;
        }
    }
