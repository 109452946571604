import { Vue, Watch, Prop } from 'vue-property-decorator';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import template from './DonneesEngie.Template.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { Utilisateur, AccordCadre, TypeCee, Dossier, Negociateur, Paiement, PrescripteurInterne, TypeContribution } from '@/models';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';
import { EnumProfilsAsNumber, Profils, TypeContribution as TypeContributionEnum, SecteurAsNumber } from '@/shared/enums';
import IWizardEtapeComponent from '@/components/Wizard/@Abstracts/IWizardEtapeComponent';
import IWizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/IWizardEtapeComponentsDossier';
import { Getter } from 'vuex-class';
import { cloneDeep, isEqual } from 'lodash-es';
import DonneesEngieValidator from './DonneesEngie.Validator';
import { ApiService } from '@/services/base/ApiService';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { SousCanal } from '@/shared/enums/SousCanal.enum';
import { DateHelper, formatNumber } from '@/shared/helpers';
import { CanalCollecte } from '@/models/CanalCollecte.model';
import ApiHelper from '@/services/ApiHelper';
import PaiementComponent from './PaiementComponent.vue';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { DossierReferentiels } from '../../../../models/DossierReferentiels.model';
//  Créer une interface pour fusionner les MIXINS.
interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, DonneesEngieValidator, Vue { }
@Component({
    ...template,
    name: 'DonneesEngie',
    ref: 'DonneesEngie',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
    components: {
        CeeDatePicker, CeeAutocomplete, PaiementComponent
    }
})
export default class DonneesEngie extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, DonneesEngieValidator)
    implements IWizardEtapeComponent, IWizardEtapeComponentsDossier {
    /**
     * constructor.
     */
    constructor() { super(); }
    // Titre.
    public titre(): string { return 'Données ENGIE'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-engie'; }
    public $refs!: { form: HTMLFormElement };

    public listeAvoirs: TypeContributionEnum[] =
        [
            TypeContributionEnum.AVOIR_COMMISSION_APPORTEUR_AFFAIRES,
            TypeContributionEnum.AVOIR_PREFINANCEMENT_PRIME_CEE_BENEFICIAIRE,
            TypeContributionEnum.AVOIR_PREFINANCEMENT_REMUNERATION_PARTENAIRE_INSTALLATEUR,
            TypeContributionEnum.AVOIR_PRIME_CEE_BENEFICIAIRE,
            TypeContributionEnum.AVOIR_REMUNERATION_PARTENAIRE_DIVERS,
            TypeContributionEnum.AVOIR_REMUNERATION_PARTENAIRE_INSTALLATEUR
        ];

    public listePrefinancements: TypeContributionEnum[] =
        [
            TypeContributionEnum.PREFINANCEMENT_REMUNERATION_PARTENAIRE_DIVERS,
            TypeContributionEnum.PREFINANCEMENT_REMUNERATION_PARTENAIRE_INSTALLATEUR,
            TypeContributionEnum.AVANCE_PRIME_BENEFICIAIRE
        ];

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    @Prop()
    public referentielsEtape: DossierReferentiels;

    public donneesPrescripteurInterne: { entiteCommerciale: string, equipe: string } = {
        entiteCommerciale: '',
        equipe: '',
    };
    /**
     * Le dossier est saisi pour un apporteur d'affaires ?
     *
     * @type {boolean}
     * @memberof DonneesEngie
     */
    public estDossierPourApporteurAffaires: boolean = false;

    /**
     * Définit le modèle de données sur lequel observer les changements.
     */
    public get modeleDonnees(): Dossier {
        return this.dossier;
    }

    /* Ajouté comme proxy entre le getter et le template pour permettre la réactivité
            quand on modifie la valeur d'une des visibilité directement sur le v-switch */
    public visibilite: { accordCadre: boolean, offreService: boolean, message: boolean, prescripteurInterne: boolean } = {
        accordCadre: false,
        offreService: false,
        message: false,
        prescripteurInterne: false
    };

    /**
     * Gestion initialisation du canal collecte.
     */
    private initCanalCollecte: boolean = true;

    /**
     * Détermine si l'étape a été modifiée depuis le dernier enregistrement.
     * Marque l'onglet comme modifié lors de la création car il contient des données essentielles à l'enregistrement.
     */
    public get estModifiee(): boolean {
        return !this.simulationDossierId || !isEqual(this.modeleDonnees, this.modeleDonneesInitial);
    }
    /**
     * Profil admin.
     */
    public estAdministrateur: boolean = false;

    /** Détermine quels champs sont visibles en fonction des données du dossier. */
    @Watch('dossier', { immediate: false, deep: true })
    public onDataVisibiliteChange(value: Dossier, oldValue: Dossier) {
        this.visibilite = {
            accordCadre: !!value.accordCadreId,
            message: !!value.messageAvertissement,
            offreService: !!value.numeroOffreService,
            prescripteurInterne: !!value.prescripteurInterneId
        };

        // On definit le switch d'apporteur d'affaires en fonction du profil créateur du dossier et on choisit "hide/unhide" l'onglet.
        this.estDossierPourApporteurAffaires = (value.utilisateurCreation.profilUtilisateur === Profils.ApporteurAffaires ||
            value.sousCanal === SousCanal.WebCEEApporteurAffaires) ? true : this.estDossierPourApporteurAffaires;
        this.disableOrEnableTabApporteurAffaire(this.estDossierPourApporteurAffaires);

        if (!!value.prescripteurInterne) {
            this.alimenterEntiteCommercialEtEquipe(value.prescripteurInterne);
        }
    }


    public typeCee: TypeCee[];
    public typeContribution: TypeContribution[];
    public canauxCollectes: string[] = [];

    /**
     * Exécuté lors de la création du composant.
     *
     * @memberof DonneesEngie
     */
    public created() {
        this.typeCee = this.referentielsEtape.typeCee;
        this.typeContribution = this.referentielsEtape.typeContribution;
        this.canauxCollectes = this.referentielsEtape.canalCollecte;
        this.getUserProfile.then((donneesProfil: any) => {
            if (!!donneesProfil && parseInt(donneesProfil.sub as string, 10) > 0) {

                this.estAdministrateur = donneesProfil.isAdmin;
            }
        });
    }

    /**
     * Exporter le modèle de cette étape.
     *
     * @returns {{ model: any; meta: any; }}
     * @memberof DonneesEngie
     */
    public exporterEtapeModel(): { model: any; meta: any; } {
        return {
            model: this.dossier,
            meta: {
                param: 'dossier'
            }
        };
    }
    /**
     * Virer/Remettre l'onglet Données apporteurs affaires en fonction de la valeur du switch.
     *
     * @param {boolean} value
     * @memberof DonneesEngie
     */
    public disableOrEnableTabApporteurAffaire(value: boolean) {
        this.bus.$emit('disableOrEnableTabApporteurAffaire', value);
    }

    /**
     * Récupération des gestionnaire Bo.
     *
     * @param {string} recherche
     * @returns {Promise<Utilisateur[]>}
     * @memberof DonneesEngie
     */
    public getGestionnaireBOAutoCompletePromise(recherche: string): Promise<Utilisateur[]> {
        return ApiAutocompleteHelper.getAutocompleteByUtilisateurCriteriaPromise<Utilisateur>(recherche,
            [EnumProfilsAsNumber.GestionnaireBO, EnumProfilsAsNumber.AdministrateurCEE, EnumProfilsAsNumber.Finance], 'utilisateur/obtenirTousUtilisateursActifParType');
    }

    public getGestionnaireBOByIdAutoCompletePromise(id: number): Promise<Utilisateur> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<Utilisateur>(id, 'utilisateur/obtenirPublic');
    }

    /**
     * Récupération des prescripteurs internes.
     *
     * @param {string} recherche
     * @returns {Promise<PrescripteurInterne[]>}
     * @memberof DonneesEngie
     */
    public getPrescripteurAutoCompletePromise(recherche: string): Promise<PrescripteurInterne[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<PrescripteurInterne>(recherche, 'prescripteurInterne/obtenirTous');
    }
    public getPrescripteurByIdAutoCompletePromise(id: number): Promise<PrescripteurInterne> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<PrescripteurInterne>(id, 'prescripteurInterne/obtenir');
    }

    /**
     * Récupération des accords cadres.
     *
     * @param {string} recherche
     * @returns {Promise<AccordCadre[]>}
     * @memberof DonneesEngie
     */
    public getAccordsCadresAutoCompletePromise(recherche: string): Promise<AccordCadre[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<AccordCadre>(recherche, 'accordCadre/obtenirTous', true);
    }

    public getAccordsCadresByIdAutoCompletePromise(id: number): Promise<AccordCadre> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<AccordCadre>(id, 'accordCadre/obtenir');
    }

    /**
     * Récupération des négociateurs.
     *
     * @param {string} recherche
     * @returns {Promise<any[]>}
     * @memberof DonneesEngie
     */
    public getNegociateursAutoCompletePromise(recherche: string): Promise<Negociateur[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<any>(recherche, 'negociateur/obtenirTous');
    }
    public getNegociateursByIdAutoCompletePromise(id: number): Promise<Negociateur> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<any>(id, 'negociateur/obtenir');
    }

    public getCanauxCollectes(): void {
        ApiHelper.getAll<CanalCollecte>('canalCollecte/obtenirTous').then((response) => {
            this.canauxCollectes = response.map((c: CanalCollecte) => c.nom);
        });
    }
    /**
     * Ajouter un paiement.
     *
     * @memberof DonneesEngie
     */
    public addPaiement(): void {
        let paiement = new Paiement();
        paiement.numeroPaiement = '99999999';
        this.dossier.paiements.push(paiement);
    }
    /**
     * Supprimer un paiement.
     *
     * @param {number} index
     * @param {number} index
     * @memberof DonneesEngie
     */
    public deletePaiement(index: number, paiement: Paiement): void {
        if (index > -1) {
            if (!!this.model.simulationDossierId && paiement.dossierId > 0) {
                const paiementService = new ApiService<Paiement>('paiement/supprimerPaiement');
                paiementService.delete(paiement.id)
                    .then(() => {
                        this.dossier.paiements.splice(index, 1);
                    })
                    .catch(() => {
                        throw new Error('Erreur survenu lors de la suppression du paiement.');
                    });
            } else {
                this.dossier.paiements.splice(index, 1);
                // Actualise le message d'avertissement à la suppression d'un paiement.
                this.updateMontant(index - 1, '', 0);
            }
        }
    }

    public dupliquerPaiement(paiement: Paiement): void {
        const newPaiement = cloneDeep(paiement);
        newPaiement.id = 0;
        this.dossier.paiements.push(newPaiement);
    }

    /**
     * Alimenter le canal collecté et le sous canal en fonction du négociateur choisi.
     *
     * @param {Negociateur} negociateur
     * @memberof DonneesEngie
     */
    public alimenterCanal(negociateur: Negociateur): void {
        this.dossier.sousCanal = negociateur.sousCanal ? negociateur.sousCanal.nom : '';
        this.dossier.canalCollecte = negociateur.canalCollecte.nom;
    }

    /**
     * Alimenter l'équipe et l'entité commerciale en fonction du prescripteur choisi.
     *
     * @param {PrescripteurInterne} prescripteurInterne
     * @memberof DonneesEngie
     */
    public alimenterEntiteCommercialEtEquipe(prescripteurInterne: PrescripteurInterne): void {
        this.donneesPrescripteurInterne.entiteCommerciale = prescripteurInterne.entiteCommerciale;
        this.donneesPrescripteurInterne.equipe = prescripteurInterne.equipe;
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        // Avant de relancer la validation, on clear les messages d'erreurs.
        this.setErrorMessage(null);
        let estValide = false;
        if (this.$refs.form) {
            estValide = this.$refs.form.validate();
        }
        return Promise.resolve(new ResultatValidationEtape(estValide, estValide));
    }

    /**
     * Mise à jour de la date de prise en charge au moment de la prise en charge, ci celle-ci est vide.
     */
    public updateDatePriseEnCharge(): void {
        if (this.dossier.idGestionnaire && !this.dossier.datePriseEnChargeParEngie) {
            this.dossier.datePriseEnChargeParEngie = DateHelper.toIsoString(new Date());
        }
    }

    public updateMontant(index: number, param: string, value: number) {
        this.$set(this.dossier.paiements[index], param, value);
        this.dossier.paiements = [...this.dossier.paiements];
        // Si le type de contribution selectionné est un avoir alors les montants passent au négatif.
        let aAvoir = this.listeAvoirs.includes(this.dossier.paiements[index].typeContributionId);
        if (aAvoir) {
            if (this.dossier.paiements[index].montantCeeClassique > 0) {
                this.$set(this.dossier.paiements[index], 'montantCeeClassique', - this.dossier.paiements[index].montantCeeClassique);
            }
            if (param === 'montantCeeClassique' && value > 0) {
                this.$set(this.dossier.paiements[index], 'montantCeeClassique', - value);
            }
            if (this.dossier.paiements[index].montantCeePrecarite > 0) {
                this.$set(this.dossier.paiements[index], 'montantCeePrecarite', - this.dossier.paiements[index].montantCeePrecarite);
            }
            if (param === 'montantCeePrecarite' && value > 0) {
                this.$set(this.dossier.paiements[index], 'montantCeePrecarite', - value);
            }
            this.dossier.paiements = [...this.dossier.paiements];
        }
        this.getAvertissementPaiement(this.dossier);
    }

    private getAvertissementPaiement(dossier: Dossier): void {
        if (!this.dossier.operations.some(x => x.secteurId === SecteurAsNumber.NonStandard)) {

            let aAvoir = dossier.paiements.some(p => this.listeAvoirs.includes(p.typeContributionId));
            let aPrefinancement = dossier.paiements.some(p => this.listePrefinancements.includes(p.typeContributionId));
            let engagementTotal = formatNumber(dossier.paiements.SumBy(p => p.montantCeeClassique + p.montantCeePrecarite));
            let primeTotale = formatNumber(dossier.operations.SumBy(o => o.prixCeeClassique + o.prixCeePrecarite + (o.remunerationApporteurAffaires * o.volumeCeeClassique)));

            if (engagementTotal !== primeTotale) {
                if (aAvoir) {
                    (this.dossier.avertissementPaiements as any).item1 = "Les lignes de paiement contiennent des avoirs, il est possible que le montant total des lignes de paiement soit différent du montant total de la prime";
                    (this.dossier.avertissementPaiements as any).item2 = "orange--text";
                }
                else if (aPrefinancement) {
                    (this.dossier.avertissementPaiements as any).item1 = "Les lignes de paiement contiennent des préfinancements, il est possible que le montant total des lignes de paiement soit différent du montant total de la prime.";
                    (this.dossier.avertissementPaiements as any).item2 = "orange--text";
                }
                else {
                    (this.dossier.avertissementPaiements as any).item1 = "Le montant total des lignes de paiement ne correspond pas au montant total de la prime.";
                    (this.dossier.avertissementPaiements as any).item2 = "red--text";
                }
            }
            else {
                (this.dossier.avertissementPaiements as any).item1 = "Le montant total des lignes de paiement correspond au montant total de la prime.";
                (this.dossier.avertissementPaiements as any).item2 = "green--text";
            }
        }
        else {
            (this.dossier.avertissementPaiements as any).item1 = "";
            (this.dossier.avertissementPaiements as any).item2 = "";
        }
    }
}
