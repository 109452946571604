import { Component, Mixins } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant DonneesBeneficiaire.
 */
@Component
export default class DonneesEngieValidator extends Mixins(ValidationMixin) {

    public numDossierRule = [
        (v: string | any) => this.required(v) || 'Le numéro de dossier est requis pour l\'enregistrement d\'un dossier',
    ];

    public paiementValorisationRule = [
        (v: string | any) => this.required(v) || 'La valorisation est obligatoire',
    ];

    public typeContributionRule = [
        (v: string | any) => this.required(v) && v > 0 || 'Le type de contribution est obligatoire',
    ];

    public numeroPaiementRule = [
        (v: string | any) => v.length <= 8 || 'Le numéro de paiement est invalide',
    ];

    public gestionnaireRule = [
        (v: string | any, model : any) => this.required(v) || 'Le gestionnaire du dossier est requis pour l\'enregistrement d\'un dossier',
    ]

    public typeCeeRule = [
        (v: string | any, model : any) => this.required(v) || 'Le Type de CEE est requis pour l\'enregistrement d\'un dossier',
    ]
}
