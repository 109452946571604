/**
 * Gestion des fichiers chargé pour l'upload.
 *
 * @export
 * @class FileHelper
 */
export default class FileHelper {
    /**
     * Nom du fichier.
     *
     * @type {string}
     * @memberof FileHelper
     */
    public name: string;
    /**
     * Taille du fichier en Bytes.
     *
     * @type {number}
     * @memberof FileHelper
     */
    public size: number;
    /**
     * Extension du fichier.
     *
     * @type {string}
     * @memberof FileHelper
     */
    public type: string;

    /**
     * Creates an instance of FileHelper.
     * @param {string} [name]
     * @param {number} [size]
     * @param {string} [type]
     * @memberof FileHelper
     */
    constructor();
    constructor(name: string, size: number, type: string);
    constructor(name?: string, size?: number, type?: string) {
        this.name = name || '';
        this.size = size || -1;
        this.type = type || '';
    }

    /**
     * Vérification de la validité (extension = PDF, Taille < 10Mo) du fichier.
     *
     * @returns {boolean} true si le fichier est valide, sinon false
     * @memberof FileHelper
     */
    public validate(): boolean {
        return this.name !== undefined
            && (this.type !== undefined && this.type === 'application/pdf')
            && (this.size !== undefined && this.size <= 10 * 1024 * 1024);
    }

    /**
     * Lecture et chargement du fichier en Base 64.
     *
     * @param {File} file fichier chargé par l'administrateur.
     * @returns {(Promise<string | ArrayBuffer | null>)} Promise du fichier en base 64 en cas de succès, a défaut une exception
     * @memberof FileHelper
     */
    public toBase64(file: File): Promise<string | ArrayBuffer | null> {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException('Le fichier chargé est invalide'));
            };
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }

    public downloadBase64(sourceBase64: string, fileName: string) {
        const downloadLink = document.createElement("a");
        downloadLink.href = sourceBase64;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}
