import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

import template from './SimulateurOuDossier.Template.vue';
import WizardEtapeComponentBase from '@/components/Wizard/@Abstracts/WizardEtapeComponentBase';
import { composants, composantsParType, TypeDeWizardComposant } from '@/components/Wizard/Composants';

import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { UserProfile } from '@/store/modules/auth/types';

import { isNullOrUndefined } from '@/shared/helpers';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import FormWizardSimulationDossier from '@/components/Wizard/FormWizardSimulationDossier/FormWizardSimulationDossier';
import { Profils } from '@/shared/enums';
import { isEmpty } from 'lodash-es';
import Confirm from '@/components/Confirm.vue';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';

// class-component-hooks.js
import Component from 'vue-class-component';
import { Route, RawLocation } from 'vue-router';

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

@Component({
    ...template,
    name: 'SimulateurOuDossier',
    components: {
        FormWizardSimulationDossier,
        Confirm,
        ...composants,
    },
})
export default class SimulateurOuDossier extends Vue {

    /**
     * L'identifiant de la simulation.
     */
    @Prop({ default: null }) public readonly simulationDossierId!: number | null;
    /**
     * Simulation Ou Dossier.
     */
    @Prop({ default: null }) public readonly simulationOuDossier!: SimulationOuDossier;

    @Getter(InformationsSimulationDossierStoreMethods.DISCRIMINATOR)
    public discriminator: string;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string; width?: number; zIndex?: number }) => Promise<boolean>);
        };
    };

    /**
     * Liste des étapes.
     */
    public etapes: Array<{
        index: number,
        composant: WizardEtapeComponentBase | typeof Component
    }> = new Array<{ index: number, composant: WizardEtapeComponentBase | typeof Component }>();

    /**
     * Permet de savoir si on est en mode chargement ou pas.
     */
    public loading: boolean = false;

    /**
     * Indique si l'utilisateur est installateur mais qu'il n'a pas de contrat valide.
     */
    public installateurAvecContratNonValide: boolean = false;

    /**
     * Indique si l'utilisateur est un apporteur d'affaires mais qu'il n'a pas de contrat en cours.
     */
    public apporteurAffairesSansContratEnCours: boolean = false;

    /**
     * Droit de faire une nouvelle simu ou dossier.
     */
    public hasAccesCreationSimulationDossier: boolean = false;

    /**
     * Erreur flag.
     */
    public isErrorOnOpen: boolean = false;

    public SimulationOuDossier = SimulationOuDossier;

    // Promesse pour récupérer le profil du user.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    // Profil
    public userProfile: UserProfile = null;

    /**
     * La position de départ.
     */
    public etapeStartIndex: number = 0;

    /**
     * Retourne les étapes de façon ordonnée par index.
     */
    public get etapesOrdonnees(): Array<WizardEtapeComponentBase | typeof Component> {
        const result = this.etapes.sort((a, b) => a.index - b.index).map((item) => item.composant);
        return result;
    }
    /**
     *  Permet de savoir si les étapes sont bien définies.
     */
    public get isEtapesOrdonneesDefined(): boolean {
        if (!(this.etapesOrdonnees && this.etapesOrdonnees.length >= 1)) {
            return false;
        } else {
            return (this.etapesOrdonnees || new Array<WizardEtapeComponentBase | typeof Component>()).every((item) => {
                return !isEmpty(item);
            });
        }
    }
    /**
     * Retourne l'URL qui permet de démarrer une simulation.
     */
    public get urlDemerrageSimulation(): string {
        if (!isNullOrUndefined(this.simulationDossierId)) {
            return `/simulationDossier/demarrerSimulationDossier/${this.simulationOuDossier}/${this.simulationDossierId}`;
        } else {
            return `/simulationDossier/demarrerSimulationDossier/${this.simulationOuDossier}`;
        }
    }

    /**
     * Libellé simulation ou dossier.
     */
    public get simulationOuDossierStrings() {
        return this.simulationOuDossier ===  SimulationOuDossier.Simulation ? {
            param1: 'La', param2: 'à la', libelle: 'simulation'} : {param1: 'Le', param2: 'au', libelle: 'dossier'};
    }

    /**
     * Hook qui se déclenche lorsque le composant est attaché au dom.
     *
     */
    public mounted(): void {
        this.loading = true;
        this.getUserProfile.then((userProfile) => {
            this.userProfile = userProfile;
            this.hasAccesCreationSimulationDossier = userProfile.hasAccesCreationSimulationDossier;
            // Bloque l'accès au simulateur si l'utilisateur est installateur et que le contrat de partenariat n'est pas valide.
            if (userProfile.profilCode === Profils.Installateur && !userProfile.hasContratPartenariatValide) {
                this.installateurAvecContratNonValide = true;
            } else if (userProfile.profilCode === Profils.ApporteurAffaires && !userProfile.hasContratApporteurAffairesEnCours) {
                this.apporteurAffairesSansContratEnCours = true;
            } else {
                // Récupération des infos pour démarrer la simulation.
                this.$http.get(this.urlDemerrageSimulation).then((result) => {
                    const { data: { data: responseData } } = result as ({
                        data: {
                            data: {
                                etapeStartIndex: number, etapes: Array<{ id: number, code: string, libelle: string }>
                            },
                            isError: boolean,
                            messages: any[]
                        }
                    });

                    if (responseData) {
                        this.etapeStartIndex = responseData.etapeStartIndex;
                        this.etapes = Object.values(responseData.etapes).map((item, index) => {
                            return {
                                index,
                                composant: (composantsParType as unknown as Array<{ nom: TypeDeWizardComposant, composant: WizardEtapeComponentBase | typeof Component }>)
                                    .find((composantType) => composantType.nom === item.code).composant
                            };
                        }) as Array<{ index: number, composant: WizardEtapeComponentBase | typeof Component }>;
                    }
                }).catch(() => {
                    this.isErrorOnOpen = true;
                });
            }
        }).finally(() => this.loading = false);
    }

    /**
     * Affichage d'une confirmation d'abandon de simulation.
     * @param message
     */
    public async confirmationAbandonSimulation(message: string) {
        return await this.$refs.confirm
            .open(`Quitter ${message}`,
                `Souhaitez-vous quitter ${message} ? Les changements non sauvegardés seront perdus.`,
                { color: 'red', width: 500, zIndex: 200 });
    }

    private beforeRouteLeave(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) {

            if (this.discriminator) {

                const message = (this.discriminator === 'Dossier' ?
                'le ' :
                'la ')
                + this.discriminator.toLowerCase();
                // Affichage d'une confirmation avant de recharger la page.
                this.confirmationAbandonSimulation(message)
                    .then((confirmation: boolean) => {
                        if (confirmation) {
                            next();
                        } else {
                            next(false);
                        }
                });
            } else {
                next();
            }
        }
}
