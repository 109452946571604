

















import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectableSecteur from '@/components/SelectableSecteur.vue';
import { SecteurReferentiel } from '@/models';
import { Secteurs, Profils } from '@/shared/enums';

import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';

@Component({
    name: 'SelectableSecteurList',
    components: {
        SelectableSecteur
    }
})
export default class SelectableSecteurList extends Vue {
    /**
     * Le mapping entre un secteur de la BDD et l'affichage
     *
     * @type { SecteurReferentiel[] }
     * @memberof SelectableSecteurList
     */
    @Prop({ default: () => new Array<SecteurReferentiel>() })
    public readonly secteurs!: SecteurReferentiel[];

    /**
     * Détermine si la page est en consultation seulement (page "Nos offres") ou utilisée pour ajouter une opération.
     * Les secteurs affichés seront différents selon les cas.
     */
    @Prop({ default: false })
    public readonly estConsultation: boolean;

    /**
     * Le secteur sélectionné.
     *
     * @type { SecteurReferentiel }
     * @memberof SelectableSecteurList
     */
    @Prop({ default: () => null as unknown as SecteurReferentiel })
    public selectedSecteur: SecteurReferentiel | null;

    /**
     * Le profil utilisateur.
     */
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    /**
     * Profil de l'utilisateur, utilisé pour consulter ses permissions.
     */
    private profil: UserProfile = null;

    /**
     * Indique si l'utilisateur connecté est de type syndic/sdc.
     */
    private isSyndicSdc: boolean = false;

    /**
     * Constructeur
     */
    constructor() {
        super();
    }

    /**
     * Montage de la vue.
     * */
    public mounted() {
        // Les secteurs affichées doivent être les secteurs standards pour les profils externes. Pour les internes on affiche tout.
        this.getUserProfile.then((profil: UserProfile) => {
            this.profil = profil;
        });
    }

    /**
     * Récupère les secteurs à afficher.
     */
    public get secteursAffiches(): SecteurReferentiel[] {

        // Un interne peut voir tous les secteurs, on cache juste le secteur non-standard sur la page "Nos offres".
        if (this.profil && this.profil.isInterne) {
            if (this.estConsultation) {
                return this.secteurs.filter((s: SecteurReferentiel) => s.code !== Secteurs.NonStandard);
            }
            else {
                return this.secteurs;
            }
        }

        // Construit la liste des secteurs disponibles.
        // Les secteurs Résidentiel et Tertiaire sont disponibles pour tous les profils.
        const secteurs: Secteurs[] = [Secteurs.Residentiel, Secteurs.Tertiaire];

        // Les secteurs Agriculture et Industrie sont uniquement cachés pour les Syndics/SDC lors de l'ajout d'une opération.
        if (!this.profil || this.profil.profilCode !== Profils.SyndicSDC || this.estConsultation) {
            secteurs.push(Secteurs.Agriculture);
            secteurs.push(Secteurs.Industrie);
        }

        // Les secteurs Réseaux et Transport dépendent des droits accordés individuellement à l'utilisateur.
        if (this.profil && this.profil.hasAccesOperationsReseau) {
            secteurs.push(Secteurs.Reseaux);
        }
        if (this.profil && this.profil.hasAccesOperationsTransport) {
            secteurs.push(Secteurs.Transport);
        }
        if (this.profil && this.profil.hasAccesOperationsNonStandard) {
            secteurs.push(Secteurs.NonStandard);
        }

        // Retourne les secteurs référentiels.
        return this.secteurs.filter((s: SecteurReferentiel) => secteurs.map((secteur: string) => secteur).includes(s.code));
    }
}
