














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { SiteTravaux } from '@/models';
import CeeBouttonImport from '@/components/CeeBouttonImport.vue';

@Component({
    name: 'SiteTravauxDataPicker',
    components: { CeeBouttonImport },
})
export default class SiteTravauxDataPicker extends Vue {
    @Prop({ default: () => new Array<SiteTravaux>() })
    private data!: SiteTravaux[];

    /**
     * Vrai si un site des travaux a déjà été choisi au préalable, faux sinon.
     */
    @Prop({ default: () => false })
    public isSiteTravauxPicked: boolean;

    private dialog = false;
    private siteTravaux: SiteTravaux = new SiteTravaux();

    public importSiteTravaux() {
        this.$emit('importSiteTravaux', this.siteTravaux);
        this.dialog = false;
    }

    /**
     * Désélectionne le site des travaux.
     */
    public deselectionnerSiteTravaux() {
        this.$emit('deselectionnerSiteTravaux');
    }
}
