import { SimulationDossierSocieteBase } from './SimulationDossierSocieteBase.model';

/**
 * Classe SimulationDossierSociete Installateur.
 *
 * @export
 * @class SimulationSociete
 */
export class SimulationDossierSocieteInstallateur extends SimulationDossierSocieteBase {

    constructor() {
        super();
    }

}

/**
 * Classe SimulationDossierSociete Pilotage.
 *
 * @export
 * @class SimulationSociete
 */
export class SimulationDossierSocietePilotage extends SimulationDossierSocieteBase {

    constructor() {
        super();
    }

}
