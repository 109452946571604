





































































































































































































import Vue from 'vue';
import { Mixins, Prop, Component, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { DataTableHeader } from '@/shared/models';
import { Operation } from '@/models';
import { StatutOperation, libelleStatutOperation } from '../models/StatutOperation';
import { ApiService } from '@/services/base/ApiService';
import { ConvertHelper } from '@/shared/helpers';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { cloneDeep } from 'lodash-es';
import { SecteurAsNumber, StatutDossier, StatutCommercialOperation } from '@/shared/enums';
import { Dossier } from '@/models';
import { Notifications } from '@/shared/Notifications';
import { MessageLevelAsString } from '@/shared/enums/MessageLevel.enum';

@Component({
    name: 'ResultatSimulationTab',

})
export default class ResultatSimulationTab extends Mixins(GrilleMixin)
{
    @Prop({ default: new Array<Operation>() })
    public items!: Operation[];

    @Prop({ default: false })
    public estAdministrateur!: boolean;

    @Prop({ default: false })
    public estInterne!: boolean;

    @Prop({ default: false })
    public estApporteurAffaires!: boolean;

    // Permet la modification des valorisations d'un AA à un admin si l'on est sur un dossier
    @Prop({ default: false })
    public estDossier!: boolean;

    // N'affiche les données d'apporteur d'affaires que si le créateur de la simulation en est un.
    // La modification des valorisations est également bloquée pour ces simulations.
    @Prop({ default: false })
    public estSimulationApporteurAffaires!: boolean;

    // Chargement des éléments de la grille en cours ou non.
    @Prop({ default: false })
    public loading: boolean;

    @Prop({ default: 2 })
    public nbDecimal: number;

    @Prop({ default: null })
    public dossier: Dossier;

    @Prop({ default: false })
    public peutSupprimerOperation!: boolean;

    // Référentiel StatutsCommercial
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.StatutCommercial))
    public statutsCommercial: ValeurReferentielle[];

    // Référentiel EtatTraitement
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.EtatTraitement))
    public etatsTraitement: ValeurReferentielle[];

    // Référentiel StatutsPNCEE
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.StatutPncee))
    public statutsPncee: ValeurReferentielle[];

    // Permet de récupérer les données référentielles.
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    // Variable intermédiaire pour pouvoir enregistrer la saisie d'un administrateur.
    public nouvelleValorisation: string;

    // Dernière colonne triée.
    public lastSortProperty: string = 'numero';

    public statutDossiers = StatutDossier;

    public statutCommercialOperation = StatutCommercialOperation;

    /**
     * Liste des colonnes à afficher pour tous les profils utilisateur.
     */
    private colonnesTousProfils: DataTableHeader[] = [
        { text: '', sortable: false },
        { text: 'État', sortable: false },
        { text: 'Référence interne \nde l\'opération', value: 'numero' },
        { text: 'Code de \nl\'opération', value: 'code' },
        { text: 'Nature des \ntravaux', value: 'natureDesTravaux' },
        { text: 'Volume CEE \nclassique', value: 'volumeCeeClassique' },
        { text: 'Valorisation CEE \nclassique', value: 'valorisationCeeClassique' },
        { text: 'Montant CEE \nclassique', value: 'prixCeeClassique' },
        { text: 'Volume CEE \nprécarité', value: 'volumeCeePrecarite' },
        { text: 'Valorisation CEE \nprécarité', value: 'valorisationCeePrecarite' },
        { text: 'Montant CEE \nprécarité', value: 'prixCeePrecarite' },
        { text: '', sortable: false, class: 'colonneFixe' }
    ];

    /**
     * Liste des colonnes à afficher en plus s'il s'agit d'une simulation/d'un dossier AA.
     */
    private colonnesProfilsAA: DataTableHeader[] = [
        { text: 'Valorisation apporteur \nd\'affaires', value: 'remunerationApporteurAffaires' },
        { text: 'Rémunération apporteur \nd\'affaires', value: 'valorisationApporteurAffaires' }
    ];

    /**
     * Liste des colonnes à afficher en plus pour un profil utilisateur interne.
     */
    private colonnesProfilsInternesEtAA: DataTableHeader[] = [
        { text: 'Date d\'engagement \ndes travaux', value: 'dateDebutTravaux' },
        { text: 'Date d\'achèvement \ndes travaux', value: 'dateFinTravaux' },
        { text: 'Statut \ncommercial', value: 'statutCommercial', sortable: false },
    ];

    /**
     * Liste des colonnes à afficher en plus pour un profil utilisateur interne.
     */
    private colonnesProfilsInternes: DataTableHeader[] = [
        { text: 'Statut \nPNCEE', sortable: false },
        { text: 'Etat du traitement', value: 'etatTraitement', sortable: false },
    ];

    /**
     * Liste des colonnes à afficher.
     */
    public colonnes: DataTableHeader[] = cloneDeep(this.colonnesTousProfils);

    // Opération modifiée en cours d'enregistrement.
    public operationModifiee: Operation = null;

    /**
     * Dictionnaire des status disponibles.
     */
    public statuts: { [statut: string]: { icone: string, couleur: string, texte: string } } = {};

    /**
     * La colonne triée.
     */
    private currentSortBy: string = 'numero';

    /**
     * Permet la modification des valorisations pour un admin, sauf dans le cas d'une simulation AA.
     */
    public get peutModifierValorisation(): boolean {
        return this.estAdministrateur && (!this.estSimulationApporteurAffaires || this.estDossier);
    }

    /**
     * Remplissage du dictionnaire des statuts.
     */
    public mounted(): void {
        this.getValeursReferentielles(TypeValeurReferentielle.StatutPncee);
        this.getValeursReferentielles(TypeValeurReferentielle.StatutCommercial);
        this.getValeursReferentielles(TypeValeurReferentielle.EtatTraitement);
        this.statuts[StatutOperation.Complete.toString()] = { icone: "check_circle", couleur: "success", texte: libelleStatutOperation[StatutOperation.Complete.toString()] };
        this.statuts[StatutOperation.EnCours.toString()] = { icone: "check_circle", couleur: "yellow", texte: libelleStatutOperation[StatutOperation.EnCours.toString()] };
        this.statuts[StatutOperation.Partiele.toString()] = { icone: "error", couleur: "red", texte: libelleStatutOperation[StatutOperation.Partiele.toString()] };
        this.onSortOperation(null, false);
    }

    /**
     * Sauvegarde la valeur éditée par l'administrateur dans la grille et met à jour l'opération avec les nouvelles valeurs calculées au retour..
     */
    public sauvegardeValeursOperation(operation: Operation, propriete: string): void {

        if (operation.secteurId !== SecteurAsNumber.NonStandard) {
            // Met à jour la propriété modifiée pour rétablir la bonne unité (€/kwHc), en forçant l'arrondi.
            Vue.set(operation, propriete, Math.round(ConvertHelper.parseFloat(this.nouvelleValorisation) * 1000) / 1000000);
        } else {
            Vue.set(operation, propriete, this.nouvelleValorisation);
        }
        // Sauvegarde immédiatement la nouvelle valeur.
        this.operationModifiee = operation;
        new ApiService<Operation>('operation/modifierValorisation').post(operation).then((response) => {

            // Mise à jour des valeurs calculées. Comme le volume n'est pas forcément à jour côté back à cette étape, on fait la mise à jour à partir des valeurs front.
            this.operationModifiee.prixCeeClassique = this.operationModifiee.volumeCeeClassique * this.operationModifiee.valorisationCeeClassique;
            this.operationModifiee.prixCeePrecarite = this.operationModifiee.volumeCeePrecarite * this.operationModifiee.valorisationCeePrecarite;
            this.operationModifiee.valorisationApporteurAffaires = (this.operationModifiee.volumeCeeClassique + this.operationModifiee.volumeCeePrecarite)
                * this.operationModifiee.remunerationApporteurAffaires;

            // On refresh les opérations values car la valorisation est aussi stockée ici.
            if (operation.secteurId === SecteurAsNumber.NonStandard) {
                this.operationModifiee.operationValues = response.data.data.operationValues;
            }
            // Supprime le résultat du calcul en masse précédent car il est maintenant obsolète.

            this.$emit('onModificationAdmin', this.operationModifiee);

            // On affiche une notification.
            const message = { messageLevel: MessageLevelAsString.Warning, messageString: "Les valorisations ont été modifiées veuillez vérifier les paiements.", messageType: "Alert" };
            Notifications.sendSimpleMessages(message);

            this.operationModifiee = null;
        });
    }

    /**
     * Rétourne la valeur de la valorisation en €/MWhc, en forçant l'affichage au nombre de décimales voulu.
     */
    public getEurosMWhc(valeur: number): number {
        return +(valeur * 1000).toFixed(this.nbDecimal);
    }

    /**
     * Récupère les variables d'affichage du statut de l'opération.
     * @param operation Opération dont le statut doit être affiché.
     */
    public getAffichageStatut(operation: Operation) {

        // Dans le cas d'une simulation, on surcharge pour afficher l'opération comme complète même s'il manque des valeurs facultatives pour le moment.
        if (!this.estDossier && operation.statutOperationId === StatutOperation.EnCours.toString()) {
            return this.statuts[StatutOperation.Complete.toString()];
        }
        return this.statuts[operation.statutOperationId];
    }

    public getValorisationEdition(secteurId: number, valorisation: number) {
        return secteurId === SecteurAsNumber.NonStandard ? valorisation : this.getEurosMWhc(valorisation);
    }

    /**
     * Formate la valorisation donnée.
     */
    public getValorisationFormat(secteurId: number, valorisation: number): number {
        return this.$options.filters.toEurosMWhc(valorisation);
    }

    /**
     * Fourni aux composants parents les informations liés au tri du tableau.
     */
    public onSortOperation(property: string, desc: boolean): void {
        if (property !== null) {
            this.lastSortProperty = property;
        }

        this.$emit('onSortOperation', this.lastSortProperty, desc);
    }

    // S'il s'agit d'une simulation AA, ajoute les colonnes correspondantes.
    @Watch('estSimulationApporteurAffaires', { immediate: true })
    public onEstSimulationApporteurAffairesChanged() {
        if (this.estSimulationApporteurAffaires) {
            this.colonnesProfilsAA.forEach((c, index) => {
                this.colonnes.splice(this.colonnesTousProfils.length - 1 + index, 0, c);
            });
        }
    }

    // S'il s'agit d'un utilisateur interne, ajoute les colonnes correspondantes.
    @Watch('estInterne', { immediate: true })
    public onEstInterneChanged() {
        if (this.estInterne) {
            this.colonnesProfilsInternesEtAA.forEach((c) => {
                if (c.value === 'statutCommercial') {
                    this.colonnes.splice(2, 0, c);
                }
                else if (c.value === 'dateDebutTravaux') {
                    this.colonnes.splice(5, 0, c);
                }
                else if (c.value === 'dateFinTravaux') {
                    this.colonnes.splice(6, 0, c);
                }
                else {
                    this.colonnes.splice(this.colonnes.length - 1, 0, c);
                }
            });

            this.colonnesProfilsInternes.forEach((c) => {
                if (c.value === 'etatTraitement') {
                    if (this.estDossier && this.items.some(x => x.statutCommercialId === StatutCommercialOperation.EN_TRAITEMENT_ENGIE)) {
                        this.colonnes.splice(3, 0, c);
                    }
                }
                else {
                    this.colonnes.splice(this.colonnes.length - 1, 0, c);
                }
            });
        }
    }

    // S'il s'agit d'un utilisateur AA, ajoute les colonnes correspondantes.
    @Watch('estApporteurAffaires', { immediate: true })
    public onEstApporteurAffairesChanged() {
        if (this.estApporteurAffaires) {
            this.colonnesProfilsInternesEtAA.forEach((c) => {
                if (c.value === 'statutCommercial') {
                    this.colonnes.splice(2, 0, c);
                }
                else if (c.value === 'dateDebutTravaux') {
                    this.colonnes.splice(5, 0, c);
                }
                else if (c.value === 'dateFinTravaux') {
                    this.colonnes.splice(6, 0, c);
                }
                else {
                    this.colonnes.splice(this.colonnes.length - 1, 0, c);
                }
            });
        }
    }

    // Gère l'affichage de la colonne état traitement selon le statut commercial de l'opération.
    public statutCommercialChanged() {
        let etatTraitementColonne: DataTableHeader = { text: 'Etat du traitement', value: 'etatTraitement', sortable: false };
        if (!this.colonnes.some(x => x.value === 'etatTraitement') && this.items.some(x => x.statutCommercialId === StatutCommercialOperation.EN_TRAITEMENT_ENGIE)) {
            this.colonnes.splice(3, 0, etatTraitementColonne);
        }
        else if (this.colonnes.some(x => x.value === 'etatTraitement') && !this.items.some(x => x.statutCommercialId === StatutCommercialOperation.EN_TRAITEMENT_ENGIE)) {
            let indexColonneEtatTraiement = this.colonnes.findIndex(x => x.value === 'etatTraitement');
            this.colonnes.splice(indexColonneEtatTraiement, 1);
        }

        this.$emit('operationChanged')
    }

    private handleToggleSelection(selectAll: boolean) {
        this.items.forEach((o: Operation) => { o.selected = selectAll; });
    }
}
