



















































































































































































import Vue from 'vue';
import { CommonMixin } from '@/shared/mixins';
import { Prop, Component, Mixins as VueMixinsDecorator } from 'vue-property-decorator';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import CeeCurrencyTextField from '@/components/CeeCurrencyTextField.vue';
import { Paiement, TypeContribution, Document, Utilisateur } from '@/models';
import FacturationValidator from './Facturation.Validator';
import FileHelper from '@/services/FileHelper';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Document as DocumentModel } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { FileContentResult } from '@/shared/models';
import ApiHelper from '@/services/ApiHelper';
import { isBoolean } from 'lodash-es';
import { UserProfile } from '@/store/modules/auth/types';
import { EnumProfilsAsNumber, StatutPaiement, StatutPaiementExterne, FluxPaiement, TypeContribution as EnumTypeContribution, Profils } from '@/shared/enums';
import Confirm from '@/components/Confirm.vue';
import { ValeurReferentielle } from '@/shared/models';

interface IMixinInterface extends CommonMixin, FacturationValidator, Vue { }

@Component({
    name: 'FactureComponent',
    components: {
        CeeDatePicker,
        CeeCurrencyTextField,
        Confirm
    },
    mixins: [CommonMixin],
})
export default class FactureComponent extends VueMixinsDecorator<IMixinInterface>(CommonMixin, FacturationValidator)
{
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement,
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };

    private loading: boolean = false;

    // FileHelper.
    public uploadFile: FileHelper = new FileHelper();

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    @Prop()
    public paiement: Paiement;

    @Prop()
    public statutDossier: number;

    @Prop()
    public isInterne: boolean;

    @Prop({ default: 0 })
    public index: number;

    @Prop({ default: () => new Array<TypeContribution>() })
    public typeContribution: TypeContribution[];

    @Prop()
    public createur: Utilisateur;

    @Prop({ default: () => ({}) })
    public bus: Vue;

    @Prop()
    public numeroFournisseurVesta: string;

    @Prop()
    public estModifiee: boolean;

    @Prop(({ default: () => new Array<ValeurReferentielle>() }))
    public statutPaiement: ValeurReferentielle[];

    @Prop()
    public gestionFluxFinancier: boolean;

    public get estValide() {
        return this.paiement.statutPaiementId === StatutPaiement.Valide;
    }

    public get estPaye() {
        return this.statutDossier === 7;
    }

    public get isErreurPaiement() {
        return this.paiement.statutPaiementId === StatutPaiement.ErreurPaiement;
    }

    public currentUserId: number = 0;

    public estProfilFinance: boolean = false;

    public get currentUserIsCreateur(): boolean {
        return this.currentUserId === this.createur.id;
    }

    public get estCommissionApporteurAffaires(): boolean {
        return this.paiement.typeContributionId === EnumTypeContribution.COMMISSION_APPORTEUR_AFFAIRES;
    }

    public get estDossierApporteurAffaires(): boolean {
        return this.createur.profilUtilisateur === Profils.ApporteurAffaires;
    }

    public created() {
        this.chargerFacture();
        this.getUserProfile.then((profil: UserProfile) => {
            this.currentUserId = parseInt(profil.sub, 10);
            this.estProfilFinance = profil.profilId === EnumProfilsAsNumber.Finance;
        });

        this.bus.$on('on-update-dossier', () => {
            this.chargerFacture();
        });

        // Si externe on altère les libelles de referentiel de paiement pour que les externes ai un libelle plus userfriendly.
        if (!this.isInterne) {
            this.modifierReferentielStatutPaiement();
        }
    }

    public get canValidatePaiement(): boolean {
        if (this.paiement
            && this.paiement.numeroFacture && this.paiement.dateFacture
            && ((this.paiement.iban && this.paiement.bic && this.paiement.statutPaiementId === StatutPaiement.FavorableTrustpair)
                ||
                (this.numeroFournisseurVesta && this.paiement.statutPaiementId < StatutPaiement.EnCoursValidationVesta)))
        {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Chargement de la facture.
     */
    public chargerFacture(): void {
        if (this.paiement.id) {
            const url = `paiement/getFacture/${this.paiement.id}`;
            this.$http.get(url).then((response) => {
                Vue.set(this.paiement, 'facture', response.data.data);
            }).catch((error) => { throw (error); });
        }
    }

    /**
     * Enregistrement du facture.
     * @param facture
     */
    public async enregistrerFacture(item: any, file: File): Promise<Document> {
        let ret: Document = null;
        if (this.paiement.id) {
            try {
                this.loading = true;
                const base64 = await this.onFilePicked(item, file);
                const entityFromBack = await this.$http.put('paiement/enregistrerFacture', item);
                ret = entityFromBack.data.data as Document;
            } catch (ex) {
                throw new Error(ex);
            }
            finally {
                this.loading = false;
            }
        }
        Vue.set(this.paiement, "facture", ret);
        return ret;
    }

    /**
     * Récupération du fichier, vérification de la validité du fichier et conversion en Base64
     * @param file fichier récupérer de la méthode pickFile
     * @returns void
     */
    public onFilePicked(item: any, file: File): Promise<string | ArrayBuffer | null> {
        return new Promise < string | ArrayBuffer | null > ((resolve) => {
            // Ici le fichier n'existe pas ou a été supprimé
            if (file && file.name && file.size && file.type) {
                this.uploadFile = new FileHelper(file.name, file.size, file.type);
                this.uploadFile.toBase64(file).then((response: string | ArrayBuffer) => {
                    this.updatePropsDocument(item, file, response);
                    resolve(response);
                }).catch((error: any) => { throw (error); });
            } else {
                // Ici le fichier n'existe pas ou a été supprimé.
                this.updatePropsDocument(item, file, null);
                resolve(null);
            }
        });
    }

    /**
     * Met à jour les propriétés du document courant.
     * @param Le document courant
     * @param file fichier récupérer de la méthode pickFile.
     * @param fileBase64 fichier encodé en Base64.
     * @returns void
     */
    public updatePropsDocument(item: Document, file: File, fileBase64: string | ArrayBuffer | null): void {
        item.paiementId = this.paiement.id || null;
        if (item && file) {
            item.titre = file.name;
            item.contenu = this.extractBase64FromDataUrl(fileBase64);
            item.utilisateurId = this.currentUserId;
        } else if (item) {
            item.id = 0;
            item.titre = null;
            item.contenu = null;
        }
    }

    /**
     * Met à jour les propriétés du document courant.
     * https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
     * @param fileBase64 fichier encodé en Base64.
     * @returns string
     */
    public extractBase64FromDataUrl(fileBase64: string | ArrayBuffer | null): string | null {
        if (!fileBase64) {
            return null;
        }
        // Split the base64 string in data and contentType
        const block = (fileBase64 as string).split(';');
        // Get the real base64 content of the file
        const realData = block[1].split(',')[1];

        return realData;
    }

    // Supprime le contenu du document.
    public async supprimerDocument(documentId: number, document: DocumentModel, operationId: number = null): Promise<void> {
        if (!!documentId) {
            await this.$http.delete(`dossier/supprimerDocument/${documentId}`);
            this.updatePropsDocument(document, null, null);
        }
    }

    // Télécharge un document par Identifiant.
    public telechargerDocument(documentId: number): void {
        if (documentId > 0) {
            const endpoint = `/telecharger/document/${documentId}`;
            const apiExport = new ApiService < FileContentResult > (endpoint);
            apiExport.getWhereSingle('').then((reponse: any) => {
                // Vérifie qu'il y' a pas d'erreurs.
                if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                    if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                        ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                    }
                }
                //
            });
        }
    }

    /**
     * Permet d'indiquer si le document est valise.
     */
    private estUnDocumentValide(document: DocumentModel): boolean {
        return document
            && document.id
            && (document.titre)
            && (document.typeDocumentId > 0);
    }

    /**
     * Refuse un paiement en verifiant qu'un commentaire a bien été saisi.
     */
    private refuserPaiement(): void {
        const endpoint = `/paiement/refuserPaiement/${this.paiement}//`;
        // On redefinit la rule pour qu'elle ne s'execute que au refus d'un paiement.
        this.commentaireRules = [
            (v: string | any) => {
                return !!v || 'Un commentaire indiquant la raison du refus est obligatoire lors de l\'invalidation du paiement.';
            },
        ];
        
        // Le nextTick ici permet au DOM d'uptate la rule modifiée au dessus.
        this.$nextTick().then(() => {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http.put(endpoint, this.paiement).then((reponse) => {
                    if (reponse.data) {
                        this.paiement.statutPaiementId = reponse.data.data;
                    }
                }).finally(() => (this.loading = false));
            }
        });
    }

    /**
     * Valide un paiement, soit en l'envoyant via Comm+ soit directement via VESTA.
     * */
    private validerPaiement(fluxPaiement: FluxPaiement): void {
        if (this.estModifiee) {
            this.$refs.confirm.open("", "Des modifications sont en cours sur cet onglet, veuillez sauvegarder le dossier avant de continuer", {});
            return;
        }

        const endpoint = `/paiement/validerPaiement/${this.paiement.id}/${fluxPaiement}`;
        this.loading = true;

        const apiExport = new ApiService<any> (endpoint);
        this.loading = true;
        apiExport.getWhereSingle(null).then((reponse) => {
            if (reponse.data) {
                this.paiement.statutPaiementId = reponse.data.data;
            }
        }).finally(() => (this.loading = false));
    }

    /**
     * Récupère la color du statut du paiement.
     */
    private get colorStatutPaiement(): string {
        let colorByStatut: { [statutPaiementId: number]: string; } = {}
        colorByStatut[1] = "warning";
        colorByStatut[2] = "primary";
        colorByStatut[3] = "error";
        colorByStatut[4] = "success";
        colorByStatut[5] = "error";
        colorByStatut[6] = "primary";
        colorByStatut[7] = "error";
        colorByStatut[8] = "success";

        return colorByStatut[this.paiement.statutPaiementId];
    }

    /**
     * Modifie le referentiel statut paiement pour avoir des libelles plus adaptés. 
     */
    private modifierReferentielStatutPaiement(): void {
        this.statutPaiement.forEach((statutReferentiel: ValeurReferentielle) => {
            statutReferentiel.libelle = (<any>StatutPaiementExterne)[statutReferentiel.code];
        });
    }
}
