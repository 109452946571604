




















    import { Prop, Component, Mixins } from 'vue-property-decorator';
    import { DataTableHeader } from '@/shared/models';
import { GrilleMixin } from '../../../../shared/mixins/Grille.mixin';

    @Component({
        name: 'DataTableValorisationOperation',
    })
    export default class DataTableValorisationOperation extends Mixins(GrilleMixin) {
        private columns: DataTableHeader[];
        @Prop({ default: [] }) private items: Array<{
            classiqueOuPrecarite: string;
            primeInstallateur: number;
            primeAA: number;
        }>;

        /**
         * Type de client Installateur/Bénéficiaire.
         *
         * @type {number}
         * @memberof DataTableValorisationOperation
         */
        @Prop({ default : 0 })
        public typeClientId: number;

        /**
         * Le nombre de decimal avec lequel les resultats doivent s'afficher.
         *
         * @type {number}
         * @memberof DataTableValorisationOperation
         */
        @Prop({ default : 0 })
        public nbDecimal: number;

/**
     * Hook qui se déclenche lorsque le composant est crée.
     *
     */
    public created(): void {
        this.columns = [
            { text: ''},
            { text: 'Prime ' + (this.typeClientId === 1 ? 'BENEFICIAIRE' : 'INSTALLATEUR') },
            { text: 'Votre rémunération' },
        ];
    }
    }
