export class ChoixSecteurEtOperationResult {
    /**
     * Formulaire valide.
     *
     * @type {boolean}
     * @memberof ChoixSecteurEtOperationResult
     */
    public valid: boolean = false;

    /**
     * Id secteur opération.
     *
     * @type {number}
     * @memberof ChoixSecteurEtOperationResult
     */
    public secteurId?: number = null;

    /**
     * Id template opération.
     *
     * @type {number}
     * @memberof ChoixSecteurEtOperationResult
     */
    public templateOperationId?: number = null;

    /**
     * Date d'engagement des travaux.
     *
     * @type {string}
     * @memberof ChoixSecteurEtOperationResult
     */
    public dateEngagementTravaux?: string = null;

    /**
     * Opération soumise à controle.
     *
     * @type {boolean}
     * @memberof ChoixSecteurEtOperationResult
     */
    public estSoumiseAControle: boolean = false;

    /**
     * Text politique de contrôle si soumise à controle.
     *
     * @type {string}
     * @memberof ChoixSecteurEtOperationResult
     */
    public textePolitiqueControle?: string = null;

    /**
     * Créer une instance de ChoixSecteurEtOperationResult.
     * @param {boolean} valid
     * @param {number} secteurId
     * @param {number} templateOperationId
     * @param {string} dateEngagementTravaux 
     * @param {boolean} estSoumiseAControle
     * @param {string} textePolitiqueControle
     * @memberof ChoixSecteurEtOperationResult
     */
    constructor(
        valid: boolean, 
        secteurId: number, 
        templateOperationId: number, 
        dateEngagementTravaux: string, 
        estSoumiseAControle: boolean,
        textePolitiqueControle: string
    ) {
        this.valid = valid;
        this.secteurId = secteurId;
        this.templateOperationId = templateOperationId;
        this.dateEngagementTravaux = dateEngagementTravaux;
        this.estSoumiseAControle = estSoumiseAControle;
        this.textePolitiqueControle = textePolitiqueControle;
    }
}
