






































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { Societe, FormeJuridique, TypeVoie } from '@/models';
import { CompteValidationMixin } from '@/shared/mixins';
import CeeAdresse from '@/components/CeeAdresse.vue';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { ProfilsInternes } from '@/shared/enums';

@Component({
    name: 'SocieteFormSimulation',
    components: { CeeAdresse }
})
export default class SocieteFormSimulation extends Mixins(CompteValidationMixin) {
    public profil: string = '';
    public estInterne: boolean = false;
    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;
    @Prop({ default: new Societe() })
    private readonly societe!: Societe;
    @Prop({ default: () => new Array<FormeJuridique>() })
    private formeJuridique: FormeJuridique[];
    @Prop({ default: false })
    private isDisabled: boolean;

    // Pour savoir si c'est un dossier ou pas.
    @Prop({ default: false })
    private estDossier: boolean;

    // Pour savoir si on affiche le titre ou pas.
    @Prop({ default: true })
    private showTitle!: boolean;

    // Si ça concerne une société bénéficiaire SDC/Syndic.
    @Prop({ default: false})
    private estBeneficiaireSdc: boolean;

    @Prop()
    private typeVoie: TypeVoie[];

    /** SIRET au chargement des données */
    public siretBase: string = null;

    constructor() {
        super();
    }
    public created() {
        this.getUserProfile.then((userProfile) => {
            this.profil = userProfile.profilCode;
            this.estInterne = Object.keys(ProfilsInternes).some((key: string) => key === this.profil);
        });
    }

    @Watch("societe")
    public onSocieteLoaded()
    {
        this.siretBase = this.societe ? this.societe.siret : null;
    }

    /**
     * Définition de la bonne règle à appliquer sur le champ siret.
     */
    public get rulesSociete() {
        if (this.estInterne || this.societe.estSansSiret) {
            return this.siretRulesInterne;
        }
        
        // Pour les cas où le SIRET a été modifié par un autre utilisateur, 
        // et que le propriétaire du dossier n'y a pas touché.
        if (!!this.siretBase && this.societe.siret === this.siretBase)
        {
            return this.siretRulesInterne;
        }

        if (this.estBeneficiaireSdc) {
            return this.siretRulesBeneficiaireSdc;
        }

        return this.siretRules;
    }
}
