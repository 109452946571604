export class ResultatValidationEtape {
    /**
     * Etape valide.
     */
    public estValide: boolean = false;

    /**
     * Sauvegarde possible (étape pas forcément valide)
     */
    public sauvegardePossible: boolean = false;

    constructor(estValide: boolean, sauvegardePossible: boolean) {
        this.estValide = estValide;
        this.sauvegardePossible = sauvegardePossible;
    }
}