import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import template from './Documents.Template.vue';
import DocumentsValidator from './Documents.Validator';
import FileHelper from '@/services/FileHelper';
import { Operation, Document as DocumentModel } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { FileContentResult } from '@/shared/models';
import ApiHelper from '@/services/ApiHelper';
import { cloneDeep, isBoolean } from 'lodash-es';
import moment from 'moment';
import { Getter } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { TypeDeWizardComposant, composantsSimulationDossier } from '../../Composants';
import { Notifications } from '@/shared/Notifications';
import { AxiosResponse } from 'axios';
import { InformationsSimulationDossierStoreMethods } from '@/store/modules/informationsSimulationDossier/informationsSimulationDossierStore';
import { EnumProfilsAsNumber } from '@/shared/enums';
import { DocumentsOperationDossier } from '@/models/DocumentsOperationDossier.model';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { Operation as OperationModel } from '@/models';
import Confirm from '@/components/Confirm.vue';

interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, DocumentsValidator, Vue { }

@Component({
    ...template,
    name: 'Documents',
    ref: 'Documents',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
    components: {
        Confirm
    },
})

export default class Documents extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier, DocumentsValidator) {

    public titre(): string { return 'Documents'; }
    public title: string = this.titre();
    public icone(): string { return 'fas fa-file'; }

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };

    // FileHelper.
    public uploadFile: FileHelper = new FileHelper();
    // Loader pour les actions.
    public loadings: {
        Ah: boolean,
        cadreContribution: boolean,
        doc: boolean,
        docs: boolean,
        upload: boolean,
        delete: boolean,

    } = {
            Ah: false,
            cadreContribution: false,
            doc: false,
            docs: false,
            upload: false,
            delete: false,
        };

    public currentDocId: number = null;

    public currentUserId: number = 0;

    @Getter(InformationsSimulationDossierStoreMethods.CHAMPS_NON_RENSEIGNES)
    public champsNonRenseignes: Map<string, string[]>;

    @Getter(InformationsSimulationDossierStoreMethods.LOADING_ETAPES)
    public loadingEtapes: string[];

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER)
    public getUser: Promise<any>;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    public estInterne: boolean = false;

    public estUserSdc: boolean = false;

    public pendingDocuments: Array<number> = [];

    /**
     * Code 'autre document'.
     */
    private autreDocumentCode: string = 'AUTRE_DOCUMENT';

    public created() {
        this.bus.$on('on-modification-nombre-operations', (operations: OperationModel[]) => {
            this.onOperationsForDossierChanged(operations);
        });

        this.chargerDocuments();
        this.recupererDocumentOperations();
        this.getUserProfile.then((profil) => {
            this.estInterne = profil.isInterne;
            this.estUserSdc = profil.profilId === EnumProfilsAsNumber.SyndicSDC;
            this.currentUserId = parseInt(profil.sub, 10);
        });
    }

    /**
     * Ces quelques étapes doivent être validées avant de pouvoir générer l'AH.
     */
    private etapesPrealables = [
        TypeDeWizardComposant.SiteDesTravaux,
        TypeDeWizardComposant.Operation,
        TypeDeWizardComposant.DonneesBeneficiaire,
        TypeDeWizardComposant.DonneesInstallateur
    ];
    public get etapesPrealablesCompletes(): WizardEtapeComponentsDossier[] {
        return this.etapesCompletes.filter((etape) => this.etapesPrealables.includes((<any>TypeDeWizardComposant)[etape.$options.name]));
    }

    /**
     * Ces quelques étapes doivent être validées avant de pouvoir générer l'AH.
     */
    private etapesPrealablesCadreContribution = [
        TypeDeWizardComposant.SiteDesTravaux,
        TypeDeWizardComposant.DonneesBeneficiaire,
        TypeDeWizardComposant.DonneesInstallateur
    ];
    public get etapesPrealablesCompletesCadreContribution(): WizardEtapeComponentsDossier[] {
        return this.etapesCompletes.filter((etape) => this.etapesPrealablesCadreContribution.includes((<any>TypeDeWizardComposant)[etape.$options.name]));
    }

    /**
     * Obtient la liste des étapes préalables n'ayant pas été complétées ainsi que les champs correspondants.
     * Les noms d'étapes sont retournés sous la forme "<li>Libellé de l'étape</li>".
     * Les noms des champs sont retournés sous la forme "<li>Message erreur champ"</li>".
     */
    public nomsEtapesPrealablesEtChampsIncomplets(etapesPrealables: TypeDeWizardComposant[]): string {

        const etapes = etapesPrealables.filter((e) => !this.etapesCompletes.map((etape) => (<any>TypeDeWizardComposant)[etape.$options.name]).includes(e));
        let etapesEtChamps = '';
        etapes.forEach((etape) => {
            etapesEtChamps += '<li class="margin-top-5">' + composantsSimulationDossier.filter((c) => c.nom === etape)[0].libelle;
            // A reprendre post MEP avec un template...
            if (this.champsNonRenseignes.get(etape)) {
                const champsOperationsNonRenseignes = this.champsNonRenseignes.get(etape).map((champ) =>
                    '<li>' + champ + '</li>').join('');

                etapesEtChamps += '<ul>' + champsOperationsNonRenseignes + '</ul>';
            }
            etapesEtChamps += '</li>';
        });

        return etapesEtChamps;
    }

    /**
     * Vérifie que toutes les étapes préalables sont validées.
     */
    public get estAHDisponible(): boolean {
        return this.etapesPrealablesCompletes.length === this.etapesPrealables.length;
    }

    /**
     * Vérifie que toutes les étapes préalables sont validées.
     */
    public get canDownloadCadreContribution(): boolean {
        return this.etapesPrealablesCompletesCadreContribution.length === this.etapesPrealablesCadreContribution.length;
    }

    /**
     * Affiche ou non le bouton pour télécharger le cadre de contribution
     */
    public get displayButtonCadreContribution(): boolean {
        return this.dossier.hasCadreContribution && this.dossier.estOperationSecteurResidentielExiste && !this.estUserSdc;
    }

    /**
     * Récupération du fichier, vérification de la validité du fichier et conversion en Base64
     * @param file fichier récupérer de la méthode pickFile
     * @returns void
     */
    public onFilePicked(item: any, file: File): Promise<string | ArrayBuffer | null> {
        return new Promise<string | ArrayBuffer | null>((resolve) => {
            // Ici le fichier n'existe pas ou a été supprimé
            if (file && file.name && file.size && file.type) {
                this.uploadFile = new FileHelper(file.name, file.size, file.type);
                this.uploadFile.toBase64(file).then((response) => {
                    this.updatePropsDocument(item, file, response);
                    resolve(response);
                }).catch((error) => { throw (error); });
            } else {
                // Ici le fichier n'existe pas ou a été supprimé.
                this.updatePropsDocument(item, file, null);
                resolve(null);
            }
        });
    }
    /**
     * Met à jour les propriétés du document courant.
     * @param Le document courant
     * @param file fichier récupérer de la méthode pickFile.
     * @param fileBase64 fichier encodé en Base64.
     * @returns void
     */
    public updatePropsDocument(item: DocumentModel, file: File, fileBase64: string | ArrayBuffer | null): void {
        item.simulationDossierId = this.dossier.id || null;
        if (item && file) {
            item.titre = file.name;
            item.contenu = this.extractBase64FromDataUrl(fileBase64);
        } else if (item) {
            item.id = 0;
            item.titre = null;
            item.contenu = null;
        }
    }

    /**
     * Met à jour les propriétés du document courant.
     * https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
     * @param fileBase64 fichier encodé en Base64.
     * @returns string
     */
    public extractBase64FromDataUrl(fileBase64: string | ArrayBuffer | null): string | null {
        if (!fileBase64) {
            return null;
        }
        // Split the base64 string in data and contentType
        const block = (fileBase64 as string).split(';');
        // Get the real base64 content of the file
        const realData = block[1].split(',')[1];

        return realData;
    }

    /**
     * Validation du formulaire de l'étape : tout va bien tant qu'on n'a pas de doublon.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        return Promise.resolve(new ResultatValidationEtape(true, true));
    }

    /**
     * Détermine si l'étape est complète ou non.
     */
    public get estComplete(): boolean {

        // Liste des documents incomplets
        const documentsIncomplets = this.documents.filter(d => !this.estUnDocumentComplet(d));

        return this.estValide
            && this.dateTelechargementAH
            && documentsIncomplets.length === 0;
    }

    // Exporte les données du modèle.
    public exporterEtapeModel(): { model: DocumentModel[], meta: any } {
        return {
            model: null,
            meta: {
                // simulationDossierId: this.model.simulationDossierId as number,
                param: null,
            }
        };
    }

    // Colonnes des documents
    public headers: Array<{ text: string, sortable: boolean, width: string }> = [
        {
            text: 'Type de document',
            sortable: false,
            width: '30%',
        },
        {
            text: 'Nom du fichier',
            sortable: false,
            width: '50%',
        },
        {
            text: '',
            sortable: false,
            width: '20%',
        },
    ];

    // Liste desDocuments par profil.
    public listeDocumentParProfil: DocumentModel[] = [];

    // Liste desDocuments par opération.
    public listeDocumentParOperation: DocumentsOperationDossier[] = [];

    /**
     * Liste de tous les documents du dossier.
     */
    public get documents(): DocumentModel[] {
        return [
            ...this.listeDocumentParProfil,
            ...this.listeDocumentParOperation.flatMap((item) => item.documents)
        ];
    }

    /**
     * Permet de filter les documents à envoyer.
     */
    public get documentsValides(): DocumentModel[] {
        return this.documents.filter((item) => this.estUnDocumentValide(item));
    }

    /**
     * Date de téléchargement de l'attestation sur l'honneur si elle a été téléchargée.
     */
    public get dateTelechargementAH(): string {
        return this.dossier
            ? this.dossier.dateTelechargementAH
            : null;
    }

    /**
     * Watcher pour faire en sorte que les opération du dossiers changes.
     */
    public async onOperationsForDossierChanged(newItems: Operation[]) {
        // S'il y a pas de nouveau élément et que la liste n'est pas vide alors on ne fait rien.
        if (newItems && newItems.length >= 1) {
            const items = (newItems && newItems.length >= 1) ? newItems : (new Array<Operation>());
            if (this.simulationDossierId) {
                this.recupererDocumentOperations();
            } else {
                this.listeDocumentParOperation = items.map((itemOperation) => {
                    // Retourne les données.
                    return {
                        code: itemOperation.code,
                        arreteLibelle: itemOperation.arreteLibelle,
                        documents: itemOperation.typeDocumentTemplateOperations.map((itemDoc) => {
                            return this.construireDocuments(itemOperation.id, itemDoc);
                        }),
                    };
                });
            }
        }
    }

    /**
     * Construire document à partir des TypeDocument.
     * @param itemOperation L'opération.
     * @param DocumentModel.
     * @param fileBase64 fichier encodé en Base64.
     * @returns DocumentModel
     */
    public construireDocuments(operationId: number, itemTypeDoc: DocumentModel): DocumentModel {
        const doc = new DocumentModel();
        doc.operationId = operationId;
        doc.simulationDossierId = this.model.simulationDossierId || null;
        doc.typeDocumentCode = itemTypeDoc.typeDocumentCode;
        doc.typeDocumentId = itemTypeDoc.typeDocumentId;
        doc.typeDocumentLibelle = itemTypeDoc.typeDocumentLibelle;
        doc.utilisateurId = this.dossier.utilisateurCreationId || (this.currentUserId || 0);
        // Retourne le document.
        return doc;
    }

    // Télécharge un document par Identifiant.
    public telechargerDocument(documentId: number): void {

        if (documentId > 0) {
            const endpoint = `/telecharger/document/${documentId}`;

            const apiExport = new ApiService<FileContentResult>(endpoint);
            this.loadings.doc = true;
            this.currentDocId = documentId;
            apiExport.getWhereSingle('').then((reponse: any) => {
                // Vérifie qu'il y' a pas d'erreurs.
                if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                    if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                        ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                    }
                }
                //
            }).finally(() => {
                this.loadings.doc = false;
                this.currentDocId = null;
            });
        }
    }

    public telechargerAllDocument() {
        if (this.simulationDossierId > 0) {
            const endpoint = `/telecharger/allDocument/${this.simulationDossierId}`;

            const apiExport = new ApiService<FileContentResult>(endpoint);
            this.loadings.docs = true;
            apiExport.getWhereSingle('').then((reponse: any) => {
                // Vérifie qu'il y' a pas d'erreurs.
                if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                    if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                        ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                    }
                }
                //
            }).finally(() => {
                this.loadings.docs = false;
            });
        }
    }

    // Supprime le contenu du document.
    public async supprimerDocument(documentId: number, document: DocumentModel, operationId: number = null): Promise<void> {
        this.$refs.confirm.open('Supprimer le document',
            'Souhaitez-vous supprimer le document ?',
            { color: 'blue', width: 500, zIndex: 200 }).then((responseConfirmation) => {
                if (responseConfirmation) {
                    if (!!documentId) {
                        this.$http.delete(`dossier/supprimerDocument/${documentId}`);

                        if (document.typeDocumentCode === this.autreDocumentCode) {
                            if (operationId) {
                                const documentsOperation = this.listeDocumentParOperation.find((x: any) => x.operationId === operationId);
                                if (documentsOperation && documentsOperation.documents.length > 0) {
                                    const index = documentsOperation.documents.findIndex((doc: DocumentModel) => doc.id === document.id);
                                    documentsOperation.documents.splice(index, 1);
                                }
                            } else {
                                const index = this.listeDocumentParProfil.findIndex((doc: DocumentModel) => doc.id === document.id);
                                this.listeDocumentParProfil.splice(index, 1);
                            }
                        }
                        this.updatePropsDocument(document, null, null);
                        if (!this.simulationDossierId) {
                            this.pendingDocuments = [...this.pendingDocuments.filter((d) => d !== documentId)];
                            this.bus.$emit('on-trigger-pending-documents', this.pendingDocuments);
                        }
                    }
                }
            });
    }

    // Télécharge une AH par Identifiant du dossier.
    public telechargerAttestationHonneur(): void {
        // Vérifie que les étapes préalables ont bien été remplies (sauf si on est connecté sur un compte interne).
        if (!this.estAHDisponible && !this.isInterne) {
            this.estEnErreur = true;
            this.erreursSpecifiques = [`Les étapes suivantes doivent être complétées avant de pouvoir télécharger l'attestation sur l'honneur :
                <ul>
                    ${this.nomsEtapesPrealablesEtChampsIncomplets(this.etapesPrealables)}
                </ul>`
            ];
        }
        // S'il y a des changements en cours sur les étapes préalables, force l'enregistrement avant de pouvoir télécharger l'AH.
        else if (this.etapesPrealablesCompletes.filter((etape) => etape.estModifiee && etape.title !== 'Opérations').length) {
            this.estEnErreur = true;
            this.erreursSpecifiques = ['Veuillez enregistrer le dossier avant de télécharger l\'attestation sur l\'honneur.'];
        }
        else {
            this.estEnErreur = false;
            this.erreursSpecifiques = [];
            const simulationDossierId = this.model.simulationDossierId;
            if (simulationDossierId > 0 && this.dossier && this.dossier.id > 0) {
                const endpoint = `/attestationHonneur/telechargerTemplateAHParIdDossier/${simulationDossierId}`;

                const apiExport = new ApiService<FileContentResult>(endpoint);
                this.loadings.Ah = true;
                apiExport.getWhereSingle('')
                    .then((reponse: any) => {
                        // Vérifie qu'il y' a pas d'erreurs.
                        if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                            if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                                ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                                // Met à jour la dateTelechargementAH.
                                this.dossier.dateTelechargementAH = moment().format();
                            }
                        }
                        //
                    }).catch((errorResponse: AxiosResponse<any>) => {
                        // Met à jour la dateTelechargementAH.
                        this.dossier.dateTelechargementAH = null;
                        Notifications.handledNotifications(errorResponse);

                    }).finally(() => this.loadings.Ah = false);
            }
        }
    }

    // Télécharge Cadre Contribution par Identifiant du dossier.
    public telechargerCadreContribution(): void {

        if (!this.canDownloadCadreContribution) {
            this.estEnErreur = true;
            this.erreursSpecifiques = [`Les étapes suivantes doivent être complétées avant de pouvoir télécharger le cadre de contribution :
                <ul>
                    ${this.nomsEtapesPrealablesEtChampsIncomplets(this.etapesPrealablesCadreContribution)}
                </ul>`
            ];
        } else if (this.etapesPrealablesCompletes.filter((etape) => etape.estModifiee).length) {
            this.estEnErreur = true;
            this.erreursSpecifiques = ['Veuillez enregistrer le dossier avant de télécharger le cadre de contribution.'];
        } else {
            const simulationDossierId = this.model.simulationDossierId;
            if (simulationDossierId > 0 && this.dossier && this.dossier.id > 0) {
                const endpoint = `/telecharger/cadreContribution/${simulationDossierId}`;

                const apiExport = new ApiService<FileContentResult>(endpoint);
                this.loadings.cadreContribution = true;
                apiExport.getWhereSingle('').then((reponse: any) => {
                    // Vérifie qu'il y' a pas d'erreurs.
                    if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                        if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                            ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                        }
                    }
                    //
                }).finally(() => this.loadings.cadreContribution = false);
            }
        }
    }

    /**
     * Permet d'indiquer si le document est valise.
     */
    public estUnDocumentValide(document: DocumentModel): boolean {
        return document
            && document.id
            && (document.titre)
            && (document.typeDocumentId > 0);
    }

    public async enregistrerDocumentUnitaire(item: any, file: File, operationId: number = null): Promise<DocumentModel> {
        try {
            this.loadings.upload = true;
            const base64 = await this.onFilePicked(item, file);
            const entityFromBack = await this.$http.put('dossier/enregistrerDocument', item);
            if (this.estAutreDocument(item)) {
                let documents = this.listeDocumentParProfil;
                if (item.operationId) {
                    const documentsOperation = this.listeDocumentParOperation.find((x: any) => x.operationId === operationId);
                    if (documentsOperation) {
                        documents = documentsOperation.documents;
                        const nouveauDoc = this.genererAutreDocument(documents);
                        documents.push(nouveauDoc);
                    }
                } else {
                    const nouveauDoc = this.genererAutreDocument(documents);
                    this.listeDocumentParProfil.push(nouveauDoc);
                }
            }

            item.id = entityFromBack.data.data.id;

            if (!this.simulationDossierId) {
                this.pendingDocuments.push(item.id);
                this.bus.$emit('on-trigger-pending-documents', this.pendingDocuments);
            }
            return entityFromBack.data.data as DocumentModel;
        } catch (ex) {
            throw new Error(ex);
        }
        finally {
            this.loadings.upload = false;
        }
    }

    /**
     * Chargement des documents.
     */
    public chargerDocuments(): void {
        let url = `dossier/getDocumentProfil/`;
        if (this.model.simulationDossierId) {
            url = `dossier/getDocumentProfil/${this.model.simulationDossierId}`;
        }
        this.$http.get(url).then((response) => {
            this.listeDocumentParProfil = response.data.data as DocumentModel[];
            const nouveauDoc = this.genererAutreDocument(this.listeDocumentParProfil);
            if (nouveauDoc) {
                this.listeDocumentParProfil.push(nouveauDoc);
            }
        });
    }

    /**
     * Surcharge rafraichissement étape pour recharger les documents.
     * @param forcerRefresh Force refresh
     */
    public rafraichirEtape(forcerRefresh: true): void {
        if (!this.modeleDonneesInitial || forcerRefresh) {
            this.chargerDocuments();

            // Copie la valeur originale du modèle.
            this.modeleDonneesInitial = cloneDeep(this.modeleDonnees);

            // Effectue une validation lors du changement de modèle.
            if (this.estDossier && this.$refs.form) {
                this.$nextTick().then(() => {
                    this.validerEtape(true);
                });
            }
        }
    }

    /**
     * Permet d'indiquer si le document est valide si requis.
     */
    private estUnDocumentComplet(document: DocumentModel): boolean {

        if (!document) {
            return false;
        }

        return this.estUnDocumentValide(document) || (DocumentModel.codeDocumentsFacultatifs.indexOf(document.typeDocumentCode) >= 0 && !document.contenu && !document.titre);
    }

    /**
     * Loader pour l'attestation sur l'honneur.
     * @returns Vrai si action ou validation d'une étape en cours.
     */
    private get loadingAh(): boolean {
        return this.loadingActions || !!this.loadingEtapes.length;
    }

    /**
     * Renvoie vrai si le document est de type 'autre document'.
     * @param document
     */
    private estAutreDocument(document: DocumentModel): boolean {
        return !!document && document.typeDocumentCode === this.autreDocumentCode;
    }

    /**
     * Ajout d'un nouveau document 'autre document'.
     * @param documents
     */
    private genererAutreDocument(documents: DocumentModel[]): DocumentModel {
        const autreDocumentVierge = documents.find((doc: DocumentModel) => this.estAutreDocument(doc) && !doc.titre);
        if (!autreDocumentVierge) {
            const autreDocument = documents.find((doc: DocumentModel) => this.estAutreDocument(doc));
            if (autreDocument) {
                const nouveauDoc = this.construireDocuments(autreDocument.operationId, autreDocument);
                return nouveauDoc;
            }
        }

        return null;
    }

    /**
     * Mise à jour du nom.
     * @param document
     */
    private updateNomTypeDocumentUtilisateur(document: DocumentModel): void {
        if (!!document.id && document.nomTypeDocumentUtilisateur !== this.nomTypeDocumentUtilisateurSelected) {
            this.$http.put('dossier/enregistrerDocument', document);
        }
    }

    private recupererDocumentOperations(): void {
        if (this.simulationDossierId) {
            this.$http.get(`dossier/getDocumentsOperation/${this.simulationDossierId}`).then((reponse) => {
                this.listeDocumentParOperation = reponse.data.data;
                this.listeDocumentParOperation.forEach((operation: any) => {
                    if (operation.documents && operation.documents.length > 0) {
                        const nouveauDoc = this.genererAutreDocument(operation.documents);
                        if (nouveauDoc) {
                            operation.documents.push(nouveauDoc);
                        }
                    }
                });
            });
        }
    }
}
