
































































import { Prop , Component, Mixins} from 'vue-property-decorator';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import CeeCurrencyTextField from '@/components/CeeCurrencyTextField.vue';
import { Paiement, TypeContribution } from '@/models';
import DonneesEngieValidator from './DonneesEngie.Validator';

@Component({
    name: 'PaiementComponent',
    components: {
        CeeDatePicker,
        CeeCurrencyTextField,
    }
})
export default class PaiementComponent extends Mixins(DonneesEngieValidator){

    @Prop({default: new Paiement()})
    public paiement: Paiement;

    @Prop({default: 0})
    public index: number;

    @Prop({default: () => new Array<TypeContribution>()}) 
    public typeContribution: TypeContribution[];

    removeSpacesOnPaste(event: ClipboardEvent) {
      let text = event.clipboardData.getData("text");
      event.preventDefault();
      this.paiement.numeroPaiement = text.replace(/\s+/g, '');
    }
}
