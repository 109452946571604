import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationMixin } from '@/shared/mixins';

/**
 * Mixin regroupant les différentes méthodes utilisées pour valider le composant opération.
 */
@Component
export default class ChoixBeneficiareOuInstallateurValidator extends VueMixinsDecorator<ValidationMixin>(ValidationMixin)  {
    public clientrepresentesRule = [
        (v: number | any) => this.required(v) && v > 0 || 'Le choix du type de client representé est obligatoire',
    ];
    public societeRule = [
        (v: number | any) => this.required(v) && v > 0 || 'Le choix de la société est obligatoire',
    ];
}
