import Vue from 'vue';
import { Component, Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { CommonMixin } from '@/shared/mixins';
import WizardEtapeComponentsDossier from '@/components/Wizard/@Abstracts/WizardEtapeComponentsDossier';
import template from './HistoriqueDossier.Template.vue';
import { HistoriqueInfosDossier } from './models/HistoriqueInfosDossier.model';
import { ApiService } from '@/services/base/ApiService';
import { Watch } from 'vue-property-decorator';
import ApiHelper from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';
import { Getter } from 'vuex-class';
import { UserProfile } from '@/store/modules/auth/types';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { StatutDossier } from '@/shared/enums/StatutDossier.enum';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';
import { Historique } from '@/models/Historique.model';
import { DataTableHeader } from '@/shared/models';

interface IMixinInterface extends CommonMixin, WizardEtapeComponentsDossier, Vue { }
@Component({
    ...template,
    name: 'HistoriqueDossier',
    ref: 'HistoriqueDossier',
    mixins: [template, CommonMixin, WizardEtapeComponentsDossier],
})
export default class HistoriqueDossier extends VueMixinsDecorator<IMixinInterface>(CommonMixin, WizardEtapeComponentsDossier) {

    public titre(): string { return 'Historique du dossier'; }
    public icone(): string { return 'fas fa-history'; }

    /**
     * Infos libellé et valeurs du dossier.
     *
     * @memberof HistoriqueDossier
     */
    public itemsDossier: Array<{
        libelle: string,
        value: string,
    }> = [];

    /**
     * Détails des modifications.
     *
     * @memberof HistoriqueDossier
     */
    public details: Array<Historique> = [];

    private colonnes: DataTableHeader[] = [
        { text: 'Date', value: '' },
        { text: 'Modificateur', value: '' },
        { text: 'Champs', value: '' },
        { text: 'Avant', value: '' },
        { text: 'Après', value: '' },
    ];

    /**
     * Infos libellé et valeurs du prix.
     *
     * @memberof HistoriqueDossier
     */
    public itemsPrix: Array<{
        libelle: string,
        value: string,
    }> = [];

    /**
     * Exporter le modèle (pas oligatoire d'avoir model/meta/param sur ce composant)
     *
     * @returns {{ model: any, meta: any }}
     * @memberof HistoriqueDossier
     */
    public exporterEtapeModel(): { model: any, meta: any } {
        return {
            model: null, meta: {
                param: null
            }
        };
    }

    /**
     * Validation du formulaire de l'étape.
     */
    public validerForm(): Promise<ResultatValidationEtape> {
        return Promise.resolve(new ResultatValidationEtape(true, true));
    }

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    /**
     * Le profil de l'utilisateur courant est-il interne
     * @type {boolean}
     * @memberof HistoriqueDossier
     */
    private estInterne: boolean = false;

    /**
     * Statut dossier.
     *
     * @type {StatutDossier}
     * @memberof HistoriqueDossier
     */
    public statutDossier: StatutDossier = StatutDossier.NONE;

    // Loading
    public loadingEnvoie: boolean = false;
    public loadingValidation: boolean = false;



    /**
     * Life cycle component mounted.
     *
     * @memberof HistoriqueDossier
     */
    public mounted() {
        this.getUserProfile.then((profile: UserProfile) => {
            this.estInterne = profile && profile.isInterne;
        });
        this.fetchInformationsDossier();
    }

    public get estDossierValide() {
        return !!this.model.simulationDossierId &&
            (this.statutDossier === StatutDossier.FINALISE_ENGIE
                || this.statutDossier === StatutDossier.EN_TRAITEMENT_ENGIE
                || this.statutDossier === StatutDossier.DEPOSE_PNCEE
                || this.statutDossier === StatutDossier.TRAITE_PNCEE
            );
    }

    public get estDossierEnvoye() {
        return !!this.model.simulationDossierId &&
            (this.statutDossier === StatutDossier.ENVOYE_ENGIE
                || this.statutDossier === StatutDossier.FINALISE_ENGIE
                || this.statutDossier === StatutDossier.EN_TRAITEMENT_ENGIE
                || this.statutDossier === StatutDossier.DEPOSE_PNCEE
                || this.statutDossier === StatutDossier.TRAITE_PNCEE
            );
    }

    public fetchInformationsDossier() {
        if (!!this.model.simulationDossierId) {
            this.recupererInfosHistoriqueDossier().then((historique) => {
                this.statutDossier = historique.statutDossierId;
                this.details = historique.historiques;
                this.itemsDossier = [
                    { libelle: 'Créateur du dossier :', value: historique.utilisateurCreation.prenomNom },
                    { libelle: 'Date de création du dossier :', value: this.$options.filters['dateFormat'](historique.dateCreation) },
                    {
                        libelle: 'Date d\'envoi du dossier à ENGIE :', value: historique.dateEnvoiDossierAEngie ?
                            this.$options.filters['dateFormat'](historique.dateEnvoiDossierAEngie) : '-'
                    },
                    {
                        libelle: 'Date de prise en charge par ENGIE :', value: historique.datePriseEnChargeParEngie ?
                            this.$options.filters['dateFormat'](historique.datePriseEnChargeParEngie) : '-'
                    },
                    { libelle: 'Nom du Gestionnaire ENGIE :', value: !!historique.gestionnaireEngie ? historique.gestionnaireEngie.prenomNom : '-' },
                    { libelle: 'Modificateur du dossier :', value: historique.utilisateurModification.prenomNom },
                    { libelle: 'Date de modification du dossier :', value: this.$options.filters['dateFormat'](historique.dateDerniereModification) },
                    {
                        libelle: 'Date de validation du dossier :', value: historique.dateFinalisationBO ?
                            this.$options.filters['dateFormat'](historique.dateFinalisationBO) : '-'
                    },
                    {
                        libelle: 'Date de téléchargement de l\'AH :', value: historique.dateTelechargementAH ?
                            this.$options.filters['dateFormat'](historique.dateTelechargementAH) : '-'
                    },
                ];

                // Affiche les informations de prix si l'on en a.
                if (historique) {
                    this.itemsPrix = [
                        {
                            libelle: 'Dossier créé au prix de :', value:
                                `Classique : ${this.toEurosMWh(historique.valorisationClassiqueCreation)} <small>€/MWhc</small>,
                                 Précarité: ${this.toEurosMWh(historique.valorisationPrecariteCreation)} <small>€/MWhc</small>`
                        },
                        {
                            libelle: 'Prix à appliquer au dossier :', value:
                                `Classique : ${historique.operations.length ? this.toEurosMWh(historique.operations[0].valorisationCeeClassique) : 0} <small>€/MWhc</small>,
                                 Précarité: ${historique.operations.length ? this.toEurosMWh(historique.operations[0].valorisationCeePrecarite) : 0} <small>€/MWhc</small>`
                        },
                        // À mettre en place pour l'évolution de l'historique du changement prix.
                        // {libelle: 'Prix modifié le XX/XX/XXXX:', value: 'xx euro'},
                    ];

                    if (historique.remunerationApporteurAffaires) {
                        this.itemsPrix.push({
                            libelle: 'Rémunération apporteur d\'affaires :',
                            value: `${this.toEurosMWh(historique.remunerationApporteurAffaires)} <small>€/MWhc</small>`
                        });
                    }
                }
            });
        }
    }

    /**
     * Watcher après la création du dossier afin de mettre a jour l'historique automatiquement sans avoir à refresh la page.
     *
     * @memberof HistoriqueDossier
     */
    @Watch('model.simulationDossierId')
    public onDossierCreated() {
        this.fetchInformationsDossier();
    }

    /**
     * Récupérer les informations d'historique du dossier.
     *
     * @returns {Promise<HistoriqueInfosDossier>}
     * @memberof HistoriqueDossier
     */
    public recupererInfosHistoriqueDossier(): Promise<HistoriqueInfosDossier> {
        return new Promise<HistoriqueInfosDossier>((resolve, reject) => {
            const simulationService = new ApiService<HistoriqueInfosDossier>('dossier/recupererInfosHistoriqueDossier');
            simulationService.get(this.model.simulationDossierId)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                });
        });
    }

    public telechargerRecapitulatifDossier(typeGeneration: number) {
        return new Promise<FileContentResult>((resolve, reject) => {
            const simulationService = new ApiService<FileContentResult>('dossier/telechargerRecapitulatif');
            simulationService.getWhereSingle(`dossierId=${this.model.simulationDossierId}&typeGeneration=${typeGeneration}`)
                .then((response) => {
                    ApiHelper.createAndDownloadBlobFile(response.data);
                    resolve(response.data);
                })
                .catch((error: { response: Error; }) => {
                    reject(error.response);
                })
                .finally(() => {
                    this.loadingEnvoie = false;
                    this.loadingValidation = false;
                });
        });
    }

    /**
     * Convertit une valeur en €/kWhc en €/MWhc avec le bon affichage.
     * @param valeur
     */
    protected toEurosMWh(valeur: number) {
        return this.$options.filters.numberFormat(this.$options.filters.toEurosMWhc(valeur));
    }
}

